/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './services/auth.guard';

import { LoadingComponent } from './pages/loading/loading.component';
import { MainComponent } from './pages/main/main.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { CustomerLookupComponent } from './pages/customer-lookup/customer-lookup.component';
import { CustomerConfirmComponent } from './pages/customer-confirm/customer-confirm.component';
import { ProgramTypesComponent } from './pages/program-types/program-types.component';
import { QualifyComponent } from './pages/qualify/qualify.component';
import { EnrollSuccessComponent } from './pages/enroll-success/enroll-success.component';
import { ScheduleListComponent } from './pages/schedule-list/schedule-list.component';
import { EnrollListComponent } from './pages/enroll-list/enroll-list.component';
import { UtilityErrorComponent } from './pages/utility-error/utility-error.component';
import { ReScheduleComponent } from './pages/re-schedule/re-schedule.component';
import { EnrollcustomerComponent } from './pages/enrollcustomer/enrollcustomer.component';
//import { WorkorderGetSaveComponent } from './pages/workorder-getsave/workorder-getsave.component';
import { AuditComponent } from './pages/audit/audit.component';
import { AuditReportComponent } from './pages/audit-report/audit-report.component';
import { OnlineAppComponent } from './pages/rebates/online-app/online-app.component';
import { UploadreceiptsComponent } from './pages/rebates/uploadreceipts/uploadreceipts.component';
import { ReceiptsComponent } from './pages/rebates/receipts/receipts.component';

const routes: Routes = [
    { path: 'loading', component: LoadingComponent,data : {title:'Loading'} },
    { path: 'utilityerror', component: UtilityErrorComponent, data : {title:'Utility Error'} },

    // need utility set. Checked by AuthGuard
    //{ path: 'main', component: MainComponent, canActivate: [AuthGuard] },
    { path: 'customerlookup', component: CustomerLookupComponent, canActivate: [AuthGuard] ,data : {title:'Customer Look Up'}},
    { path: 'customerconfirm', component: CustomerConfirmComponent, canActivate: [AuthGuard],data :{ title:'Customer Confirmation'} },
    { path: 'programtypes', component: ProgramTypesComponent, canActivate: [AuthGuard],data :{ title:'Program Types'} },
    { path: 'scheduling', component: ScheduleComponent, canActivate: [AuthGuard], data :{ title:'Scheduling'} },
    { path: 'qualify', component: QualifyComponent, canActivate: [AuthGuard] ,data :{ title:'Qualification'} } ,
    { path: 'success', component: EnrollSuccessComponent, canActivate: [AuthGuard] ,data :{ title:'Enrollment Successful'}},
    { path: 'appointments', component: ScheduleListComponent, canActivate: [AuthGuard],data :{ title:'Schedule List'} },
    { path: 'enrollments', component: EnrollListComponent, canActivate: [AuthGuard],data :{ title:'Enrollment List'}},
    { path: 'reschedule', component: ReScheduleComponent, canActivate: [AuthGuard] ,data :{ title:'Reschedule'}},
    { path: 'audit', component: AuditComponent, canActivate: [AuthGuard] ,data :{ title:'Home Energy Assessment'}},
    { path: 'auditreport', component: AuditReportComponent, canActivate: [AuthGuard] ,data :{ title:'Home Energy Assessment'}},
    { path: 'onlineapp',component:OnlineAppComponent,canActivate: [AuthGuard] ,data : {title:'Online Rebate App'}},
    { path: 'receipts',component:ReceiptsComponent,canActivate: [AuthGuard] ,data : {title:'Upload Receipts'}},

    //{ path: 'workorderGetSave', component: WorkorderGetSaveComponent, canActivate: [AuthGuard]},
    // Notice no leading slash. "loading" not "/loading".  This is to preserve the query params
    { path: 'enrollcustomer', component: EnrollcustomerComponent, canActivate: [AuthGuard] ,data :{ title:'Enroll New Customer'}},
    { path: '', redirectTo: 'loading', pathMatch: 'full',data : {title:'Loading'} },
    { path: '**', redirectTo: 'customerlookup',data : {title:'Customer Look Up'} }    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
