/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

import { DataTransferService } from 'src/app/services/data-transfer.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilityService } from '../../../services/utility.service';
import {Input} from '@angular/core';
import { RebateService } from 'src/app/services/rebate.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-measure',
  templateUrl: './measure.component.html',
  styleUrls: ['./measure.component.scss'],
  //encapsulation:ViewEncapsulation.None
})
export class MeasureComponent implements OnInit {

  constructor( public utilityService: UtilityService, private datePipe: DatePipe,
    public dataTransfer:DataTransferService, private spinner: NgxSpinnerService,
    private rebate:RebateService) { 
      this.spinner.show();  
     }

  expandedItem: any;
  subGroup1:any;
  WorkorderId:any;
  response:any;
  addMesureResponse:any;
  disableMainSave:boolean;
  isSaveEnabled:boolean;  
  @Input() data: any;

ngOnInit(): void { 
  this.WorkorderId =this.data.WorkorderId ;// localStorage.getItem('WorkorderId');   
  console.log('getMeasureInfo', this.WorkorderId);
  this.getData();  
}

 getData(): void{
  this.isSaveEnabled=false;
  this.spinner.show();  
  this.rebate.getMeasureInfo(this.WorkorderId).subscribe((res:any)=>{   
    this.response=res;     
    this.countMeasure(); 
    this.spinner.hide();
  },
  err => {
    this.spinner.hide();
   //console.log("ERRRRR");
  });   
}
  //Validation with numbers and decimal values
  onlyNumbersWithDecimal(event) {
      var pattern = /^[0-9]+.[0-9]{2}/g;
      var charCode = (event.which) ? event.which : event.keyCode;
      if (charCode != 46 && charCode > 31
          && (charCode < 48 || charCode > 57)) {
          event.preventDefault();
          return false;
      }

      return true;
  }

addMesureInfo(tile,lineitemmaster,i): void{
  this.spinner.show();  
   this.rebate.getAddMeasureInfo(this.WorkorderId, lineitemmaster).subscribe((res:any)=>{
     console.log("res", res);  
     tile.DetailedInfo.push(res.Sections[0].DetailedInfo[0]);
     console.log(tile);
     this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    });  
 }
 
 save() {

    console.log("Save Response", this.response); 
  this.spinner.show();

  console.log(this.response);
  let copyResp = this.response;
  let errorFlag=false;
  let sectionName='';
  let lineitem='';
  console.log('loop');

  copyResp.Sections.forEach((element) => {
    if (element.IsSectionModified == true) {
      console.log(element);
      if(!errorFlag) {
       sectionName=element.SectionName;
      }
      element.DetailedInfo.forEach((item) => {
      // console.log(item) // return lineitem
       if (item.IsRowModified == true) { 
        const parent = item;      
        for (const child of parent.Attributes) {          
          if (child.DataType=='DATE') {
           // child.SelectedValue= moment(child.SelectedValue).format("MM-DD-YYYY");          
          }
          if(child.Mandatory=='Yes' && (child.SelectedValue==null || child.SelectedValue==undefined 
            ||child.SelectedValue=='')) {
              console.log(child);
              errorFlag=true;
            }
        }
       }
       });
    }
  });
  if (errorFlag) {

    this.spinner.hide();
    Swal.fire({
      customClass:{       
        confirmButton: 'swal2-popup-confirm',
        cancelButton: 'swal2-popup-button',     
       closeButton: 'swal2-popup-button'
       },
     // title: "Warning",
      text: "Please fill all the mandatory field in "+  sectionName,
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result: any) => { 
      //console.log("result swal", result);      
    })
  }
  else {  
  this.isSaveEnabled=false;
  //this.spinner.show();
  this.rebate.saveMeasureInfo(this.WorkorderId, this.response).subscribe((res:any)=>{
    console.log("Save Response", res); 
    //this.spinner.hide(); 
   setTimeout(() => {
      if(res.Action){
        Swal.fire({
          customClass:{       
            confirmButton: 'swal2-popup-confirm',          
           },
          text: res.Message,
           icon:'error',
           timer: 3000,
          confirmButtonText: 'OK',
        });
        this.spinner.hide();
      } else {
        Swal.fire({
          customClass:{       
            confirmButton: 'swal2-popup-confirm',          
           },
          text: res.Message,
           icon:'success',
           timer: 30000,
          confirmButtonText: 'OK',
        });
        this.rebate.setItem('MeasureComponent', true);
        this.spinner.hide();
      }       
    }, 1000);

    this.getData();
  },
  err => {
    this.spinner.hide();
  });   
  
  this.spinner.hide(); 
  }
 }

accordionOpen(i) {
  this.expandedItem = i;
}

addApplianceInfo(tile,i){ 
  this.addMesureInfo(tile,tile.SectionId, i);
}

formatDate(subResponse, date: any) {
console.log("formatDate", subResponse);
  subResponse.SelectedValue= date;
/*
   console.log('Date 01',  date);
    subResponse.SelectedValue= moment(date).format("MM/DD/YYYY");
    console.log("Date", subResponse.SelectedValue);

  if(date!==null){
    subResponse.SelectedValue= moment(date).format("MM/DD/YYYY");
    console.log( subResponse.SelectedValue);
  }*/

}

 setTwoNumberDecimal(event) {
  event.value = parseFloat(event.value).toFixed(2);
}

countMeasure():any {
  let copyResp= this.response;
   let noOfMeasure:number=0;
  copyResp.Sections.forEach((element) => {   
      element.DetailedInfo.forEach((item) => {
     // console.log(item) 
      noOfMeasure++;
    });
    console.log("noOfMeasure",noOfMeasure);
    noOfMeasure>0 ? this.rebate.setItem('MeasureComponent', true): this.rebate.setItem('MeasureComponent', false);   
  }) 
        
}
onDeleteAppliance(lineItemId, section, lineItemIndex, sectionId) {

  this.subGroup1 = localStorage.getItem("subGroup");

  if (lineItemId) {
    Swal.fire({
      customClass:{       
        confirmButton: 'swal2-popup-confirm',
        cancelButton: 'swal2-popup-button'  
       },
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "CANCEL",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "YES",
    }).then((result: any) => { 
     //("result swal", result);
      if(result.isConfirmed)
      {   
    this.spinner.show();
    this.rebate.getDeleteMeasureInfo(this.WorkorderId, lineItemId).subscribe((res:any)=>{
   // console.log("Save Response", res); 
    //this.spinner.hide(); 
        
          if(res.Action){
            Swal.fire({
              customClass:{       
                confirmButton: 'swal2-popup-confirm',              
               },
              text: res.Message,
              icon:'error',
              timer: 3000,
              confirmButtonText: 'OK',
            });         
           // this.getData();
            
          } else {
            Swal.fire({
              customClass:{       
                confirmButton: 'swal2-popup-confirm'               
               },
              text: res.Message,
              icon:'success',
              timer: 30000,
              confirmButtonText: 'OK',
            });
            this.response.Sections[section].DetailedInfo.splice(lineItemIndex,1);
            this.countMeasure();
          }    
    this.spinner.hide(); 
  },
  err => {
    this.spinner.hide();
  });  
  
  }})
  }
  else {
          this.response.Sections[section].DetailedInfo.splice(lineItemIndex,1);
          this.countMeasure();
  }
  
}

dataChanged($event,i,d) { 
  this.response.Sections[i].IsSectionModified=true; 
  this.response.Sections[i].DetailedInfo[d].IsRowModified=true; 
  this.rebate.setItem('MeasureComponent', false);
  this.isSaveEnabled=true;
}
countDots(c) { 
  var result = 0, i = 0;
  for(i;i<c.length;i++)if(this[i]==c)result++;
  return result;
};
onKeyPressDecimal(event, value ){
  console.log('Decimal value', value);
  if(value!=undefined){  
  let nodots= [...value.toString()].reduce((a, c) => c === '.' ? ++a : a, 0);
  console.log('no of dots', nodots);
  if (nodots >1) { 
    value = value.substring(0, value.length - 1);
    console.log(value);
    this.parseToDouble(value);
    return false;
  }
   this.parseToDouble(value);
  }
   return (event.charCode >= 48 && event.charCode <= 57)|| event.charCode ==46 || event.charCode==8
}

parseToDouble(value) {
  value =parseFloat(value).toFixed(2); 
  console.log('parse,',value);
}
  
}