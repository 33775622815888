
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#ff4081" type = "timer" [fullScreen] = "true">
    <p style="color: white" > Loading... </p>
</ngx-spinner>

<router-outlet></router-outlet>
<!-- 
<ngx-spinner bdColor="none" size = "medium" color = "#ff4081" type = "none" [fullScreen] = "true">
    <mat-spinner diameter='100' style='text-align: center;margin:0 auto; margin-top:5vw' color='primary'
></mat-spinner>
 -->
