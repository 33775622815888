/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BucketService } from 'src/app/services/bucket.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-schedule-list',
  templateUrl: './schedule-list.component.html',
  styleUrls: ['./schedule-list.component.scss']
})
export class ScheduleListComponent implements OnInit {

    appointments: string[] = [];
    noOfAppointments:any;
    constructor(private router: Router, private bucket:BucketService,  private spinner: NgxSpinnerService) { }

    ngOnInit(): void {
    
     let pcId = localStorage.getItem("personcompanyid");
     this.loadAllAppointments(pcId);
    }

    loadAllAppointments(personcompanyId){
      this.spinner.show();
      this.bucket.getAllAppointments(personcompanyId).subscribe(res=>{
        const tmpEnrll =  res.Enrollments || []
        
        this.noOfAppointments=res.Enrollments.length;
        if(this.noOfAppointments==0)
        {
          
          Swal.fire({

            text: "You have no active scheduled appointments.",
            icon: 'success',
            confirmButtonText: 'OK',

          }).then(() => {

            this.spinner.show();
            this.router.navigate(['/main']);
            
          })
         
        }

        this.spinner.hide();
        //date wise sorting desc
        this.appointments = tmpEnrll.sort((a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
        );
      }, 
      err => {
        this.spinner.hide();
        console.log(err);
       })

       
    }

    gotoAppointment(workorderId, WorkorderType, status, workorder) {
      if(status == 'Ready to Schedule') {
        this.router.navigate(['/scheduling'],{state: {data: { WorkorderId: workorderId, WorkorderType: WorkorderType}}});
      } else if(status === 'SCHEDULED') {
        this.router.navigate(['/reschedule'],{state: {data: workorder}});
      } else {
        Swal.fire({
          text: `This appointment can’t be changed because it is in ${status} status`,
          icon: 'info',
          confirmButtonText: 'OK',
        })
      }
    }

}
