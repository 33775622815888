<app-enrollment-header></app-enrollment-header>
<div class="font-style"> 
<div class="intro-text" [innerHTML]=utilityService.utilitySettings.mainIntro *ngIf="utilityService.utilitySettings.custominfoacctconfmsg==null"></div>
<div class="intro-text" [innerHTML]=utilityService.utilitySettings.custominfoacctconfmsg *ngIf="utilityService.utilitySettings.custominfoacctconfmsg!=null"></div>
<span *ngIf="!smallScreen">
<form>
<mat-card class="mat-elevation-z0">
  <div class="container">
    <!-- <div class="ribbon" *ngIf="isEnableHelpContent && utilityService.utilitySettings.custominfocallback2==null">Need
      help enrolling?<br>{{utilityService.utilitySettings.enableHelpContent}}<br>
      Or&nbsp;<a class='ribbon' [routerLink]="[]" (click)="callback()">request a call back </a>
    </div> -->
    <div class="ribbon" *ngIf="isEnableHelpContent && utilityService.utilitySettings.custominfocallback2!=null  "
        innerHTML="{{utilityService.utilitySettings.custominfocallback}}">
    </div>
  </div>
</mat-card>
<mat-card>
<table class="content-table">
 
  <tr>
    <td style="height: 2vw !important;font-size: 1vw">First Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp; {{confirmData.FirstName}}</td>
    <td style="height: 2vw !important;font-size: 1vw">Last Name&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{confirmData.LastName}}</td>
  </tr>
  <tr>
    <td style="height: 2vw !important;font-size: 1vw">Street Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{confirmData.ServiceStreetAddress1}}</td>
  </tr>
  <tr>
    <td style="height: 2vw !important;font-size: 1vw">City&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp; {{confirmData.ServiceCity != "" ||  confirmData.ServiceCity != null ? confirmData.ServiceCity : confirmData.MailingCity}}</td>
    <td style="height: 2vw !important;font-size: 1vw">State&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp; {{confirmData.ServiceState != "" ||  confirmData.ServiceState != null ? confirmData.ServiceState : confirmData.MailingState}}</td>
    <td style="height: 2vw !important;font-size: 1vw">ZIP&nbsp;&nbsp;:&nbsp;&nbsp;   {{confirmData.ServiceZipcode != "" ||  confirmData.ServiceZipcode != null ? confirmData.ServiceZipcode : confirmData.MailingZipcode}}</td>
  </tr>
  <tr>
    <td style="height: 2vw !important;font-size: 1vw"> Phone Number&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{formatPhoneNumber(confirmData.PrimaryPhone)}}</td>
  </tr>
  </table> 
  <br>
  <div *ngIf= " homeTypeLength!=0" style="font-size: 12px;margin-left: 5px;" >
    
  <mat-form-field appearance="outline" style="width:230px !important" >
    <mat-label>Please select your housing type:</mat-label>
    <mat-select name="hometype"  [(ngModel)]="hometype" required>
      <mat-option  [value]="item.Id" *ngFor="let item of homeType">{{item.Value}}</mat-option> 
    </mat-select>
  </mat-form-field>

  <ng-container *ngIf="hometype == 'STC1CONDO'">
    
    <mat-radio-group aria-label="Do you own or rent" name="ownorrent"  [(ngModel)]="ownorrent"  required >
      <label id="example-radio-group-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Do you own or rent ?&nbsp;<sup style="color:red">*</sup> &nbsp;&nbsp;</label>
          <mat-radio-button  *ngFor="let method of ownOrRent" [value]="method.Id" >
          {{method.Value}}&nbsp;&nbsp;
        </mat-radio-button>
    </mat-radio-group>
   
  </ng-container>


  </div>

    <mat-card-content>
      <mat-checkbox   name="agree"  style="font-size: 12px;margin-left: 5px;"  required [(ngModel)]="agree" #isActive="ngModel">By checking this box, you are confirming that the above information is correct.</mat-checkbox>
      <div style="font-size: 12px;margin-left: 5px;" [innerHTML]="utilityService.utilitySettings.communicationinfo"></div>
    </mat-card-content> 


  <!-- Phonenumber start-->

  <div *ngIf= "homeTypeLength!=0" style="font-size: 12px;margin-left: 5px;">Please provide preferred phone number:
    <br>
    <mat-form-field appearance="outline" style="width:230px !important" >
      <mat-label>Phone number</mat-label>
      <input type="text" (input)="validatePhoneNo($event.target)"  placeholder="Please enter phone number"  name="preferredphone" maxlength="14" id="preferredphone"  [(ngModel)]="preferredphone"    matInput  required>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width:230px !important;margin-left:30px" >
      <mat-label>Confirm phone number</mat-label>
      <input type="text" (input)="validateCPhoneNo($event.target)"  placeholder="Please confirm phone number"  name="cphonenumber" maxlength="14" id="cphonenumber" [(ngModel)]="cphonenumber"  #confirmPhonenumber="ngModel" matInput  required>
    </mat-form-field>
    <div style="font-size: 10px; color: red" *ngIf="!isValidFlg">Invalid Phonenumber  </div>
    <div style="font-size: 10px; color: red" *ngIf="!isValidFlg1">Invalid  Confirm Phonenumber  </div>
    <div  style="font-size: 10px; color: red" *ngIf=" (confirmphonenumber) && (preferredphone !=cphonenumber) "> Phone numbers does not match.
    </div> 
  </div>
   <!-- Phonenumber End-->
   <div style="font-size: 12px;margin-left: 5px;" [innerHTML]="utilityService.utilitySettings.emailinfo"></div>

<div>
  <mat-form-field appearance="outline" style="width:230px !important" >
    <mat-label>Email address</mat-label>
    <input type="text"  placeholder="Please enter email address"  name="email" id="email" matInput  value="{{email}}"   pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required  [(ngModel)]="email" #primaryEmail="ngModel">
  </mat-form-field>
  <mat-form-field appearance="outline" style="width:230px !important;margin-left:30px"  >
    <mat-label>Confirm email address</mat-label>
    <input type="text"  placeholder="Please enter confirm email "  name="confirmemail" appConfirmEqualValidator="email" matInput   pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required [(ngModel)]="confirmemail" #secondaryEmail="ngModel">
  </mat-form-field>
  <br>
  <span  style="font-size: 10px; color: red" *ngIf="primaryEmail.touched && primaryEmail.errors?.required"> Email address is required.<br>
  </span>
  <span  style="font-size: 10px; color: red" *ngIf="secondaryEmail.touched && secondaryEmail.errors?.required"> Confirm email address is required.
  </span>
  <span  style="font-size: 10px; color: red" *ngIf="secondaryEmail.touched && secondaryEmail.errors?.notEqual && !secondaryEmail.errors?.required "> Confirm email address does not match email address.
  </span> 
  <div *ngIf="primaryEmail.errors && (primaryEmail.invalid || primaryEmail.touched)">
    <small class="text-danger" *ngIf="primaryEmail.errors.pattern" style="font-size: 10px; color: red">Please enter a valid email address.</small>
  </div>
  <div *ngIf="secondaryEmail.errors && (secondaryEmail.invalid || secondaryEmail.touched)">
    <small class="text-danger" *ngIf="secondaryEmail.errors.pattern" style="font-size: 10px; color: red">Please enter a valid email address.</small>
  </div>
  <span  style="font-size: 10px; color: red" *ngIf="isActive.touched && isActive.errors?.required "> Please verify the above data and confirm the checkbox.
  </span>

<!-- BWS Checkbox and Phone number start-->

<div *ngIf="existcommunication == 1">
  <mat-card-content>
  <mat-checkbox   name="communication"  style="font-size: 12px;margin-left: 5px;"  required [(ngModel)]="communication" #isActive="ngModel" ></mat-checkbox><span style="font-size: 12px;margin-left: 5px;">{{utilityService.utilitySettings.ProgramInfoCommunications}}</span>
</mat-card-content>
</div>

  <div *ngIf= "existDayTimePhone == 1" style="font-size: 12px;margin-left: 5px;">{{utilityService.utilitySettings.CustomPhoneInfo}}
    <br>
    <mat-form-field appearance="outline" style="width:230px !important" >
      <mat-label> DayTime phone number</mat-label>
      <input type="text" (input)="validatePhoneNo($event.target)"  placeholder="Please enter daytime phone number"  name="dayTimePhone" maxlength="14" id="dayTimePhone"  [(ngModel)]="dayTimePhone"    matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width:230px !important;margin-left:30px" >
      <mat-label>Confirm daytime phone number</mat-label>
      <input type="text" (input)="validatedaytimeCPhoneNo($event.target)"  placeholder="Please confirm daytime phone number"  name="cdayTimePhone" maxlength="14" id="cdayTimePhone" [(ngModel)]="cdayTimePhone"  #confirmPhonenumber="ngModel" matInput>
    </mat-form-field>
    <div style="font-size: 10px; color: red" *ngIf="!isValidFlg">Invalid daytime phonenumber  </div>
    <div style="font-size: 10px; color: red" *ngIf="!isValidFlg2">Invalid  confirm daytime phonenumber  </div>
    <div  style="font-size: 10px; color: red" *ngIf=" (confirmdaytimephone) && (dayTimePhone !=cdayTimePhone) "> Daytime phone numbers does not match.
    </div> 

    <br>
    <mat-form-field appearance="outline" style="width:230px !important">
      <mat-label> Alternate phone number</mat-label>
      <input type="text" (input)="validatePhoneNo($event.target)"  placeholder="Please enter alternate phone number"  name="alternatePhone" maxlength="14" id="alternatePhone"  [(ngModel)]="alternatePhone"  matInput>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width:230px !important;margin-left:30px" >
      <mat-label>Confirm alternate phone number</mat-label>
      <input type="text" (input)="validatealternateCPhoneNo($event.target)"  placeholder="Please confirm alternate phone number"  name="calternatePhone" maxlength="14" id="calternatePhone" [(ngModel)]="calternatePhone"  #confirmPhonenumber="ngModel" matInput>
    </mat-form-field>
    
    <div style="font-size: 10px; color: red" *ngIf="!isValidFlg">Invalid alternate phonenumber  </div>
    <div style="font-size: 10px; color: red" *ngIf="!isValidFlg3">Invalid  confirm alternate phonenumber  </div>
    <div  style="font-size: 10px; color: red" *ngIf=" (confirmalternatephone) && (alternatePhone !=calternatePhone) ">  Alternate phone numbers does not match.
    </div> 

  </div>


<!-- BWS checkbox  and phone number end-->


  <span *ngIf= "homeTypeLength!=0">
    <span *ngIf="(hometype == 'STC1SF') || (hometype == 'STC1DUPLEX') ">
    <mat-card-actions>
      <button mat-raised-button class="mainbutton" routerLink="/customerlookup" [state]="{data: confirmData}">BACK</button>       
      <button mat-raised-button class="mainbutton" [disabled]= "(email != confirmemail) || (!agree) || (!hometype) || (preferredphone != cphonenumber) "  (click)="savedetails()">NEXT</button>
    </mat-card-actions>
  </span>
</span>

  <span *ngIf= "homeTypeLength!=0">
    <span *ngIf="(hometype != 'STC1SF') && (hometype != 'STC1DUPLEX') && (hometype != 'STC1CONDO')">
    <mat-card-actions>
      <button mat-raised-button class="mainbutton" routerLink="/customerlookup" [state]="{data: confirmData}">BACK</button>       
      <button mat-raised-button class="mainbutton" [disabled]="true" (click)="savedetails()">NEXT</button>
    </mat-card-actions>
  </span>
  </span>

  <span *ngIf= "homeTypeLength!=0">
    <span *ngIf="(hometype == 'STC1CONDO')">
    <mat-card-actions>
      <button mat-raised-button class="mainbutton" routerLink="/customerlookup" [state]="{data: confirmData}">BACK</button>       
      <button mat-raised-button class="mainbutton" [disabled]= "(email != confirmemail) || (!agree) || (!hometype) || (preferredphone != cphonenumber) || (!ownorrent) "  (click)="savedetails()">NEXT</button>
    </mat-card-actions>
  </span>
  </span>


  <span *ngIf= "homeTypeLength==0">
    <mat-card-actions>
      <button mat-raised-button class="mainbutton" routerLink="/customerlookup" [state]="{data: confirmData}">BACK</button>       
      <button mat-raised-button class="mainbutton" [disabled]= "(email != confirmemail) || (!agree) "  (click)="savedetails()">NEXT</button>
    </mat-card-actions>
  </span>
  </div>
</mat-card>
</form>
</span>

<!--Mobile screen start-->

<span *ngIf="smallScreen">
  <form>
  <mat-card>
    <table >
      
      <tr>
        <td style="height: 3vw !important;font-size: 3vw">First Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{confirmData.FirstName}}</td>
      </tr>
      <tr>
        <td style="height: 3vw !important;font-size: 3vw">Last Name &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{confirmData.LastName}}</td>
      </tr>
      <tr>
        <td style="height: 3vw !important;font-size: 3vw">Street Address&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{confirmData.ServiceStreetAddress1}}</td>
      </tr>
      <tr>
        <td style="height: 3vw !important;font-size: 3vw">City&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{confirmData.ServiceCity != "" ||  confirmData.ServiceCity != null ? confirmData.ServiceCity : confirmData.MailingCity}}</td>   
      </tr>
      <tr>
        <td style="height: 3vw !important;font-size: 3vw">State&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{confirmData.ServiceState != "" ||  confirmData.ServiceState != null ? confirmData.ServiceState : confirmData.MailingState}}</td>
      </tr>
      <tr>
        <td style="height: 3vw !important;font-size: 3vw">ZIP&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{confirmData.ServiceZipcode != "" ||  confirmData.ServiceZipcode != null ? confirmData.ServiceZipcode : confirmData.MailingZipcode}}</td>
      </tr>
      <tr>
        <td style="height: 3vw !important;font-size: 3vw" appPhoneMask> Phone Number&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{{formatPhoneNumber(confirmData.PrimaryPhone)}}</td>
      </tr>
      </table> 
      <br>
      <span *ngIf= " homeTypeLength!=0" >
        <mat-form-field appearance="outline" style="width:270px !important" >
          <mat-label>Please select your housing type:</mat-label>
          <mat-select name="hometype"  [(ngModel)]="hometype" required>
            <mat-option  [value]="item.Id" *ngFor="let item of homeType">{{item.Value}}</mat-option> 
          </mat-select>
        </mat-form-field>

        <ng-container *ngIf="hometype == 'STC1CONDO'">
              <mat-radio-group aria-label="Do you own or rent" name="ownorrent"  [(ngModel)]="ownorrent"  required >
            <label id="example-radio-group-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Do you own or rent ?&nbsp;<sup style="color:red">*</sup> &nbsp;&nbsp;</label>
                <mat-radio-button  *ngFor="let method of ownOrRent" [value]="method.Id" >
                {{method.Value}}&nbsp;&nbsp;
              </mat-radio-button>
          </mat-radio-group>
        </ng-container>
      
      </span>

      <mat-card-content>
        <mat-checkbox   name="agree"  style="font-size: 12px;margin-left: 5px;"  required [(ngModel)]="agree" #isActive="ngModel">By checking this box, you are confirming that the <br> above information is correct.</mat-checkbox>
        <div style="font-size: 12px;margin-left: 5px;" [innerHTML]="utilityService.utilitySettings.communicationinfo"></div>
      </mat-card-content>  

  <!-- Phonenumber start-->
  <div *ngIf= "homeTypeLength!=0" style="font-size: 12px;margin-left: 5px;">Please provide preferred phone number:
    <br>
    <mat-form-field appearance="outline" style="width:230px !important" >
      <mat-label>Phone number</mat-label>
      <input type="text" (input)="validatePhoneNo($event.target)"  placeholder="Please enter phone number"  name="preferredphone" maxlength="14" id="preferredphone"  [(ngModel)]="preferredphone"    matInput  required>
    </mat-form-field>
    <mat-form-field appearance="outline" style="width:230px !important;margin-left:30px" >
      <mat-label>Confirm phone number</mat-label>
      <input type="text" (input)="validateCPhoneNo($event.target)"  placeholder="Please confirm phone number"  name="cphonenumber" maxlength="14" id="cphonenumber" [(ngModel)]="cphonenumber"  #confirmPhonenumber="ngModel" matInput  required>
    </mat-form-field>
    <div style="font-size: 10px; color: red" *ngIf="!isValidFlg">Invalid Phonenumber  </div>
    <div style="font-size: 10px; color: red" *ngIf="!isValidFlg1">Invalid  Confirm Phonenumber  </div>
    <div  style="font-size: 10px; color: red" *ngIf=" (confirmphonenumber) && (preferredphone !=cphonenumber) "> Phone numbers does not match.
    </div> 
  </div>
  
  <!-- Phonenumber End-->
  <div style="font-size: 12px;margin-left: 5px;" [innerHTML]="utilityService.utilitySettings.emailinfo"></div>
  <div>
      <mat-form-field appearance="outline" style="width:230px !important" >
        <mat-label>Email address</mat-label>
        <input type="text"  placeholder="Please enter email address"  name="email" id="email" matInput  value="{{email}}"   pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required  [(ngModel)]="email" #primaryEmail="ngModel">
      </mat-form-field>
      <mat-form-field appearance="outline" style="width:230px !important;" >
        <mat-label>Confirm email address</mat-label>
        <input type="text"  placeholder="Please enter confirm email "  name="confirmemail" appConfirmEqualValidator="email" matInput   pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required [(ngModel)]="confirmemail" #secondaryEmail="ngModel">
      </mat-form-field>
    <br>
    <span  style="font-size: 10px; color: red" *ngIf="primaryEmail.touched && primaryEmail.errors?.required"> Email address is required.<br>
    </span>
    <span  style="font-size: 10px; color: red" *ngIf="secondaryEmail.touched && secondaryEmail.errors?.required"> Confirm email address is required.
    </span>
    <span  style="font-size: 10px; color: red" *ngIf="secondaryEmail.touched && secondaryEmail.errors?.notEqual && !secondaryEmail.errors?.required "> Confirm email address does not match email address.
    </span> 
    <div *ngIf="primaryEmail.errors && (primaryEmail.invalid || primaryEmail.touched)">
      <small class="text-danger" *ngIf="primaryEmail.errors.pattern" style="font-size: 10px; color: red">Please enter a valid email address.</small>
    </div>
    <div *ngIf="secondaryEmail.errors && (secondaryEmail.invalid || secondaryEmail.touched)">
      <small class="text-danger" *ngIf="secondaryEmail.errors.pattern" style="font-size: 10px; color: red">Please enter a valid email address.</small>
    </div>
    <span  style="font-size: 10px; color: red" *ngIf="isActive.touched && isActive.errors?.required "> Please verify the above data and confirm the checkbox.
    </span>

    <!-- BWS Checkbox and Phone number start-->

    <div *ngIf="existcommunication == 1">
      <mat-card-content>
      <mat-checkbox   name="communication"  style="font-size: 12px;margin-left: 5px;"  required [(ngModel)]="communication" #isActive="ngModel" ></mat-checkbox><span style="font-size: 12px;margin-left: 5px;">{{utilityService.utilitySettings.ProgramInfoCommunications}}</span>
    </mat-card-content>
  </div>
  
    <div *ngIf= "existDayTimePhone == 1" style="font-size: 12px;margin-left: 5px;">{{utilityService.utilitySettings.CustomPhoneInfo}}
      <br>
      <mat-form-field appearance="outline" style="width:230px !important" >
        <mat-label> DayTime phone number</mat-label>
        <input type="text" (input)="validatePhoneNo($event.target)"  placeholder="Please enter daytime phone number"  name="dayTimePhone" maxlength="14" id="dayTimePhone"  [(ngModel)]="dayTimePhone"    matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width:230px !important;" >
        <mat-label>Confirm daytime phone number</mat-label>
        <input type="text" (input)="validatedaytimeCPhoneNo($event.target)"  placeholder="Please confirm daytime phone number"  name="cdayTimePhone" maxlength="14" id="cdayTimePhone" [(ngModel)]="cdayTimePhone"  #confirmPhonenumber="ngModel" matInput>
      </mat-form-field>
      <div style="font-size: 10px; color: red" *ngIf="!isValidFlg">Invalid daytime phonenumber  </div>
      <div style="font-size: 10px; color: red" *ngIf="!isValidFlg2">Invalid  confirm daytime phonenumber  </div>
      <div  style="font-size: 10px; color: red" *ngIf=" (confirmdaytimephone) && (dayTimePhone !=cdayTimePhone) "> Daytime phone numbers does not match.
      </div> 
  
      <br>
      <mat-form-field appearance="outline" style="width:230px !important">
        <mat-label> Alternate phone number</mat-label>
        <input type="text" (input)="validatePhoneNo($event.target)"  placeholder="Please enter alternate phone number"  name="alternatePhone" maxlength="14" id="alternatePhone"  [(ngModel)]="alternatePhone"  matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" style="width:230px !important;" >
        <mat-label>Confirm alternate phone number</mat-label>
        <input type="text" (input)="validatealternateCPhoneNo($event.target)"  placeholder="Please confirm alternate phone number"  name="calternatePhone" maxlength="14" id="calternatePhone" [(ngModel)]="calternatePhone"  #confirmPhonenumber="ngModel" matInput>
      </mat-form-field>
      
      <div style="font-size: 10px; color: red" *ngIf="!isValidFlg">Invalid alternate phonenumber  </div>
      <div style="font-size: 10px; color: red" *ngIf="!isValidFlg3">Invalid  confirm alternate phonenumber  </div>
      <div  style="font-size: 10px; color: red" *ngIf=" (confirmalternatephone) && (alternatePhone !=calternatePhone) ">  Alternate phone numbers does not match.
      </div> 
  
    </div>
  
  
  <!-- BWS checkbox  and phone number end-->

  <span *ngIf= "homeTypeLength!=0">
    <span *ngIf="(hometype == 'STC1SF') || (hometype == 'STC1DUPLEX') ">
    <mat-card-actions>
      <button mat-raised-button class="mainbutton" routerLink="/customerlookup" [state]="{data: confirmData}">BACK</button>       
      <button mat-raised-button class="mainbutton" [disabled]= "(email != confirmemail) || (!agree) || (!hometype) || (preferredphone != cphonenumber) "  (click)="savedetails()">NEXT</button>
    </mat-card-actions>
  </span>
</span>


<span *ngIf= "homeTypeLength!=0">
  <span *ngIf="(hometype != 'STC1SF') && (hometype != 'STC1DUPLEX') && (hometype != 'STC1CONDO')">
  <mat-card-actions>
    <button mat-raised-button class="mainbutton" routerLink="/customerlookup" [state]="{data: confirmData}">BACK</button>       
    <button mat-raised-button class="mainbutton" [disabled]="true" (click)="savedetails()">NEXT</button>
  </mat-card-actions>
</span>
</span>


<span *ngIf= "homeTypeLength!=0">
  <span *ngIf="(hometype == 'STC1CONDO')">
  <mat-card-actions>
    <button mat-raised-button class="mainbutton" routerLink="/customerlookup" [state]="{data: confirmData}">BACK</button>       
    <button mat-raised-button class="mainbutton" [disabled]= "(email != confirmemail) || (!agree) || (!hometype) || (preferredphone != cphonenumber) || (!ownorrent) "  (click)="savedetails()">NEXT</button>
  </mat-card-actions>
</span>
</span>


<span *ngIf= "homeTypeLength==0">
  <mat-card-actions>
    <button mat-raised-button class="mainbutton" routerLink="/customerlookup" [state]="{data: confirmData}">BACK</button>       
    <button mat-raised-button class="mainbutton" [disabled]= "(email != confirmemail) || (!agree) "  (click)="savedetails()">NEXT</button>
  </mat-card-actions>
</span>


  </div>
  </mat-card>
  </form>
  </span>
</div>
<!-- Mobile screen End-->
<app-enrollment-footer></app-enrollment-footer>
