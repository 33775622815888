/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, Input, AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { DataTransferService } from '../services/data-transfer.service';
import { Router } from '@angular/router';
import { UtilityService } from '../services/utility.service';

@Component({
    selector: 'app-enrollment-header',
    templateUrl: './enrollment-header.component.html',
    styleUrls: ['./enrollment-header.component.scss'],
    
})
export class EnrollmentHeaderComponent implements OnInit, AfterViewChecked {

    @Input() backgroundcolor: string;
    @Input() programName: string;
    isShowButton: boolean=true;
    desc:string;
    isShowHeader:boolean;
    workorderTypeImage:any;
    smallScreen:any;
    isHeaderCustomization:any;
    //showItNow:any;
    constructor(public utilityService: UtilityService,public router: Router, public dataTransfer:DataTransferService) {
      
     }

    ngOnInit(): void {

        // insert CSS Rule
        this.programName = localStorage.getItem('ProgramName');
        //To hide/un-hide based on office id
        this.smallScreen=screen.width < 600;
       if(localStorage.getItem('SelfSchedule')=='0')
        {
           this.isShowButton=false;
        }
        else
        {
           this.isShowButton=true;
        }

           this.desc=this.utilityService.utilitySettings.menuItems[0];
           //console.log('menuname:', this.desc);
          this.isShowHeader= this.utilityService.utilitySettings.showHeader;
          this.isHeaderCustomization= this.utilityService.utilitySettings.HeaderCustomization;
          console.log("header",this.isHeaderCustomization);
    }
    

    ngAfterViewChecked(): void {
       
            const style = document.createElement('style');
            document.head.appendChild(style);
            const {styles = []} = this.utilityService.utilitySettings;
            styles.forEach(cssstyle => style.sheet.insertRule(cssstyle));

            //hide/unhide based on self-schedule
            if(localStorage.getItem('SelfSchedule')=='0')
            {
               
                this.isShowButton=false;
            }
            else
            {
               
                this.isShowButton=true;
            }
            
    }

    gotoMain(){
        sessionStorage.removeItem('getAccountNumber')
        this.router.navigate(['/main']);
    }
}
