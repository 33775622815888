/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */


// possibilities are:
// {"id": "1", "desc": "Login to Existing Account"},
// {"id": "2", "desc": "Account Number"},
// {"id": "3", "desc": "Name and Service Address"}, 
// {"id": "4", "desc": "Name and Phone Number"}
export class LoginMethod {
    id: number;
    desc: string;
}

// subtype under program.  Program has an array of these
export class ProgramType {
    id: string;
    desc: string;
    selfSchedule = false;
}

// programs that can be enrolled in through this protal
export class Program {
    name: string;
    programTypes: ProgramType[];
}

export class UtilitySettings {
    utilityName: string;

    // displayed in the top nav bar
    title: string;
    
    // full html for header under nav bar. Can include image for logo and links
    headerHtml: string;

    // full html for footer. Can include image for logo and links
    footerHtml: string;
    
    // programs for this utility.  See Program class above.  Has array of ProgramTypes
    programs: Program[];

    // login methods 1 to 4 possible.  See LoginMethod class above
    loginMethods: LoginMethod[];

    // pure CSS styles for tag attributes
    styles: string[] = [];


    // The following provide the text for different pages
    mainIntro: string;
    mainDesc: string;

    progTypeIntro: string;
    progTypeDesc: string;

    qualIntro: string;
    qualDesc: string;

    qualificationscriptenabled: boolean;
    qualificationscript: string;

    enrollSuccessIntro: string;
    enrollSuccessDesc: string;

    enrollErrorIntro: string;
    enrollErrorDesc: string;

    utilMessage1: string;
    utilMessage2: string;
    
    menuItems: string[] = [];
    
    showHeader:boolean;

    communicationinfo: string;

    custominfocallback: string;

    custominfocallback2: string;

    custominfomyprofile: string;

    custominfoacctconfmsg:string;

    custominfoshowuploaddoc:string;

    custominfoaptnotetb:string;

    custominforedirhome:boolean;

    emailinfo:string;

    workorderTypeImage:any;

    enableEnrollCustomer:any;

    //To use text for upload documents
    UploadEnableContent:any;

    //To enable the help content on loading page
    enableHelpContent:any;

    // to enable Captcha on customer lookup
    enableCaptcha: boolean;

    //To enable search buttons and text on landing page
    enableSearches:boolean;
    searchContent:any;
    DownloadW9Form:any;
    Gatag:any;
    SearchContentHelp:any;
    FileUploadContent1:any;
    FileUploadContent2:any;
    FileUploadContent3:any;
    IsQuestionsEnableSeq: any;
    
    SelfScheduleEnablement:any;
    ReportEnablement:any;
    ReportLogo:any;
    HomeImage:any;
    HeaderCustomization:any;
    PayeeInfo:any;

    CustomProgramGuide:any;
    ProgramInfoCommunications:any;
    CustomPhoneInfo:any;

    Isuploaddoc:any;
    Isviewdoc:any

}