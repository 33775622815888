/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/services/utility.service';
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class SubmitComponent {

  constructor(public utilityService: UtilityService,private spinner: NgxSpinnerService,private router: Router) { }
  
  officeid= localStorage.getItem('currentUtility');
  workorderId = "W009737605";
  userId='sfen_user';
  isEP=0;

}
