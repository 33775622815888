/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-cancelschedule-dialog',
  templateUrl: './cancelschedule-dialog.component.html',
  styleUrls: ['./cancelschedule-dialog.component.scss']
})
export class CancelscheduleDialogComponent implements OnInit {

  reasons: any = [];
  dialogTitle: any;
  workorderId: any;
  user: any;
  alertText: any;
  loadReasons: any = true;
  constructor(public router:Router,public route:ActivatedRoute,@Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialogRef<CancelscheduleDialogComponent>,
    private utilityService: UtilityService) { }


  ngOnInit(): void {
  
    this.workorderId = this.data.workorderID;
    this.getReasons();
  }

  getReasons() {
  
    this.loadReasons = true;
    this.utilityService.getCancelScheduleReasons().subscribe(res => {
      this.reasons = res;
      this.loadReasons = false;
    })
  }

  cancelSchedule(reason) {

    Swal.fire({
      text: "Are you sure you want to cancel an appointment?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes'
    }).then((result) => {
      if (result.value) {
        this.doCancelSchedule(reason);
      }
    });

  }
  doCancelSchedule(reason) {
  
    if(this.workorderId == undefined){
      this.utilityService.cancelOpenAppointments(this.data.scheduleDate.toLocaleDateString(),this.data.personcompany,reason.ReasonCode,'ALL').subscribe(res=>{

        if (!res['Action']) {
          Swal.fire({
            text: "Appointment Cancelled Successfully",
            confirmButtonText: 'OK',
            icon: 'success'
          }).then(() => {
           this.router.navigate(['/appointments']);
            //this.dialog.close({data: '/appointments'});
            this.dialog.close({data:false});
          })
        } else {
          Swal.fire({
            text: res['Message'],
            confirmButtonText: 'OK',
            icon: 'success'
          }).then(() => {
            //this.dialog.close({data: '/appointments'});
            this.dialog.close({data:false});
          })
        }
      })
    }

    //This one
    else
    {
      this.utilityService.cancelSchedule(this.workorderId, reason.ReasonCode).subscribe(res => {
    
        if (res['Action'] == 0) {
          Swal.fire({
            text: "Appointment Cancelled Successfully",
            confirmButtonText: 'OK',
            icon: 'success'
          })
          .then(() => {
            this.dialog.close({data:false});
                      });
           
          
        } else {
          Swal.fire({
            text: res['Message'],
            confirmButtonText: 'OK',
            icon: 'success'
          }).then(() => {
            
            //this.dialog.close({data: '/appointments'});
            this.dialog.close({data:false});
          })
        }
      })
    }
   

  }

}
