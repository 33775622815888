/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BucketService } from 'src/app/services/bucket.service';
import { NgxSpinnerService } from "ngx-spinner";
import {Location} from '@angular/common';
import { MatDialog } from "@angular/material/dialog";
import * as moment from 'moment';
import { SchedulerDialogComponent } from '../scheduler-dialog/scheduler-dialog.component';
import { CancelscheduleDialogComponent } from "../cancelschedule-dialog/cancelschedule-dialog.component";

@Component({
  selector: 'app-re-schedule',
  templateUrl: './re-schedule.component.html',
  styleUrls: ['./re-schedule.component.scss']
})
export class ReScheduleComponent implements OnInit {

  appointments: string[] = [];
  response: any;
  constructor( private dialog: MatDialog,private router: Router, private bucketService: BucketService, private bucket: BucketService, private spinner: NgxSpinnerService,
    private _location: Location) { }
  confirmData: any = {}
  stringDate: any;
  redate: any;
  desc: any;
  reschduleTime:any;

  ngOnInit(): void {
    this.confirmData = history.state.data || {}
    console.log('in reschedhule', history.state.data)
    this.redate = this.confirmData.ApptStartDate || this.confirmData.AppointmentDate;
    this.desc = this.confirmData.ProgramdetailDesc || this.confirmData.WorkorderType;
    this.reschduleTime = this.confirmData.AppointmentTime || this.confirmData.ApptStartTime;
    this.slotsForDay();
  }

  slotsForDay() {
    this.stringDate = moment(this.confirmData.StartDate || this.confirmData.AppointmentDate).format("MM/DD/YYYY");
    this.spinner.show();
    this.bucketService.getSlotsForDay(this.stringDate, this.confirmData.Workorder).subscribe(res => {
      this.spinner.hide();
      this.response = res;
      console.log('address for reschedule', res);
    },
      err => {
        console.log(err);
        this.spinner.hide();
      })
  }

  formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

  reschedule(){
    const dialogRef = this.dialog.open(SchedulerDialogComponent, {
      data: {
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      console.log('on reason select code', res)
      const { data = ""} = res;
      this.router.navigate(['/scheduling'],{state: {data: { WorkorderId:  this.response.WorkorderId, WorkorderType: this.response.WorkorderType, reasonCode: data || ''}}});
    });
  }

  cancel(){

    const dialogRef = this.dialog.open(CancelscheduleDialogComponent, {
      data: {
        workorderID: this.confirmData.Workorder,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      // go to /appointments page
      console.log('canel reschdule', res);
      if(res == true){
        // stay on same page
      } else {
        this.router.navigate(['/enrollments'],{state: {data: {}}});
      }
    });
  }

  back() {
    // go to prevoius page
    this._location.back();
  }
}
