/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BucketService } from 'src/app/services/bucket.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-timeslotdialog',
  templateUrl: './timeslotdialog.component.html',
  styleUrls: ['./timeslotdialog.component.scss']
})
export class TimeslotdialogComponent implements OnInit {

  WonotesObj:any;
  ApptnotesObj:any;
  email1:any;
  email2:any;
  bucketObj:any;
  address:any;
  workorderId:any;
  timeSlotId:any;
  phone:any;
  bucketResponse: any;
  email1Val = false;
  email2Val = false;
  reasonCode: any;
  butttonName = 'Schedule';
  Email1Id:any;
  Email2Id:any;
  showSpinner:boolean;
  isShowHeader:boolean;
  smallScreen:any;
  enable:any;
  custominforedirhome:boolean;
  //scheduleInstructions:any;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public router:Router,
  private dialog: MatDialogRef<TimeslotdialogComponent>, private scheduleService: BucketService,
  public utilityService: UtilityService) { }

  ngOnInit(): void {
    console.log('this.data', this.data);
     console.log('this.data.response;', this.data.response);
    this.butttonName =  this.data.reasonCode ? 'Reschedule': 'Schedule'
    this.bucketObj = this.data.bucketObj;
    this.bucketResponse = this.data.response;
    //Added for check
     
     //console.log('bucket object',this.data.response.Email1);
     this.email1=this.data.response.Email1;
     this.email2=this.data.response.Email2;
      this.ApptnotesObj=this.data.response.AssignmentNotes;
      this.WonotesObj=this.data.response.WorkorderNotes;
     
    this.workorderId = this.data.workorderId;
    this.reasonCode = this.data.reasonCode;
    this.timeSlotId = this.bucketObj.Resources[0].WebTimeSlotId;
    this.isShowHeader= this.utilityService.utilitySettings.showHeader;
    this.custominforedirhome=this.utilityService.utilitySettings.custominforedirhome;
    this.enable=false;
    this.smallScreen=screen.width < 600;
  }

  makeAPi(body) {
    console.log('body',body);
    this.scheduleService.selfScheduleAppointment(body,this.workorderId,this.timeSlotId, this.reasonCode).subscribe(res=>{
      this.showSpinner=false;
      if(res.Action==0){
        Swal.fire({
       // text: this.reasonCode ? 'Rescheduled Successfully!' : 'Scheduled Successfully!',
       //text: 'Thank you! Your appointment has been successfully scheduled.You will receive a confirmation email shortly.',
        icon: 'success',
        
        //html: "Thank you! Your appointment has been successfully scheduled.<br>You will receive a confirmation email shortly.<br><br>"+this.utilityService.utilitySettings.utilMessage2,
        html:'<div style="text-align:left"> '+this.utilityService.utilitySettings.enrollSuccessDesc+' </div>',
        width:'800px',
        confirmButtonText: 'OK',
      }).then((result) => {
       // This is for SJG
        if (result.value && this.isShowHeader) {
          this.dialog.close({data:true}); 
        }
        // This is for SanteeCooper // custominforedirhome added for Consumers rediret to home page
        if(result.value && (!this.isShowHeader || this.custominforedirhome==true)){
          this.dialog.close();
          this.router.navigate(['/customerlookup']);
        }
      });
    } else {
      Swal.fire({
        text: res.Message,
        icon: 'error',
        confirmButtonText: 'OK',
      }).then((result) => {

        if (result.value) {
          this.dialog.close({data: null});
          //this.onLoad();
        }
      });
    }
  })
  }

  onSchedule(){
    this.showSpinner=true;
    this.enable=true;
    const data1={
    "User":"sfen_user",
    "StartDate":this.bucketObj.SlotStart,
    "EndDate":this.bucketObj.SlotEnd,
    "WorkorderNotes":this.WonotesObj,
    "AppointmentNotes":this.ApptnotesObj,
    "EmailId1":this.data.response.Email1Id,
    "Email1":this.email1,
    "EmailId2":this.data.response.Email2Id,
    "Email2":this.email2,
    "ProgramdetailId":this.data.response.ProgramdetailId,
    }
    let body: any;

    if(this.reasonCode){
      body = {
        ...data1,
        "ScheduleType":"RESCHEDULE",
        "Reason": this.reasonCode
      }
    } else {
      body = {
        ...data1,
      }
    }
    /*Swal.fire({
      text: 'Thank you! Your appointment has been successfully scheduled.'
      
    })*/
    this.makeAPi(body);
    /*if(this.reasonCode){
      Swal.fire({
        text: 'Thank you! Your appointment has been successfully scheduled.',
        icon: 'question',
        confirmButtonText: 'Yes',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33'
        
        
      }).then((result) => {
        if (result.value) {
          this.makeAPi(body);
        }
      });
    } else {
      this.makeAPi(body);
    }*/

  }

  

  formatPhoneNumber(phoneNumberString) {
    let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }

}
