<span *ngIf="utilityService.utilitySettings.qualificationscriptenabled"
  [innerHTML]="utilityService.utilitySettings.qualificationscript">
</span>

<span *ngIf="!smallScreen">
    <div mat-dialog-container style="height: 33vw !important;" class="enroll-card-div">
        <div mat-dialog-title style="margin: 0 !important" class="one-row">
            <pre class="schedule-info"
                style="margin:0 !important; font-size:1.2vw; width:100% !important;float:left;text-align: center;"><b>{{butttonName}} Appointment</b></pre>
            <button mat-button style="height: 2.5vw !important;" class="close-icon" mat-dialog-close>
                <mat-icon style="margin-bottom: 0.5vw;">close</mat-icon>
            </button>
        </div>

        <div mat-dialog-content style="height: 100vw; width:30vw;float:right;margin-top:5vw;">
            <mat-card style="margin-top: 0.5vw;">
                <mat-card-content>
                    <table style="table-layout: fixed;width:100%">

                        <tr>
                            <td colspan="0">
                                <mat-form-field appearance="outline" [color]="primary"
                                    style="width:100%;font-size: 0.9vw;">
                                    <mat-label
                                        *ngIf="utilityService.utilitySettings.custominfoaptnotetb==null">Appointment
                                        Notes/Instructions</mat-label>
                                    <mat-label *ngIf="utilityService.utilitySettings.custominfoaptnotetb!=null"
                                        [innerHTML]="utilityService.utilitySettings.custominfoaptnotetb"></mat-label>
                                    <textarea appearance="outline" [color]="primary" rows="3" cols="30" matInput
                                        [(ngModel)]="ApptnotesObj"></textarea>
                                </mat-form-field>
                            </td>
                        </tr>

                        <tr>
                            <button mat-raised-button class="mainbutton" [disabled]="enable"
                                (click)="onSchedule()">Confirm Appointment</button>
                            <mat-icon *ngIf="showSpinner"><mat-spinner color="primary" diameter="30">
                                </mat-spinner></mat-icon>
                        </tr>
                    </table>

                </mat-card-content>

            </mat-card>
        </div>
        <div mat-dialog-content style=" width:40vw;float:left;margin-top:4vw;text-align: left;color:white">
            <div class="schedule-info" style="font-size:1.2vw;float:left;"><b>Please confirm your appointment for</b>
            </div>
            <br>
            <br>
            <div class="schedule-info">{{bucketObj.SlotStart | date:'EEEE'}},&nbsp;{{bucketObj.SlotStart |
                date:'longDate'}}, at {{bucketObj.SlotStart | date:'shortTime'}}<br><br></div>
            <pre class="schedule-info">{{bucketResponse.CustomerName}}</pre>
            <div class="schedule-info" *ngIf="bucketResponse.Street">
                {{bucketResponse.Street}}
            </div>

            <pre class="schedule-info">{{bucketResponse.City}},{{bucketResponse.State}} {{bucketResponse.Zip}}</pre>
            <pre class="schedule-info">{{formatPhoneNumber(bucketResponse.Phone)}}</pre>
            <pre class="schedule-info">{{bucketResponse.Email1}}<br><br></pre>
            <div class="schedule-info">{{bucketResponse.ScheduleInstruction}}</div>
        </div>
    </div>
</span>
<!-- Mobile screen support Start-->

<span *ngIf="smallScreen">
    <div mat-dialog-container
        style="height: 12vw !important;padding: 20px; background-color: rgb(10, 83, 28); margin: auto;min-height: 280px;color: white">
        <div mat-dialog-title style="text-align:center;">
            <span style="font-size:3vw;text-align:center;margin-left:30px"><b>{{butttonName}} Appointment</b></span>
            <button mat-button style="height: 0.2vw !important;float:right;" mat-dialog-close>
                <mat-icon>close</mat-icon>
            </button>
        </div>

        <div style="font-size:3vw;text-align:left;">Please confirm your appointment for</div>
        <div style="font-size:3vw;text-align:left;">{{bucketObj.SlotStart | date:'EEEE'}},&nbsp;{{bucketObj.SlotStart |
            date:'longDate'}}, at {{bucketObj.SlotStart | date:'shortTime'}}</div><br>
        <div style="font-size:3vw;text-align:left;">{{bucketResponse.CustomerName}}</div>
        <div style="font-size:3vw;text-align:left;" *ngIf="bucketResponse.Street">{{bucketResponse.Street}}</div>
        <div style="font-size:3vw;text-align:left;">{{bucketResponse.City}},{{bucketResponse.State}}
            {{bucketResponse.Zip}}</div>
        <div style="font-size:3vw;text-align:left;">{{formatPhoneNumber(bucketResponse.Phone)}}</div>
        <div style="font-size:3vw;text-align:left;">{{bucketResponse.Email1}}</div><br>
        <div>

            <mat-form-field appearance="outline" [color]="warn"
                style="width:100%;font-size: 2vw; color: white !important">
                <mat-label style="color:white">Appoinment Notes/Instructions</mat-label>
                <textarea rows="5" cols="30" matInput [(ngModel)]="ApptnotesObj"></textarea>
            </mat-form-field>

        </div>
    </div>
    <div style="margin-top:10px;text-align:center;font-size:1vw;">
        <button mat-raised-button class="mainbutton" [disabled]="enable" (click)="onSchedule()">Confirm
            Appointment</button>
        <mat-icon *ngIf="showSpinner"><mat-spinner color="primary" diameter="30">
            </mat-spinner></mat-icon>
    </div>
    <br>
    <div style="font-size:3vw;text-align:left;"><b>Note:</b>&nbsp;{{bucketResponse.ScheduleInstruction}}</div>


</span>

<!-- Mobile screen support End-->