/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {  

  message: string = ""
  cancelButtonText = "Cancel"
  image1=""  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AlertDialogComponent>) {
    if (data) {
      this.message = data.message || this.message;
      if(data.image1!=null && data.image1[0] !==null && data.image1[0]!==undefined&&data.image1[0]!=='')
      this.image1=data.image1[0].IMAGE_CONTENT||this.image1;                   
      if (data.buttonText) {
        this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
        
      }
    }
     if(screen.width<600)
     {
    this.dialogRef.updateSize('300px','500px');
    // this.dialogRef.updatePosition({top:'50px',left:'50px'});
     }
     else
     {
      this.dialogRef.updateSize('500px','500px');
    // this.dialogRef.updatePosition({top:'0px',left:'0px'});
     }

  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }



}
