<app-enrollment-header></app-enrollment-header>

<br />
<div class="intro-text">{{ workOrdername }}</div>
<br />
<mat-card style="width:300px;margin-right:50px;">
  <p style="text-align: center;">{{ ((answered)/(totalQuestions)) | percent }} Answered</p>
  <mat-progress-bar mode="determinate" value="{{((answered)*100/(totalQuestions))}}"></mat-progress-bar>
</mat-card>
<br>

<mat-card>
  <mat-card-content>
    <mat-tab-group mat-align-tabs="center" [selectedIndex]="selectedTabIndex" (selectedTabChange)="onTabClick($event)">
      <mat-tab *ngFor="let tabs of questions.Tabs; index as tabindex">
        <ng-template mat-tab-label>

          <mat-spinner diameter="29" mode="determinate" value="{{ tabAnswers[tabs.TabName][2] }}" class="custom-spinner"
            style="background-image: url('{{ tabs.TAB_IMAGE_CONTENT }}');"></mat-spinner>
          {{ tabs.TabName }}
        </ng-template>

        <br />

        <div class="grid-container">
          <div class="grid-item" *ngFor="let ques of tabs.Questions; index as i">
            <div style="height: 22px; float:right">
              <mat-icon fontIcon="help_outline" color="primary" style="transform: scale(.8);"
                (click)="openAlertDialog(ques.QUESTION_IMAGES,ques.HELP_CONTENT)"
                [matTooltip]="checkHelpContent(ques.HELP_CONTENT)" matTooltipPosition="after" matTooltipClass="Tooltip"
                *ngIf="(ques.QUESTION_IMAGES[0] !=='' && ques.QUESTION_IMAGES[0] !== null && ques.QUESTION_IMAGES[0] !== undefined) || 
            (ques.HELP_CONTENT !=='' && ques.HELP_CONTENT !== null && ques.HELP_CONTENT !== undefined) ">Tooltip</mat-icon>
            </div>
            <div style="height: 74px;clear: right;vertical-align: middle;">
              <h3 [ngClass]="{'asterix--before': ques.QUESTION_REQUIRED }">{{ ques.QUESTION_TEXT }}</h3>
            </div>
            <mat-form-field appearance="outline" *ngIf="ques.QUESTION_TYPE === 'TEXT'">
              <input matInput [(ngModel)]="ques.currentValue" (focusout)="onAnswering(tabindex, i, ques.currentValue)"
                maxlength="{{ ques.RESPONSE_LENGTH }}">
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="ques.QUESTION_TYPE === 'DECIMAL'">
              <input matInput (keypress)="onlyNumbersWithDecimal($event)" (paste)="$event.preventDefault()"
                [(ngModel)]="ques.currentValue" (focusout)="onAnswering(tabindex, i, ques.currentValue)"
                maxlength="{{ ques.RESPONSE_LENGTH }}">
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="fill" *ngIf="ques.QUESTION_TYPE === 'DATE'">
              <mat-label>Choose a date</mat-label>
              <input matInput #resultPickerModel="ngModel" (paste)="$event.preventDefault()" [matDatepicker]="picker"
                [(ngModel)]="ques.currentValue" (ngModelChange)="onAnswering(tabindex, i, ques.currentValue)"
                readonly="true">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>

              <mat-error *ngIf="resultPickerModel.hasError('matDatepickerParse')">
                "{{resultPickerModel.getError('matDatepickerParse').text}}" is not a valid date!
              </mat-error>

            </mat-form-field>

            <span *ngIf="ques.QUESTION_TYPE === 'LABEL'">
              {{ ques.currentValue }}
            </span>

            <mat-form-field appearance="outline"
              *ngIf="ques.QUESTION_TYPE === 'NUMBER' || ques.QUESTION_TYPE === 'INTEGER' || ques.QUESTION_TYPE === 'VALUE'">
              <input matInput type="number" style="text-align: right;" min=0 [(ngModel)]="ques.currentValue"
                (keypress)="onlyNumbers($event)" (focusout)="onAnswering(tabindex, i, ques.currentValue)"
                (paste)="$event.preventDefault()">
            </mat-form-field>

            <mat-form-field appearance="outline" *ngIf="ques.QUESTION_TYPE === 'LIST'">
              <mat-select [(value)]="ques.currentValue" (selectionChange)="onAnswering(tabindex, i, ques.currentValue)">
                <mat-option *ngFor="let selitem of ques.QUESTION_RESPONSES" value="{{selitem.RESPONSE_ID}}"
                  style="font-size: 11px;">
                  {{ selitem.RESPONSE_TEXT }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <app-chargers *ngIf="ques.QUESTION_TYPE === 'CUST_CHRGS'" [loading]="savingIndividual" [question]="ques"
              [numOfChargers]="10" (answered)="onAnswering(tabindex, i, $event)"></app-chargers>

            <app-chargers *ngIf="ques.QUESTION_TYPE === 'CHRGS_RES'" [loading]="savingIndividual" [question]="ques"
              [numOfChargers]="1" (answered)="onAnswering(tabindex, i, $event)"></app-chargers>
          </div>
        </div>
        <div style="text-align:center;min-height: 30px;">
          <button mat-icon-button style="margin-right: 10px;" [hidden]="selectedTabIndex == 0"
            (click)="prevTab()"><mat-icon>arrow_back</mat-icon></button>
          <button mat-icon-button style="margin-left: 10px;" [hidden]="selectedTabIndex == questions.Tabs.length - 1"
            (click)="nextTab()"><mat-icon>arrow_forward</mat-icon></button>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card-content>
  <mat-card-actions style="text-align:center">
    <button mat-raised-button class="mainbutton" routerLink="/programtypes">Back</button>
    <button mat-raised-button class="mainbutton" (click)="enroll()" *ngIf="!readyToVerify">Verify</button>
  </mat-card-actions>
</mat-card>

<app-enrollment-footer></app-enrollment-footer>