<app-enrollment-header></app-enrollment-header>

<!-- <mat-spinner diameter="100" style="text-align: center;margin:0 auto; margin-top:5vw" color="primary"
  *ngIf="response == null || response == undefined"></mat-spinner> -->
  <br>
  <!--<div> <h2 class="scheduleinstruction" [innerHTML]="scheduleInstruction"></h2></div>-->
  <div>
    <mat-card class="mat-elevation-z0" style="margin-left:25px;">
      <mat-card-header>
        <mat-card-title style="font-size:16px;text-align:left;" [innerHTML]="scheduleInstruction"></mat-card-title>
      </mat-card-header>
    </mat-card>
  </div>
  
  <div *ngIf="response != null" class="flex-container">
  <div class="enroll-card-div enroll-card-flex1">
    <mat-card style="width: 90%">
      <br>
      <br>
      <h2  style="font-size: 16px;">Available Appointments for </h2>
      <pre class="schedule-info">{{response.Street}}</pre>
      <pre class="schedule-info">{{response.City}},{{response.State}} {{response.Zip}}</pre>
      <pre class="schedule-info">{{formatPhoneNumber(response.Phone)}}</pre>
      <pre class="schedule-info">{{response.Email1}}</pre>
      <br>
      <mat-form-field appearance="outline" style="padding-right: 2.2vw; width:75%">
        <mat-label>Select Appointment Date</mat-label>
        <input matInput [matDatepicker]="datePicker" placeholder="Choose a date" [(ngModel)]="selectedDate"  [matDatepickerFilter]="myAvailableFilter"
          (dateInput)="onDate($event)" [min]="minDate" [max]="maxDate">
          <mat-hint>MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
        <mat-datepicker #datePicker (selectedChanged)="onDate($event)"></mat-datepicker>
        <mat-datepicker #picker disabledDates="disabledDates"></mat-datepicker>
      </mat-form-field>  
    </mat-card>
  </div>

  <div class="enroll-card-div enroll-card-flex2">
    <mat-card *ngFor="let day of days" style="width: 90%;">
      <mat-card-header>
        <mat-card-title style="font-size: 16px">{{day | date:'EEEE'}},&nbsp;{{ day | date:'longDate'}}</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <h3 *ngIf="response.AvailableSlotsResources.length == 0">No available slots.</h3>

        <button *ngFor="let bucket of response.AvailableSlotsResources" mat-raised-button 
          class="schedule-button" matTooltip="Resource Availability : {{bucket.AvailableResources}}"
          (click)="openTimeSlotDialog(bucket,response)">
          {{ slotlabel(bucket.SlotStart, bucket.SlotEnd) }}
        </button>
      </mat-card-content>
    </mat-card>
  </div>
  <br>
<br>
</div>
<div align="right" [innerHTML]="utilityService.utilitySettings.custominfocallback" *ngIf="utilityService.utilitySettings.custominfocallback!=null">
  Test footer for consumers
</div>
<app-enrollment-footer></app-enrollment-footer>
