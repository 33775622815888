<!--NORMAL SCREEN START-->

<span *ngIf="!smallScreen">

<div *ngIf="!isShowHeader" >
  <mat-toolbar class="portalbar">
  </mat-toolbar>
  <div [innerHTML]="utilityService.utilitySettings.headerHtml">
  </div>
</div>
<div *ngIf="isShowHeader" >
  <div *ngIf="isHeaderCustomization==1">
  <mat-toolbar class="portalbar">
  
    <a button mat-icon-button class="header-icon" [routerLink]="['/main']">
      <mat-icon>home</mat-icon>
      
    </a>
    <div id="portalheaderdiv" [innerHTML]="utilityService.utilitySettings.headerHtml">
    </div>
  
    <span class="header-spacer"></span>  

        <button mat-icon-button [matMenuTriggerFor]="menu" class="header-myrebates" *ngIf="utilityService.utilitySettings.custominfomyprofile!=null">
          <mat-icon>account_circle</mat-icon><span>{{ utilityService.utilitySettings.custominfomyprofile   }}</span>
          
        </button>
       
    <mat-menu #menu="matMenu">
     
      <button *ngIf="dataTransfer.showItNow" mat-menu-item routerLink="/enrollments">
        <span>Enrollments</span>
      </button>
    
      <button mat-menu-item (click)="gotoMain()">
        <span>Logout</span>
      </button>
    </mat-menu>
    </mat-toolbar>
    <mat-toolbar style="background-color: white;height: 2px">
    </mat-toolbar>
    <mat-toolbar class="middleheaderbar">
    </mat-toolbar>
    <mat-toolbar class="middleheaderbar1">
    </mat-toolbar>
    <mat-toolbar class="lowheaderbar">
    </mat-toolbar>
    <br>
    <br>
  </div>
  <div *ngIf="isHeaderCustomization!=1">
    <mat-toolbar class="portalbar">
  
      <a button mat-icon-button class="header-icon" [routerLink]="['/main']">
        <mat-icon>home</mat-icon>
      </a>
      <h2 class="portaltitle">{{ utilityService.utilitySettings.title }}</h2>
      <span class="header-spacer"></span>

          <button mat-icon-button [matMenuTriggerFor]="menu" class="header-icon" *ngIf="utilityService.utilitySettings.custominfomyprofile!=null">
            <mat-icon>account_circle</mat-icon><span class="font-style-bold">{{ utilityService.utilitySettings.custominfomyprofile   }}</span>
            
          </button>
         
      <mat-menu #menu="matMenu">
       
        <button *ngIf="dataTransfer.showItNow" mat-menu-item routerLink="/enrollments">
          <span>Enrollments</span>
        </button>
      
        <button mat-menu-item (click)="gotoMain()">
          <span>Logout</span>
        </button>
        </mat-menu>
      </mat-toolbar>
    <div id="portalheaderdiv" [innerHTML]="utilityService.utilitySettings.headerHtml">
    </div>
</div>
</div>
</span>
<!--NORMAL SCREEN END-->


<!--MOBILE SCREEN START-->
<span *ngIf="smallScreen">

<div *ngIf="!isShowHeader" >
  <mat-toolbar class="portalbar">
  </mat-toolbar>
  <div [innerHTML]="utilityService.utilitySettings.headerHtml">
  </div>
</div>
<div *ngIf="isShowHeader" >
  <div *ngIf="isHeaderCustomization==1">
  <mat-toolbar class="portalbar">
  
    <a button mat-icon-button class="mheader-icon" [routerLink]="['/main']">
      <mat-icon>home</mat-icon>
    </a>

    <div id="portalheaderdiv" [innerHTML]="utilityService.utilitySettings.headerHtml">
    </div>
    <span class="header-spacer"></span>

    <button mat-icon-button [matMenuTriggerFor]="menu" class="header-myrebates" *ngIf="utilityService.utilitySettings.custominfomyprofile!=null">
      <mat-icon>account_circle</mat-icon><span>{{ utilityService.utilitySettings.custominfomyprofile   }}</span>
      
    </button>

           
    <mat-menu #menu="matMenu">
     
      <button *ngIf="dataTransfer.showItNow" mat-menu-item routerLink="/enrollments">
        <span>Enrollments</span>
      </button>
    
      <button mat-menu-item (click)="gotoMain()">
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar>
 
  <mat-toolbar style="background-color: white;height: 2px">
  </mat-toolbar>
  <mat-toolbar class="middleheaderbar">
  </mat-toolbar>
  <mat-toolbar class="middleheaderbar1">
  </mat-toolbar>
  <mat-toolbar class="lowheaderbar">
  </mat-toolbar>
 
</div>


<div *ngIf="isHeaderCustomization!=1">
  <mat-toolbar class="portalbar">

    <a button mat-icon-button class="header-icon" [routerLink]="['/main']">
      <mat-icon>home</mat-icon>
    </a>
    <h2 class="portaltitle">{{ utilityService.utilitySettings.title }}</h2>
    <span class="header-spacer"></span>  

        <button mat-icon-button [matMenuTriggerFor]="menu" class="header-icon" *ngIf="utilityService.utilitySettings.custominfomyprofile!=null">
          <mat-icon>account_circle</mat-icon><span class="font-style-bold">{{ utilityService.utilitySettings.custominfomyprofile   }}</span>
          
        </button>
       
    <mat-menu #menu="matMenu">
     
      <button *ngIf="dataTransfer.showItNow" mat-menu-item routerLink="/enrollments">
        <span>Enrollments</span>
      </button>
    
      <button mat-menu-item (click)="gotoMain()">
        <span>Logout</span>
      </button>
      </mat-menu>
    </mat-toolbar>
   
  <div id="portalheaderdiv" [innerHTML]="utilityService.utilitySettings.headerHtml">
  </div>
</div>
</div>
</span>
<!--Mobile  SCREEN END-->

<!--test


        <div class="mheader-profileicon">
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="utilityService.utilitySettings.custominfomyprofile==null" >
          <mat-icon>account_circle</mat-icon><b>My Profile</b>
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="utilityService.utilitySettings.custominfomyprofile!=null" >
          <mat-icon>account_circle</mat-icon><b>{{ utilityService.utilitySettings.custominfomyprofile   }}</b>
        </button>
      </div>
      
    <mat-menu #menu="matMenu">
      
      <button *ngIf="dataTransfer.showItNow" mat-menu-item routerLink="/enrollments">
        <span>Enrollments</span>
      </button>
      <button mat-menu-item (click)="gotoMain()">
        <span>Logout</span>
      </button>
   
    </mat-menu>
    
  </mat-toolbar>
  <mat-toolbar class="middleheaderbar">
  </mat-toolbar>
  <mat-toolbar class="middleheaderbar1">
  </mat-toolbar>
  <mat-toolbar class="lowheaderbar">
  </mat-toolbar>
  <div id="portalheaderdiv" [innerHTML]="utilityService.utilitySettings.headerHtml">
  </div>
</div>

</span>

Test-->