/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
//This workorder service is used to upload the files
export class WorkorderService {

  private woSearchWsUrl1 = environment.apiUrl + 'workorder/';
  private saveRebateUrl = environment.apiUrl;
  constructor(private utilityService:UtilityService, private http:HttpClient) { }

  //To upload file content
  uploadFile(contentBody,workorderID): Observable<any[]> {
    console.log("uploadFile");
    const searchUrl = this.woSearchWsUrl1 + "documents/upload/" + this.utilityService.currentUtility
      + '/id/' + workorderID + '?code=' + environment.apiCode;
    const body = contentBody;
    console.log("SearchUrl",searchUrl );
    return this.http.post<any>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
   }
   
   //SAVE REBATE WORKORDER
   saveRebateWorkOrder(workorderID,userId,isEP): Observable<any[]> {
    
    const searchUrl = this.saveRebateUrl + "rebate/"+ this.utilityService.currentUtility+"/workorder/" + workorderID + "/user/" + userId +"/"+ isEP+'?code=' + environment.apiCode;
    
    const body = "save";
    return this.http.post<any>(searchUrl,body,this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

 //To view the file
  getDocumentsForWorkorder(workorderID): Observable<any[]> {
    console.log("View Test");
    const searchUrl = this.woSearchWsUrl1 + "documents/get/" + this.utilityService.currentUtility
      + '/id/' + workorderID + '?code=' + environment.apiCode;
      console.log("SearchURL",searchUrl);
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => this.utilityService.handleError(err, this, []))
      );
  }

  //To open PDF file
  openPdfFile(file){
    var arrBuffer = this.base64ToArrayBuffer(file.FileContent);
    var blob = new Blob([arrBuffer], { type: 'application/pdf' });
    const downloadURL = URL.createObjectURL(blob);
    /*   var a = document.createElement("a");
      document.body.appendChild(a);
      a.href = downloadURL;
      a.download = fileName
      a.click(); */
    window.open(downloadURL);
  }

  //To open image file
  openImageFile(file){
    let type = 'image/' + file.FileType;
    var arrBuffer = this.base64ToArrayBuffer(file.FileContent);
    var blob = new Blob([arrBuffer], { type: type });
    const downloadURL = URL.createObjectURL(blob);
    window.open(downloadURL);
  }
  base64ToArrayBuffer(data) {
    var binaryString = window.atob(data);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };
  
  private getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-email': 'SEHonAdmin@honeywell.com',
            'x-programsponsorid': this.utilityService.currentUtility
        })
    };
    console.log("httpoptions",httpOptions);
    return httpOptions;
}

}

