<app-enrollment-header></app-enrollment-header>

<div class="intro-text">Your Appointments</div>
<div class="desc-text">Click on scheduled appointment to reschedule or cancel.</div>

<mat-selection-list #shoes [multiple]="false" class="appointment">
  <ng-container *ngFor="let appt of appointments">
    <mat-list-option *ngIf="['SCHEDULED', 'CLOSED', 'CANCELLED'].indexOf(appt.AppointmentStatus) > -1 || !appt.AppointmentStatus" [value]="appt">
      <button mat-raised-button (click)="gotoAppointment(appt.Workorder, appt.WorkorderType, appt.AppointmentStatus, appt)" class="mainbutton">
        Workorder ID  {{appt.Workorder}}&nbsp;({{appt.WorkorderType}})&nbsp;appointment&nbsp;{{appt.AppointmentStatus}}&nbsp;<span *ngIf="appt.AppointmentDate"></span>on&nbsp;{{appt.AppointmentDate | date:'EEEE'}}&nbsp;{{appt.AppointmentDate | date:'longDate'}}&nbsp;<span *ngIf="appt.AppointmentTime"></span>at&nbsp;{{appt.AppointmentTime}}</button>
  </mat-list-option>
  </ng-container>
</mat-selection-list>


<app-enrollment-footer></app-enrollment-footer>
