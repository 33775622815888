/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, ElementRef, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import { DataTransferService } from 'src/app/services/data-transfer.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilityService } from '../../../services/utility.service';
import { MatDialog } from "@angular/material/dialog";
import { FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import {Input} from '@angular/core';
import { RebateService } from 'src/app/services/rebate.service';
import * as htmlToImage from "html-to-image";
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.scss'],
 // encapsulation:ViewEncapsulation.None
})
export class OtherComponent implements OnInit {

  constructor( public utilityService: UtilityService,
    public dataTransfer:DataTransferService, private spinner: NgxSpinnerService,
    private rebate: RebateService, @Inject(DOCUMENT) private coreDoc: Document) {    
     }

  expandedItem:any;
  WorkorderId:any;
  response:any;
  isSaveEnabled=false;
  @Input() data: any;
  ///-------------HPWH------------
  locationArray: any = [];
  storeArray: any = [];
  barccodeValue: any = null;

  equals(objOne, objTwo) {
    if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
      return objOne.Id === objTwo.Id;
    }  
  }

ngOnInit(): void {  
  console.log("OtherData,", this.data)  
  this.WorkorderId = this.data.WorkorderId;//'W009737262' ;// localStorage.getItem('WorkorderId');   
  this.getData(); 
  
        

}
  
   getData(): void{
   this.spinner.show();  
   this.isSaveEnabled=false;   
    this.rebate.getOtherInfo( this.WorkorderId).subscribe((res:any)=>{                           
      this.response = res;
      ///-------------HPWH------------
      this.barccodeValue = this.response?.BarcodeId;
      if (this.response?.NoBarcodeMessage != undefined) {
        Swal.fire({
          customClass: {
            confirmButton: 'swal2-popup-confirm',
          },
          text: this.response?.NoBarcodeMessage,
          icon: 'warning',
          confirmButtonText: 'OK',
        });
      }
      this.response?.Sections[0]?.DetailedInfo.forEach(element => {
        if (element.AttributeId.toLowerCase() == 'dealer_id' && element.IsStore != undefined) {
          this.storeArray = element.PossibleValues;
          element.SelectedId != null ? this.onDropdownChange(element.SelectedId) : "";
        }
      });
      //---------------End of HPWH------------
      this.setItem(res);//set for submit   
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    });  
  }
  setItem(res) {
    let copyResp = res;
    copyResp.Sections.forEach((element) =>
      element.DetailedInfo.forEach((item) => {
        if (item.Mandatory == 'Yes' && item.DataType == "CHECKBOX") {
          if (item.SelectedValue==true || item.SelectedValue==1)
            this.rebate.setItem('OtherComponent', true);
          else
            this.rebate.setItem('OtherComponent', false);
        }
        if (item.DataType == "CHECKBOX") {
          item.SelectedValue = (item.SelectedValue == true) ? 1 : 0;
          item.SelectedId = (item.SelectedValue == true) ? 1 : 0;
        }
      }));
  }
 
  dataChanged() {
    let validation=true;
    this.rebate.setItem('OtherComponent', false);
    this.response.Sections[0].IsSectionModified=true; 
    let copyResp = this.response;
    copyResp.Sections.forEach((element) =>
      element.DetailedInfo.forEach((item) => {
        console.log("item", item);
        if (item.Mandatory == 'Yes') {
          if ((item.SelectedValue == null || item.SelectedValue == false) && item.DataType != 'DROPDOWN')
            validation = false;
          if ((item.SelectedId == null || item.SelectedId == "") && item.DataType == 'DROPDOWN')
            validation = false;
        }
        if (item.DataType == "CHECKBOX") {
          item.SelectedValue = (item.SelectedValue == true) ? 1 : 0;
          item.SelectedId = (item.SelectedValue == true) ? 1 : 0;
        }
      }));

    if (validation) {
      this.isSaveEnabled = true;
      //  this.rebate.setItem('other', false);
    }
    else {
      this.isSaveEnabled = false;
    }
  }

  save() {
    this.spinner.show(); 
    console.log(this.response.Sections[0].DetailedInfo);   
    this.rebate.saveOtherInfo(this.WorkorderId, this.response).subscribe((res:any)=>{
      console.log("Save Response", res); 
      if(res.Action){
        Swal.fire({
          customClass:{       
            confirmButton: 'swal2-popup-confirm',            
           },
          text: res.Message,
           icon:'error',
          confirmButtonText: 'OK',
        });
      } else {
        Swal.fire({
          customClass:{       
            confirmButton: 'swal2-popup-confirm',
            cancelButton: 'swal2-popup-button'  
           },
          text: res.Message,
           icon:'success',
          confirmButtonText: 'OK',
        });
        this.rebate.setItem('OtherComponent', true);
      } 
    
      this.getData();
      this.spinner.hide();
    },
    err => {
      this.spinner.hide();
    });  
  }

  ///-------------HPWH------------
  onDropdownChange($event) {
    this.response?.Sections[0]?.DetailedInfo?.forEach(element => {
      this.isSaveEnabled = false;
      if (element.AttributeId.toLowerCase() == 'question2' && element.IsLocation != undefined) {
        this.locationArray = element?.PossibleValues?.filter(
          (ele) => ele.ItemNo == $event?.value || ele.ItemNo == $event
        );
      }
    });
  }

  @ViewChild("downloadEl") downloadEl: ElementRef;
  downloadDataUrl(dataUrl: string, filename: string): void {
    var a = this.coreDoc?.createElement("a");
    a.href = dataUrl;
    a.download = filename;
    this.coreDoc?.body?.appendChild(a);
    a.click();
    this.coreDoc?.body?.removeChild(a);
  }

  downloadBarcode(): void {
    const theElement = this.downloadEl?.nativeElement;
    htmlToImage?.toPng(theElement).then(dataUrl => {
      this.downloadDataUrl(dataUrl, this.barccodeValue + ".png");
    });
  }
}


