/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTransferService {

  myMethod$: Observable<any>;
  showItNow:any;
  private myMethodSubject = new Subject<any>();

  constructor() {

    this.myMethod$ = this.myMethodSubject.asObservable();
  }

  myMethod(data) {
    this.showItNow = data;
    //this.myMethodSubject.next(data);
  }
}




