/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class EnrollService {

  constructor(private http: HttpClient, private utilityService: UtilityService) { }

  private getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': 'SEHonAdmin@honeywell.com',
        'x-programsponsorid': this.utilityService.currentUtility
      })
    };
    return httpOptions;
  }

  getQuestions(personcompanyid): Observable<any> {
    const searchUrl = environment.apiUrl + "customer/eligibility/QuestionsResponses/" + this.utilityService.currentUtility + "/" + personcompanyid + "?code=" + environment.apiCode;
    console.log(searchUrl);
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))//this.utilityService.handleError(err, this, []))
      );
  }

  getIndividualQuestions(personcompanyid, ProgramdetailId): Observable<any> {
    //{{uri}}/api/customer/individualeligibility/:officeId/:personcompanyId/programdetail/:programdetailId?code={{account-onering-api-key}}
    const searchUrl = environment.apiUrl + "customer/individualeligibility/" + this.utilityService.currentUtility + "/" + personcompanyid + "/programdetail/" + ProgramdetailId + "?code=" + environment.apiCode;
    console.log(searchUrl);
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))//this.utilityService.handleError(err, this, []))
      );
  }

  getRecommendationsByPersonCompanyId(personcompanyId: string): Observable<any> {
    const searchUrl = environment.apiUrl
      + 'customer/eligibility/recommendations/'
      + this.utilityService.currentUtility
      + '/' + personcompanyId
      + '?code=' + environment.apiCode;

    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))//this.utilityService.handleError(err, this, []))
      );
  }
  public getEnrollmentAndWorkorderByPersonCompanyId(personCompanyId: string) {
    // {{uri}}/api/customer/:officeId/userRole/:userRole/:Id?code={{account-onering-api-key}}

    const searchUrl = environment.apiUrl + 'customer/' + this.utilityService.currentUtility
      + '/userRole/' + "ADMIN" + '/' + personCompanyId + '?code=' + environment.apiCode;


    return this.http.get<any>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))// this.utilityService.handleError(err, this, []))
      );
  }

  public saveQuestionsByPersonCompanyId(personcompanyId: string, elList): Observable<any> {

    const searchUrl = environment.apiUrl
      + 'customer/eligibility/saverecommendations/'
      + this.utilityService.currentUtility
      + '/' + personcompanyId
      + '?code=' + environment.apiCode;


    /*   const temp = [];
      const body = temp.concat(elList); */

    return this.http.post(searchUrl, elList, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))// this.utilityService.handleError(err, this, []))
      );
  }

  public verifyIndividualEligibilityQuestions(personCompanyId: string, programDetailID: any) {
    // {{uri}}/api/customer/verifyeligibility/:officeId/:personcompanyId/programdetail/:programdetailId?code={{account-onering-api-key}}

    const searchUrl = environment.apiUrl + 'customer/verifyeligibility/' + this.utilityService.currentUtility
      + '/' + personCompanyId + '/programdetail/' + programDetailID + '?code=' + environment.apiCode;


    return this.http.get<any>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))// this.utilityService.handleError(err, this, []))
      );
  }

  public saveIndividualQuestionsByPersonCompanyId(personcompanyId: string, elList, programDetailId: any, group: any): Observable<any> {

    // {{uri}}/api/customer/eligibility/updateeligibility/:officeId/:personcompanyId?code={{account-onering-api-key}}
    const searchUrl = environment.apiUrl
      + 'customer/eligibility/updateeligibility/'
      + this.utilityService.currentUtility
      + '/' + personcompanyId
      + '/' + programDetailId
      + '/Group/' + group
      + '?code=' + environment.apiCode;


    return this.http.post(searchUrl, elList, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))// this.utilityService.handleError(err, this, []))
      );
  }

  public enrollCustomer(personcompanyId: string, userId: string, programDetailId: string, agreeTerms: any, group: any, landlordconsent: any): Observable<any> {
    const searchUrl = environment.apiUrl
      + 'customer/eligibility/enrolldrcustomer/'
      + this.utilityService.currentUtility
      + '/' + personcompanyId
      + '/Group/' + group
      + '?code=' + environment.apiCode;

    const body = {
      UserId: userId,
      PersonCompanyId: personcompanyId,
      ProgramdetailId: programDetailId,
      TermsConditions: agreeTerms,
      Group: group,
      LandLordConsent: landlordconsent,
    };

    return this.http.post(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))// this.utilityService.handleError(err, this, []))
      );
  }

}
