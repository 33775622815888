<div   style=" float: right;top:-24px;right:-24px">
    <button mat-icon-button class="mainbutton" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>
    </div>

<h1 mat-dialog-title class="intro-text">View Files</h1>
<div style=" overflow:scroll" mat-dialog-content class="enrollmentPanel enroll-card-div">

    <mat-card style="width: 40vw;">
        <mat-card-content>
            <div id="loading" *ngIf="loadFile">
                <mat-spinner style="left:40%;top:50%"  [diameter]="50" color="warn"></mat-spinner>
            </div>
            <mat-list *ngIf="!loadFile">
                <mat-list-item *ngFor="let file of files">
                    <button matTooltip="File" style="width: 100% !important; font-family: Prometo,Roboto,sans-serif" mat-button>{{file.FileName}}
                        <mat-icon>folder</mat-icon>
                    </button>
                </mat-list-item>
            </mat-list>

        </mat-card-content>
    </mat-card>
</div>