/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component } from '@angular/core';

import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-enrollment-footer',
  templateUrl: './enrollment-footer.component.html',
  styleUrls: ['./enrollment-footer.component.scss']
})
export class EnrollmentFooterComponent {

  constructor(public utilityService: UtilityService) { }

}
