/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, HostListener } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd,ActivatedRoute} from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { UtilityService } from './services/utility.service';
declare const gtag: Function;
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'enrollment';
  constructor( private http: HttpClient,private utilityService: UtilityService,private router: Router,private activatedRoute: ActivatedRoute,private titleService: Title) {
    
    this.addGAScript();
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
        /** START : Code to Track Page View  */
        gtag('event', 'page_view', {
            page_path: event.urlAfterRedirects
        })
        /** END */
    })
}
@HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    $event.returnValue = true;
  }
ngOnInit() {
 
    this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      )
      .subscribe(() => {
 
        var rt = this.getChild(this.activatedRoute)
 
        rt.data.subscribe(data => {
          console.log(data);
          this.titleService.setTitle(data.title)})
      })
 
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
 
  }
  public getHeaders(util) {
    const httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'x-email': 'SEHonAdmin@honeywell.com',
            'x-programsponsorid': util // 'FEPA'
        })
    };

    return httpOptions;
}
/** Add Google Analytics Script Dynamically */
addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    var util="";
    
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    util = urlParams.get('util');
    console.log("utilParam",util);
    localStorage.setItem('utilParam', util);
    
        if(util)
        {

            let promise = new Promise((resolve, reject) => {
            const searchUrl = `${environment.apiUrl}selfschedule/utilitySettings/${util}?code=${environment.apiCode}`;
            this.http.get(searchUrl, this.getHeaders(util))
              .toPromise()
              .then((res: any) => { // Success
                  console.log('utility', res);
                  localStorage.setItem('Gatag', res.Gatag);
                  let gaTag= localStorage.getItem('Gatag');
                  console.log("gTag",gaTag);
                  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id='+gaTag;
                  document.head.prepend(gtagScript);
                  /** Disable automatic page view hit to fix duplicate page view count  **/
                  gtag('config', gaTag, { send_page_view: false });
  
                }
              ).catch(
                  err=> {
                     // go to error
                     console.log('utility err', err);
                     this.router.navigate(['/utilityerror']);
                  }
              );
          });
          
        }
        else
        {
          let gaTag= localStorage.getItem('Gatag');
          gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id='+gaTag;
          document.head.prepend(gtagScript);
          /** Disable automatic page view hit to fix duplicate page view count  **/
          gtag('config', gaTag, { send_page_view: false });
        }
}

}
