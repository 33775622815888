/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChargerDialogComponent } from 'src/app/custom/chargers/charger-dialog/charger-dialog.component';
import { ChargerData } from './charger-data';
import { Charger } from './charger-model';

@Component({
  selector: 'app-chargers',
  templateUrl: './chargers.component.html',
  styleUrls: ['./chargers.component.scss']
})
export class ChargersComponent implements OnInit {

  @Input() question = '';
  @Input() loading = false;
  @Input() disableControl = false;
  @Input() numOfChargers = 10;  // this number can  be changed in the html
  @Output() answered = new EventEmitter<any[]>();

  chargerData: ChargerData;

  numOfQuestions = 5;  // 4, 5 or 6.  port type and juice net id are the extras

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    this.chargerData = new ChargerData();

    this.loadChargers();
  }

  loadChargers() {
    this.numOfQuestions = this.question['QUESTIONS']["QUESTIONS_ARRAY"].length / this.numOfChargers;
    this.chargerData.juiceLinkIdUrl = this.question['QUESTIONS']["QUESTIONS_ARRAY"][3].JUICENET_LINK;
    this.chargerData.hasPortType = this.numOfQuestions > 5;
    this.chargerData.hasJuiceNetId = this.numOfQuestions > 4;

    let questionNo = 0;

    for (let i = 0; i < this.numOfChargers; i++) {
      if (this.question['QUESTIONS']["QUESTIONS_ARRAY"][i * this.numOfQuestions]["ELIGIBILITY_ID"] !== undefined && this.question['QUESTIONS']["QUESTIONS_ARRAY"][i * this.numOfQuestions]["ELIGIBILITY_ID"] !== null) {
        // found saved answer
        var charger = new Charger();

        let eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
        charger.manufacturerID = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_ID;
        charger.manufacturerName = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_TEXT;

        questionNo++;

        eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
        charger.modelID = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_ID;
        charger.modelName = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_TEXT;

        questionNo++;

        eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
        charger.serialNumber = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_TEXT;

        questionNo++;

        // Juice Net ID
        eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
        charger.juiceNetId = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_TEXT;

        questionNo++;

        eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
        if (eligibilityIndex >= 0) {
          charger.networkID = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_ID;
          charger.networkName = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_TEXT;
        }

        questionNo++;

        if (this.chargerData.hasPortType) {
          eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
          if (eligibilityIndex >= 0) {
            charger.portTypeID = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_ID;
            charger.portTypeName = this.question['QUESTIONS']["QUESTIONS_ARRAY"][questionNo].QUESTION_RESPONSES[eligibilityIndex].RESPONSE_TEXT;
          }

          questionNo++;
        }

        this.chargerData.chargers.push(charger);
      } else {
        questionNo = questionNo + this.numOfQuestions;
      }
    }
  }

  updateLists() {
    if (this.chargerData.manufacturerList === undefined) {
      this.chargerData.manufacturerList = this.question['QUESTIONS']["QUESTIONS_ARRAY"][0]["QUESTION_RESPONSES"];
    }

    if (this.chargerData.modelList === undefined) {
      this.chargerData.modelList = this.question['QUESTIONS']["QUESTIONS_ARRAY"][1]["QUESTION_RESPONSES"];
    }

    if (this.chargerData.networkList === undefined) {
      this.chargerData.networkList = this.question['QUESTIONS']["QUESTIONS_ARRAY"][4]["QUESTION_RESPONSES"];
    }

    if (this.chargerData.hasPortType && this.chargerData.portTypeList === undefined) {
      this.chargerData.portTypeList = this.question['QUESTIONS']["QUESTIONS_ARRAY"][5]["QUESTION_RESPONSES"];
    }
  }

  openDialog() {
    this.updateLists();
    const dialogRef = this.dialog.open(ChargerDialogComponent, {
      data: {
        cdata: this.chargerData,
        numChargers: this.numOfChargers
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.answered.emit(this.buildResponse());
    });
  }

  buildResponse(): any[] {
    let res = [];

    let cnt = 1;
    let chrgno = 1;

    this.chargerData.chargers.forEach(c => { this.questionsFromCharger(res, chrgno, c); cnt++; chrgno = chrgno + this.numOfQuestions; });

    for (var x = cnt; x <= this.numOfChargers; x++) {
      this.questionsFromCharger(res, chrgno, null);
      chrgno = chrgno + this.numOfQuestions;
    }

    return res;
  }

  questionsFromCharger(target: any[], chargerNo: number, charger: Charger) {

    // manufacturer ///////////////////////
    const q1 = {
      QUESTION_ID: this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo - 1].QUESTION_ID,
      RESPONSE_ID: charger === null ? null : charger.manufacturerID,
      RESPONSE_TEXT: charger === null ? null : charger.manufacturerName,
    };

    let eligibilityIndex = -1;
    let eligibility_Id = '';

    eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo - 1].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
    if (eligibilityIndex > -1) {
      eligibility_Id = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo - 1].QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
    } else {
      eligibility_Id = null;
    }

    if (eligibility_Id !== null) {
      q1["ELIGIBILITY_ID"] = eligibility_Id;
    }
    else
      if (charger !== null) {
        q1["ELIGIBILITY_ID"] = null;
      }

    target.push(q1);

    // model type ///////////////////////////////
    const q2 = {
      QUESTION_ID: this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo].QUESTION_ID,
      RESPONSE_ID: charger === null ? null : charger.modelID,
      RESPONSE_TEXT: charger === null ? null : charger.modelName,
    };

    eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
    if (eligibilityIndex > -1) {
      eligibility_Id = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo].QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
    } else {
      eligibility_Id = null;
    }

    if (eligibility_Id !== null) {
      q2["ELIGIBILITY_ID"] = eligibility_Id;
    }
    else
      if (charger !== null) {
        q2["ELIGIBILITY_ID"] = null;
      }

    target.push(q2);

    // serial number ////////////////
    const q3 = {
      QUESTION_ID: this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 1].QUESTION_ID,
      RESPONSE_ID: charger === null ? null : this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 1]["QUESTION_RESPONSES"][0]["RESPONSE_ID"],
      RESPONSE_TEXT: charger === null ? null : charger.serialNumber,
    };

    eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 1].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
    if (eligibilityIndex > -1) {
      eligibility_Id = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 1].QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
    } else {
      eligibility_Id = null;
    }

    if (eligibility_Id !== null) {
      q3["ELIGIBILITY_ID"] = eligibility_Id;
    }
    else
      if (charger !== null) {
        q3["ELIGIBILITY_ID"] = null;
      }

    target.push(q3);

    // Juice Net ID ////////////////
    const q6 = {
      QUESTION_ID: this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 2].QUESTION_ID,
      RESPONSE_ID: charger === null ? null : this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 2]["QUESTION_RESPONSES"][0]["RESPONSE_ID"],
      RESPONSE_TEXT: charger === null ? null : charger.juiceNetId,
    };

    eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 2].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
    if (eligibilityIndex > -1) {
      eligibility_Id = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 2].QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
    } else {
      eligibility_Id = null;
    }

    if (eligibility_Id !== null) {
      q6["ELIGIBILITY_ID"] = eligibility_Id;
    }
    else
      if (charger !== null) {
        q6["ELIGIBILITY_ID"] = null;
      }

    target.push(q6);

    // network type //////////////////
    let netrescid = null;

    if (charger != null) {
      let netrescid_idx = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 3].QUESTION_RESPONSES.findIndex(val => val.RESPONSE_TEXT == charger.networkName);
      if (netrescid_idx > -1) {
        netrescid = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 3].QUESTION_RESPONSES[netrescid_idx].RESPONSE_ID;
      }
    }

    const q4 = {
      QUESTION_ID: this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 3].QUESTION_ID,
      RESPONSE_ID: charger === null ? null : netrescid,
      RESPONSE_TEXT: charger === null ? null : charger.networkName,
    };

    eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 3].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
    if (eligibilityIndex > -1) {
      eligibility_Id = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 3].QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
    } else {
      eligibility_Id = null;
    }

    if (eligibility_Id !== null) {
      q4["ELIGIBILITY_ID"] = eligibility_Id;
    }
    else
      if (charger !== null) {
        q4["ELIGIBILITY_ID"] = null;
      }

    target.push(q4);

    // Port type  /////////////////////
    if (this.chargerData.hasPortType) {
      let portrescid = null;

      if (charger != null) {
        let portrescid_idx = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 4].QUESTION_RESPONSES.findIndex(val => val.RESPONSE_TEXT == charger.portTypeName);
        if (portrescid_idx > -1) {
          portrescid = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 4].QUESTION_RESPONSES[portrescid_idx].RESPONSE_ID;
        }
      }

      const q5 = {
        QUESTION_ID: this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 4].QUESTION_ID,
        RESPONSE_ID: charger === null ? null : portrescid,
        RESPONSE_TEXT: charger === null ? null : charger.portTypeName,
      };

      eligibilityIndex = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 4].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
      if (eligibilityIndex > -1) {
        eligibility_Id = this.question['QUESTIONS']["QUESTIONS_ARRAY"][chargerNo + 4].QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
      } else {
        eligibility_Id = null;
      }

      if (eligibility_Id !== null) {
        q5["ELIGIBILITY_ID"] = eligibility_Id;
      }
      else
        if (charger !== null) {
          q5["ELIGIBILITY_ID"] = null;
        }

      target.push(q5);
    }
  }

}
