<mat-accordion class="font-style" #Woaccordion="matAccordion" displayMode="flat" multi="true" [togglePosition]="'after'">

  <mat-expansion-panel [expanded]="true" style="margin-top: 1vw;margin-right: 1.3vw;" #panel
    *ngFor="let tile of response?.Sections;let i = index;" (opened)="accordionOpen(i)">
    <mat-expansion-panel-header class="mat-header-work-qa" *ngIf="tile.SectionName != ' '">
      <mat-panel-title  class="mat-header-color  font-style"
        [ngClass]="(panel.expanded?'panel-expanded':'panel-collapse' )" >
       <div class="measure-section">
        {{tile.SectionName}}
       </div>
      </mat-panel-title>
      <mat-panel-description>
        <!-- <button mat-button style="margin-right: 0.5vw;height: 2.2vw !important;"
          (click)="$event.stopPropagation();addApplianceInfo(tile,i)">
          <mat-icon style="margin-bottom: 1vw !important;" class="white-icon" matTooltip="Add">add_circle_outline </mat-icon>
        </button>  -->
        
        <button  mat-mini-fab class="addButton" 
        (click)="$event.stopPropagation();addApplianceInfo(tile,i)">
        <mat-icon  matTooltip="Add">add </mat-icon>
      </button>
      </mat-panel-description>
    </mat-expansion-panel-header>


    <!-- start of detailed layout -->
    <div class="accordion-content font-style">

      <div *ngFor="let subTile of tile?.DetailedInfo; let d = index ">
        <div style="width: 100%;display: flex; flex-direction: row ">
        <div class="mainDiv">
          <div class="grid-container enroll-card-div" >        
          <ng-container *ngFor="let subResponse of subTile?.Attributes">
            <div class="grid-item" [ngStyle]="{'text-align': subResponse.DataType=='RADIOLIST' ? 'center' : 'inherit' }"
              colspan="{{subResponse.DataType=='RADIOLIST' ? '12' : '4'}}">
              <div style="width: 100%;">
                <div style="width: 60%;">
                  <label *ngIf="subResponse.DataType=='RADIOLIST'"
                    style="text-align: left; height: 50%;  font-weight: 500;"
                    [ngClass]="{'asterix': subResponse.Mandatory=='Yes' }">{{subResponse.Name}}
                  </label>
                  <label *ngIf="subResponse.DataType!='RADIOLIST'"
                    style="text-align: left;height: 50%;  font-weight: 500;"
                    [ngClass]="{'asterix': subResponse.Mandatory=='Yes' }">{{subResponse.Name}}
                  </label>
                </div>

                <!-- <mat-form-field appearance="outline" class="work-get-form"
                style="width:50% !important; margin-top:0.5vw; font-size: 0.9vw;"
                *ngIf="subResponse.DataType=='List'">
                <mat-select [(ngModel)]="subResponse.SelectedValue"
                  (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId)"
                  (ngModelChange)="dataChanged_customize($event,i,d,subResponse.AttributeId)"
                 >
                  <mat-option *ngFor="let optionValues of subResponse.Values" [value]="optionValues.Id"
                    style="font-size: 11px;">
                    {{optionValues.Value}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

                <!-- <div appearance="outline" style="width:20% !important;font-size: 0.9vw;"
                *ngIf="subResponse.DataType=='RadioList'">
                <mat-radio-group aria-labelledby="work-get-radio-group-label" class="work-get-radio-group"
                  [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)">
                  <mat-radio-button class="work-get-radio-button" *ngFor="let val of subResponse.Values"
                    [value]="val.Id" [disabled]="(subResponse.IsEditable == 'Yes'? false : true)">
                    {{val.Value}}</mat-radio-button>
                </mat-radio-group>
              </div> -->
                <div style="width: 60%; ">
                  <mat-form-field appearance="" style="height:50% !important;width: 100%;"
                    *ngIf="subResponse.DataType=='STRING'">
                    <ng-container *ngIf="subResponse.Size <=500">
                      <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                        (ngModelChange)="dataChanged($event,i,d)"
                        [required]="subResponse.Mandatory=='Yes'? 'required': null"
                        [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                        [maxlength]="subResponse.Size">
                    </ng-container>
                  </mat-form-field>

                 
                  <mat-form-field appearance="" style="height:50% !important; width: 100%;"   
                    *ngIf="subResponse.DataType=='DATE'">
                    <input matInput  [matDatepicker]="picker"  placeholder="MM/DD/YYYY"
                    [required]="subResponse.Mandatory=='Yes'? 'required': null"  readonly="true"
                    [(ngModel)]="subResponse.SelectedValue"
                    (ngModelChange)="formatDate(subResponse,$event);dataChanged($event,i,d) "                  
                    >
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                
                

               <!-- onkeypress='return event.charCode >= 48 && event.charCode <= 57 && if(this.value.length==10) return false;'
                 onkeypress='if(this.value.length==5) return false; else event.charCode >= 48 && event.charCode <= 57;' -->
                  <mat-form-field appearance="" style="height:50% !important;width: 100%;"
                    *ngIf="subResponse.DataType=='INTEGER'">
                    <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" matInput
                      [required]="subResponse.Mandatory=='Yes'? 'required': null"
                      (ngModelChange)="dataChanged($event,i,d)" min="0" step="1"
                      onkeypress='return event.charCode >= 48 && event.charCode <= 57'                 
                      [maxlength]="subResponse.Size"                    
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" type="text"                  
                      [max]="subResponse.Max">
                  </mat-form-field>      
                  <!-- && this.value.split(".").length-1<1'   -->

             <mat-form-field appearance="" style="height:50% !important;width: 100%;"
                    *ngIf="subResponse.DataType=='DECIMAL'">
                    <input  [(ngModel)]="subResponse.SelectedValue" matInput 
                      [required]="subResponse.Mandatory=='Yes'? 'required': null"                    
                      (keypress)='onKeyPressDecimal($event,subResponse.SelectedValue)'  
                      onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);
                        subResponse.SelectedValue=parseFloat(el.value).toFixed(2); })(this);"                     
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" type="text"
                      (ngModelChange)="dataChanged($event,i,d)"
                      [maxlength]="subResponse.Size-2"
                      [max]="subResponse.Max" >
                  </mat-form-field>             

                  <!-- <mat-form-field appearance="" style="height:50% !important;width: 100%;"
                    *ngIf="subResponse.DataType=='DECIMAL'">
                    <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" matInput id="myHTMLNumberInput"
                      [required]="subResponse.Mandatory=='Yes'? 'required': null" 
                      onchange="(function(el){el.value=parseFloat(el.value).toFixed(2);
                     subResponse.SelectedValue=parseFloat(el.value).toFixed(2); })(this); dataChanged($event,i,d)"
                      onkeypress='return (event.charCode >= 48 && event.charCode <= 57)|| event.charCode ==46'   
                      pattern="^[a-z\d,]*\.?[a-z\d,]*$"
                      [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" type="text"
                      [maxlength]="subResponse.Size"
                      [max]="subResponse.Max" step=".01">
                  </mat-form-field> -->

                  <mat-checkbox style=" height:50% !important;" *ngIf="subResponse.DataType=='Bit'"
                    [(ngModel)]="subResponse.SelectedValue" (ngModelChange)="dataChanged($event,i,d)">
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </ng-container>        
          
        </div>
        </div>

        <div style="width: 20%;" class="deleteDiv">
          <button mat- mat-mini-fab color="accent"  class="deleteButton"
            (click)="onDeleteAppliance(subTile.LineitemId, i, d, tile.SectionId)">
            <mat-icon matTooltip="Delete">delete</mat-icon>
          </button>
        </div>

        <div>
        </div>
      </div>
      </div>
    </div>
    <!-- end of detailed layout -->
  </mat-expansion-panel>
</mat-accordion>
<div class="button-container ">
  <button class="mainbutton font-style" type="button"
    [disabled]="!isSaveEnabled" [disabled]="true" (click)="save()" mat-flat-button>SAVE
  </button>
</div>