/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

export class ValueState {
    refId: string;
    required: boolean = false;
    currentValue: any;
    answered: boolean = false;
    tabName: string;
}