<button mat-raised-button class="mainbutton" style="margin-bottom: 20px;" [disabled]="loading || disableControl" (click)="openDialog()">
  Edit Chargers
</button>
<mat-spinner style="margin-bottom:10px;" *ngIf="loading" [diameter]="20" color="primary"></mat-spinner>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title *ngIf="chargerData.chargers.length == 1">
      {{ chargerData.chargers.length }} Charger Configured
    </mat-panel-title>
    <mat-panel-title *ngIf="chargerData.chargers.length != 1">
      {{ chargerData.chargers.length }} Chargers Configured
    </mat-panel-title>
  </mat-expansion-panel-header>
  <hr>
  <span *ngFor="let charg of chargerData.chargers; index as i">
    <strong>{{ charg.getDisplayLine1() }}</strong>
    <p style="margin-bottom: 0px;">{{ charg.getDisplayLine2() }}</p>
    <p>{{ charg.getDisplayLine3(chargerData.hasPortType, chargerData.hasJuiceNetId) }}</p>
  </span>
</mat-expansion-panel>