/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Injectable } from '@angular/core';
import { UtilitySettings } from '../models/utility-settings';
import { Router } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders,HttpErrorResponse } from '@angular/common/http';
import { catchError, delay, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import Swal from 'sweetalert2';
//import { mainModule } from 'node:process';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    currentUtility: any;
    providerName:any;
    utilitySettings: UtilitySettings;
    programs: string[];
    programtypes: any[];
    loginMethods: any[];
    getSampleStyle:any;
    private programUrl;
    private woSearchWsUrl1;
    private saveEmailAddressUrl = environment.apiUrl;

    constructor(
        private http: HttpClient,
        private authGuard: AuthGuard,
        private router: Router
    ) {
        this.programUrl = environment.apiUrl;
        this.woSearchWsUrl1 = environment.apiUrl + 'workorder/';
    }

    async loadUtilitySettings(util: string) {
        
        this.updateUtility(util);

        this.authGuard.isConfigured = true;
        

       return of(this.utilitySettings);
    }

    private updateUtility(util: string) {
        
        if (util === undefined || util === null || util === '') {
            util = localStorage.getItem('currentUtility');
        } else if(localStorage.getItem('currentUtility') != util){
          localStorage.setItem('currentUtility', util);
          localStorage.setItem('providerName', util);
        } else {
            localStorage.setItem('currentUtility', util);
            localStorage.setItem('providerName', util);
        }

        console.log("util=" + util);

        if (util === undefined || util === null || util === '') {
            // go to error
            this.utilitySettings = undefined;
        } else {
            this.currentUtility = util;
            this.providerName=util;
            this.getDefaultUtilitySettings();    
    }
}

    // mandatory fields - - what is the source of truth for below fields?
    // --------------------
    // OfficeID 
    // x-programsponsrid 

    public getHeaders() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'x-email': 'SEHonAdmin@honeywell.com',
                'x-programsponsorid': this.currentUtility // 'FEPA'
            })
        };

        return httpOptions;
    }

    public getPrograms(): Observable<any> {
        const searchUrl = `${this.programUrl}workorder/${this.currentUtility}/programs?code=${environment.apiCode}`;
        return this.http.get<any>(searchUrl, this.getHeaders())
        .pipe(map(response => response),
            catchError(async (err) => console.log(err))
        );
    }

    public getProgramWorkOrdeList(): Observable<any> {
        //const searchUrl = `${this.programUrl}workorder/${this.currentUtility}/programs/ProgramSponsorId/${this.currentUtility}?code=${environment.apiCode}`;
        const programId = localStorage.getItem('ProgramId')
        const personcompanyId = localStorage.getItem('personcompanyid')
        const searchUrl = `${this.programUrl}selfschedule/workorderTypes/${this.currentUtility}/customer/${personcompanyId}/ALL?code=${environment.apiCode}`;
        return this.http.get<any>(searchUrl, this.getHeaders())
        .pipe(map(response => response),
            catchError(async (err) => console.log(err))
        );
    }
  
  public createWorkorder(data, groupId, subGroupId): Observable<any[]> {
    const createUrl = environment.apiUrl + "bbcs/" + this.currentUtility
      + '/workorder/add/group/' + groupId + '/subgroup/'+ subGroupId + '?code=' + environment.apiCode;

    return this.http.post<any>(createUrl, data, this.getHeaders())
    .pipe(map(response => response),
      catchError(async (err) => console.log(err))
    );
  }

  
  public loadDynamicMenu(workorderID){
    const searchUrl = this.woSearchWsUrl1 + "actions/" + this.currentUtility
      + '/' + workorderID + '/role/Admin?code=' + environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
      catchError(async (err) => console.log(err))
      );
  }
    
    public getCustomerSearchOptions(): Observable<any> {
        const searchUrl = `${this.programUrl}selfschedule/customersearches/${this.currentUtility}?code=${environment.apiCode}`;
        return this.http.get<any>(searchUrl, this.getHeaders())
        .pipe(map(response => response),
            catchError(async (err) => console.log(err))
        );
    }

    getCancelScheduleReasons() {
      const searchUrl = environment.apiUrl + "workorder/appointments/reschedulecancel/reasons/" + this.currentUtility + "?code=" + environment.apiCode;
      return this.http.get<any[]>(searchUrl, this.getHeaders())
        .pipe(map(response => response),
          catchError(async (err) => console.log(err))
        );
    }

    cancelOpenAppointments(scheduleDate, personcompany, reason, orgCode) {

      const searchUrl = environment.apiUrl + "workorder/shedule/cancelappointments/" + this.currentUtility + "/organization/" + orgCode + "/personcompany/" + personcompany+ "?code=" + environment.apiCode;
  
      let scheduleObj = {
        scheduleDate: scheduleDate,
        reason: reason,
        user: this.currentUtility
      }
  
      return this.http.post<any[]>(searchUrl, scheduleObj, this.getHeaders())
        .pipe(map(response => response),
        catchError(async (err) => console.log(err))
        );
    }

    cancelSchedule(workorderID, reason) {

      let cancelObj = {
        user: 'sfen_user',
        reason: reason
      }
  
      const searchUrl = environment.apiUrl + "workorder/unschedule/" + this.currentUtility + "/workorder/" + workorderID + "?code=" + environment.apiCode;
  
  
      return this.http.post<any[]>(searchUrl, cancelObj, this.getHeaders())
        .pipe(map(response => response),
        catchError(async (err) => console.log(err))
        );
  
    }

    public getCustomerOptions(obj): Observable<any> {
        const searchUrl = `${this.programUrl}customer/${this.currentUtility}/search?code=${environment.apiCode}`;
        // const body = {
        //     "SearchType": "ACCOUNT_STREETNUM_SRCH",
        //     "AccountNumber": "1311509706",
        //     "StreetNumber": "576",
        //     "Zip": "",
        //     "FirstName": "",
        //     "LastName": ""        
        // };

        return this.http.post<any>(searchUrl, obj, this.getHeaders())
        .pipe(map(response => response),
            catchError(async (err) => console.log(err))
        );
    }

   
     getDefaultUtilitySettings() {
        let title:any;
        let promise = new Promise((resolve, reject) => {
            const searchUrl = `${this.programUrl}selfschedule/utilitySettings/${this.currentUtility}?code=${environment.apiCode}`;
            this.http.get(searchUrl, this.getHeaders())
              .toPromise()
              .then((res: any) => { // Success
                  console.log('utility', res);
                  
                  const { OfficeId = '' } = res;
                  this.currentUtility = OfficeId;
                  localStorage.setItem('Gatag', res.Gatag);
                  localStorage.setItem('currentUtility', OfficeId)
                  if(res['utilityName']) {
                    this.newStyle(res);
                    if (res === undefined) {
                      this.router.navigate(['/utilityerror']);
                    } else {
                      this.router.navigate(['/main']);
                    }
                  } else {
                    // go to error
                    this.utilitySettings = undefined;
                    this.router.navigate(['/utilityerror']);
                  }

                }
              ).catch(
                  err=> {
                     // go to error
                     console.log('utility err', err);
                     this.utilitySettings = undefined;
                     this.router.navigate(['/utilityerror']);
                  }
              );
          });
          return promise;
      /*   if (this.currentUtility === 'test1') {
            this.getTest1SampleData();
        }
        else if(this.currentUtility === 'APC1'){
            this.newStyle();
        }
         else {
            this.getTest2SampleData();
        } */
    }

   

    getCancelDeactivateReasons(status){
      const searchUrl = this.woSearchWsUrl1 + "reasons/" + this.currentUtility
      + '/status/' + status + '?code=' + environment.apiCode;
    

      return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))
      );
    }

    cancelDeactivateWorkorder(workorderObj){
      const searchUrl = this.woSearchWsUrl1 + "canceldeactivate/" + this.currentUtility
        + '/id/' + workorderObj.workorderID + '?code=' + environment.apiCode;
  
  
      const body = {
        User: workorderObj.User,
        Reason: workorderObj.Reason,
        Status: workorderObj.Status
      };
    
      return this.http.post<any>(searchUrl, body, this.getHeaders())
        .pipe(map(response => response),
        catchError(async (err) => console.log(err))
        );
    }

    saveEmailAddress(officeId,personCompanyId,bodyContent): Observable<any> {
  
      
      const searchUrl = this.saveEmailAddressUrl + "customer/" + officeId + "/" + personCompanyId
        +  '?code=' + environment.apiCode; 
        
      return this.http.patch<any>(searchUrl, bodyContent, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => this.handleError(err, this, []))
        );
    
    
     }

     //To enroll customer in JCPl - START

     enrollCustomer(officeId,bodyContent): Observable<any[]> {
  
      
      const searchUrl = environment.apiUrl  + "customercreate/" + officeId + "/" 
        +  '?code=' + environment.apiCode; 
    
        const body={
    
         Firstname: bodyContent.Firstname,
         LastName: bodyContent.LastName,
         CompanyName:bodyContent.CompanyName,
         AccountNumber: bodyContent.AccountNumber,
         ServiceStreetnumber:bodyContent.ServiceStreetnumber,
         ServiceStreetName:bodyContent.ServiceStreetName,
         ServiceAppartment:bodyContent.ServiceAppartment,
         ServiceCity:bodyContent.ServiceCity,
         ServiceState:bodyContent.ServiceState,
         ServiceZipCode:bodyContent.ServiceZipCode,
         MailStreetNumber:bodyContent.MailStreetNumber,
         MailStreetName:bodyContent.MailStreetName,
         MailAppartment:bodyContent.MailAppartment,
         MailCity:bodyContent.MailCity,
         MailState:bodyContent.MailState,
         MailZip:bodyContent.MailZip,
         PrimaryPhone:bodyContent.PrimaryPhone,
         AlternatePhone:bodyContent.AlternatePhone,
         Email:bodyContent.Email,
         CustomerType:bodyContent.CustomerType,
        }
        
      return this.http.post<any[]>(searchUrl, body, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => this.handleError(err, this, []))
        );
    
     }

     //to request a callback

     requestCallback(officeId,bodyContent): Observable<any[]> {
  
      
      const searchUrl = environment.apiUrl  + "selfschedule/sendemail/" + officeId + '?code=' + environment.apiCode; 
    
        const body={
         Name: bodyContent.Name,
         Phonenumber: bodyContent.Phonenumber,
         Remarks:bodyContent.Remarks
        
        }
        
      return this.http.post<any[]>(searchUrl, body, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => this.handleError(err, this, []))
        );
    
     }

     //To enroll customer in JCPL - END
     
     public getVirtualAuditWOInfo(workorder): Observable<any> {
      const searchUrl = `${this.programUrl}customer/auditreport/${this.utilitySettings.utilityName}/workorder/${workorder}?code=${environment.apiCode}`;
      console.log(searchUrl);
      console.log( this.http.get<any>(searchUrl));
      return this.http.get<any>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
          catchError(async (err) => console.log(err))
      );  

      
  }

  public sendVirtualAuditWOInfo(body,workorder): Observable<any> {
    const searchUrl = `${this.programUrl}audit/notificaton/workorder/${workorder}?code=${environment.apiCode}`;
    console.log(searchUrl);
    console.log("body")
    console.log(body);   
    return this.http.post<any[]>(searchUrl, body, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => this.handleError(err, this, []))
        );
  }
  
    /* private getStyle(){
        let styleObj = {
            "utilityName": "PSEG",
            "Title": "PSEG Enrollment Portal(Test)",
            "headerHtml": "\u003Cimg src=\"https:\/\/www.sdge.com\/sites\/default\/files\/logo.svg\" height=\"100\"\u003E",
            "footerHtml": "Copyright and copy; 2021",
            "mainIntro": "Welcome to PSEG Energy Saving Programs",
            "mainDesc": "You can use this screen to enroll in energy saving programs and schedule service",
            "progTypeIntro": "Welcome to PSEG Energy Saving Programs",
            "progTypeDesc": "You can use this screen to enroll in energy saving programs and schedule service",
            "qualIntro": "Energy Star Rebate",
            "qualDesc": "We need to ask you some questions to qualify you for the Energy Star Rebate.",
            "enrollSuccessIntro": "Congratulations",
            "enrollSuccessDesc": "Your rebate is being prepared. You will receive notifications by email as it processed. You can you also check your rebates status on this web site.",
            "Styles": [
              {
                "styleName": "portaltitle",
                "styleValue": ".portaltitle {padding-left: 30px;}"
              },
              {
                "styleName": "portalfooterdiv",
                "styleValue": "#portalfooterdiv {margin-top: 100px; margin-bottom: 50px; text-align: center;}"
              },
              {
                "styleName": "intro-text",
                "styleValue": ".intro-text {text-align: center; margin-top: 10px; margin-bottom: 10px; font-weight: bold; font-size: 20px;}"
              },
              {
                "styleName": "desc-text",
                "styleValue": ".desc-text {text-align: center; margin-bottom: 50px;}"
              },
              {
                "styleName": "program",
                "styleValue": ".program {margin: 0 auto; max-width: 30%; background-color: red;}"
              },
              {
                "styleName": "mat-card",
                "styleValue": ".mat-card {width: 60%; margin: auto;}"
              },
              {
                "styleName": "mat-form-field",
                "styleValue": ".mat-form-field {width: 50%;}"
              },
              {
                "styleName": "cardheader",
                "styleValue": ".cardheader {background-color: #3F51B5;color: white;margin: 0;padding: 20px;}"
              },
              {
                "styleName": "mainbutton",
                "styleValue": ".mainbutton {background-color: blue; color: white;}"
              },
              {
                "styleName": "qual-intro-div",
                "styleValue": ".qual-intro-div {width: 30%;min-height: 500px;background-color: lightsteelblue;margin-top: 20px;padding-top: 20px;margin-left: auto;margin-bottom: auto;position: -webkit-sticky;position: sticky;top: 0;}"
              },
              {
                "styleName": "qual-notes",
                "styleValue": ".qual-notes {margin: 20px 10px 20px 10px;}"
              },
              {
                "styleName": "qual-page-div",
                "styleValue": ".qual-page-div {max-width: 860px;width: 65%;margin: auto;margin-top: 20px;}"
              },
              {
                "styleName": "qual-card-div",
                "styleValue": ".qual-card-div {padding: 20px;background-color: lightsteelblue;margin: auto;min-height: 500px;}"
              },
              {
                "styleName": "schedule-card",
                "styleValue": ".schedule-card {max-width: 800px; margin-top: 20px; margin-bottom: 20px;}"
              },
              {
                "styleName": "schedule-button",
                "styleValue": ".schedule-button {width: 150px;margin-right: 20px; margin-bottom: 10px; margin-top: 10px;background-color: blue; color: white;}"
              },
              {
                "styleName": "appointment",
                "styleValue": ".appointment {margin: 0 auto;max-width: 80%;}"
              }
            ]
          }
          
          return styleObj;
          
    } */

    private getTest1SampleData() {
        this.programs = ["Apply for Rebate", "Request Energy Audit", "Demand Response"];
        this.programtypes = [
            {"id": "1", "desc": "Energy Star", "selfSchedule": true},
            {"id": "2", "desc": "Water Heater", "selfSchedule": true},
            {"id": "3", "desc": "Medical", "selfSchedule": true}
        ]; 

        this.loginMethods = [
            {"id": "1", "desc": "Login to Existing Account"},
            {"id": "2", "desc": "Account Number"},
            {"id": "3", "desc": "Name and Service Address"}, 
            {"id": "4", "desc": "Name and Phone Number"}
        ];

        // qualification questions
        // schedule

        // Available account verfication methods

        this.utilitySettings = new UtilitySettings();
        this.utilitySettings.utilityName = 'test1';
        this.utilitySettings.title = 'Test One Utility Enrollment Portal';
        this.utilitySettings.headerHtml = '<img src="https://www.sdge.com/sites/default/files/logo.svg" height="100">';
        this.utilitySettings.footerHtml = 'Copyright &copy; 2021';
        this.utilitySettings.mainIntro = 'Welcome to BGE Energy Saving Programs';
        this.utilitySettings.mainDesc = 'You can use this screen to enroll in energy saving programs and schedule service';

        this.utilitySettings.progTypeIntro = 'Apply for Rebate';
        this.utilitySettings.progTypeDesc = 'Click on the rebate program you are interested in';

        this.utilitySettings.qualIntro = 'Energy Star Rebate';
        this.utilitySettings.qualDesc = 'We need to ask you some questions to qualify you for the Energy Star Rebate.';

        this.utilitySettings.enrollSuccessIntro = 'Congratulations';
        this.utilitySettings.enrollSuccessDesc = 'Your rebate is being prepared. You will receive notifications by email as it processed. You can you also check your rebates status on this web site.';

        this.utilitySettings.styles = [
            ".portaltitle {padding-left: 30px;}",
            "#portalfooterdiv {margin-top: 100px; margin-bottom: 50px; text-align: center;}",
            ".intro-text {text-align: center; margin-top: 10px; margin-bottom: 10px; font-weight: bold; font-size: 20px;}",
            ".desc-text {text-align: center; margin-bottom: 50px;}",
            ".program {margin: 0 auto; max-width: 30%; background-color: red;}",
            ".mat-card {width: 60%; margin: auto;}",
            ".mat-form-field {width: 50%;}",
            ".cardheader {background-color: #3F51B5;color: white;margin: 0;padding: 20px;}",
            ".mainbutton {background-color: blue; color: white;}",
            ".qual-intro-div {width: 30%;min-height: 500px;background-color: lightsteelblue;margin-top: 20px;padding-top: 20px;margin-left: auto;margin-bottom: auto;position: -webkit-sticky;position: sticky;top: 0;}",
            ".qual-notes {margin: 20px 10px 20px 10px;}",
            ".qual-page-div {max-width: 860px;width: 65%;margin: auto;margin-top: 20px;}",
            ".qual-card-div {padding: 20px;background-color: lightsteelblue;margin: auto;min-height: 500px;}",
            ".schedule-card {max-width: 800px; margin-top: 20px; margin-bottom: 20px;}",
            ".schedule-button {width: 150px;margin-right: 20px; margin-bottom: 10px; margin-top: 10px;background-color: blue; color: white;}",
            ".appointment {margin: 0 auto;max-width: 80%;}",
        ];
    }

    private getTest2SampleData() {
        this.programs = ["Apply for Demand Response"];
        this.programtypes = [
            {"id": "1", "desc": "Energy Star", "selfSchedule": false},
        ]; 

        this.loginMethods = [
            {"id": "2", "desc": "Account Number"},
            {"id": "3", "desc": "Name and Service Address"}
        ];

        this.utilitySettings = new UtilitySettings();
        this.utilitySettings.utilityName = 'test2';
        this.utilitySettings.title = 'Test II Utility Enrollment Portal';
        this.utilitySettings.headerHtml = 'SCE';
        this.utilitySettings.footerHtml = 'Copyright &copy; 2021 and 2022';
        this.utilitySettings.mainIntro = 'SCE Programs';
        this.utilitySettings.mainDesc = 'Read the instructions';

        this.utilitySettings.progTypeIntro = 'Apply for Rebate';
        this.utilitySettings.progTypeDesc = 'Click on the rebate program you are interested in';

        this.utilitySettings.qualIntro = 'Demand Response';
        this.utilitySettings.qualDesc = 'We need to ask you some questions to qualify you for the Energy Star Rebate.';

        this.utilitySettings.enrollSuccessIntro = 'Congratulations!';
        this.utilitySettings.enrollSuccessDesc = 'Your rebate is being prepared. You will receive notifications by email as it processed. You can you also check your rebates status on this web site.';

        this.utilitySettings.styles = [
            ".portalbar {background-color: green;}",
            ".portaltitle {padding-left: 30px; color: orange;}",
            "#portalheaderdiv {font-family: Lucida Handwriting; font-size: 24px; padding-top: 20px; text-align: center;}",
            "#portalfooterdiv {margin-top: 100px; margin-bottom: 50px; text-align: center;}",
            ".intro-text {text-align: center; margin-top: 100px; margin-bottom: 50px; font-weight: bold; font-size: 20px; color: green;}",
            ".desc-text {text-align: center; margin-top: 50px;}",
            ".program {margin: 0 auto; max-width: 50%;}",
            "@keyframes mymove {from {left: 0px;} to {left: 300px;}}",
            "h1 {color: white;}",
            "body {color: white; background-color: orange;}",
            ".program {margin: 0 auto; max-width: 30%;}",
            ".programlist {background-color: chartreuse;}",
            ".mat-card {width: 80%; margin: auto;}",
            ".mat-form-field {width: 80%;}",
            ".cardheader {background-color: green; color: white; margin: 0; padding: 20px;}",
            ".mainbutton {background-color: green;}",
            ".qual-intro-div {width: 30%;min-height: 500px;background-color: lightgreen;margin-top: 20px;padding-top: 20px;margin-left: auto;margin-bottom: auto;position: -webkit-sticky;position: sticky;top: 0;}",
            ".qual-notes {margin: 20px 10px 20px 10px;}",
            ".qual-page-div {max-width: 860px;width: 65%;margin: auto;margin-top: 20px;}",
            ".qual-card-div {padding: 20px;background-color: lightgreen;margin: auto;min-height: 500px;}",
            ".schedule-card {max-width: 800px;margin-top: 20px;margin-bottom: 20px;}",
            ".schedule-button {width: 150px;margin-right: 20px;margin-bottom: 10px;margin-top: 10px;background-color: green;}",
            ".appointment {margin: 0 auto;max-width: 80%;}",
        ];
    }


    private newStyle(response) {
     
       // this.getDefaultUtilitySettings().subscribe(res=>{
            this.getSampleStyle = response;
       // })
        
        //console.log("SampleStyle", this.getSampleStyle);
       
        // Available account verfication methods
      
        this.utilitySettings = new UtilitySettings();
        this.utilitySettings.utilityName = this.getSampleStyle.utilityName;
        this.utilitySettings.title = this.getSampleStyle.Title;
        this.utilitySettings.headerHtml = this.getSampleStyle.headerHtml;
        this.utilitySettings.footerHtml = this.getSampleStyle.footerHtml;
        this.utilitySettings.enableEnrollCustomer = this.getSampleStyle.enableenrollcustomer;
               
        // this.utilitySettings.footerHtml = "<div class='flex-container'><div class='foot-left'><a class='navbar-brand' href='https://SCsmartrewards.com'><img src='https://enrollmentportaldev.azurefd.net/assets/sclogo.png' class='stc1-footer-image' alt='Santee Cooper'/></a></div><div class='foot-center'><span class='foot-offset'>©2022 Santee Cooper All Rights Reserved</span></div><div class='foot-right flex-to-the-right'><span class='foot-offset'>Best viewed in Google Chrome or Microsoft Edge.</span></div></div>";

        this.utilitySettings.mainIntro = this.getSampleStyle.mainIntro;
        this.utilitySettings.mainDesc = this.getSampleStyle.mainDesc;

        this.utilitySettings.progTypeIntro = this.getSampleStyle.progTypeIntro;
        this.utilitySettings.progTypeDesc = this.getSampleStyle.progTypeDesc;

        this.utilitySettings.qualIntro = this.getSampleStyle.qualIntro;
        this.utilitySettings.qualDesc = this.getSampleStyle.qualDesc;

        this.utilitySettings.qualificationscriptenabled = this.getSampleStyle.qualificationscriptenabled == "1";
        this.utilitySettings.qualificationscript = this.getSampleStyle.qualificationscript;

        this.utilitySettings.enrollSuccessIntro = this.getSampleStyle.enrollSuccessIntro;
        this.utilitySettings.enrollSuccessDesc = this.getSampleStyle.enrollSuccessDesc;
        
        this.utilitySettings.utilMessage1=this.getSampleStyle.utilmessage1;
        this.utilitySettings.utilMessage2=this.getSampleStyle.utilmessage2;

        this.utilitySettings.showHeader=this.getSampleStyle.showheader;
        this.utilitySettings.workorderTypeImage=this.getSampleStyle.workordertypeimage;
        

        this.utilitySettings.communicationinfo=this.getSampleStyle.communicationinfo;
        this.utilitySettings.emailinfo=this.getSampleStyle.emailinfo;

        this.utilitySettings.UploadEnableContent = this.getSampleStyle.UploadEnableContent;

        this.utilitySettings.enableHelpContent = this.getSampleStyle.EnableHelpContent;
        this.utilitySettings.enableSearches= this.getSampleStyle.EnableSearches;
        this.utilitySettings.searchContent= this.getSampleStyle.SearchContent;
        this.utilitySettings.DownloadW9Form= this.getSampleStyle.DownloadW9Form;
        this.utilitySettings.Gatag= this.getSampleStyle.Gatag;
        this.utilitySettings.SearchContentHelp= this.getSampleStyle.SearchContentHelp;
        this.utilitySettings.FileUploadContent1= this.getSampleStyle.FileUploadContent1;
        this.utilitySettings.FileUploadContent2= this.getSampleStyle.FileUploadContent2;
        this.utilitySettings.FileUploadContent3= this.getSampleStyle.FileUploadContent3;
        this.utilitySettings.IsQuestionsEnableSeq = this.getSampleStyle.IsQuestionsEnableSeq != null && this.getSampleStyle.IsQuestionsEnableSeq == 1;
        
        this.utilitySettings.SelfScheduleEnablement= this.getSampleStyle.SelfScheduleEnablement;
        this.utilitySettings.ReportEnablement= this.getSampleStyle.ReportEnablement;
        this.utilitySettings.ReportLogo= this.getSampleStyle.ReportLogo;
        this.utilitySettings.HomeImage= this.getSampleStyle.HomeImage;
        this.utilitySettings.HeaderCustomization= this.getSampleStyle.IsHeaderCustomization;
        this.utilitySettings.PayeeInfo= this.getSampleStyle.PayeeInfo;
        this.utilitySettings.custominfocallback= this.getSampleStyle.custominfocallback;
        this.utilitySettings.custominfocallback2= this.getSampleStyle.custominfocallback2;
        this.utilitySettings.custominfomyprofile=this.getSampleStyle.custominfomyprofile;
        this.utilitySettings.custominfoacctconfmsg=this.getSampleStyle.custominfoacctconfmsg;
        this.utilitySettings.custominfoshowuploaddoc=this.getSampleStyle.custominfoshowuploaddoc;
        this.utilitySettings.custominfoaptnotetb=this.getSampleStyle.custominfoaptnotetb; 
        this.utilitySettings.custominforedirhome=this.getSampleStyle.custominforedirhome; 
        this.utilitySettings.enableCaptcha = this.getSampleStyle.EnableCaptcha;
        this.utilitySettings.CustomProgramGuide=this.getSampleStyle.CustomProgramGuide; 
        this.utilitySettings.ProgramInfoCommunications=this.getSampleStyle.ProgramInfoCommunications; 
        this.utilitySettings.CustomPhoneInfo=this.getSampleStyle.CustomPhoneInfo; 

        this.utilitySettings.Isuploaddoc=this.getSampleStyle.Isuploaddoc; 
        this.utilitySettings.Isviewdoc=this.getSampleStyle.Isviewdoc; 

        this.getSampleStyle.Styles.forEach(element => {
            this.utilitySettings.styles.push(element.styleValue);
        }); 

        
    }
    public handleError(error: HttpErrorResponse, x, returnVal): Observable<any> {

      let isWarning = false;
    
      let mesg = 'An error has occurred. Please try again.';
      let showErrorMesg = true;
      if (error.error instanceof ErrorEvent) {
          // A client-side or network error occurred. Handle it accordingly.
          mesg = 'An error occurred:' + error.error.message;
          console.error('An error occurred:', error.error.message);
      } else {
          // The backend returned an unsuccessful response code.
          // The response body may contain clues as to what went wrong,
          // mesg = 'Backend returned code ' + error.status + ', body was: ' + error.error;
          mesg = 'Incorrect response!';
          console.error('Backend returned code ' + error.status + ', body was: ' + error.error);
    
          if (error.status === 401) {
              mesg = 'You do not have permission to perform this function.';
          }
    
          if (error.status === 400) {    //  && error.error === 404) { various error codes are returned for not found 200, 500, 404
              // search not found
              showErrorMesg = false;
              mesg = 'Server error!';
          }
    
          if (error.status === 404) {    //  && error.error === 404) { various error codes are returned for not found 200, 500, 404
              // search not found
              showErrorMesg = true;
              isWarning = true;
              mesg = 'Issue with service.';
          }
      }
    
      if (showErrorMesg) {
          if (isWarning) {
              Swal.fire({
                  title: 'No Results',
                  text: mesg,
                  icon: 'info',
                  confirmButtonText: 'OK',
              });
          } else {
              Swal.fire({
                  title: 'Error',
                  text: mesg,
                  icon: 'error',
                  confirmButtonText: 'OK',
              });
          }
    
      }
      return throwError(mesg);
    }

//to save email address

public getWorkorderDetails(workorder,userId,userRole): Observable<any> {
  
  const searchUrl = `${this.programUrl}rebate/${this.currentUtility}/workorder/${workorder}/user/${userId}/${userRole}?code=${environment.apiCode}`;
  return this.http.get<any>(searchUrl, this.getHeaders())
  .pipe(map(response => response),
      catchError(async (err) => console.log(err))
  );
}

//to save and submit rebate

submitWorkorder(officeId,workorderId,userId,isEP): Observable<any[]> {
  
      
  const searchUrl = environment.apiUrl  + "rebate/" + officeId +"/workorder/"+ workorderId + "/user/"+ userId +"/" + isEP +'?code=' + environment.apiCode; 
    
  return this.http.post<any[]>(searchUrl,this.getHeaders())
    .pipe(map(response => response),
      catchError(err => this.handleError(err, this, []))
    );

 }



}
