/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WorkorderService } from 'src/app/services/workorder.service';

@Component({
  selector: 'app-view-dialog',
  templateUrl: './view-dialog.component.html',
  styleUrls: ['./view-dialog.component.scss'],
})
export class ViewDialogComponent implements OnInit {

  loadFile:any = true;
  files:any =[];
  WorkorderId:any;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,private workorderService:WorkorderService) { }

  ngOnInit(): void {
    //let workOrderId1= localStorage.getItem("workOrderID");
    //let workOrderId = "W009738161";
    //let workOrderId= this.data.WorkorderId;
    this.loadFile = true;
    this.workorderService.getDocumentsForWorkorder(this.data.workOrderID).subscribe(res=>{
      this.files = res;
      this.loadFile = false;
  })
  }

  //To Open file
  openFile(file){
    if(file.FileType == "PDF")
    {
      this.workorderService.openPdfFile(file);
      
    }
    else
    {
      
     this.workorderService.openImageFile(file);
    }
  }
}
