<div class="enroll-card-div" mat-dialog-container style="height: 35vw !important;">
    <div mat-dialog-title style="margin: 0 !important" class="btn-align">
        <button matTooltip="Close" mat-button style="height: 2.5vw !important;" 
            [mat-dialog-close]="hasSaved">
            <mat-icon style="margin-bottom: 0.5vw;">close</mat-icon>
        </button>
    </div>

    <div mat-dialog-content style="height: 100vw; width:60vw;">
        <mat-card style="margin-top: 0.5vw;">
            <mat-card-content>
                <table style="table-layout: fixed;width:100%">
                    <tr>
                        <td colspan="2">
                            <mat-form-field appearance="outline" style="width:100%;font-size: 0.9vw;">
                                <mat-label>Please provide a reason for your reschedule</mat-label>
                                <mat-select [(ngModel)]="reasonName" [disabled]="!disableReasons" (selectionChange)="changeType($event)">
                                    <mat-option *ngFor="let reason of reasons" [value]="reason.ReasonCode"
                                        style="font-size: 11px;">
                                        {{reason.Description}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>

                    </tr>
                </table>

            </mat-card-content>
        </mat-card>
    </div>

    <br>
</div>