<!-- Normal screen START-->
<span *ngIf="!smallScreen">
<app-enrollment-header [programName]="selProgramName"></app-enrollment-header>
<br>
<div class="intro-text" [innerHTML]=utilityService.utilitySettings.mainIntro></div>

<form>
    <mat-card>
        <div><b> Name and Contact Information:</b></div>
        <mat-card class="address">
        <table>
        
      <tr>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>First Name</mat-label>
                <input type="text" placeholder="Please enter First Name" maxlength="20" name="fname" id="fname" [(ngModel)]="fname" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Last Name</mat-label>
                <input type="text"  placeholder="Please enter Last Name" maxlength="40"  name="lname" id="lname" [(ngModel)]="lname" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Account Number</mat-label>
                <input type="text"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="Please enter Account Number" maxlength="23"  name="anumber" id="anumber" [(ngModel)]="anumber" matInput required>
            </mat-form-field>
        </td>
      </tr>     
      <tr>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label> Primary Phone Number</mat-label>

<input type="text" (input)="validatePhoneNo($event.target)"  placeholder="Please enter Primary Phone Number"  name="pphonenumber" maxlength="14" id="pphonenumber" [(ngModel)]="pphonenumber"  #primaryPhone="ngModel" matInput  required>
<!--       <input type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  placeholder="Please enter Primary Phone Number"  name="pphonenumber" minlength="10" maxlength="10" id="pphonenumber" [(ngModel)]="pphonenumber"  #primaryPhone="ngModel" matInput  required>
           -->  
           <div class="text-danger" *ngIf="!isValidFlg">Invalid Phonenumber"</div>  
            </mat-form-field>
            
            <div *ngIf="primaryPhone.errors && (primaryPhone.invalid || primaryPhone.touched)">
                <small class="text-danger" *ngIf="primaryPhone.touched" style="font-size: 10px; color: red">Please enter 10 digit Phone number.</small>
                 </div>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label> Alternate Phone Number</mat-label>
                <input type="text"  (input)="validateAPhoneNo($event.target)" maxlength="14" placeholder="Please enter Alternate Phone Number"  name="aphonenumber"  id="aphonenumber" [(ngModel)]="aphonenumber" #alternatePhone="ngModel" matInput>
                <div class="text-danger" *ngIf="!isValidFlg1">Invalid Phonenumber"</div>
            </mat-form-field>
            <div *ngIf="alternatePhone.errors && (alternatePhone.invalid || alternatePhone.touched)">
                <small class="text-danger" *ngIf="alternatePhone.touched" style="font-size: 10px; color: red">Please enter 10 digit Phone number.</small>
                 </div>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Email Address</mat-label>
                <input type="text"  placeholder="Please enter Email Address" maxlength="80"  name="email" id="email" matInput [(ngModel)]="email" #primaryEmail="ngModel" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required>
              </mat-form-field>
                 <div *ngIf="primaryEmail.errors && (primaryEmail.invalid || primaryEmail.touched)">
                <small class="text-danger" *ngIf="primaryEmail.errors.pattern" style="font-size: 10px; color: red">Please enter a valid email address.</small>
                 </div>
        </td>
      </tr>
      <tr>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Customer Type</mat-label>
                <mat-select  name="customerType" [(ngModel)]="customerType"  required>
                   <mat-option  [value]="item.id" *ngFor="let item of data">{{item.name}}</mat-option> 
                </mat-select>
            </mat-form-field>
        </td>
        
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Company Name</mat-label>
                <input type="text"  placeholder="Please enter Company Name" maxlength="40" name="companyname" id="companyname" [(ngModel)]="companyname" matInput>
            </mat-form-field>
        </td>
      </tr> 
    </table> 
    </mat-card>
    <br>
      <div><b> Service Address:</b></div>
      <mat-card class="address">

      <table>
      <tr>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Street Number</mat-label>
                <input type="text"  placeholder="Please enter Street Number" maxlength="10" name="snumber" id="snumber" [(ngModel)]="snumber" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Street Name</mat-label>
                <input type="text"  placeholder="Please enter Street Name" maxlength="40" name="sname" id="sname" [(ngModel)]="sname" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Apartment</mat-label>
                <input type="text"  placeholder="Please enter Apartment Name" maxlength="20"  name="aname" id="aname" [(ngModel)]="aname" matInput >
            </mat-form-field>
        </td>
      </tr>
      <tr>
        
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>City</mat-label>
                <input type="text"  placeholder="Please enter City Name" maxlength="30" name="cname" id="cname" [(ngModel)]="cname" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>State Name</mat-label>
                <input type="text"  placeholder="Please enter State Name" maxlength="2" name="statename" id="statename" [(ngModel)]="statename"  matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Zip Code</mat-label>
                <input type="text"  placeholder="Please enter Zip Code" maxlength="10" name="zipcode" id="zipcode" [(ngModel)]="zipcode" matInput  required>
            </mat-form-field>
        </td>
      </tr>
    </table>
    </mat-card>
    <br>
      <div>
        <mat-card-content>
            <mat-checkbox   name="agree" required [(ngModel)]="agree" style="font-size: 12px;margin-left: 5px;" >Please check this box if Mailing Address is same as Service Address.</mat-checkbox>
        </mat-card-content>
      </div>
      
      
        <div>
           <b>Mailing Address</b>(If different from Service Address):
        </div>
    
      <mat-card class="address">
      <table>
      <tr>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Street Number</mat-label>
                <input type="text"  [disabled]= "agree" placeholder="Please enter Street Number" maxlength="10" name="msnumber" id="msnumber" [(ngModel)]="msnumber" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Street Name</mat-label>
                <input type="text" [disabled]= "agree" placeholder="Please enter Street Name" maxlength="40" name="msname" id="msname" [(ngModel)]="msname" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Apartment</mat-label>
                <input type="text" [disabled]= "agree" placeholder="Please enter Apartment Name" maxlength="20" name="maname" id="maname" [(ngModel)]="maname" matInput>
            </mat-form-field>
        </td>
      </tr>
      <tr>
        
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>City</mat-label>
                <input type="text" [disabled]= "agree" placeholder="Please enter City Name" maxlength="30" name="mcname" id="mcname" [(ngModel)]="mcname" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>State Name</mat-label>
                <input type="text" [disabled]= "agree"  placeholder="Please enter State Name" maxlength="2"  name="mstatename" id="mstatename" [(ngModel)]="mstatename" matInput  required>
            </mat-form-field>
        </td>
        <td>
            <mat-form-field appearance="outline" style="width:230px !important" >
                <mat-label>Zip Code</mat-label>
                <input type="text" [disabled]= "agree" placeholder="Please enter Zip Code" maxlength="10" name="mzipcode" id="mzipcode" [(ngModel)]="mzipcode" matInput  required>
            </mat-form-field>
        </td>
      </tr>
      </table>
    </mat-card>

    <br>
    
    <mat-card-actions>
        <button mat-raised-button routerLink="/customerlookup" class="mainbutton" [state]="{data: confirmData}">Back</button>       
        <button mat-raised-button (click)="routeToPrograms()"  class="mainbutton" [disabled]=" ((!fname) || (!lname) || (!anumber) || (!pphonenumber) || (!email) || (!customerType) || (!snumber) || (!sname) || (!cname) || (!statename) || (!zipcode) || (!agree) ) && ((!msnumber) || (!msname) || (!mcname) || (!mstatename) || (!mzipcode) )">Create</button>
    </mat-card-actions>
    
  </mat-card>
  
</form>
<app-enrollment-footer></app-enrollment-footer>
</span>
<!-- NORMAL SCREEN END-->

<!-- MOBILE SCREEN START-->
<span *ngIf="smallScreen">
    <app-enrollment-header [programName]="selProgramName"></app-enrollment-header>
    <br>
    <div class="intro-text">{{utilityService.utilitySettings.mainIntro}}</div>
    
    <form>
        <mat-card class="mat-elevation-z0"> 
            <div style="margin-left:14px"><b> Name and Contact Information:</b></div>
            <mat-card class="maddress">
            <table>
            <tr>
            <td >
               <mat-form-field appearance="outline" style="width:230px !important;" >
                    <mat-label>First Name</mat-label>
                    <input type="text" placeholder="Please enter First Name" maxlength="20" name="fname" id="fname" [(ngModel)]="fname" matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Last Name</mat-label>
                    <input type="text"  placeholder="Please enter Last Name" maxlength="40"  name="lname" id="lname" [(ngModel)]="lname" matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Account Number</mat-label>
                    <input type="text"  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="Please enter Account Number" maxlength="23"  name="anumber" id="anumber" [(ngModel)]="anumber" matInput required>
                </mat-form-field>
            </td>
            </tr>
             
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label> Primary Phone Number</mat-label>
                    <input type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  placeholder="Please enter Primary Phone Number"  name="pphonenumber" minlength="10" maxlength="10" id="pphonenumber" [(ngModel)]="pphonenumber"  #primaryPhone="ngModel" matInput  required>
                </mat-form-field>
                <div *ngIf="primaryPhone.errors && (primaryPhone.invalid || primaryPhone.touched)">
                    <small class="text-danger" *ngIf="primaryPhone.touched" style="font-size: 10px; color: red">Please enter 10 digit Phone number.</small>
                     </div>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label> Alternate Phone Number</mat-label>
                    <input type="tel" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"  placeholder="Please enter Alternate Phone Number"  name="aphonenumber" minlength="10" maxlength="10" id="aphonenumber" [(ngModel)]="aphonenumber" #alternatePhone="ngModel" matInput>
                </mat-form-field>
                <div *ngIf="alternatePhone.errors && (alternatePhone.invalid || alternatePhone.touched)">
                    <small class="text-danger" *ngIf="alternatePhone.touched" style="font-size: 10px; color: red">Please enter 10 digit Phone number.</small>
                     </div>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Email Address</mat-label>
                    <input type="text"  placeholder="Please enter Email Address" maxlength="80"  name="email" id="email" matInput [(ngModel)]="email" #primaryEmail="ngModel" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required>
                  </mat-form-field>
                     <div *ngIf="primaryEmail.errors && (primaryEmail.invalid || primaryEmail.touched)">
                    <small class="text-danger" *ngIf="primaryEmail.errors.pattern" style="font-size: 10px; color: red">Please enter a valid email address.</small>
                     </div>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Customer Type</mat-label>
                    <mat-select  name="customerType" [(ngModel)]="customerType"  required>
                       <mat-option  [value]="item.id" *ngFor="let item of data">{{item.name}}</mat-option> 
                    </mat-select>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Company Name</mat-label>
                    <input type="text"  placeholder="Please enter Company Name" maxlength="40" name="companyname" id="companyname" [(ngModel)]="companyname" matInput>
                </mat-form-field>
            </td>
            </tr> 
        </table> 
        </mat-card>
        <br>
          <div style="margin-left:14px"><b> Service Address:</b></div>
          <mat-card class="maddress">
    
          <table>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Street Number</mat-label>
                    <input type="text"  placeholder="Please enter Street Number" maxlength="10" name="snumber" id="snumber" [(ngModel)]="snumber" matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Street Name</mat-label>
                    <input type="text"  placeholder="Please enter Street Name" maxlength="40" name="sname" id="sname" [(ngModel)]="sname" matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Apartment</mat-label>
                    <input type="text"  placeholder="Please enter Apartment Name" maxlength="20"  name="aname" id="aname" [(ngModel)]="aname" matInput >
                </mat-form-field>
            </td>
            </tr>
            <tr>
                <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>City</mat-label>
                    <input type="text"  placeholder="Please enter City Name" maxlength="30" name="cname" id="cname" [(ngModel)]="cname" matInput  required>
                </mat-form-field>
                </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>State Name</mat-label>
                    <input type="text"  placeholder="Please enter State Name" maxlength="2" name="statename" id="statename" [(ngModel)]="statename"  matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Zip Code</mat-label>
                    <input type="text"  placeholder="Please enter Zip Code" maxlength="10" name="zipcode" id="zipcode" [(ngModel)]="zipcode" matInput  required>
                </mat-form-field>
            </td>
          </tr>
        </table>
        </mat-card>
        <br>
          <div>
            <mat-card-content>
                <mat-checkbox  name="agree" required [(ngModel)]="agree" style="font-size:9px;margin-left:14px;" >Please check this box if Mailing Address is same as Service Address.</mat-checkbox>
            </mat-card-content>
          </div>
          
          
            <div style="margin-left:14px">
               <b>Mailing Address</b>(If different from Service Address):
            </div>
        
          <mat-card class="maddress">
          <table>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Street Number</mat-label>
                    <input type="text"  [disabled]= "agree" placeholder="Please enter Street Number" maxlength="10" name="msnumber" id="msnumber" [(ngModel)]="msnumber" matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Street Name</mat-label>
                    <input type="text" [disabled]= "agree" placeholder="Please enter Street Name" maxlength="40" name="msname" id="msname" [(ngModel)]="msname" matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Apartment</mat-label>
                    <input type="text" [disabled]= "agree" placeholder="Please enter Apartment Name" maxlength="20" name="maname" id="maname" [(ngModel)]="maname" matInput>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>City</mat-label>
                    <input type="text" [disabled]= "agree" placeholder="Please enter City Name" maxlength="30" name="mcname" id="mcname" [(ngModel)]="mcname" matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>State Name</mat-label>
                    <input type="text" [disabled]= "agree"  placeholder="Please enter State Name" maxlength="2"  name="mstatename" id="mstatename" [(ngModel)]="mstatename" matInput  required>
                </mat-form-field>
            </td>
            </tr>
            <tr>
            <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label>Zip Code</mat-label>
                    <input type="text" [disabled]= "agree" placeholder="Please enter Zip Code" maxlength="10" name="mzipcode" id="mzipcode" [(ngModel)]="mzipcode" matInput  required>
                </mat-form-field>
            </td>
            </tr>
          </table>
        </mat-card>
    
        <br>
        
        <mat-card-actions>
            <button style="margin-left:23px" mat-raised-button routerLink="/customerlookup" class="mainbutton" [state]="{data: confirmData}">Back</button>       
            <button mat-raised-button (click)="routeToPrograms()"  class="mainbutton" [disabled]=" ((!fname) || (!lname) || (!anumber) || (!pphonenumber) || (!email) || (!customerType) || (!snumber) || (!sname) || (!cname) || (!statename) || (!zipcode) || (!agree) ) && ((!msnumber) || (!msname) || (!mcname) || (!mstatename) || (!mzipcode) )">Create</button>
        </mat-card-actions>
        
      </mat-card>
      
    </form>
    <app-enrollment-footer></app-enrollment-footer>
    </span>
    <!-- MOBILE SCREEN END-->
    