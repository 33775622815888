<!--This is for Santecooper-->
<div *ngIf="isRebateFlow==0">
  <mat-dialog-content>
    <div *ngIf="!smallScreen">
      <div class="close">
        <button mat-icon-button class="mainbutton" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <h1 mat-dialog-title class="intro-text">File Upload</h1>
      <br>
      <h2 *ngIf="errorMesg != ''" style="color:red;text-align: center;">{{errorMesg}}</h2>
      <div *ngFor="let uptext of uploadText; let i = index">
        <span [innerHTML] = uptext></span>
      </div>
      <br>
      <div class="upload-drop-zone" id="droptarget" (drop)="handleDrop($event)" (dragover)="allowDrop($event)"
        (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()">
        Drag and drop your files to upload here
        
      </div>
        <h6 style="text-align: center;font-size:13px;"><b>Note:</b> File upload supports .jpg, .png, .gif, .doc, .docx & .pdf extension formats</h6>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
        Look for files
        
        <label for="choose-file" class="custom-file-upload" id="choose-file-label">
           Choose files
        </label>

        <input name="uploadDocument" type="file" id="choose-file" multiple [disabled]="uploading"
           accept=".jpg,.pdf,.doc,.docx,.png,.gif|image/*" style="display: none;" (change)="handleFile($event)"/>
       
      <div *ngIf="files.length > 0">
       <br>
        <h2>Files Ready for Uploading:</h2>
        <mat-list *ngFor="let file of files; let i = index">
          <mat-item>
            <mat-icon class="action" (click)="removeFile(i)">cancel</mat-icon>{{file.name}}
          </mat-item>
        </mat-list>
      
        <button mat-raised-button class="mainbutton" *ngIf="files.length > 0" [disabled]="uploading"
          (click)="removeAll()">Remove All</button>
        &nbsp;&nbsp;&nbsp;&nbsp;<button mat-raised-button class="mainbutton" *ngIf="isUploadButtonEnablement==0" [disabled]="!enable" 
            (click)="uploadDocDR()">Next</button>
        
      </div>
     
      <div *ngIf="isShowHeader==1">
        <div *ngIf="isUploadButtonEnablement==1" style="text-align:center">
          <button mat-raised-button class="mainbutton" [disabled]="!enable" style="text-align:center;" (click)="uploadDocEnrollments()">Upload</button>
        </div>
      </div>
         
    </div>
  </mat-dialog-content>
  </div>
<!--This is for JCPL -->
<div *ngIf="isRebateFlow==1">
      <div *ngIf="!smallScreen">
      <div class="close">
        <button mat-icon-button class="mainbutton" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <h1 mat-dialog-title class="intro-text">File Upload</h1>
        <div style="font-size:15px;margin-left:10px" [innerHTML]=utilityService.utilitySettings.FileUploadContent1></div>
  <div style="font-size: 15px;text-align:right; margin-right:14px;font-weight:bold;">
    <span [innerHTML] = DownloadW9Form></span>
  </div>
  <div>
  <h6 style="font-size:13px;"><b>&nbsp;&nbsp;&nbsp;Note:</b> File upload supports .jpg, .png, .gif, .doc, .docx & .pdf extension formats</h6>
  </div>
  <table class="content-table">
    <tbody>
        <tr>
            <td>Charger Serial Number </td> 
            <td>
              <input #ep_upload type="file" [disabled]="uploading" style="color: rgba(0, 0, 0, 0)"
                accept=".jpg,.pdf,.doc,.docx,.png,.gif|image/*" (change)="handleFileCSN($event)" />
              <div *ngIf="files1.length > 0">
                <h4>Files Ready for Uploading:</h4>
                <mat-list *ngFor="let file of files1; let i = index">
                  <mat-item>
                    <mat-icon class="action" (click)="removeFileCSN(i)">cancel</mat-icon>{{file.name}}
                  </mat-item>
                </mat-list>

                <!--
                <button mat-raised-button class="mainbutton" *ngIf="files1.length > 0" [disabled]="uploading"
                  (click)="removeAllCSN()">Remove All</button> 
                -->
                
              </div>
            </td>         
        </tr>
        <tr>
          <td>W9 Form</td> 
          <td>
            <input type="file" [disabled]="uploading" style="color: rgba(0, 0, 0, 0)"
                accept=".jpg,.pdf,.doc,.docx,.png,.gif|image/*" (change)="handleFileW9($event)" />
                <div *ngIf="files2.length > 0">
                  <h4>File Ready for Uploading:</h4>
                  <mat-list *ngFor="let file of files2; let i = index">
                    <mat-item>
                      <mat-icon class="action" (click)="removeAllW9()">cancel</mat-icon>{{file.name}}
                    </mat-item>
                  </mat-list>
                </div>        
          </td>         
      </tr>
      <tr>
        <td>Final Installation Invoice</td> 
        <td>
          <input type="file" [disabled]="uploading" style="color: rgba(0, 0, 0, 0)"
                accept=".jpg,.pdf,.doc,.docx,.png,.gif|image/*" (change)="handleFileInvoice($event)" />              
                <div *ngIf="files3.length > 0">
                  <h4>File Ready for Uploading:</h4>
                  <mat-list *ngFor="let file of files3; let i = index">
                    <mat-item>
                      <mat-icon class="action" (click)="removeAllInvoice()">cancel</mat-icon>{{file.name}}
                    </mat-item>
                  </mat-list>
                </div> 
        </td>         
    </tr>
    <tr>
      <td>Image of Charger mounted on wall</td> 
      <td>
        <input type="file" [disabled]="uploading" style="color: rgba(0, 0, 0, 0)"
              accept=".jpg,.pdf,.doc,.docx,.png,.gif|image/*" (change)="handleImageCharger($event)" />              
              <div *ngIf="files4.length > 0">
                <h4>File Ready for Uploading:</h4>
                <mat-list *ngFor="let file of files3; let i = index">
                  <mat-item>
                    <mat-icon class="action" (click)="removeAllCharger()">cancel</mat-icon>{{file.name}}
                  </mat-item>
                </mat-list>
              </div> 
      </td>         
  </tr>
    </tbody>
</table>
<br>
   
<div *ngIf="isShowHeader==1">
  <div *ngIf="isUploadButtonEnablement==1" style="text-align:center">
   <button mat-raised-button class="mainbutton" [disabled]="(!enable) && (!enableW9) && (!enableIn)" style="text-align:center;" (click)="uploadDocEnrollments()">Upload</button>
   <h3 style="color:red;text-align:left">{{utilityService.utilitySettings.FileUploadContent2}}<br>{{utilityService.utilitySettings.FileUploadContent3}} </h3>
   </div>
</div>
<div *ngIf="isUploadButtonEnablement==0">
<button mat-raised-button class="mainbutton" [disabled]="(!enable) && (!enableW9) && (!enableIn)"
   (click)="uploadDoc()">Next</button>
   &nbsp;&nbsp;&nbsp;
 <button mat-raised-button class="mainbutton" [disabled]="(!uploadlaterEnableCSN) || (!uploadlaterEnableW9) || (!uploadlaterEnableIn)" (click)="uploadDocsLater()">Upload Later</button>
<br><br>
  <h3 style="color:red;text-align:left">{{utilityService.utilitySettings.FileUploadContent2}}<br>{{utilityService.utilitySettings.FileUploadContent3}} </h3>
  
</div>
</div>

