<div class="main-div" >
<div style=" width: 85%; margin-top: 1%; padding-left: 1%; "
   *ngFor="let tile of response?.Sections;let i = index;" > 
    
  <!-- start of detailed layout -->
  <div class="accordion-content font-style">
    <div >

      <div class="grid-container enroll-card-div">
        <ng-container *ngFor="let subResponse of tile?.DetailedInfo; let d = index " >
            
          <div class="grid-item"
            [ngStyle]="{'text-align': subResponse.DataType=='RadioList' ? 'center' : 'inherit' }"
            colspan="{{subResponse.DataType=='RadioList' ? '12' : '4'}}">
            <div >
            <div   *ngIf="subResponse.DataType!='CHECKBOX'">
              <label *ngIf="subResponse.DataType=='RadioList'"
               style="text-align: left;   font-weight: 500;"
              [ngClass]="{'asterix': subResponse.Mandatory=='Yes' }">
                    {{subResponse.Name}}
              </label>
              <label *ngIf="subResponse.DataType!='RadioList'"
               style="text-align: left; font-weight: 500;"
              [ngClass]="{'asterix': subResponse.Mandatory=='Yes' }">{{subResponse.Name}}
              </label>
            </div>                   
       
          <mat-form-field appearance="outline"
              style=" margin-top:0.5vw; width:80%"
              *ngIf="subResponse.DataType=='MULTISELECT'">
              <mat-select [(ngModel)]="subResponse.SelectedValue" multiple   [compareWith]="equals"       
              (ngModelChange)="dataChanged()">
               <mat-select-trigger>
                {{subResponse.SelectedValue?.[0]?.Value || ''}}
                <span *ngIf="(subResponse.SelectedValue?.length || 0) > 1" >
                  (+{{(subResponse.SelectedValue?.length || 0) - 1}} {{subResponse.SelectedValue?.length === 2 ? 'other' : 'others'}})
                </span>
              </mat-select-trigger> 
                <mat-option *ngFor="let optionValues of subResponse.PossibleValues" [value]="optionValues" >
                  {{optionValues.Value}}
                </mat-option>
              </mat-select>
            </mat-form-field>  

           <mat-form-field appearance="outline"
            style=" margin-top:0.5vw; width: 80%"
            *ngIf="subResponse.DataType=='DROPDOWN' && (subResponse.AttributeId.toLowerCase() != 'dealer_id' && subResponse?.IsStore == undefined) && 
            (subResponse.AttributeId.toLowerCase() != 'question2' && subResponse?.IsLocation == undefined)">
            <mat-select [(ngModel)]="subResponse.SelectedId" 
            (ngModelChange)="dataChanged()"
            [disabled]="subResponse.IsEditable ? (subResponse.IsEditable == 'Yes'? false : true) : false">
              <mat-option *ngFor="let optionValues of subResponse.PossibleValues" [value]="optionValues.Id">
                {{optionValues.Value}}
              </mat-option>
            </mat-select>
          </mat-form-field>   
        
            <div style="width: 100%; ">
            <mat-form-field appearance=""  style="height:50% !important;"
              *ngIf="subResponse.DataType=='STRING'">
              <ng-container *ngIf="subResponse.Size <=500">
                <input autocomplete="off" appInputlengthFilter matInput [(ngModel)]="subResponse.SelectedValue"
                  (ngModelChange)="dataChanged($event,i,d)"            
                  [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''"
                  [maxlength]="subResponse.Size">
              </ng-container>          
            </mat-form-field>

            <mat-form-field appearance=""   style="height:50% !important ;padding-right: 2.2vw"
               *ngIf="subResponse.DataType=='DATE'" >               
              <input matInput [matDatepicker]="fromPicker" placeholder="MM/DD/YYYY"
                [(ngModel)]="subResponse.SelectedValue " (ngModelChange)="subResponse.SelectedValue = $event.toUpperCase()">            
              <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
              <mat-datepicker #fromPicker></mat-datepicker>
            </mat-form-field>

            <mat-form-field appearance=""  style="height:50% !important;"
              *ngIf="subResponse.DataType=='INTEGER'" >
              <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" matInput
                (ngModelChange)="dataChanged($event,i,d)"                  
                [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" type="number"
                [max]="subResponse.Max">
            </mat-form-field>

            <mat-form-field appearance=""  style="height:50% !important;"
              *ngIf="subResponse.DataType=='DECIMAL'">
              <input appInputlengthFilter [(ngModel)]="subResponse.SelectedValue" matInput
                (ngModelChange)="dataChanged($event,i,d)"               
                [value]="subResponse.SelectedValue != 'null' ? subResponse.SelectedValue: ''" type="number"
                [max]="subResponse.Max">
            </mat-form-field>

            <mat-checkbox style=" height:50% !important;" *ngIf="subResponse.DataType=='Bit'"
             [(ngModel)]="subResponse.SelectedValue"
              (ngModelChange)="dataChanged($event,i,d)">
            </mat-checkbox>  

            <div  *ngIf="subResponse.DataType=='CHECKBOX'"  >
              <table>
                <tr>
                  <td> 
                        <mat-checkbox   name="agree"  style="font-size: 12px;margin-left: 0px; float: left;"  [ngClass]="{'asterix': subResponse.Mandatory=='Yes' }"
                        [required]="subResponse.Mandatory=='Yes'? 'required': null"
                        [(ngModel)]="subResponse.SelectedValue" #isActive="ngModel"      
                        [checked]="subResponse.SelectedValue==1?true:false"      
                        (ngModelChange)="dataChanged($event,i,d)"
                        [disabled]="subResponse.IsEditable ? (subResponse.IsEditable == 'Yes'? false : true) : false">
                        </mat-checkbox>
                  </td>
                  <td>
                    <div style="font-size: 12px;margin-left: 2px; float: right;" [innerHTML]="subResponse.Name"></div>
                  </td>
                </tr>
              </table>
                     
            </div>  

            <mat-form-field appearance="outline" style=" margin-top:0.5vw; width: 80%"
              *ngIf="subResponse.DataType=='DROPDOWN' && (subResponse.AttributeId.toLowerCase() == 'dealer_id' && subResponse?.IsStore != undefined)">
              <mat-select [(ngModel)]="subResponse.SelectedId"
                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId)" (ngModelChange)="dataChanged()"
                (selectionChange)="onDropdownChange($event)"
                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)">
                <mat-option *ngFor="let optionValues of storeArray" [value]="optionValues.Id" style="font-size: 11px;">
                  {{optionValues.Value}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" style=" margin-top:0.5vw; width: 80%"
              *ngIf="subResponse.DataType=='DROPDOWN' && (subResponse.AttributeId.toLowerCase() == 'question2' && subResponse?.IsLocation != undefined)">
              <mat-select [(ngModel)]="subResponse.SelectedId" 
                (ngInit)="oninitialize(subResponse.SelectedValue,subResponse.AttributeId)"
                (ngModelChange)="dataChanged()"
                [disabled]="(subResponse.IsEditable == 'Yes'? false : true)">
                <mat-option *ngFor="let optionValues of locationArray" [value]="optionValues.Id" style="font-size: 11px;">
                  {{optionValues.Value}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            
          </div>
        </div>
         </div>         
        </ng-container>      
      </div>
    </div>
  </div> 
  <div class="button-container ">
    <button type="button" class="mainbutton font-style"
    [disabled]="!isSaveEnabled" (click)="save()" mat-flat-button >Save
      </button>
  </div>
  <!-- end of detailed layout -->
  <br/><span *ngIf="response?.Note != null && barccodeValue != null" style="font-size: 13px !important;">{{response?.Note}}</span>
</div>
</div>
<!----Barcode-->
<div class="button-container">
  <div *ngIf="barccodeValue != null" #downloadEl>
    <ngx-barcode [bc-element-type]="'img'" [bc-value]="barccodeValue" [bc-format]="'CODE39'" [bc-width]="1"
      [bc-height]="50" [bc-display-value]="true" [bc-font]="'monospace'" [bc-text-align]="center"
      [bc-text-position]="bottom" [bc-text-margin]="15" [bc-font-size]="20" [bc-background]="'white'" [bc-margin]="20"
      [bc-margin-top]="24" [bc-margin-bottom]="20" [bc-margin-left]="20" [bc-margin-right]="20" download></ngx-barcode>
  </div>
</div>
<div class="button-container">
  <button type="button" class="mainbutton font-style" (click)="downloadBarcode()" *ngIf="barccodeValue != null"
    mat-flat-button>Download</button>
</div>