/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { UtilityService } from './utility.service';
import { KeyValuePair } from '../pages/rebates/classes/KeyValuePair';
@Injectable({
  providedIn: 'root'
})
export class RebateService {

  constructor(private http: HttpClient,private utilityService: UtilityService) { }
  setItem(key: string, value: any) {
    let item = new KeyValuePair();
    item.key = key;
    item.value = value;
    sessionStorage.setItem(key, JSON.stringify(item));
  }
  getItem(key: string) {
    let item = sessionStorage.getItem(key);
    if (item) {
      return JSON.parse(item).value;
    }
    return null;
  }
  private getHeaders() {
    const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': 'SEHonAdmin@honeywell.com',
        'x-programsponsorid': this.utilityService.currentUtility
      })
    };
    return httpOptions;
  }
  gettabcomponentinfo(programdetailid): Observable<any> {
    const searchUrl = environment.apiUrl + "rebate/module/getmoduledetails/programdetail/" + programdetailid +"/"+this.utilityService.currentUtility+ "?code=" + environment.apiCode;
    console.log(searchUrl);
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map(response => response),
        catchError(async (err) => console.log(err))//this.utilityService.handleError(err, this, []))
      );

  }
getpayeeinfo(workorderid): Observable<any> {
  const searchUrl = environment.apiUrl + "rebate/payee/getpayeerebateDetails/"+this.utilityService.currentUtility+"/workorder/" + workorderid + "?code=" + environment.apiCode;
  console.log(searchUrl);
  return this.http.get<any[]>(searchUrl, this.getHeaders())
    .pipe(map(response => response),
      catchError(async (err) => console.log(err))//this.utilityService.handleError(err, this, []))
    );

}
public savepayee(data, workorderid): Observable<any[]> {
  const savepayeeurl = environment.apiUrl + "Rebate/SavePayeeInfo/"+this.utilityService.currentUtility+"/workorder/" + workorderid + "?code=" + environment.apiCode;
  console.log(savepayeeurl);
  return this.http.post<any>(savepayeeurl, data, this.getHeaders())
  .pipe(map(response => response),
    catchError(async (err) => console.log(err))
  );
}
public getMeasureInfo(workorder): Observable<any> {
  const searchUrl = `${environment.apiUrl}Rebate/GetMeasureData/${this.utilityService.currentUtility}/workorder/${workorder}?code=${environment.apiCode}`;
  return this.http.get<any>(searchUrl, this.getHeaders())
  .pipe(map(response => response),
      catchError(async (err) => console.log(err))
  );  
}

public getAddMeasureInfo(workorder, lineitemmaster): Observable<any> {
  const searchUrl = `${environment.apiUrl}Rebate/AddNewMeasure/${this.utilityService.currentUtility}/workorder/${workorder}/lineitemmaster/${lineitemmaster}?code=${environment.apiCode}`;
  return this.http.get<any>(searchUrl, this.getHeaders())
  .pipe(map(response => response),
      catchError(async (err) => console.log(err))
  );  
}

public getDeleteMeasureInfo(workorder, lineitem): Observable<any> {
  const searchUrl = `${environment.apiUrl}Rebate//${this.utilityService.currentUtility}/workorder/${workorder}/deletemeasure/${lineitem}/user?code=${environment.apiCode}`;
  return this.http.get<any>(searchUrl, this.getHeaders())
  .pipe(map(response => response),
      catchError(async (err) => console.log(err))
  );  
}

public saveMeasureInfo(workorder, body): Observable<any> {
  const searchUrl = `${environment.apiUrl}Rebate/SaveMeasures/${this.utilityService.currentUtility}/workorder/${workorder}?code=${environment.apiCode}`;
  return this.http.post<any[]>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),  
      catchError(async (err) => console.log(err))
      );  
} 

public saveOtherInfo(workorder, body): Observable<any> {
  const searchUrl = `${environment.apiUrl}Rebate/SaveOtherInfo/${this.utilityService.currentUtility}/workorder/${workorder}?code=${environment.apiCode}`;
  return this.http.post<any[]>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),     
        catchError(async (err) => console.log(err))
      );
  
} 

public getOtherInfo(workorder): Observable<any> {
  const searchUrl = `${environment.apiUrl}rebate/otherinfo/getotherinfodetails/${this.utilityService.currentUtility}/workorder/${workorder}?code=${environment.apiCode}`;
  return this.http.get<any>(searchUrl, this.getHeaders())
  .pipe(map(response => response),
      catchError(async (err) => console.log(err))
  );  
  }
  
  //-------------Pool pump---------
  getContractorInfo(workorderid): Observable<any> {
    const searchUrl = environment.apiUrl +'Rebate/GetContractorData/' + this.utilityService.currentUtility +'/workorder/' + workorderid + '?code=' + environment.apiCode;
    return this.http.get<any[]>(searchUrl, this.getHeaders())
      .pipe(map((response) => response),
        catchError(async (err) => console.log(err))
    );
  }

  public saveContractor(data, workorderid): Observable<any[]> {
    const savepayeeurl = environment.apiUrl + 'Rebate/SaveContractorInfo/' + this.utilityService.currentUtility + '/workorder/' + workorderid +'?code=' + environment.apiCode;
    return this.http.post<any>(savepayeeurl, data, this.getHeaders())
      .pipe(map((response) => response),
        catchError(async (err) => console.log(err))
    );
  }
}
