<meta name="viewport" content="width=1024" *ngIf="!smallScreen && isMobile">
<meta name="viewport" content="width=device-width,height=device-height,initial-scale=1.0" *ngIf="smallScreen && isMobile"/>

<div class="container" style="margin-bottom: 3%;" *ngIf="!smallScreen">
    <div class="row">
  
      <div class="col-md-8" id="htmlData">  
        <div style="height: 1100px; margin-top: 4%;">
          <div id="header" class="reportheader">
            <div>
              <div style="font-size:18pt;">
                {{reportData?.REPORTNAME.Value}}
              </div>
            </div>
          </div>
     
          <div>
            <div class="element" style="height: 800px;">
              <div class="left">       
           
              <img [src]="reportLogo" (load)="imageLoad()"/>
              <br>
                <p style="margin-top: 5%;"> <b>{{reportData?.Introduction.Header}} </b> </p>
                <div [innerHTML]="reportData?.Introduction.Value"></div>
                <br/>           

                <img [src]="this.homeLogo" style="width: 100% !important; height: 350px!important"/>               
              </div>
              <div class="right">
  
              <div class="customerstyle" ></div>
              <div style="padding-top: 10px;">
                <p *ngFor="let customerInfo of reportData?.CUSTOMER.Value;">{{customerInfo.Header}} :
                {{customerInfo.Value}}</p>
                </div>
              <div style="padding-top: 10px;"></div>
              <div class="customerstyle" ></div>

              <p style="padding-top: 3%;"><b>{{reportData?.Explanation.Header}} </b></p>
              <div>
                <div [innerHTML]="reportData?.Explanation.Value"> </div>
              </div>
              <div style="  width: 90%; margin-top: 6%;
                border: 1px solid #000000;">
                <p style="padding-left: 5%; padding-top: 3%;"><b>{{reportData?.TOP3RECOMMENDATIONS.Header}} </b></p>
                <div class="customerstyleline" ></div>
                <div>
                  <ol>
                    <ng-container *ngFor="let rec of reportData?.TOP3RECOMMENDATIONS.Value; let i=index">
                      <li style="padding: 2px; padding-top: 2%; margin-bottom: 4px;">{{rec.Measure}}</li>
                    </ng-container>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <br style="clear: left;" />
          <br style="clear: left;" />
          <br style="clear: left;" />

          <div class="footerstyle" ></div>
          <div style="margin-bottom: 1%; font-size: 12px;  margin: auto;  text-align: center">
            {{reportData?.REPORTFOOTERINFO.Value}}</div>
        </div>
      </div>

      <!--page 2-->
      <div>
        <div class="element" style="border: white 2px solid; height: 1000px;">
          <div class="section">
            <div class="left" style="width:58%;">
              <table id="customers">
                <thead>
                  <tr>
                    <th colspan="5" style=" margin: auto; padding: 0.5%;
                    text-align: center;text-transform: uppercase;">{{reportData?.TABLES[0].TableName1}}</th>
                  </tr>
                  <tr>
                    <th style=" background-color: white; color: black;" rowspan="2">
                      {{reportData?.TABLES[0].TableHeaderInfo1.Header1}}</th>
                    <th style=" background-color: white; color: black;"  rowspan="2">
                      {{reportData?.TABLES[0].TableHeaderInfo1.Header2}}</th>
                    <th style=" background-color: white; color: black;" colspan="2">
                      {{reportData?.TABLES[0].TableHeaderInfo1.Header3}}</th>
                  </tr>
                  <tr>
                    <th style="background-color: white; color: black; text-align: right;"> Min </th>
                    <th style="background-color: white; color: black; text-align: right;">Max</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let measureInfo of reportData?.TABLES[0].MEASUREINFO;let i=index">
                    <td *ngIf="i!=reportData?.TABLES[0].MEASUREINFO.length-1">{{measureInfo.MEASURE}}</td>                    
                    <td *ngIf="i!=reportData?.TABLES[0].MEASUREINFO.length-1">
                      <button mat-icon-button color="primary" (click)="openAlertDialog(null,measureInfo.ICON)">
                        <mat-icon>help_outline</mat-icon>
                      </button>
                      </td>
                    <td *ngIf="i!=reportData?.TABLES[0].MEASUREINFO.length-1" class="number-alignment">
                      ${{measureInfo.MINRANGE}}</td>
                    <td *ngIf="i!=reportData?.TABLES[0].MEASUREINFO.length-1" class="number-alignment">
                      ${{measureInfo.MAXRANGE}}</td>                      
                  </tr>                  
                </tbody>
                <tfoot>
                  <tr style="font-weight: 600;">
                    <td>{{reportData?.TABLES[0].MEASUREINFO[reportData?.TABLES[0].MEASUREINFO.length-1].MEASURE}}</td>
                    <td></td>
                    <td class="number-alignment">
                      ${{reportData?.TABLES[0].MEASUREINFO[reportData?.TABLES[0].MEASUREINFO.length-1].MINRANGE}}</td>
                    <td class="number-alignment">
                      ${{reportData?.TABLES[0].MEASUREINFO[reportData?.TABLES[0].MEASUREINFO.length-1].MAXRANGE}}</td>
                  </tr>
                </tfoot>
              </table>

              <div style="margin-top: 2%;"></div>
              <div *ngIf="reportData?.TABLES[1].MEASUREINFO[reportData?.TABLES[1].MEASUREINFO.length-1].MAXRANGE>0">
                <table id="customers">
                  <thead>
                    <tr>
                      <th colspan="5" style=" margin: auto; padding: 0.5%;
                    text-align: center;text-transform: uppercase;">{{reportData?.TABLES[1].TableName2}}</th>
                    </tr>
                    <tr>
                      <th style=" background-color: white; color: black;"  rowspan="2">
                        {{reportData?.TABLES[1].TableHeaderInfo2.Header1}}</th>
                      <th style=" background-color: white; color: black;"  rowspan="2">
                        {{reportData?.TABLES[1].TableHeaderInfo2.Header2}}</th>
                      <th colspan="2" style=" background-color: white; color: black;">
                        {{reportData?.TABLES[1].TableHeaderInfo2.Header3}}</th>
                    </tr>
                    <tr>
                      <th style="background-color: white; color: black; text-align: right;"> Min </th>
                      <th style="background-color: white; color: black; text-align: right;">Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let measureInfo of reportData?.TABLES[1].MEASUREINFO;let i=index">
                      <td *ngIf="i!=reportData?.TABLES[1].MEASUREINFO.length-1">{{measureInfo.MEASURE}}</td>                      
                      <td *ngIf="i!=reportData?.TABLES[1].MEASUREINFO.length-1">
                        <button mat-icon-button color="primary" (click)="openAlertDialog(null,measureInfo.ICON)">
                          <mat-icon>help_outline</mat-icon>
                        </button>
                        </td>
                      <td *ngIf="i!=reportData?.TABLES[1].MEASUREINFO.length-1" class="number-alignment">
                        ${{measureInfo.MINRANGE}}</td>
                      <td *ngIf="i!=reportData?.TABLES[1].MEASUREINFO.length-1" class="number-alignment">
                        ${{measureInfo.MAXRANGE}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style="font-weight: 600;">
                      <td>{{reportData?.TABLES[1].MEASUREINFO[reportData?.TABLES[1].MEASUREINFO.length-1].MEASURE}}</td>
                      <td></td>
                      <td class="number-alignment">
                        ${{reportData?.TABLES[1].MEASUREINFO[reportData?.TABLES[1].MEASUREINFO.length-1].MINRANGE}}</td>
                      <td class="number-alignment">
                        ${{reportData?.TABLES[1].MEASUREINFO[reportData?.TABLES[1].MEASUREINFO.length-1].MAXRANGE}}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

            </div>
            <div class="right" style="width:40%;">
              <div style="border: 1px solid #000000;">
                <p style="padding-left: 2%; margin-top: 3%;"><b>{{reportData?.LowCostMeasuresText.Header}}</b></p>
                <div class="customerstyleline" ></div>
                <div [innerHTML]="reportData?.LowCostMeasuresText.Value" style="padding: 5px;">
                </div>

              </div>
            </div>
          </div>
          <div class="footer">
            <div class="footerstyle" ></div>
            <div> {{reportData?.REPORTFOOTERINFO.Value}}</div>
          </div>

        </div>
      </div>

      <br style="clear: left;" />
      <br style="clear: left;" />

      <!--page 3-->
      <div>
        <div class="element" style="border: white 2px solid; height: 1050px;">
          <div class="section">
            <div class="left" style="width:58%;">
              <table id="customers">
                <thead>
                  <tr>
                    <th colspan="5" style=" margin: auto; padding: 0.5%;
                  text-align: center;text-transform: uppercase;">{{reportData?.TABLES[2].TableName3}}</th>
                  </tr>
                  <tr>
                    <th  rowspan="2" style="background-color: white; color: black;">
                      {{reportData?.TABLES[2].TableHeaderInfo3.Header1}}
                    </th>
                    <th  rowspan="2" style="background-color: white; color: black;">
                      {{reportData?.TABLES[2].TableHeaderInfo3.Header2}}
                    </th>
                    <th colspan="2" style=" background-color: white; color: black;">
                      {{reportData?.TABLES[2].TableHeaderInfo3.Header3}}</th>
                  </tr>
                  <tr>
                    <th style="background-color: white; color: black; text-align: right;"> Min </th>
                    <th style="background-color: white; color: black; text-align: right;">Max</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let measureInfo of reportData?.TABLES[2].MEASUREINFO;let i=index">
                    <td *ngIf="i!=reportData?.TABLES[2].MEASUREINFO.length-1">{{measureInfo.MEASURE}}</td>                    
                    <td *ngIf="i!=reportData?.TABLES[2].MEASUREINFO.length-1">
                      <button mat-icon-button color="primary" (click)="openAlertDialog(null,measureInfo.ICON)">
                        <mat-icon>help_outline</mat-icon>
                      </button>
                      </td>
                    <td *ngIf="i!=reportData?.TABLES[2].MEASUREINFO.length-1" class="number-alignment">
                      ${{measureInfo.MINRANGE}}</td>
                    <td *ngIf="i!=reportData?.TABLES[2].MEASUREINFO.length-1" class="number-alignment">
                      ${{measureInfo.MAXRANGE}}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr style="font-weight: 600;">
                    <td>{{reportData?.TABLES[2].MEASUREINFO[reportData?.TABLES[2].MEASUREINFO.length-1].MEASURE}}</td>
                    <td></td>
                    <td class="number-alignment">
                      ${{reportData?.TABLES[2].MEASUREINFO[reportData?.TABLES[2].MEASUREINFO.length-1].MINRANGE}}</td>
                    <td class="number-alignment">
                      ${{reportData?.TABLES[2].MEASUREINFO[reportData?.TABLES[2].MEASUREINFO.length-1].MAXRANGE}}</td>
                  </tr>
                </tfoot>
              </table>

              <div style="margin-top: 2%;"></div>
              <div *ngIf="reportData?.TABLES[3].MEASUREINFO[reportData?.TABLES[3].MEASUREINFO.length-1].MAXRANGE>0">
                <table id="customers">
                  <thead>
                    <tr>
                      <th colspan="5" style=" margin: auto; padding: 0.5%;
                  text-align: center;text-transform: uppercase;">{{reportData?.TABLES[3].TableName4}}</th>
                    </tr>
                    <tr>
                      <th  rowspan="2" style=" background-color: white; color: black;">
                        {{reportData?.TABLES[3].TableHeaderInfo4.Header1}}</th>
                      <th  rowspan="2" style=" background-color: white; color: black;">
                        {{reportData?.TABLES[3].TableHeaderInfo4.Header2}}</th>
                      <th colspan="2" style=" background-color: white; color: black;">
                        {{reportData?.TABLES[3].TableHeaderInfo4.Header3}}</th>
                    </tr>
                    <tr>
                      <th style="background-color: white; color: black; text-align: right;"> Min </th>
                      <th style="background-color: white; color: black; text-align: right;">Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let measureInfo of reportData?.TABLES[3].MEASUREINFO;let i=index">
                      <td *ngIf="i!=reportData?.TABLES[3].MEASUREINFO.length-1">{{measureInfo.MEASURE}}</td>                      
                      <td *ngIf="i!=reportData?.TABLES[3].MEASUREINFO.length-1">
                        <button mat-icon-button color="primary" (click)="openAlertDialog(null,measureInfo.ICON)">
                          <mat-icon>help_outline</mat-icon>
                        </button>
                        </td>
                      <td *ngIf="i!=reportData?.TABLES[3].MEASUREINFO.length-1" class="number-alignment">
                        ${{measureInfo.MINRANGE}}</td>
                      <td *ngIf="i!=reportData?.TABLES[3].MEASUREINFO.length-1" class="number-alignment">
                        ${{measureInfo.MAXRANGE}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style="font-weight: 600;">
                      <td>{{reportData?.TABLES[3].MEASUREINFO[reportData?.TABLES[3].MEASUREINFO.length-1].MEASURE}}</td>
                      <td></td>
                      <td class="number-alignment">
                        ${{reportData?.TABLES[3].MEASUREINFO[reportData?.TABLES[3].MEASUREINFO.length-1].MINRANGE}}</td>
                      <td class="number-alignment">
                        ${{reportData?.TABLES[3].MEASUREINFO[reportData?.TABLES[3].MEASUREINFO.length-1].MAXRANGE}}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>

            </div>
            <div class="right" style="width:40%;">
              <div style="
              border: 1px solid #000000;">
                <p style="padding-left: 5%; margin-top: 3%;"><b>{{reportData?.MajorMeasuresText.Header}}</b></p>
                <div class="customerstyleline" ></div>
                <div [innerHTML]="reportData?.MajorMeasuresText.Value" style="padding: 5px;">
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="footerstyle" ></div>
            <div> {{reportData?.REPORTFOOTERINFO.Value}}</div>
          </div>


        </div>
      </div>

      <br style="clear: left;" />

      <!--page 4-->
      <div>
        <div class="element" style="border: white 2px solid; height: 1050px;">
          <div class="section">
            <div>
              <div>
                <div style="
              border: 1px solid black; padding: 1%;">
                  <p style="padding-left: 1%; margin-top: 2%; text-transform: uppercase;">
                    <b>{{reportData?.GeneralRecommendations.Header}}</b></p>
                  <div class="customerstyleline" ></div>
                <div style="padding-top: 1%;"></div>
                <div [innerHTML]="reportData?.GeneralRecommendations.HeaderText">
                 </div>
                </div>
                <br />
                <table>
                  <thead>
                    <tr>
                      <th colspan="2" style=" margin: auto;  text-align: center">General Recommendations</th>
                    </tr>
                    <tr>
                      <th style="text-align: center;">Sequence</th>
                      <th style="text-align: center;">Description</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let measureInfo of reportData?.GeneralRecommendations.VALUE;let i=index">
                      <td style="text-align: center; width: 8%;" *ngIf="measureInfo.Sequence<=13"
                        style="text-align: center;">{{measureInfo.Sequence}}</td>
                      <td style="text-align: left; width: 92%; padding: 1%;" *ngIf="measureInfo.Sequence<=13">
                        {{measureInfo.Recommendation}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="footerstyle" ></div>
            <div> {{reportData?.REPORTFOOTERINFO.Value}}</div>
          </div>
        </div>
      </div>
      <br style="clear: left;" />


      <!--page 4.1-->
      <div *ngIf="reportData?.GeneralRecommendations.VALUE.length>14">
        <div class="element" style="border: white 2px solid; height: 1050px;">
          <div class="section">
            <div>
              <div style="
            border: 1px solid white;">
                <table>
                  <tbody>
                    <tr *ngFor="let measureInfo of reportData?.GeneralRecommendations.VALUE;let i=index">
                      <td *ngIf="measureInfo.Sequence>13 && measureInfo.Sequence<=31"
                        style="text-align: center; width: 8%;">{{measureInfo.Sequence}}</td>
                      <td *ngIf="measureInfo.Sequence>13 && measureInfo.Sequence<=31"
                        style="text-align: left; width: 92%; padding: 1%;"> {{measureInfo.Recommendation}}</td>
                    </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div class="footer">
            <div class="footerstyle" ></div>
            <div> {{reportData?.REPORTFOOTERINFO.Value}}</div>
          </div>
        </div>
        <br style="clear: left;" />
      </div>

      <!--page 4.2-->
      <div *ngIf="reportData?.GeneralRecommendations.VALUE.length>31">
        <div class="element" style="border: white 2px solid; height: 1050px;">
          <div class="section">
            <div>
              <table>
                <tbody>
                  <tr *ngFor="let measureInfo of reportData?.GeneralRecommendations.VALUE;let i=index">
                    <td *ngIf="measureInfo.Sequence>31 && measureInfo.Sequence<=50"
                      style="text-align: center; width: 8%;">{{measureInfo.Sequence}}</td>
                    <td *ngIf="measureInfo.Sequence>31&& measureInfo.Sequence<=50"
                      style="text-align: left; width: 92%; padding: 1%;"> {{measureInfo.Recommendation}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="footer">
            <div class="footerstyle" ></div>
            <div> {{reportData?.REPORTFOOTERINFO.Value}}</div>
          </div>
        </div>
        <br style="clear: left;" />
      </div>


      <!--page 5-->
      <div>
        <div class="element" style="border: white 2px solid; height: 1050px;">
          <div class="section">
            <div>
              <div style="
             border: 1px solid black; padding: 1%;">
                <p style="padding-left: 1%; margin-top: 2%; text-transform: uppercase;">
                  <b>{{reportData?.Summary.Header}}</b></p>
                <div class="customerstyleline" ></div>
                <div style=" padding-top: 1%;"></div>
                <div [innerHTML]="reportData?.Summary.Value" style="padding: 5px;">
                </div>
              </div>
              <br style="clear: left;" />
            </div>
          </div>
          <div class="footer">
            <div class="footerstyle" ></div>
            <div> {{reportData?.REPORTFOOTERINFO.Value}}</div>
          </div>
        </div>
      </div>

      <br style="clear: left;" />




      <!--page 6-->
      <div>
        <div class="element" style="border: white 2px solid; height: 1050px;">
          <div class="section">
            <div>
              <div style="border: 1px solid black; padding: 1%;">
                <p style="padding-left: 1%; margin-top: 2%; text-transform: uppercase;">
                  <b>{{reportData?.TermsAndConditions.Header}}</b></p>
                <div class="customerstyleline" ></div>
              <div style=" padding-top: 2%;"></div>
                <ol>
                  <ng-container *ngFor="let rec of reportData?.TermsAndConditions.Value; let i=index">
                    <li>{{rec.Header}}: {{rec.Value}} </li>
                  </ng-container>
                </ol>
              </div>
            </div>
          </div>
          <div class="footer">
            <div class="footerstyle" ></div>
            <div> {{reportData?.REPORTFOOTERINFO.Value}}</div>
          </div>
        </div>
      </div>


    </div> <!--Html print end-->

    <div class="col-md-4">
      <button class="btn btn-success btn-block reportDesktopButton"  (click)="openPDF()"
      *ngIf="!smallScreen && !isMobile" 
      >Download PDF & Email Me</button>
    </div>

  </div>
</div>

<!--Mobile-->

<div class="container" style="margin-bottom: 3%;" *ngIf="smallScreen">
  <button class="btn btn-success btn-block reportMobileButton"
    (click)="Mobile()">Download PDF & Email Me</button>
    
    <div class="row">  
      <div class="san-col-md-8" id="htmlData01">
        <div style="margin-top: 4%;">      
            <div class="mobileheader">
            <div>
              <div style="font-size:20pt;">
                {{reportData?.REPORTNAME.Value}}
              </div>
            </div>
          </div>
  
          <div>
            <div class="element" >
              <div class="mobileleft">           
                <img [src]="reportLogo" />
                <br>
                <p style="margin-top: 5%;"> <b>{{reportData?.Introduction.Header}} </b> </p>
                <div [innerHTML]="reportData?.Introduction.Value"></div>
              </div>
              <br/>
              <div class="mobileright">  
                <div class="customerstyle" ></div>
                <div style="padding-top: 10px;">
                  <p *ngFor="let customerInfo of reportData?.CUSTOMER.Value;">{{customerInfo.Header}} :
                  {{customerInfo.Value}}</p>
                  </div>
                <div style="padding-top: 10px;"></div>
                <div class="customerstyle" ></div>
  
                <p style="padding-top: 3%;"><b>{{reportData?.Explanation.Header}} </b></p>
                <div>
                  <div [innerHTML]="reportData?.Explanation.Value"> </div>
                </div>
                
                <div style="  width: 100%; margin-top: 6%;
                  border: 1px solid #000000;">
                  <p style="padding-left: 5%; padding-top: 3%;"><b>{{reportData?.TOP3RECOMMENDATIONS.Header}} </b></p>
                  <div class="customerstyleline" ></div>
                  <div>
                    <ol>
                      <ng-container *ngFor="let rec of reportData?.TOP3RECOMMENDATIONS.Value; let i=index">
                        <li style="padding: 2px; padding-top: 2%; margin-bottom: 4px;">{{rec.Measure}}</li>
                      </ng-container>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            <br style="clear: left;" />    
        </div>
  
        <!--page 2-->
        <div>
          <div class="element" style="border: white 2px solid; height: 1000px;">
            <div class="section">
              <div class="mobileleft" style="width:100%;">
                <table id="customers">
                  <thead>
                    <tr>
                      <th colspan="5" style=" margin: auto; padding: 2%;
                      text-align: center;text-transform: uppercase;">{{reportData?.TABLES[0].TableName1}}</th>
                    </tr>
                    <tr>
                      <th style=" background-color: white; color: black;" rowspan="2">
                        {{reportData?.TABLES[0].TableHeaderInfo1.Header1}}</th>
                      <th style=" background-color: white; color: black;"  rowspan="2">
                        {{reportData?.TABLES[0].TableHeaderInfo1.Header2}}</th>
                      <th style=" background-color: white; color: black;" colspan="2">
                        {{reportData?.TABLES[0].TableHeaderInfo1.Header3}}</th>
                    </tr>
                    <tr>
                      <th style="background-color: white; color: black; text-align: right;"> Min </th>
                      <th style="background-color: white; color: black; text-align: right;">Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let measureInfo of reportData?.TABLES[0].MEASUREINFO;let i=index">
                      <td *ngIf="i!=reportData?.TABLES[0].MEASUREINFO.length-1">{{measureInfo.MEASURE}}</td>                      
                      <td *ngIf="i!=reportData?.TABLES[0].MEASUREINFO.length-1">
                        <button mat-icon-button color="primary" (click)="openAlertDialog(null,measureInfo.ICON)">
                          <mat-icon>help_outline</mat-icon>
                        </button>
                        </td>
                      <td *ngIf="i!=reportData?.TABLES[0].MEASUREINFO.length-1" class="number-alignment">
                        ${{measureInfo.MINRANGE}}</td>
                      <td *ngIf="i!=reportData?.TABLES[0].MEASUREINFO.length-1" class="number-alignment">
                        ${{measureInfo.MAXRANGE}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style="font-weight: 600;">
                      <td>{{reportData?.TABLES[0].MEASUREINFO[reportData?.TABLES[0].MEASUREINFO.length-1].MEASURE}}</td>
                      <td></td>
                      <td class="number-alignment">
                        ${{reportData?.TABLES[0].MEASUREINFO[reportData?.TABLES[0].MEASUREINFO.length-1].MINRANGE}}</td>
                      <td class="number-alignment">
                        ${{reportData?.TABLES[0].MEASUREINFO[reportData?.TABLES[0].MEASUREINFO.length-1].MAXRANGE}}</td>
                    </tr>
                  </tfoot>
                </table>
  
                <div style="margin-top: 5%;"></div>
                <div *ngIf="reportData?.TABLES[1].MEASUREINFO[reportData?.TABLES[1].MEASUREINFO.length-1].MAXRANGE>0">
                  <table id="customers">
                    <thead>
                      <tr>
                        <th colspan="5" style=" margin: auto; padding: 2%;
                      text-align: center;text-transform: uppercase;">{{reportData?.TABLES[1].TableName2}}</th>
                      </tr>
                      <tr>
                        <th style=" background-color: white; color: black;"  rowspan="2">
                          {{reportData?.TABLES[1].TableHeaderInfo2.Header1}}</th>
                        <th style=" background-color: white; color: black;"  rowspan="2">
                          {{reportData?.TABLES[1].TableHeaderInfo2.Header2}}</th>
                        <th colspan="2" style=" background-color: white; color: black;">
                          {{reportData?.TABLES[1].TableHeaderInfo2.Header3}}</th>
                      </tr>
                      <tr>
                        <th style="background-color: white; color: black; text-align: right;"> Min </th>
                        <th style="background-color: white; color: black; text-align: right;">Max</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let measureInfo of reportData?.TABLES[1].MEASUREINFO;let i=index">
                        <td *ngIf="i!=reportData?.TABLES[1].MEASUREINFO.length-1">{{measureInfo.MEASURE}}</td>                        
                        <td *ngIf="i!=reportData?.TABLES[1].MEASUREINFO.length-1">
                          <button mat-icon-button color="primary" (click)="openAlertDialog(null,measureInfo.ICON)">
                            <mat-icon>help_outline</mat-icon>
                          </button>
                          </td>
                        <td *ngIf="i!=reportData?.TABLES[1].MEASUREINFO.length-1" class="number-alignment">
                          ${{measureInfo.MINRANGE}}</td>
                        <td *ngIf="i!=reportData?.TABLES[1].MEASUREINFO.length-1" class="number-alignment">
                          ${{measureInfo.MAXRANGE}}</td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr style="font-weight: 600;">
                        <td>{{reportData?.TABLES[1].MEASUREINFO[reportData?.TABLES[1].MEASUREINFO.length-1].MEASURE}}</td>
                        <td></td>
                        <td class="number-alignment">
                          ${{reportData?.TABLES[1].MEASUREINFO[reportData?.TABLES[1].MEASUREINFO.length-1].MINRANGE}}</td>
                        <td class="number-alignment">
                          ${{reportData?.TABLES[1].MEASUREINFO[reportData?.TABLES[1].MEASUREINFO.length-1].MAXRANGE}}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
  
              </div>
              <div class="mobileright" style="width:100%;">
                <div style="border: 1px solid #000000;">
                  <p style="padding-left: 2%; margin-top: 3%;"><b>{{reportData?.LowCostMeasuresText.Header}}</b></p>
                  <div class="customerstyleline" ></div>
                  <div [innerHTML]="reportData?.LowCostMeasuresText.Value" style="padding: 5px;">
                  </div>
  
                </div>
              </div>
            </div>
              
          </div>
        </div>
<!--   
        <br style="clear: left;" />
        <br style="clear: left;" /> -->    
       
  
        <!--page 3-->
        <div>
          <div class="element" style="border: white 2px solid; ">
            <div class="section">
              <div class="mobileleft" style="width:100%;">
                <table id="customers">
                  <thead>
                    <tr>
                      <th colspan="5" style=" margin: auto; padding: 2%;
                    text-align: center;text-transform: uppercase;">{{reportData?.TABLES[2].TableName3}}</th>
                    </tr>
                    <tr>
                      <th  rowspan="2" style="background-color: white; color: black;">
                        {{reportData?.TABLES[2].TableHeaderInfo3.Header1}}
                      </th>
                      <th  rowspan="2" style="background-color: white; color: black;">
                        {{reportData?.TABLES[2].TableHeaderInfo3.Header2}}
                      </th>
                      <th colspan="2" style=" background-color: white; color: black;">
                        {{reportData?.TABLES[2].TableHeaderInfo3.Header3}}</th>
                    </tr>
                    <tr>
                      <th style="background-color: white; color: black; text-align: right;"> Min </th>
                      <th style="background-color: white; color: black; text-align: right;">Max</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let measureInfo of reportData?.TABLES[2].MEASUREINFO;let i=index">
                      <td *ngIf="i!=reportData?.TABLES[2].MEASUREINFO.length-1">{{measureInfo.MEASURE}}</td>                      
                      <td *ngIf="i!=reportData?.TABLES[2].MEASUREINFO.length-1">
                        <button mat-icon-button color="primary" (click)="openAlertDialog(null,measureInfo.ICON)">
                          <mat-icon>help_outline</mat-icon>
                        </button>
                        </td>
                      <td *ngIf="i!=reportData?.TABLES[2].MEASUREINFO.length-1" class="number-alignment">
                        ${{measureInfo.MINRANGE}}</td>
                      <td *ngIf="i!=reportData?.TABLES[2].MEASUREINFO.length-1" class="number-alignment">
                        ${{measureInfo.MAXRANGE}}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr style="font-weight: 600;">
                      <td>{{reportData?.TABLES[2].MEASUREINFO[reportData?.TABLES[2].MEASUREINFO.length-1].MEASURE}}</td>
                      <td></td>
                      <td class="number-alignment">
                        ${{reportData?.TABLES[2].MEASUREINFO[reportData?.TABLES[2].MEASUREINFO.length-1].MINRANGE}}</td>
                      <td class="number-alignment">
                        ${{reportData?.TABLES[2].MEASUREINFO[reportData?.TABLES[2].MEASUREINFO.length-1].MAXRANGE}}</td>
                    </tr>
                  </tfoot>
                </table>
  
                <div style="margin-top: 5%;"></div>
                <div *ngIf="reportData?.TABLES[3].MEASUREINFO[reportData?.TABLES[3].MEASUREINFO.length-1].MAXRANGE>0">
                  <table id="customers">
                    <thead>
                      <tr>
                        <th colspan="5" style=" margin: auto; padding: 2%;
                    text-align: center;text-transform: uppercase;">{{reportData?.TABLES[3].TableName4}}</th>
                      </tr>
                      <tr>
                        <th  rowspan="2" style=" background-color: white; color: black;">
                          {{reportData?.TABLES[3].TableHeaderInfo4.Header1}}</th>
                        <th  rowspan="2" style=" background-color: white; color: black;">
                          {{reportData?.TABLES[3].TableHeaderInfo4.Header2}}</th>
                        <th colspan="2" style=" background-color: white; color: black;">
                          {{reportData?.TABLES[3].TableHeaderInfo4.Header3}}</th>
                      </tr>
                      <tr>
                        <th style="background-color: white; color: black; text-align: right;"> Min </th>
                        <th style="background-color: white; color: black; text-align: right;">Max</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let measureInfo of reportData?.TABLES[3].MEASUREINFO;let i=index">
                        <td *ngIf="i!=reportData?.TABLES[3].MEASUREINFO.length-1">{{measureInfo.MEASURE}}</td>                        
                        <td *ngIf="i!=reportData?.TABLES[3].MEASUREINFO.length-1">
                          <button mat-icon-button color="primary" (click)="openAlertDialog(null,measureInfo.ICON)">
                            <mat-icon>help_outline</mat-icon>
                          </button>
                          </td>
                        <td *ngIf="i!=reportData?.TABLES[3].MEASUREINFO.length-1" class="number-alignment">
                          ${{measureInfo.MINRANGE}}</td>
                        <td *ngIf="i!=reportData?.TABLES[3].MEASUREINFO.length-1" class="number-alignment">
                          ${{measureInfo.MAXRANGE}}</td>                          
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr style="font-weight: 600;">
                        <td>{{reportData?.TABLES[3].MEASUREINFO[reportData?.TABLES[3].MEASUREINFO.length-1].MEASURE}}</td>
                        <td></td>
                        <td class="number-alignment">
                          ${{reportData?.TABLES[3].MEASUREINFO[reportData?.TABLES[3].MEASUREINFO.length-1].MINRANGE}}</td>
                        <td class="number-alignment">
                          ${{reportData?.TABLES[3].MEASUREINFO[reportData?.TABLES[3].MEASUREINFO.length-1].MAXRANGE}}</td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
  
              </div>
              <div class="mobileright" style="width:100%;">
                <div style="
                border: 1px solid #000000;">
                  <p style="padding-left: 5%; margin-top: 3%;"><b>{{reportData?.MajorMeasuresText.Header}}</b></p>
                  <div class="customerstyleline" ></div>
                  <div [innerHTML]="reportData?.MajorMeasuresText.Value" style="padding: 5px;">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <br style="clear: left;" />
  
        <!--page 4-->
        <div>
          <div class="element" style="border: white 2px solid;">
            <div class="section">
              <div>
                <div>
                  <div style="
                border: 1px solid black; padding: 1%;">
                    <p style="padding-left: 1%; margin-top: 2%; text-transform: uppercase;">
                      <b>{{reportData?.GeneralRecommendations.Header}}</b></p>
                    <div class="customerstyleline" ></div>
                  <div style="padding-top: 1%;"></div>
                  <div [innerHTML]="reportData?.GeneralRecommendations.HeaderText">
                   </div>
                  </div>
                  <br />
                  <table>
                    <thead>
                      <tr>
                        <th colspan="2" style=" margin: auto;  text-align: center">General Recommendations</th>
                      </tr>
                      <tr>
                        <th style="text-align: center;">Sequence</th>
                        <th style="text-align: center;">Description</th>
  
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let measureInfo of reportData?.GeneralRecommendations.VALUE;let i=index">
                        <td style="text-align: center; width: 8%;" 
                          style="text-align: center;">{{measureInfo.Sequence}}</td>
                        <td style="text-align: left; width: 92%; padding: 1%;">
                          {{measureInfo.Recommendation}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          
          </div>
        </div>
        <br style="clear: left;" />
  
  
  
        <!--page 5-->
        <div>
          <div class="element" style="border: white 2px solid;">
            <div class="section">
              <div>
                <div style="
               border: 1px solid black; padding: 1%;">
                  <p style="padding-left: 1%; margin-top: 2%; text-transform: uppercase;">
                    <b>{{reportData?.Summary.Header}}</b></p>
                  <div class="customerstyleline" ></div>
                  <div style=" padding-top: 1%;"></div>
                  <div [innerHTML]="reportData?.Summary.Value" style="padding: 5px;">
                  </div>
                </div>
                <br style="clear: left;" />
              </div>
            </div>
            
          </div>
        </div>

        <!--page 6-->
        <div>
          <div class="element" style="border: white 2px solid;">
            <div class="section">
              <div>
                <div style="border: 1px solid black; padding: 1%;">
                  <p style="padding-left: 1%; margin-top: 2%; text-transform: uppercase;">
                    <b>{{reportData?.TermsAndConditions.Header}}</b></p>
                  <div class="customerstyleline" ></div>
                <div style=" padding-top: 2%;"></div>
                  <ol>
                    <ng-container *ngFor="let rec of reportData?.TermsAndConditions.Value; let i=index">
                      <li><b>{{rec.Header}}</b>:&nbsp;&nbsp;{{rec.Value}} </li>
                    </ng-container>
                  </ol>
                </div>
              </div>
            </div>            
          </div>     
      </div> <!--Html print end--> 
    </div>
  </div>
</div>


  