<div style="height: 600px; width: 500px;">
    <h1 mat-dialog-title>RECOMMENDATIONS</h1>
    <h3>{{recommendationText}}</h3>
    <mat-divider></mat-divider>
    <div mat-dialog-content>
      <br>
      <mat-card *ngIf="searching">
        <table style="width: 100%;">
          <tr *ngIf="!showForEligible">
            <td width="80%">
              Saving Eligibility Questions
            </td>
            <td matTooltip="Check">
              <div id="loading" *ngIf="saving"></div>
              <mat-icon color="primary" *ngIf="!saving">check</mat-icon>
            </td>
          </tr>
          <tr *ngIf="!showForEligible">
            <td width="80%">
              Calculating Enrollment Recommendations
            </td>
            <td>
              <div id="loading"></div>
            </td>
          </tr>

          <tr *ngIf="showForEligible">
            <td width="80%">
             Verifying the qualification criteria. Please wait...
            </td>
            <td>
              <div id="loading"></div>
            </td>
          </tr>
        </table>
      </mat-card>
      <div  *ngIf="!searching">
        <ng-container *ngIf="!showForEligible">
          <mat-list>
            <h3 *ngIf="recommendedCount.length ==0">{{notQualifiedReason}}</h3>
            <ng-container *ngFor="let rec of recs;">
              <mat-list-item *ngIf="rec.RECOMMEND == 1 && rec.SELFSCHEDULEENABLE == 1">
                <!-- <mat-icon mat-list-icon style="color: red;margin-right: 30px;" matTooltip="Ineligible" *ngIf="rec.RECOMMEND === 0">error
                </mat-icon> -->
                <mat-icon mat-list-icon style="color: green;margin-right: 30px;" matTooltip="Eligible">check_circle
                </mat-icon>
                <!-- <h4 mat-line *ngIf="rec.RECOMMEND === 0">{{rec.PROGRAMNAME}}</h4> -->
                <button mat-raised-button class="mainbutton" (click)="handleEnroll(rec)">Enroll in {{rec.PROGRAMNAME}}</button>
                
              </mat-list-item>
            </ng-container>
          </mat-list>
        </ng-container>
        <div [innerHTML]=data.MESSAGE></div>
        <ng-container  *ngIf="showForEligible">
          <mat-list>
            <!--<h3 *ngIf="recommendedCount.length ==0">{{notQualifiedReason}}</h3>-->
            
            <h3 *ngIf="recommendedCount.length ==0" [innerHTML]=notQualifiedReason></h3>
            <ng-container *ngFor="let rec of recommendedCount;">
              <mat-list-item >
                <!-- <mat-icon mat-list-icon style="color: red;margin-right: 30px;" matTooltip="Ineligible" *ngIf="rec.RECOMMEND === 0">error
                </mat-icon> -->
                <mat-icon mat-list-icon style="color: green;margin-right: 30px;" matTooltip="Eligible">check_circle
                </mat-icon>
                <!-- <h4 mat-line *ngIf="rec.RECOMMEND === 0">{{rec.PROGRAMNAME}}</h4> -->
                <button mat-raised-button class="mainbutton" (click)="handleEnroll(rec)">Enroll in {{rec.PROGRAMNAME}}</button>
                
              </mat-list-item>
            </ng-container>
          </mat-list>
        </ng-container>

      </div>
      <br>
    </div>
    <div mat-dialog-actions>
      <button mat-raised-button class="mainbutton" (click)="handleCancel()">CANCEL</button>
    </div>
  </div>
  