/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-utility-error',
  templateUrl: './utility-error.component.html',
  styleUrls: ['./utility-error.component.scss']
})
export class UtilityErrorComponent implements OnInit {
  msgUtilityText :any;
  constructor( private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.spinner.hide();
    let officeId=localStorage.getItem('utilParam');
    console.log("ueUtilParam",officeId);
    if(officeId=='STC1')
    {
      this.msgUtilityText="<br><br><br>We are sorry, our Enrollment Portal is currently unavailable.<br> Please try again later or call our Customer Service Team to enroll in the program at 833-493-1038.";
    }
   else if(officeId=='JCP1')
    {
      this.msgUtilityText="<br><br><br>We are sorry, our Enrollment Portal is currently unavailable. <br> Please try again later or call our Customer Service Team to enroll in the program at 888-352-0908.";
    }
    else if(officeId=='BWS1')
    {
     this.msgUtilityText="<br><br><br>We are sorry, our Enrollment Portal is currently unavailable. <br> Please try again later or call our Customer Service Team to enroll in the program at 808-237-6877.";
    }
    else if(officeId=='CLR1')
    {
     this.msgUtilityText="<br><br><br>We are sorry, our Enrollment Portal is currently unavailable. <br> Please try again later or call our Customer Service Team to enroll in the program.";
    }
    else
    {
      this.msgUtilityText="<br>We are sorry, please try again by entering the correct URL.";
    }
  }

}
