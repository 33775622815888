<mat-card class="custom-card font-style">
    <mat-card-content style="margin-top: 10px" class="panel-content custom-card">
        <form [formGroup]="contractorform" (ngSubmit)="onSave()">
            <div class="form-row" style="padding-top: 10px;">
                <mat-form-field class="third-width" floatLabel="auto" appearance="outline" color="primary">
                    <mat-label>Installation Contractor</mat-label>
                    <mat-select (selectionChange)="onListChange($event)" formControlName="InstallationContractor">
                        <mat-option *ngFor="let contarctor of contractorOptions" [value]="contarctor.Id"
                            style="font-size: 11px;">
                            {{contarctor.Value}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="third-width" floatLabel="auto" appearance="outline" color="primary"
                    *ngIf="!isOtherSelected">
                    <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput
                        placeholder="Web Entered Contractor" formControlName="ContractorName" maxlength="40"/>
                    <mat-label>Web Entered Contractor</mat-label>
                    <mat-hint align="end">Up to 40 characters</mat-hint> 
                    <mat-icon matPrefix>web</mat-icon>
                </mat-form-field>

                <mat-form-field class="third-width" floatLabel="auto" appearance="outline" color="primary"
                    *ngIf="!isOtherSelected">
                    <input (keydown)="onFormChange()" (keypress)="onFormChange()" type="text" matInput appPhoneMask
                        placeholder="Web Entered Phone" formControlName="ContractorPhone" maxlength="14" />
                    <mat-label>Web Entered Phone</mat-label>
                    <mat-hint align="end">Phone No is required in format (987) 654-3210</mat-hint>
                    <mat-icon matPrefix>phone</mat-icon>
                </mat-form-field>
            </div>

            <div class="button-container">
                <button class="mainbutton font-style" #myButtonRef type="submit" [disabled]="!isSubmitEnabled"
                    mat-flat-button>
                    Save
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>