/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {

  name:any;
  phonenumber:any;
  officeid:any;
  smallScreen:any;
  remarks:any;

  constructor(private spinner: NgxSpinnerService,public utilityService: UtilityService,public router:Router,private dialog: MatDialog) { }

  ngOnInit(): void {
    this.officeid= localStorage.getItem('currentUtility');
    this.smallScreen=screen.width < 600;
  }
  

  callbackNumber()
  {
    this.spinner.show();
    if(this.remarks==undefined ||this.remarks==null ||this.remarks=='')
    {
      this.remarks="";
    }
    let bodyContent = {
    Name: this.name,
    Phonenumber: this.phonenumber,
    Remarks: this.remarks   
    };

    this.utilityService.requestCallback(this.officeid,bodyContent).subscribe((res : any) => {
      if (res.Action == 0) {
          this.spinner.hide();
      
            Swal.fire({  
              icon: 'success',
              text:'Representative will contact you shortly',
              width:'800px',
              confirmButtonText: 'OK',
            })
            .then((result) => {
              if (result.value) { 
                 this.dialog.closeAll();
                 this.spinner.show();
                 this.router.navigate(['/customerlookup']);  
                 this.spinner.hide();
              }
          }) 
        }
        else {
         
          Swal.fire({
              title: 'Error',
              text: res.Message,
              icon: 'error',
              confirmButtonText: 'OK',
          })
          // this.loading = false;
          this.spinner.hide();
        }
    }, err => {
      this.spinner.hide();
      Swal.fire({
          title: 'Error',
          text: err,
          icon: 'error',
          confirmButtonText: 'OK',
      })


  })
    

  }

}
