
<div class="close">
    <button mat-icon-button class="mainbutton" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
      </button>
</div>
    
    <h1 mat-dialog-title class="intro-text" >File Upload</h1>
    <div mat-dialog-content class="enrollmentPanel enroll-card-div">
        
        <mat-card style="width: 40vw ">
            <mat-card-actions>
                <input  class="input" color="warn"  type="file"  accept=".jpg,.pdf,.png,.doc,.docx,.gif|image/*" (change)="handleFiles($event)" [(ngModel)]="fileObj">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <button mat-raised-button class="mainbutton" [disabled]="click"  (click)="uploadDoc()">Upload</button>
                <br>
                <br>
                <h6 style="text-align: left"  size='10'><b>Note:</b> File name must be atleast 6 characters and choose only .jpg, .png, .gif, .doc, .docx & .pdf</h6>
                <div id="loading" *ngIf="loadFile">
                    <mat-spinner style="left:40%;top:50%" [diameter]="50" color="warn"></mat-spinner> 
                 </div>
                
            </mat-card-actions>
        </mat-card>
    </div>
