/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

export class Charger {
    manufacturerID: string;
    manufacturerName: string;
    modelID: string;
    modelName: string;
    serialNumber: string;
    networkID: string;
    networkName: string;
    portTypeID: string;
    portTypeName: string
    juiceNetId: string;

    getDisplayLine1() { return this.manufacturerName + ' model ' + this.modelName; }
    getDisplayLine2() { return 'SN ' + this.serialNumber + ' on ' + this.networkName; }
    getDisplayLine3(hasPortType: boolean, hasJuiceNetId: boolean) {
        let res = '';

        if (hasPortType) {
            res = "Port Type " + this.portTypeName;
        }

        if (hasJuiceNetId && this.juiceNetId != null  && this.juiceNetId != '') {
            if (hasPortType) {
                res += "   |   ";
            }
            res += "JuiceNet ID " + this.juiceNetId;
        }

        return res;
    }
}