/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Charger } from './charger-model';

export class ChargerData {
    chargers: Charger[] = [];

    manufacturerList: [];
    modelList: [];
    networkList: [];
    portTypeList: [];

    juiceLinkIdUrl: string = '';

    hasPortType = false;
    hasJuiceNetId = false;
}