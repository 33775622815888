<div style="height: 900px; width: 350px;" mat-dialog-content>

  <div style="margin: 0 !important" class="btn-align">
    <h1 mat-dialog-title class="header" style="padding:0.5vw;font-size:1.2vw; color:black"></h1>
    <button matTooltip="Close" mat-button style="height: 2.5vw !important;" class="close-icon"
      [mat-dialog-close]="true">
      <mat-icon style="margin-bottom: 0.5vw;">close</mat-icon>
    </button>
  </div>

  <span><strong>EV CHARGERS</strong> ({{ chargerData.chargers.length }} of {{ numOfChargers }})</span>
  <hr>
  <br>
  <br>
  <form [formGroup]="form">
    <mat-form-field style="width: 60%;margin-bottom: 20px;">
      <mat-label>Charger Manufacturer</mat-label>
      <mat-select (selectionChange)="changeManufacturer($event)" [formControl]="form.get('manufacturersFormControl')">
        <mat-option *ngFor="let typeobj of chargerData.manufacturerList" [value]="typeobj" style="font-size: 11px;">
          {{typeobj.RESPONSE_TEXT}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('manufacturersFormControl').hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <br />

    <mat-form-field style="width: 60%;margin-bottom: 20px;">
      <mat-label>Charger Model</mat-label>
      <mat-select [formControl]="form.get('modelFormControl')">
        <mat-option *ngFor="let typeobj of defModels" [value]="typeobj" style="font-size: 11px;">
          {{typeobj.RESPONSE_TEXT}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('modelFormControl').hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <br />

    <mat-form-field style="width: 60%;margin-bottom: 20px;">
      <mat-label>Charger Network</mat-label>
      <mat-select [formControl]="form.get('networksFormControl')">
        <mat-option *ngFor="let typeobj of defNetworks" [value]="typeobj" style="font-size: 11px;">
          {{typeobj.RESPONSE_TEXT}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('networksFormControl').hasError('required')">
        This field is required
      </mat-error>
    </mat-form-field>

    <br />

      <div class="serialno-container" *ngIf="displayJuiceNetIdField()">
        <div [innerHTML] = chargerData.juiceLinkIdUrl></div>
        <br/>
        <mat-form-field appearance="fill">
          <mat-label>Juice Net ID</mat-label>
          <input matInput placeholder="Juice Net ID" [formControl]="form.get('juiceId')" (blur)="missingJuiceNetId()">
          <mat-error *ngIf="form.get('juiceId').invalid">Juice Net ID is Required. No spaces</mat-error>
        </mat-form-field>  
      </div>
      <div class="serialno-container" *ngIf="displayJuiceNetIdField()">
        <br/>
        <mat-form-field appearance="fill">
          <mat-label>Confirm Juice Net ID</mat-label>
          <input matInput placeholder="Confirm Juice Net ID" [formControl]="form.get('confirmJuiceId')" (blur)="missingJuiceNetId()">
          <mat-error *ngIf="form.get('confirmJuiceId').hasError('required')">Juice Net ID is Required.</mat-error>
          <mat-error *ngIf="form.get('confirmJuiceId').hasError('juiceNetIdMismatch') &&
          !(form.get('confirmJuiceId').hasError('required')) && form.get('confirmJuiceId').touched">
            The Juice Net ID and Confirm Juice Net ID do not match</mat-error>
        </mat-form-field>
        <br/><br/><br/>
      </div>

    <span *ngIf="chargerData.hasPortType">
      <mat-form-field style="width: 60%;margin-bottom: 20px;" *ngIf="chargerData.hasPortType">
        <mat-label>Port Type</mat-label>
        <mat-select [formControl]="form.get('portTypesFormControl')">
          <mat-option *ngFor="let typeobj of defPortTypes" [value]="typeobj" style="font-size: 11px;">
            {{typeobj.RESPONSE_TEXT}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('portTypesFormControl').hasError('required')">
          This field is required
        </mat-error>
      </mat-form-field>

      <br />
    </span>

    <div class="serialno-container">
      <mat-form-field appearance="fill">
        <mat-label>Charger Serial Number</mat-label>
        <input matInput placeholder="Serial Number" [formControl]="form.get('serialNo')" required>
        <mat-error *ngIf="form.get('serialNo').invalid">Charger Serial Number is Required. No spaces</mat-error>
      </mat-form-field>
    </div>
    <br />
    <div class="serialno-container">
      <mat-form-field appearance="fill">
        <mat-label>Confirm Serial Number</mat-label>
        <input matInput placeholder="Confirm Serial Number" [formControl]="form.get('confirmSerialNo')" required>
        <mat-error *ngIf="form.get('confirmSerialNo').hasError('required')">Charger Serial Number is Required</mat-error>
          <mat-error *ngIf="form.get('confirmSerialNo').hasError('serialNumberMismatch') &&
           !(form.get('confirmSerialNo').hasError('required')) && form.get('confirmSerialNo').touched">
            The Charger Serial Number and Confirm Charger Serial Number do not match</mat-error>
      </mat-form-field>
    </div>
    <br />

    <div mat-dialog-actions>
      <button mat-raised-button class="mainbutton" mat-dialog-close>DONE</button>
      <button mat-raised-button class="mainbutton" [disabled]="!form.valid || chargerData.chargers.length >= numOfChargers || missingJuiceNetId()"
        (click)="addCharger()">ADD</button>
    </div>
    <br>
    <hr />
    <br />
    <div *ngFor="let charg of chargerData.chargers; index as i">
      <table>
        <tr>
          <td width="300px">
            <strong>{{ charg.getDisplayLine1() }}</strong>
            <p style="margin-bottom: 0px;">{{ charg.getDisplayLine2() }}</p>
            <p>{{ charg.getDisplayLine3(chargerData.hasPortType, chargerData.hasJuiceNetId) }}</p>
          </td>
          <td>
            <button mat-icon-button (click)="deleteCharger(i)">
              <mat-icon matTooltip="Delete">delete</mat-icon>
            </button>
          </td>
        </tr>
      </table>
    </div>
  </form>
</div>