/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import Swal from 'sweetalert2';
// import { WorkorderService } from 'src/app/services/workorder.service';

import { UtilityService } from '../../services/utility.service';
import { NgxSpinnerService } from "ngx-spinner";
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: 'app-customer-confirm',
  templateUrl: './customer-confirm.component.html',
  styleUrls: ['./customer-confirm.component.scss']
})
export class CustomerConfirmComponent implements OnInit {
  dialog: any;

  constructor(private router: Router, public utilityService: UtilityService, private dataTransfer:DataTransferService,private spinner: NgxSpinnerService) { }

  confirmData: any = {}
  emailaddress: string;
  officeid:any;
  PersoncompanyId:any;
  enableSaveButton:boolean;
  confirmemail:any;
  smallScreen:any;
  homeType: any = {};
  ownOrRent: any ={};
  hometype:any;
  ownorrent:any;
  homeTypeLength:any;
  ownOrRentLength:any;
  public isValidFlg:boolean = true;
  public isValidFlg1:boolean = true;
  public isValidFlg2:boolean = true;
  public isValidFlg3:boolean = true;
  //preferredPhone:any;
  preferredphone1:any;
  homephone:any;
  ownorrentenable:any;
  confirmphonenumber:any;
  existDayTimePhone:any;
  existcommunication:any;
  dayTimePhone1:any;
  alternatePhone1:any;
  confirmdaytimephone:any;
  confirmalternatephone:any;
  
  email:any;
  primaryPhone:any;
  altPhone:any;
  doNotCall:any;
  doNotEmail:any;
  doNotMail:any;
  agree:any;
  communication:any;
  preferredphone:any;
  dayTimePhone:any;
  alternatePhone:any;
 
  enableHelpContent:any;
  isEnableHelpContent:boolean;

  ngOnInit(): void {
      this.dataTransfer.myMethod(true);
      this.confirmData = history.state.data;
      console.log("confirmData",this.confirmData);
      this.email = this.confirmData.Email;
      this.primaryPhone=this.confirmData.PrimaryPhone;
      this.altPhone=this.confirmData.AlternatePhone;
      this.doNotCall=this.confirmData.DoNotCall;
      this.doNotEmail=this.confirmData.DoNotEmail;
      this.doNotMail=this.confirmData.DoNotMail;
      this.agree = this.confirmData.License;
      
      this.preferredphone=this.confirmData.Preferredphone;
      if(this.confirmData.AdditionalInfo!=undefined)
      {
        if(this.confirmData.AdditionalInfo.DayTimePhone!= undefined)
        {
          this.communication = this.confirmData.AdditionalInfo.Communication;
          this.dayTimePhone = this.confirmData.AdditionalInfo.DayTimePhone.Phonenumber;
          this.alternatePhone = this.confirmData.AdditionalInfo.AltPhone.Phonenumber;
          this.existDayTimePhone = "DayTimePhone" in  this.confirmData.AdditionalInfo ? 1 : 0;
          this.existcommunication = "Communication" in  this.confirmData.AdditionalInfo ? 1 : 0;
        }
    }
    this.homeTypeLength=0;
    this.ownorrentenable=0;
    this.confirmphonenumber=0;
    this.confirmdaytimephone=0;
    this.confirmalternatephone=0;
    if(this.confirmData.AdditionalInfo!=undefined)
    {
      if(this.confirmData.AdditionalInfo.HomeType!=undefined)
      {
        this.homeType=this.confirmData.AdditionalInfo.HomeType.Values;
        this.ownOrRent=this.confirmData.AdditionalInfo.OwnOrRent.Values;
        this.homeTypeLength=this.homeType.length;
        this.ownOrRentLength=this.ownOrRent.length;
        
        this.hometype = history.state.data.AdditionalInfo.HomeType.SelectedValue;
        //enable owner/rent only for MF program
        if(this.hometype=='STC1CONDO')
        {
          this.ownorrentenable=1;
        }
        this.ownorrent = history.state.data.AdditionalInfo.OwnOrRent.SelectedValue;
        this.preferredphone1 = history.state.data.AdditionalInfo.Preferredphone.Phonenumber;
        this.preferredphone = this.preferredphone1.replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }
    }

    this.agree = this.confirmData.License;
  
    if(this.confirmData.AdditionalInfo!=undefined)
    {
      if(this.confirmData.AdditionalInfo.DayTimePhone != undefined)
      {
        this.dayTimePhone1 = this.confirmData.AdditionalInfo.DayTimePhone.Phonenumber;
        this.alternatePhone1 = this.confirmData.AdditionalInfo.AltPhone.Phonenumber;
        if(this.dayTimePhone1!=null)
        {
          this.dayTimePhone = this.dayTimePhone1.replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
        if(this.alternatePhone1!=null)
        {
          this.alternatePhone = this.alternatePhone1.replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        }
      }
    }
    this.smallScreen=screen.width < 600;
    if(this.confirmData.PrimaryPhone==null || this.confirmData.PrimaryPhone=='')
    {
      this.confirmData.PrimaryPhone=this.confirmData.AlternatePhone;
    }

    //To get enable help content
    this.enableHelpContent=this.utilityService.utilitySettings.enableHelpContent;   
    if(this.enableHelpContent!=null)  
    {
        this.isEnableHelpContent=true;

    }
    else
    {
        this.isEnableHelpContent=false;
    }

    
  }

     
  formatPhoneNumber(phoneNumberString) {
            
        // Trim the remaining input to ten characters, to preserve phone number format
        phoneNumberString = phoneNumberString.substring(0,10);

        // Based upon the length of the string, formatting the phone number
        var size = phoneNumberString.length;
        if(size == 0){
          phoneNumberString = phoneNumberString;
        }else if(size < 4){
          phoneNumberString = '('+phoneNumberString+') ';
        }else if(size < 7){
          phoneNumberString = '('+phoneNumberString.substring(0,3)+') '+phoneNumberString.substring(3,6);
        }else{
          phoneNumberString = '('+phoneNumberString.substring(0,3)+') '+phoneNumberString.substring(3,6)+' - '+phoneNumberString.substring(6,10);
        }
        return phoneNumberString; 

  }

  savedetails() {

    this.spinner.show();
    this.emailaddress=this.email;
    //this.homephone=this.preferredphone;
    this.officeid= localStorage.getItem('currentUtility');
    console.log('officeid:',this.officeid);
    this.PersoncompanyId= localStorage.getItem('personcompanyid');
    if(this.email=="")
    {

    this.enableSaveButton=false;

    }
    else
    {
      this.enableSaveButton=true;

    }

    if(this.preferredphone!=null)
    {
      this.preferredphone = this.preferredphone.replace(/[^0-9 ]/g, '');
      this.preferredphone=this.preferredphone.replace(/\s/g, '');
    }

   
    if(this.dayTimePhone!=null)
    {
      this.dayTimePhone = this.dayTimePhone.replace(/[^0-9 ]/g, '');
      this.dayTimePhone=this.dayTimePhone.replace(/\s/g, '');
    }
  
    if(this.alternatePhone!=null)
    {
      this.alternatePhone = this.alternatePhone.replace(/[^0-9 ]/g, '');
      this.alternatePhone=this.alternatePhone.replace(/\s/g, '');
    }

    let bodyContent = {
      Email: this.email,
      PrimaryPhone:"",
      AlternatePhone:"",
      DoNotCall: this.doNotCall,
      DoNotEmail:this.doNotEmail,
      DoNotMail:this.doNotMail,
      License:this.agree,
      HomeType:this.hometype,
      Preferredphone:this.preferredphone,
      HomeOwnRent:this.ownorrent,
      Communication:this.communication,
      DayTimePhone:this.dayTimePhone,
      AltPhone:this.alternatePhone,
    };

    Sentry.setUser({ email: this.email });

    this.utilityService.saveEmailAddress(this.officeid,this.PersoncompanyId,bodyContent).subscribe((res:any) => {
    
     if(res.Action==false)
      {
        this.spinner.hide();
        //console.log("responsestatus1",res.status);
        this.router.navigate(['/programtypes'], {state: {data: this.confirmData }});
      }
      else {
        Swal.fire({
                   
          icon: 'info',
          html:'<div style="text-align:left"> '+this.utilityService.utilitySettings.utilMessage1+'</div>',
          width:'800px',
          confirmButtonText: 'OK',
        })
      }
       
    })
   
     
}

validatePhoneNo(field) {
  var phoneNumDigits = field.value.replace(/\D/g, '');

  this.isValidFlg = (phoneNumDigits.length==0 || phoneNumDigits.length == 10);

  var formattedNumber = phoneNumDigits;
  if (phoneNumDigits.length >= 6)
    formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
  else if (phoneNumDigits.length >= 3)
    formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);

  field.value = formattedNumber;
}

validateCPhoneNo(field) {
  var phoneNumDigits = field.value.replace(/\D/g, '');
  this.confirmphonenumber=1;

  this.isValidFlg1 = (phoneNumDigits.length==0 || phoneNumDigits.length == 10);

  var formattedNumber = phoneNumDigits;
  if (phoneNumDigits.length >= 6)
    formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
  else if (phoneNumDigits.length >= 3)
    formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);

  field.value = formattedNumber;
}

validatedaytimeCPhoneNo(field) {
  var phoneNumDigits = field.value.replace(/\D/g, '');
  this.confirmdaytimephone=1;
  
  this.isValidFlg2 = (phoneNumDigits.length==0 || phoneNumDigits.length == 10);

  var formattedNumber = phoneNumDigits;
  if (phoneNumDigits.length >= 6)
    formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
  else if (phoneNumDigits.length >= 3)
    formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);

  field.value = formattedNumber;
}

validatealternateCPhoneNo(field) {
  var phoneNumDigits = field.value.replace(/\D/g, '');
  
  this.confirmalternatephone=1;

  this.isValidFlg3 = (phoneNumDigits.length==0 || phoneNumDigits.length == 10);

  var formattedNumber = phoneNumDigits;
  if (phoneNumDigits.length >= 6)
    formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
  else if (phoneNumDigits.length >= 3)
    formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);

  field.value = formattedNumber;
}




}
