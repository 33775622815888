/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from 'src/app/services/utility.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-scheduler-dialog',
  templateUrl: './scheduler-dialog.component.html',
  styleUrls: ['./scheduler-dialog.component.scss']
})
export class SchedulerDialogComponent implements OnInit {

  reasons: any;
  hasSaved: any;
  reasonName: any;
  disableReasons: any = true;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
   private dialog: MatDialogRef<SchedulerDialogComponent>,
    private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.utilityService.getCancelScheduleReasons().subscribe(res => {
      this.reasons = res;
    })
  }

  changeType(e) {
    console.log('reasons evt', e);
    this.dialog.close({data: e.value});
  }
}
