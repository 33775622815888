/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-program-types',
  templateUrl: './program-types.component.html',
  styleUrls: ['./program-types.component.scss']
})
export class ProgramTypesComponent implements OnInit {

  constructor(private router: Router, public utilityService: UtilityService,
    public dataTransfer: DataTransferService, private spinner: NgxSpinnerService) { }

  programWorkList: any;
  confirmData: any = {};
  prgms: any;
  programName: any;
  programCount: boolean = true;
  eligibility: any;
  individualEligibility: any;
  programDetailId: any;
  qaEnrollment: any;
  subGroup: any;
  group: any;
  workOrderName: any;
  selfSchedule: any;
  displayedColumns4 = ['WorkorderType', 'AnnualIncentive', 'InstallationIncentive', 'EquipmentIncentive'];
  showAttributes: any;
  displayedColumns = ['WorkorderType', 'Eligibility'];
  isShowHeader: boolean;
  enableCount: number;
  smallScreen: any;
  rebateGroup: any;
  progmsCount: any;
  customMessage:any;

  ngOnInit(): void {
    this.dataTransfer.myMethod(true);
    this.programName = localStorage.getItem('ProgramName')
    this.confirmData = history.state.data
    this.getProgramWorkOrders()
    this.confirmData = history.state.data
    this.isShowHeader = this.utilityService.utilitySettings.showHeader;
    this.smallScreen = screen.width < 600;
  }

  startEnrollment(prog) {   

    if (prog.SubGroup2 === 'VRAUDIT') {
      this.router.navigate(['/audit'], { state: { data: this.confirmData, program: prog } });
    }

    //Redirect to Rebate functionality
    else if (prog.SubGroup === 'REBATE' && prog.SubGroup2==null) {
      this.spinner.show();
      if(prog.Workorder!=null && prog.Workorder!=undefined)
      {
        this.utilityService.getWorkorderDetails(prog.Workorder, 'webep', 'Admin').subscribe((res: any) => {
          this.spinner.hide();        
          if (res.Action) {
            Swal.fire(res.Message);
          }
          else {          
            // go to online app page
            this.router.navigate(['/onlineapp'], { state: { data: res } });
  
          }
        },
          err => {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          })      
      }
      else
      {        
      const data = {
        "PersoncompanyId": localStorage.getItem('personcompanyid'),
        "EnrollmentId": prog.QAEnrollment ? prog.QAEnrollment : null,
        "ProgramdetailId": prog.ProgramdetailId,
        "UserId": "webep",
        "OrgCode": "ALL",
        "UserRole": "Admin"
      }
        
      this.utilityService.createWorkorder(data, prog.Group, prog.SubGroup).subscribe((res: any) => {     
        this.spinner.hide();   
        if (res.Action) {
          Swal.fire(res.Message);
        }
        else {          
          // go to online app page
          this.router.navigate(['/onlineapp'], { state: { data: res } });

        }
      },
        err => {
          Swal.fire({
            title: 'Error',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })        
    }
  }

    else if(prog.Eligibility == 0)
    {
      // const newApi = prog.Eligibility == 1 && prog.IndividualEligibility == 1;
      
      const WorkorderId = prog.Workorder;
      const ProgramdetailId=prog.ProgramdetailId;
      const result = { WorkorderType: '', WorkorderId: WorkorderId, reasonCode: '' , ProgramdetailId:ProgramdetailId};
      this.router.navigate(['/scheduling'], { state: { data: result, program: prog } });
    }
    
    else if (prog.Eligibility == 1 && prog.IndividualEligibility == 1 && (prog.Workorder == null || prog.Workorder == undefined)) {
      const newApi = prog.Eligibility == 1 && prog.IndividualEligibility == 1;
      this.router.navigate(['/qualify'], { state: { data: this.confirmData, program: prog, callNewAPI: newApi } });
    }
    else if (prog.Eligibility == 1 && prog.IndividualEligibility == 1 && (prog.Workorder != null || prog.Workorder != undefined)) {
      
      const newApi = prog.Eligibility == 1 && prog.IndividualEligibility == 1;
      const WorkorderId = prog.Workorder;
      const ProgramdetailId=prog.ProgramdetailId;
      const result = { WorkorderType: '', WorkorderId: WorkorderId, reasonCode: '',ProgramdetailId:ProgramdetailId };
      this.router.navigate(['/scheduling'], { state: { data: result, program: prog, callNewAPI: newApi } });
    }
    else if (prog.Eligibility == 1 && prog.IndividualEligibility == 0) {
      const newApi = prog.Eligibility == 1 && prog.IndividualEligibility == 0;
      this.router.navigate(['/schedule'], { state: { data: this.confirmData, program: prog, callNewAPI1: newApi } });
    } else if (prog.Eligibility == 0 && prog.IndividualEligibility == 0) {
      // call create work orderAPI
      if (!prog.QAEnrollment && prog.SubGroup === "QA") {
        Swal.fire("No install workorder exists for creating QA workorder");
      } else {
        const data = {
          "PersoncompanyId": localStorage.getItem('personcompanyid'),
          "EnrollmentId": prog.QAEnrollment ? prog.QAEnrollment : null,// localStorage.getItem('personcompanyid'), // to-do: will get it from BE 
          "ProgramdetailId": prog.ProgramdetailId,
          "UserId": "SE Adm_1R",
          "OrgCode": "ALL",
          "UserRole": "Admin"
        }
        this.utilityService.createWorkorder(data, prog.Group, prog.SubGroup).subscribe((res: any) => {
          if (res.Action) {
            Swal.fire(res.Message);
          }
          else {
            // go to save work order page
            this.router.navigate(['/scheduling'], { state: { data: { WorkorderId: res.WorkorderId, WorkorderType: res.ProgramDetailName } } });

          }
        },
          err => {
            Swal.fire({
              title: 'Error',
              icon: 'error',
              confirmButtonText: 'OK',
            })
          })
      }
    }
  }

  getProgramWorkOrders() {
    //console.log('work list', ProgramId);
    this.spinner.show();
    const programID = localStorage.getItem('ProgramId')
    this.utilityService.getProgramWorkOrdeList().subscribe(res => {
      this.spinner.hide();
      console.log('program list', res)
      this.prgms = res.WorkorderTypes || []
      console.log('program count', this.prgms.length);
      this.progmsCount = this.prgms.length;

      if (this.progmsCount == 0 && !this.smallScreen ) {
        Swal.fire({
          text: " You are already enrolled into the program(s) ",
          confirmButtonText: 'Back',
          icon: 'warning',
          width: '300px',
          position: 'center'
        }).then(() => {
          this.router.navigate(['/customerlookup']);
        })
      }

      if (this.progmsCount == 0  && this.smallScreen) {
        Swal.fire({
          text: " You are already enrolled into the program(s) ",
          confirmButtonText: 'Back',
          icon: 'warning',
          width: '100px',
          position: 'center'
        }).then(() => {
          this.router.navigate(['/customerlookup']);
        })
      }


      this.enableCount = 0;
      this.showAttributes = this.prgms[0].ShowAttributes;
      this.rebateGroup = this.prgms[0].Group;
      this.customMessage = this.prgms[0].CustomMsg;
      console.log('showattributes', this.showAttributes);
      console.log('rebategroup:', this.rebateGroup);
      localStorage.setItem('rebategroup', this.rebateGroup);

      //if no enrollments or already enrolled for santee cooper

      var progIndex = 0;

      this.prgms.forEach((element, index) => {
        if (this.prgms[index].Enable) {
          this.enableCount++;
          progIndex = index;
        }
      });

      console.log('enablingcount:', this.enableCount);

      if (this.enableCount === 0) {
        if(this.customMessage!=null)
        {
          Swal.fire({
            text: this.customMessage,
            confirmButtonText: 'Check Enrollment(s) Status',
            width: '450px',
            position: 'center'
          }).then(() => {
            this.router.navigate(['/enrollments']);
          })
        }        
        else if (this.isShowHeader == false && !this.smallScreen) {
          Swal.fire({
            text: " You are already enrolled into the program(s) ",
            confirmButtonText: 'Back',
            icon: 'warning',
            width: '300px',
            position: 'center'
          }).then(() => {
            this.router.navigate(['/customerlookup']);
          })
        }

        else if (this.isShowHeader == false && this.smallScreen) {
          Swal.fire({
            text: " You are already enrolled into the program(s) ",
            confirmButtonText: 'Back',
            icon: 'warning',
            width: '100px',
            position: 'center'
          }).then(() => {
            this.router.navigate(['/customerlookup']);
          })
        }


        else if(!this.smallScreen){
          Swal.fire({
            text: " You are already enrolled into the program(s) ",
            confirmButtonText: 'Check Enrollment(s) Status',
            width: '450px',
            position: 'center'
          }).then(() => {
            this.router.navigate(['/enrollments']);
          })
        }

        else if(this.smallScreen){
          Swal.fire({
            text: " You are already enrolled into the program(s) ",
            confirmButtonText: 'Check Enrollment(s) Status',
            width: '450px',
            position: 'center'
          }).then(() => {
            this.router.navigate(['/enrollments']);
          })
        }

      }
      else if (this.enableCount === 1) {
        this.startEnrollment(this.prgms[progIndex]);
      }
    });
  }

  

}
