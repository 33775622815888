/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class BucketService {

  dayStatus = [0, 0, 0, 0, 0];  // 0 = loading, 1 = no data, 2 = has slots

  constructor(public utilityService:UtilityService, public http:HttpClient) { }

  public getHeaders() {
   // const email = localStorage.getItem('loggedInUserEmail');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-email': "SEHonAdmin@honeywell.com",
        'x-programsponsorid':  this.utilityService.currentUtility//this.utilityService.currentUtility.ProgramsponsorId
      })
    };
    return httpOptions;

    //programsponsorid; personcompanyid;email;
  }

  getSlotsForDay(date,selectedWO): Observable<any> {
   
  const searchUrl = environment.apiUrl + "workorder/selfschedule/gettimeslots/" +  this.utilityService.currentUtility + "/workorder/" +selectedWO + "?code=" + environment.apiCode;

  const body=  {
      "scheduledate":date
  }
    return this.http.post<any[]>(searchUrl, body, this.getHeaders())
      .pipe(map(response => response),
        catchError(err => [])//this.utilityService.handleError(err, this, []))
      );

    

   // return of(this.slotlist.AvailableSlotsResources);
  }

  selfScheduleAppointment(body,workorderId,timeSlotId, reasonCode){
    const searchUrl = environment.apiUrl + "workorder/selfschedule/createappt/" +  this.utilityService.currentUtility + "/workorder/" +workorderId +"/timeslot/"+timeSlotId + "?code=" + environment.apiCode;

    
      return this.http.post<any[]>(searchUrl, body, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => [])//this.utilityService.handleError(err, this, []))
        );
  }

  getAllAppointments(personcompanyId){
    const searchUrl = environment.apiUrl + "selfschedule/assignmentData/" +  this.utilityService.currentUtility + "/"+ personcompanyId +"/" + "?code=" + environment.apiCode;

    
      return this.http.get<any[]>(searchUrl, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => [])//this.utilityService.handleError(err, this, []))
        );
  }

  //get inactive dates

  getInactiveDates(officeId,workOrderId,programDetailId): Observable<any> {
   
    const searchUrl = environment.apiUrl + "workorder/selfschedule/GetInactiveDates" + "?code=" + environment.apiCode;
  
    const body=  {
        "officeid" : officeId,
        "workorderid" : workOrderId,
        "programdetailid" : programDetailId,
        "organizationcode" : "ALL"
    }
      return this.http.post<any[]>(searchUrl, body, this.getHeaders())
        .pipe(map(response => response),
          catchError(err => [])//this.utilityService.handleError(err, this, []))
        );
  
      
  
     // return of(this.slotlist.AvailableSlotsResources);
    }


}
