/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Directive, Input, ViewContainerRef } from '@angular/core';
import { ComponentTypes } from '../classes/component-types';



@Directive({
  selector: '[appMyDynamic]'
})
export class MyDynamicDirective {

  @Input() appDynamicComponent: any;
 
  @Input() data: any;
  constructor(private componentTypes:ComponentTypes, private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    
    
    const comp=this.componentTypes.toType(this.appDynamicComponent);
    
    // create and attach the component
    const componentRef = this.viewContainerRef.createComponent(comp);
    const dynamicComponent = componentRef.instance as { data: any };
    dynamicComponent.data=this.data;

    
  }

}
