/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTransferService } from 'src/app/services/data-transfer.service';

import { UtilityService } from '../../services/utility.service';

@Component({
  selector: 'app-enroll-success',
  templateUrl: './enroll-success.component.html',
  styleUrls: ['./enroll-success.component.scss']
})
export class EnrollSuccessComponent implements OnInit {

    constructor(private router: Router, public utilityService: UtilityService, public dataTransfer:DataTransferService, private spinner:NgxSpinnerService) { }
    isShowButton: boolean=false;
    isShowReportButton: boolean=false;
    rebateGroup:any;
    confirmData:any={}
    ngOnInit(): void {
      this.dataTransfer.myMethod(true);
      this.confirmData = history.state.data;
      this.spinner.hide();
      this.rebateGroup = localStorage.getItem('rebategroup');
                
      //To hide/un-hide based on Self-Schedule
      if(this.utilityService.utilitySettings.SelfScheduleEnablement=="1")
      {
        this.isShowButton=false;
      }
      else
      {
        this.isShowButton=true;
      }

     
        //To hide/un-hide based on report enablement
      if(this.utilityService.utilitySettings.ReportEnablement=="1")
      {
        this.isShowReportButton=false;
      }
     else
      {
        this.isShowReportButton=true;
      }

  }
      
    confirmed() {
      this.router.navigate(['/scheduling'],{state:{data:this.confirmData}});
    }
    navigate()
    {
      if(this.rebateGroup=="REBATE")
      {
        this.router.navigate(['/customerlookup'],{state:{data:this.confirmData}});
      }
      else
      {
        this.router.navigate(['/enrollments'],{state:{data:this.confirmData}});
      }

    }

    navigatetoReport()
    {
      console.log("navigatetoReport");
      this.router.navigate(['/auditreport'], {state:{data:this.confirmData}});
    }
  }
  
