<app-enrollment-header></app-enrollment-header>

<mat-card class="custom-card font-style" style="width: 100%; margin-top: 1%;"  >
    <mat-card-content class="custom-card">
      
        <mat-tab-group  #tabGroup  mat-align-tabs="center" [selectedIndex]="selectedTabIndex" (focusChange)="onTabClick($event)"  >     
            <mat-tab  matTooltipPosition="above" *ngFor="let tabs of TabModules; let i = index" [disabled]="shouldDisableTab(i)" >      <ng-template  mat-tab-label class="mat-tab-header">
                    <div class="box font-style" id="myDiv">
                        <mat-icon  [style.visibility]="getIconVisibility(i, tabs.Modulepath)">check</mat-icon>
                      {{ tabs.Moduleinfo }}    
                        </div>
                  </ng-template>
               
                 
                  <div appMyDynamic [appDynamicComponent]=tabs.Modulepath [data]=myData></div>
 
 
                 
                  <!--
                  <ng-container [ngSwitch]="tabs.Modulepath">                  
                    <app-payee  *ngSwitchCase="'PayeeComponent'"></app-payee>         
                    <app-measure *ngSwitchCase="'MeasureComponent'"></app-measure>
                    <app-other *ngSwitchCase="'OtherComponent'"></app-other>
                    <app-receipts *ngSwitchCase="'ReceiptsComponent'"></app-receipts>
                    <app-submit *ngSwitchCase="'SubmitComponent'"></app-submit>
                    <span *ngSwitchDefault>Module not found!</span>
                </ng-container>

                -->
            </mat-tab>
            </mat-tab-group>
   
    </mat-card-content>
</mat-card>

<app-enrollment-footer></app-enrollment-footer>