<!--This is for BSW-->
<div class="font-style"> 
<mat-dialog-content>
    <br>
    <br>
    <h2 *ngIf="errorMesg != ''" style="color:red;text-align: center;">{{errorMesg}}</h2>
    <div class="upload-drop-zone" id="droptarget" (drop)="handleDrop($event)" (dragover)="allowDrop($event)"
      (dragenter)="handleDragEnter()" (dragleave)="handleDragLeave()">
      <div style="font-size:16px;text-align: center;">
      <mat-item>
        <mat-icon class="maticon">cloud_upload</mat-icon>
    </mat-item>
      <div style="font-size:14px;text-align: center;">
            Drag and drop files here 
      </div>

      <div style="font-size:14px;text-align: center;">or</div>
           
      <div class="hyperlink">
        <button class="hyperlink">
          <label for="choose-file" id="choose-file-label">
          <a>
          <mat-icon class="maticon">attach_file</mat-icon><span class="hyperlink"><u>Browse for files</u> </span>
          </a>
        </label>
         </button> 
          <input name="uploadDocument" type="file" id="choose-file" multiple [disabled]="uploading"
             accept=".jpg,.pdf,.doc,.docx,.png,.gif|image/*" style="display: none;" (change)="handleFile($event)"/>
      </div>
      </div>
      <br>
    </div>
    
      <h6 style="text-align: center;font-size:13px;"><b>Note:</b> File upload supports .jpg, .png, .gif, .doc, .docx & .pdf extension formats and file size to be <2MB</h6>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
    
      
      <div *ngIf="isShowViewFileCount==1" style="font-size:16px;text-align: center;">
       
        <a (click)="viewFiles()">
          <mat-icon class="maticon">visibility</mat-icon><span class="hyperlink"><u>View uploaded files</u> </span>
          
        </a>
      
      </div>
 

    <div  style="text-align:center" *ngIf="files.length > 0">
     <br>
     
      <h5>Files Ready for Uploading:</h5>
     
      <mat-list *ngFor="let file of files; let i = index">
       <mat-item>
            <mat-icon class="cancelicon" matTooltip="Cancel" (click)="removeFile(i)">cancel</mat-icon>{{file.name}}
       </mat-item>
        
      </mat-list>   
   
    </div>
    <br>
    
      <div  style="text-align:center">
          <button mat-raised-button class="mainbutton" *ngIf="files.length > 0" [disabled]="uploading"
          (click)="removeAll()">CANCEL</button> &nbsp;&nbsp;
        <button mat-raised-button class="mainbutton font-style" [disabled]="!enable" style="text-align:center;" (click)="callUpload()">UPLOAD</button>
      </div>
    
       
  
</mat-dialog-content>


</div>