/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilityService } from '../../services/utility.service';
//import * as _ from 'lodash';
@Component({
  selector: 'app-enrollcustomer',
  templateUrl: './enrollcustomer.component.html',
  styleUrls: ['./enrollcustomer.component.scss']
})
export class EnrollcustomerComponent implements OnInit {
  selProgramName:any;
  confirmData:any;
  agree:any;
  selectedValue:any;
  mySelect:any;
  email:any;
  createDisable:any;
  fname:any;
  lname:any;
  anumber:any;
  aname:any;
  pphonenumber:any;
  customerType:any;
  snumber:any;
  sname:any;
  cname:any;
  companyname:any;
  statename:any;
  zipcode:any;
  msnumber:any;
  msname:any;
  mcname:any;
  mstatename:any;
  mzipcode:any;
  manumber:any;
  maname:any;
  aphonenumber:any;
  officeid:any;
  smallScreen:any;
  public isValidFlg:boolean = true;
  public isValidFlg1:boolean = true;
  data=[{id:1,name:'Residential'},{id:2,name:'Multifamily'},{id:3,name:'Non-Residential'}];
  constructor(public utilityService: UtilityService,public router:Router,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.agree=false;
    this.selProgramName = history.state.data;
    this.confirmData = history.state.data;
    this.createDisable = false;
    this.officeid= localStorage.getItem('currentUtility');
    this.smallScreen=screen.width < 600;
  }
  

  /*getDropDownText(id,object)
  {
    const selObj=_.filter(object,function(o){
      return(_.includes(id,o.id));
    });
    return selObj;
  }
  selectChange()
  {
    this.selectedValue=this.getDropDownText(this.mySelect,this.data)[0].name;
    console.log('selectedvalue:',this.selectedValue);
  }*/
  
  routeToPrograms()
    {
      this.spinner.show();
      if(this.agree)
      {
      console.log('test:',this.agree);  
      this.msnumber=this.snumber;
      this.msname=this.sname;
      this.mcname=this.cname;
      this.maname=this.aname;
      this.mstatename=this.statename;
      this.mzipcode=this.zipcode;
      }

      let bodyContent = {
        Firstname: this.fname,
        LastName: this.lname,
        CompanyName:this.companyname,
        AccountNumber: this.anumber,
        ServiceStreetnumber:this.snumber,
        ServiceStreetName:this.sname,
        ServiceAppartment:this.aname,
        ServiceCity:this.cname,
        ServiceState:this.statename,
        ServiceZipCode:this.zipcode,
        MailStreetNumber:this.msnumber,
        MailStreetName:this.msname,
        MailAppartment:this.maname,
        MailCity:this.mcname,
        MailState:this.mstatename,
        MailZip:this.mzipcode,
        PrimaryPhone:this.pphonenumber,
        AlternatePhone:this.aphonenumber,
        Email:this.email,
        CustomerType:this.customerType       
      };

      this.utilityService.enrollCustomer(this.officeid,bodyContent).subscribe((res : any) => {
        
        this.spinner.hide();
        if(res.Action == 1)
            {
              Swal.fire({
                   
                icon: 'info',
                html:'<div style="text-align:center"> '+res.Message+'</div>',
                width:'800px',
                confirmButtonText: 'OK',
              })

              .then((result) => {
                if (result.value) {
                   this.spinner.show();
                   this.router.navigate(['/enrollcustomer'], {state: {data: this.confirmData }});  
                   this.spinner.hide();
                }
            })

            }
           else if(res.Action==0)
            {
              console.log('message:',res.Message);
              Swal.fire({
                   
                icon: 'success',
                
                html:'<div style="text-align:center"> '+res.Message+'</div>',
                width:'800px',
                confirmButtonText: 'OK',
              })

              .then((result) => {
                if (result.value) {
                   this.spinner.show();
                   localStorage.setItem('personcompanyid',res.Personcompany_id);
                   console.log('pid:',res.Personcompany_id);
                   this.router.navigate(['/programtypes'], {state: {data: this.confirmData }});  
                   this.spinner.hide();
                }
            })

            }
            else 
            {
              this.spinner.show();
              this.router.navigate(['/customerlookup'], {state: {data: this.confirmData }});  
              this.spinner.hide();
            }
        
      })
      


      //Hardcoded for JCPL
     // localStorage.setItem('personcompanyid','D061449813')
      //this.router.navigate(['/programtypes']);
    }

    validatePhoneNo(field) {
      var phoneNumDigits = field.value.replace(/\D/g, '');
    
      this.isValidFlg = (phoneNumDigits.length==0 || phoneNumDigits.length == 10);
    
      var formattedNumber = phoneNumDigits;
      if (phoneNumDigits.length >= 6)
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
      else if (phoneNumDigits.length >= 3)
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    
      field.value = formattedNumber;
    }

    validateAPhoneNo(field) {
      var phoneNumDigits = field.value.replace(/\D/g, '');
    
      this.isValidFlg1 = (phoneNumDigits.length==0 || phoneNumDigits.length == 10);
    
      var formattedNumber = phoneNumDigits;
      if (phoneNumDigits.length >= 6)
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6);
      else if (phoneNumDigits.length >= 3)
        formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    
      field.value = formattedNumber;
    }


}
