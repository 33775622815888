/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { EnrollService } from 'src/app/services/enroll.service';
import { UploadDocumentsComponent } from 'src/app/upload-documents/upload-documents.component';
import { EnrollComponent } from 'src/app/util/enroll/enroll.component';
import Swal from 'sweetalert2';
import { UtilityService } from '../../services/utility.service';
import { DatePipe, JsonPipe } from '@angular/common';
import { AlertDialogComponent } from 'src/app/util/alert-dialog/alert-dialog.component';
import { ValueState } from 'src/app/models/value-state';
import { EnrollmentSuccessComponent } from '../enrollment-success/enrollment-success.component';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {

  constructor(private datePipe: DatePipe, private router: Router, public utilityService: UtilityService,
    public enrollService: EnrollService, public dialog: MatDialog,
    public dataTransfer: DataTransferService, private spinner: NgxSpinnerService) { }

  personcompanyid: any;
  confirmData: any = {};
  ind = -1;
  indCurrentValue: any = '';
  count: {
    index: Number,
    count: Number
  };
  countArray: any = [];
  workOrdername: any;
  questions: any;

  answered = 0;
  totalQuestions = 0;
  tabAnswers = {};
  readyToVerify = false;
  requiredQuesCount = 0;
  answeredQues = {};
  answeredQuesCount: any = 0;
  buttonName = "Enroll";
  programDetailId: any;
  showResponsePopup = false;
  downLoadLink: any;
  downLoadText: any;
  uploadText = [];
  enable: boolean;
  uploadUnique = [];
  agreeTerms: any;
  termsAndConditions: any;
  otherTermsAndConditions: any;
  landLordConsent: any;
  landLordConsentMsg: any;
  uploadTextOwner: any;
  uploadTextHC: any;
  uploadTextWH: any;
  downloadEnable: boolean;
  uploadTextReceipt: any;
  dialogWidth = '800px';
  rebateGroup: any;
  loading = false;
  countResponse = 0;
  savedChargerList = [];
  smallScreen: any;
  savingIndividual = false;
  landLordConsentCheck: any;
  landlordconsent: any;

  selectedTabIndex: number = 0;

  ngOnInit(): void {

    if (screen.width < 850) {
      this.dialogWidth = '300px';
    }

    this.landLordConsentCheck = 0;
    this.smallScreen = screen.width < 600;
    this.dataTransfer.myMethod(true);
    this.confirmData = history.state.data
    this.agreeTerms = false;
    this.downloadEnable = false;
    this.landlordconsent = false;
    this.rebateGroup = localStorage.getItem('rebategroup');
    this.buttonName = "Next";

    this.personcompanyid = localStorage.getItem('personcompanyid') || this.confirmData.PersonCompanyId;
    this.workOrdername = history.state.program.Shortname
    if (history.state.program.ProgramdetailId == undefined) {

      this.programDetailId = history.state.program
      this.workOrdername = history.state.shortName;
    }
    else {
      this.programDetailId = history.state.program.ProgramdetailId
    }

    this.loadQuestions(this.personcompanyid, this.programDetailId);

    localStorage.setItem('uploadButtonEnablement', "0");


  }

  onAnswering(tabindex, qindex, currentValue) {
    var index = this.questions.Tabs[tabindex].Questions[qindex].QUESTION_ID;
    var originalVal = this.answeredQues[index].answered;

    if (this.questions.Tabs[tabindex].Questions[qindex].QUESTION_TYPE === 'CUST_CHRGS' || this.questions.Tabs[tabindex].Questions[qindex].QUESTION_TYPE === 'CHRGS_RES') {
      if (currentValue[0]["RESPONSE_ID"] === null) {
        this.answeredQues[index].answered = false;
      } else {
        this.answeredQues[index].answered = true;
      }
    }
    else {
      if (this.questions.Tabs[tabindex].Questions[qindex].QUESTION_TYPE === 'TEXT') {
        if (currentValue.trim() != '') {
          this.answeredQues[index].answered = true;
        }
        else {
          this.answeredQues[index].answered = false;
        }
      }
      else {
        if (currentValue !== '') {
          this.answeredQues[index].answered = true;
        }
        else {
          this.answeredQues[index].answered = false;
        }
      }
    }

    // update individual tab progress bar
    if (this.answeredQues[index].answered != originalVal) {
      var tIndex = this.questions.Tabs[tabindex].TabName;

      if (this.answeredQues[index].answered) {
        this.tabAnswers[tIndex][0]++;
        if (this.answeredQues[index].required) {
          this.tabAnswers[tIndex][3]++;
        }
      } else {
        this.tabAnswers[tIndex][0]--;
        if (this.answeredQues[index].required) {
          this.tabAnswers[tIndex][3]--;
        }
      }

      var percent = (this.tabAnswers[tIndex][0] / this.tabAnswers[tIndex][1]) * 100;
      this.tabAnswers[tIndex][2] = percent.toFixed();
    }

    this.updateCounts();

    if (this.showResponsePopup) {

      // need to manually qualify
      var currentResponse;
      this.questions.Tabs[tabindex].Questions[qindex].QUESTION_RESPONSES.forEach(q => {
        if (q.RESPONSE_ID === currentValue) {
          currentResponse = q;
        }
      });

      if (this.rebateGroup != "REBATE") {
        //to download the consent form
        if ((currentResponse.RESPONSE_TEXT === "No") && (currentResponse.RESPONSE_DOWNLOAD_LINK != undefined)) {

          this.downLoadLink = currentResponse.RESPONSE_DOWNLOAD_LINK;
          this.downLoadText = currentResponse.RESPONSE_LABEL_TEXT;
          this.enable = true;

        }

        if (currentResponse.RESPONSE_TEXT === "Yes") {
          this.enable = false;
          this.downLoadLink = "";
          this.downLoadText = "";
        }

        if (currentResponse != undefined && currentResponse.RESPONSE_REASON != undefined) {
          Swal.fire({
            html: '<div style="text-align:left"> ' + currentResponse.RESPONSE_REASON + ' </div>',
            confirmButtonText: 'OK',
            width: this.dialogWidth,
            icon: 'error',
          }).then(() => {
            this.router.navigate(['/programtypes']);
          });
        }
      }
    }

    if (this.countArray != undefined) {
      if (this.countArray.length > 0) {
        this.ind = this.countArray.findIndex(i => i.index == index)
        this.indCurrentValue = this.countArray.find(function (el) {
          return el.index === index;
        }) || {};
      }

      if (this.questions.Tabs[tabindex].Questions[qindex].QUESTION_TYPE === 'CUST_CHRGS' || this.questions.Tabs[tabindex].Questions[qindex].QUESTION_TYPE === 'CHRGS_RES') {
        this.countArray.forEach((element) => {
          if (element.index === index) {
            if (currentValue[0]["RESPONSE_ID"] === null && this.indCurrentValue.currentValue !== '') {
              this.answeredQues[index].answered = false;
              this.answeredQues[index].currentValue = '';
              element.currentValue = '';
            } else if (currentValue[0]["RESPONSE_ID"] !== null && this.indCurrentValue.currentValue === '') {
              element.currentValue = currentValue;
              this.answeredQues[index].answered = true;
              this.answeredQues[index].currentValue = 'currentValue';
            }
          }
        });
      } else if (this.ind >= 0 && (currentValue != "")) {
        this.countArray.forEach((element) => {
          if (element.index === index) {
            element.currentValue = currentValue;
          }
        });
      } else if (currentValue == "" || currentValue == undefined) {
        this.countArray.forEach((element) => {
          if (element.index === index) {
            element.currentValue = currentValue;
          }
        });
      }

    }

    //To save questions
    let elList: any;

    if (this.questions.Tabs[tabindex].Questions[qindex].QUESTION_TYPE === 'CUST_CHRGS' || this.questions.Tabs[tabindex].Questions[qindex].QUESTION_TYPE === 'CHRGS_RES') {

      this.savedChargerList = currentValue;
      elList = currentValue;
      this.savingIndividual = true;
    } else {
      elList = this.buildEligilityListForEachQuestion(tabindex, qindex);
    }

    this.rebateGroup = localStorage.getItem('rebategroup');

    this.enrollService.saveIndividualQuestionsByPersonCompanyId(this.personcompanyid, elList, this.programDetailId, this.rebateGroup)
      .subscribe(() => {
        this.savingIndividual = false;
      }, err => {
        this.savingIndividual = false;
        console.log('Error: ' + JSON.stringify(err, null, 2));

      });
    //End of save questions on each and every question

    //Handle this to enable Next button after save
    //this.loadQuestions(this.personcompanyid, this.programDetailId);
    //End of save questions on each and every question
    //End of save questions on each and every question

  }

  private buildEligilityListForEachQuestion(tabIndex, qIndex): any[] {

    const elList = [];

    let respId;
    let respText;
    let eligibility_Id;


    if (this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_TYPE === 'LIST') {
      respId = this.questions.Tabs[tabIndex].Questions[qIndex].currentValue;

      const index = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES.findIndex(
        val => val.RESPONSE_ID === this.questions.Tabs[tabIndex].Questions[qIndex].currentValue);
      const eligibilityIndex = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
      if (index === -1) {
        respText = '';
      } else {
        respText = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES[index].RESPONSE_TEXT;

      }
      if (eligibilityIndex === -1) {
        eligibility_Id = this.questions.Tabs[tabIndex].Questions[qIndex].ELIGIBILITY_ID;
      }
      else {
        eligibility_Id = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
      }
    }
    else if (this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_TYPE === 'DATE') {

      respId = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES[0].RESPONSE_ID;
      respText = this.questions.Tabs[tabIndex].Questions[qIndex].currentValue;
      if (respText != '') {
        respText = moment(respText).format("MM/DD/YYYY");
      }
      eligibility_Id = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES[0].ELIGIBILITY_ID;

    }
    else if (this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_TYPE === 'TEXT') {

      respId = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES[0].RESPONSE_ID;
      respText = this.questions.Tabs[tabIndex].Questions[qIndex].currentValue.trim();
      eligibility_Id = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES[0].ELIGIBILITY_ID;

    }
    else if (this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_TYPE === 'CUST_CHRGS') { }
    else if (this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_TYPE === 'CHRGS_RES') { }
    else {
      respId = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES[0].RESPONSE_ID;
      respText = this.questions.Tabs[tabIndex].Questions[qIndex].currentValue;
      eligibility_Id = this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_RESPONSES[0].ELIGIBILITY_ID;
    }

    const el = {
      QUESTION_ID: this.questions.Tabs[tabIndex].Questions[qIndex].QUESTION_ID,
      RESPONSE_ID: respId,
      RESPONSE_TEXT: respText,
      ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
    };

    elList.push(el);
    return elList;
  }

  enhanceQuestions(res, percompanyid) {
    this.spinner.hide();
    this.questions = res;

    this.questions.Tabs.forEach((tab, jdex) => {
      this.tabAnswers[tab.TabName] = [0, 0, 0, 0, 0];   // # answered, # total questions, % complete, # required answered, total required

      tab.Questions.forEach((ques, index) => {
        this.totalQuestions++;
        this.tabAnswers[tab.TabName][1]++;

        var vs = new ValueState();
        vs.refId = ques.QUESTION_ID;
        vs.tabName = tab.TabName;

        if (ques.QUESTION_REQUIRED) {
          this.requiredQuesCount++;
          this.tabAnswers[tab.TabName][4]++;
          vs.required = true;
        }

        if (ques.QUESTION_TYPE === 'TEXT') {
          if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
            && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined && ques.QUESTION_RESPONSES[0].RESPONSE_TEXT !== undefined) {
            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
            vs.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;

            if (ques.currentValue != undefined) {
              vs.answered = true;
            }

          } else {
            ques.currentValue = '';
          }
        }

        //Added for Date functionality
        if (ques.QUESTION_TYPE === 'DATE') {
          if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
            && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {


            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
            vs.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;

            ques.currentValue = this.datePipe.transform(ques.currentValue, 'yyyy-MM-dd');

            if (ques.currentValue != undefined) {
              vs.answered = true;
            }
          }
        }

        if (ques.QUESTION_TYPE === 'LABEL') {
          if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
            && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
            vs.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;

            if (ques.currentValue != undefined) {
              vs.answered = true;
            }

          } else {
            ques.currentValue = '';
          }
        }

        //To handle custom type of chargers
        if (ques.QUESTION_TYPE === 'CUST_CHRGS' || ques.QUESTION_TYPE === 'CHRGS_RES') {
          if (ques.QUESTIONS !== undefined) {
            if (ques.QUESTIONS.QUESTIONS_ARRAY[0]['ELIGIBILITY_ID'] !== null) {
              ques.currentValue = ques.QUESTIONS.QUESTIONS_ARRAY;
              vs.currentValue = ques.QUESTIONS.QUESTIONS_ARRAY;

              if (ques.currentValue != undefined) {
                vs.answered = true;
              }

            } else {
              ques.currentValue = '';
            }
          }

        }
        //End 

        if (ques.QUESTION_TYPE === 'NUMBER' || ques.QUESTION_TYPE === 'VALUE') {
          if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
            && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
            vs.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;

            if (ques.currentValue != undefined) {
              vs.answered = true;
            }

          } else {
            ques.currentValue = '';
          }
        }

        if (ques.QUESTION_TYPE === 'DECIMAL') {
          if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
            && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
            ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;

            if (ques.currentValue != undefined) {
              vs.answered = true;
            }

          } else {
            ques.currentValue = '';
          }
        }

        if (ques.QUESTION_TYPE === 'LIST') {
          ques.currentValue = '';

          //To download consent form text based on question

          if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0) {
            if (ques.QUESTION_ID === "STC1P00002") {
              ques.QUESTION_RESPONSES.forEach(resp => {

                if (resp.ELIGIBILITY_ID === undefined) {
                  this.downloadEnable = false;
                }
                else {
                  ((resp.RESPONSE_TEXT === "No") && (resp.RESPONSE_UPLOAD === "1"))
                  this.enable = true;
                  this.downLoadLink = resp.RESPONSE_DOWNLOAD_LINK;
                  this.downLoadText = resp.RESPONSE_LABEL_TEXT;
                }

              });
            }

          }
          //To download consent form text based on question             

          if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0) {
            ques.QUESTION_RESPONSES.forEach(resp => {

              if (resp.ELIGIBILITY_ID !== undefined) {
                ques.currentValue = resp.RESPONSE_ID;

                if (ques.currentValue != undefined) {
                  vs.answered = true;
                  vs.currentValue = ques.currentValue;
                }

              }
            });
          }
        }

        if (vs.answered) {
          this.tabAnswers[tab.TabName][0]++;
          if (vs.required) {
            this.tabAnswers[tab.TabName][3]++;
          }
        }
        this.answeredQues[ques.QUESTION_ID] = vs;
        this.countArray.push({ index: index, currentValue: ques.currentValue });
      });

      var percent = (this.tabAnswers[tab.TabName][0] / this.tabAnswers[tab.TabName][1]) * 100;
      this.tabAnswers[tab.TabName][2] = percent.toFixed();
    });

    this.updateCounts();
  }

  updateCounts() {
    this.answeredQuesCount = 0;
    this.answered = 0;
    var x = this.answeredQues;
    var y = this.answeredQuesCount;
    var z = this.answered;

    Object.keys(this.answeredQues).forEach(function (key) {
      var val = x[key];
      if (val != null && val.required && val.answered) {
        y++;
      }

      if (val != null && val.answered) {
        z++;
      }
    });

    this.answeredQuesCount = y;
    this.answered = z;
    // this is NOT ready
    this.readyToVerify = this.requiredQuesCount != this.answeredQuesCount || sessionStorage.getItem("verified") == "true";
  }

  tabProgressStatus(tabName) {
    if (this.tabAnswers[tabName][3] < this.tabAnswers[tabName][4]) {
      return "warn";
    } else {
      return "primary";
    }
  }

  loadQuestions(percompanyid, ProgramdetailId) {
    this.answered = 0;
    this.requiredQuesCount = 0;
    this.answeredQues = {};
    this.answeredQuesCount = 0;
    this.spinner.show();
    this.questions = [];

    this.enrollService.getIndividualQuestions(percompanyid, ProgramdetailId).subscribe(res => {
      this.showResponsePopup = res.ShowResponsePopup;
      this.termsAndConditions = res.TermsAndConditions;
      this.landLordConsent = res.LandLordConsent;
      this.landLordConsentMsg = res.LandLordConsentMsg;

      if (this.landLordConsent != undefined) {
        this.landLordConsentCheck = 1;
      }
      else {
        this.landLordConsentCheck = 0;
      }
      this.otherTermsAndConditions = res.OtherTermsAndConditions;

      this.enhanceQuestions(res, percompanyid)
    },
      err => {
        this.spinner.hide();

      })
  }

  private buildEligilityList(): any[] {

    const elList = [];

    this.questions.Tabs.forEach((tab, jdex) => {
      tab.Questions.forEach((ques, index) => {
        let respId;
        let respText;
        let eligibility_Id;


        if (ques.QUESTION_TYPE === 'LIST') {
          respId = ques.currentValue;

          const index = ques.QUESTION_RESPONSES.findIndex(val => val.RESPONSE_ID === ques.currentValue);
          const eligibilityIndex = ques.QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
          if (index === -1) {
            respText = '';
          } else {
            ques.QUESTION_RESPONSES[index].RESPONSE_TEXT;
            respText = ques.QUESTION_RESPONSES[index].RESPONSE_TEXT;

          }
          if (eligibilityIndex === -1) {
            eligibility_Id = ques.ELIGIBILITY_ID;
          }
          else {
            eligibility_Id = ques.QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
          }

          const el = {
            QUESTION_ID: ques.QUESTION_ID,
            RESPONSE_ID: respId,
            RESPONSE_TEXT: respText,
            ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
          };

          elList.push(el);
        }
        else if (ques.QUESTION_TYPE === 'CUST_CHRGS' || ques.QUESTION_TYPE === 'CHRGS_RES') {
          this.savedChargerList.forEach(q => elList.push(q));
        }

        //Added newly to handle the date -8/29/2022
        else if (ques.QUESTION_TYPE === 'DATE') {

          respId = ques.QUESTION_RESPONSES[0].RESPONSE_ID;
          respText = ques.currentValue;
          eligibility_Id = ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID;

          if (respText != '') {
            respText = moment(respText).format("MM/DD/YYYY");
          }
          const el = {
            QUESTION_ID: ques.QUESTION_ID,
            RESPONSE_ID: respId,
            RESPONSE_TEXT: respText,
            ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
          };

          elList.push(el);
        }
        //End

        else {
          respId = ques.QUESTION_RESPONSES[0].RESPONSE_ID;
          respText = ques.currentValue;
          eligibility_Id = ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID;

          const el = {
            QUESTION_ID: ques.QUESTION_ID,
            RESPONSE_ID: respId,
            RESPONSE_TEXT: respText,
            ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
          };

          elList.push(el);

        }
      });
    });

    return elList;
  }


  public getRecommendations() {
    let className = localStorage.getItem("themeName");
    const dialogRef = this.dialog.open(EnrollComponent,
      {
        data: {
          personcompId: this.personcompanyid,
          elList: this.buildEligilityList(),
          callNewAPI: true,    // this.newAPI,
          programDetailID: this.programDetailId,
        }, panelClass: className
      }
    );

    dialogRef.afterClosed().subscribe((res) => {

      this.loadQuestions(this.personcompanyid, this.programDetailId);
    });
  }

  uploadDocuments(uploadText) {
    this.personcompanyid = localStorage.getItem('personcompanyid');

    let elList: any;
    elList = this.buildEligilityList();

    //to filter duplicate values
    this.uploadUnique = this.uploadText.filter((n, i) => this.uploadText.indexOf(n) === i);

    this.spinner.hide();
    const dialogRef = this.dialog.open(UploadDocumentsComponent, {
      height: '70%', width: '60%',
      data: {
        uploadText1: this.uploadUnique,
        programdetailID: this.programDetailId,
        agreeTerms: this.agreeTerms,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.loading = false;
      this.spinner.hide();
    });
  }

  /* this method is for enroll directly and move to scheduling from Qualification page*/

  enroll() {

    if (sessionStorage.getItem("verified") == "true") {
      Swal.fire({
        title: 'Error',
        text: 'Audit has already been processed',
        icon: 'error',
        confirmButtonText: 'OK',
      });

      return;
    }


    this.spinner.show();
    this.loading = true;
    //JCP Upload start
    this.rebateGroup = localStorage.getItem('rebategroup');

    this.personcompanyid = localStorage.getItem('personcompanyid');

    let elList: any;
    elList = this.buildEligilityList();
    // console.log("DataList elList", elList);
    this.uploadText = [];

    //upload documents

    elList.forEach(list => {


      if ((list.QUESTION_ID === "STC1P00002" || list.QUESTION_ID === "STC1HVAC02" || list.QUESTION_ID === "STC1PCOM02") && (list.RESPONSE_TEXT === "No")) {

        this.uploadTextOwner = "Please upload your signed Landlord Consent Form.";
        this.uploadText.push(this.uploadTextOwner);

      }
      if ((list.QUESTION_ID === "STC1P00004" || list.QUESTION_ID === "STC1PCOM04") && (list.RESPONSE_TEXT === "Not Sure")) {

        this.uploadTextHC = "Please upload a photo of your central heating system that shows the power cable, vents and exhaust pipes where they enter the unit.";
        this.uploadText.push(this.uploadTextHC);
      }
      if ((list.QUESTION_ID === "STC1P00007" || list.QUESTION_ID === "STC1PCOM07") && (list.RESPONSE_TEXT === "Not sure")) {

        this.uploadTextWH = "Please upload a photo of your water heater.";
        this.uploadText.push(this.uploadTextWH);
      }

      //JCPL prototype
      if ((list.QUESTION_ID === "APC1000002") && (list.RESPONSE_TEXT === "Required and Received")) {

        this.uploadTextReceipt = "Please upload your receipt form.";
        this.uploadText.push(this.uploadTextReceipt);
      }


    });

    if (this.rebateGroup == "REBATE") {
      this.uploadTextReceipt = this.utilityService.utilitySettings.UploadEnableContent;
      this.uploadText.push(this.uploadTextReceipt);
    }

    if (this.landLordConsentCheck == 1 && (this.rebateGroup != "REBATE")) {
      this.uploadTextOwner = "Please upload your signed Landlord Consent Form.";
      this.uploadText.push(this.uploadTextOwner);
    }

    //To save questions
    this.enrollService.saveIndividualQuestionsByPersonCompanyId(this.personcompanyid, elList, this.programDetailId, this.rebateGroup)
      .subscribe((res) => {
        if (res.Action == 0) {
          if (this.uploadText.length != 0) {
            // this also enrolls customer
            this.uploadDocuments(this.uploadText);
          } else {
            //once questions are saved then enroll customer 
            this.enrollService.enrollCustomer(
              this.personcompanyid, this.personcompanyid, this.programDetailId, this.agreeTerms, this.rebateGroup, this.landlordconsent).subscribe((res) => {
                localStorage.setItem("WorkorderId", res.WorkorderId);
                sessionStorage.setItem("verified", "true");
                this.spinner.hide();
                if (res.Action == 0) {
                  //this.router.navigate(['/success'], { state: { data: res } });
                  let dialogBoxSettings = {
                    height: '80%',
                    width: '80%',
                    disableClose: true,
                    hasBackdrop: true,

                  };
                  const dialogRef = this.dialog.open(EnrollmentSuccessComponent, dialogBoxSettings);
                } else {
                  Swal.fire({
                    title: 'Error',
                    text: res.Message,
                    icon: 'error',
                    confirmButtonText: 'OK',
                  })
                  // this.loading = false;
                  this.spinner.hide();
                }


              }, err => {

                Swal.fire({
                  title: 'Error',
                  text: err,
                  icon: 'error',
                  confirmButtonText: 'OK',
                })


              });
          }
        }

      }, err => {

        Swal.fire({
          title: 'Error',
          text: err,
          icon: 'error',
          confirmButtonText: 'OK',
        })

      });
  }

  //Validation with numbers and decimal values
  onlyNumbersWithDecimal(event) {
    var pattern = /^[0-9]+.[0-9]{2}/g;
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }

    return true;
  }

  onlyNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  openAlertDialog(imagearr, message1) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: {
        message: message1,
        image1: imagearr,
        buttonText: {
          cancel: 'Close',
        },
        position: {
          top: '0px',
          left: '0px'
        }
      },
    });
  }

  prevTab() {
    this.selectedTabIndex--;
  }

  nextTab() {
    this.selectedTabIndex++;
  }

  onTabClick(event) {
    this.selectedTabIndex = event.index;
  }

  checkHelpContent(helpmessage) {
    //if(helpmessage===null || helpmessage===undefined)
    helpmessage = "Click here(?) for more info...";
    return helpmessage;

  }
}

