/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { BucketService } from 'src/app/services/bucket.service';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { TimeslotdialogComponent } from 'src/app/util/timeslotdialog/timeslotdialog.component';
import { UtilityService } from 'src/app/services/utility.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {


    selectedDay:any = new Date();
    selectedDate:any;
    title = 'bucketscheduling';
    days = [];
    minDay:any = new Date();
    maxDay:any=new Date();
    minDate:any;
    maxDate:any;
    selectedWorkorder:any;
    WorkorderType: any;
    stringDate:any;
    response:any;
    reasonCode: any;
    scheduleInstruction:any;
    weekendsDatesFilter:any;

    weekendsDatesFilter1:any;
    myAvailableFilter:any;
    myAvailableDates:any;
    iLength:any;
    
    latestHoliday=[];
    officeid:any;
    programdetailid : any ;
    inActiveDates : any ;
    progDetailId:any;
    ProgramdetailId :any ; 
    valFirst:any=0;
    constructor(public router:Router, private bucketService:BucketService,
      public route:ActivatedRoute,public dialog:MatDialog,
      public dataTransfer:DataTransferService, private spinner: NgxSpinnerService,
      public utilityService: UtilityService,
      ) {
      
    }
  
    ngOnInit(): void {
      //setTimeout(() => this.loadDays(), 10000);
      //this.datepicker.disabledDates = this.disabledDates;
      this.spinner.show();
      this.dataTransfer.myMethod(true);
      const {WorkorderType = '', WorkorderId = '', reasonCode = '',ProgramdetailId=''} = history.state.data
      this.WorkorderType = WorkorderType;
      this.ProgramdetailId=ProgramdetailId;
      console.log("ProgramDetailId",this.ProgramdetailId);
      this.selectedWorkorder =  WorkorderId; //localStorage.getItem('workOrderID');
      this.reasonCode = reasonCode;
      this.selectedDate = new Date(this.selectedDay);
      this.selectedDate.setDate(this.selectedDate.getDate()+2);
      if(this.selectedDate.getDay()==6){this.selectedDate.setDate(this.selectedDate.getDate() + 2); }
      if(this.selectedDate.getDay()==0){this.selectedDate.setDate(this.selectedDate.getDate() + 2); }
    //  this.stringDate = moment(this.selectedDate).format("MM/DD/YYYY");
      
     this.minDate = new Date(this.minDay);
     this.minDate.setDate(this.minDate.getDate() + 2);

     //To set the max date
     let month = this.minDate.getMonth();
     let nextMonth = month +environment.scheduleApptMonth;
     this.maxDate = new Date(this.maxDay);
     this.maxDate.setMonth(nextMonth);
     //End to set the max date

     //Get Inactive Dates - start
     this.officeid= localStorage.getItem('currentUtility');
     this.progDetailId = this.ProgramdetailId;
     this.bucketService.getInactiveDates(this.officeid,this.selectedWorkorder,this.progDetailId).subscribe(res=>{
      this.inActiveDates = res;
      
    //Filtering the data based on inactive dates
     var count = Object.keys(res.InactiveDates).length;
     let rangeContainer:string[]=[];
     for(this.iLength=0;this.iLength<count;this.iLength++)
       {
         rangeContainer.push(res.InactiveDates[this.iLength].Date);
        
       }
         
      this.myAvailableFilter= (d: Date | null):boolean => {
        var dt = new Date(d);
        dt.setMinutes( dt.getMinutes() + dt.getTimezoneOffset() );
        const today1=moment(dt).format("YYYY-MM-DD");
        var valueToSetDate=rangeContainer.find(x=>x==today1);
                        
        return !rangeContainer.find(x=>x==today1);
       };
       
       //End to filter the data
       //this.spinner.hide();
      },
      err=>{
       console.log(err);
       this.spinner.hide();
     })
     
     //Get Inactive Dates - end
 
     if(this.minDate.getDay()==6){this.minDate.setDate(this.minDate.getDate() + 2); }
     if(this.minDate.getDay()==0){this.minDate.setDate(this.minDate.getDate() + 2); }

     setTimeout(() => {  
      this.loadDays()
   }, 3000)
      //this.loadDays();
      
    }
  
    loadDays(): void {
      this.spinner.show();
      this.days = [];
      this.response = null;
      this.stringDate = moment(this.selectedDate).format("MM/DD/YYYY");
      const now = moment(this.selectedDate);
      this.days.push(moment([now.year(), now.month(), now.date()]));
      this.slotsForDay(this.selectedWorkorder);
      //this.spinner.hide();
    }
  
    onDate(e){
      this.spinner.show();
      this.loadDays();
    }

    formatPhoneNumber(phoneNumberString) {
        let cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
          return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
      }
  
    slotsForDay(selectedWO){
    this.spinner.show();
      this.bucketService.getSlotsForDay(this.stringDate,selectedWO).subscribe(res=>{
        
        this.response = res;
        this.scheduleInstruction = res.EnrollmentSuccessfulInstruction;
        console.log(res);
        this.spinner.hide();
        
        
      },
      err=>{
        console.log(err);
        this.spinner.hide();
      })
    
    }
  
    openTimeSlotDialog(bucket, timeRes){
      const dialogRef = this.dialog.open(TimeslotdialogComponent, {
        data: {
          bucketObj: bucket,
          response: timeRes,
          workorderId:this.selectedWorkorder,
          reasonCode: this.reasonCode
        },
       // panelClass: className,
      });
  
      dialogRef.afterClosed().subscribe((res) => {
        if(res.data){
            //    this.loadDays();
            console.log('error cancel', res)
            this.router.navigate(['/appointments']);
        }
      });
    }
    
  
    slotlabel(start: string, end: string): string {
     
      const date1 = new Date(start);
      const date2 = new Date(end);
  
      let h1 = date1.getHours().toString();
      let m1 = date1.getMinutes().toString();
      
      let am1='am';
      let am2='pm';

       
      if (date1.getHours()<12) {
        am1 = 'am';
      }
      if (date1.getHours()>=12) {
        am1 = 'pm';
      }

      if (date2.getHours() < 12) {
        am2 = 'am';
      }
      if (date2.getHours() >= 12) {
        am2 = 'pm';
      }
  
      if (date1.getHours() > 12) {
        h1 = (date1.getHours() - 12).toString();
      }
  
      if (date1.getMinutes() < 10) {
        m1 = '0' + m1;
      }
  
      let h2 = date2.getHours().toString();
      let m2 = date2.getMinutes().toString();
  
  
      if (date2.getHours() > 12) {
        h2 = (date2.getHours() - 12).toString();
      }
  
      if (date2.getMinutes() < 10) {
        m2 = '0' + m2;
      }
  
      let res = h1 + ":" + m1 + " "+am1+ " to " + h2 + ":" + m2+ " "  + am2;
  
      return res;
    }
  
}
