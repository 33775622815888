/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WorkorderService } from 'src/app/services/workorder.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  
})
export class UploadDialogComponent implements OnInit {
  loadFile:any = false;
  click:any;
  file: any;
  result: any;
  fileObj: any;
  public data: any
  constructor(private dialog: MatDialog,private workorderService: WorkorderService) { }

  ngOnInit(): void {
    
    this.click=true;    
  }
  
  handleFiles(e) {
    this.file = e.target.files[0];
    let fileReader = new FileReader();
    let objContent = fileReader.readAsDataURL(this.file);
    fileReader.onload = (e) => {
      this.result = fileReader.result;
    
    }
    this.click=false; 
  }

  uploadDoc() {
    
    if (this.fileObj != null) {
      this.callUpload();
    }
    else {
      Swal.fire({text: "File upload error. Please enter all the fields.",confirmButtonText:'OK',icon:'error'})
   
    }
  }
    callUpload() {
      this.click=true;
      this.loadFile = true;
      this.result = this.result.split(',')[1];
        let body = {
        "File": this.file.name,
        "ImageCode":'REC',     
        "User": 'sfen_user',
        "Content": this.result
  
      }
     let workOrderId= localStorage.getItem("workOrderID");

     this.workorderService.uploadFile(body, workOrderId).subscribe((res: any) => {
      
      if (res.Success) {
        this.loadFile = false; 
        Swal.fire({
            text: "File uploaded successfully!",
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialog.closeAll();
          })
        }
        else {
          Swal.fire({
            text: "File upload error",
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialog.closeAll();
          })
        }
      })
    }
    
}
