/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { WorkorderService } from 'src/app/services/workorder.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from 'rxjs';
import {Input} from '@angular/core';
import { RebateService } from 'src/app/services/rebate.service';
import { ViewDialogComponent } from 'src/app/util/view-dialog/view-dialog.component';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss'],
  encapsulation:ViewEncapsulation.None
})
export class ReceiptsComponent implements OnInit {
  @Input() data: any;
  constructor(private dialog: MatDialog,private workorderService: WorkorderService,private router: Router, private spinner: NgxSpinnerService,private matdialog: MatDialog, private rebate:RebateService) { }
  errorMesg = '';
  files = [];
  uploading = false;
  enable:any;
  uploadlaterEnable:any;
  loadFile:any = false;
  click:any;
  file: any;
  result: any;
  fileObj: any;
  //public data1: any;
  fileNames: any[] = [];
  myFiles: any[] = [];
  uploads: any[] = [];
  isShowViewFileCount:any=0;

  ngOnInit(): void {
    this.enable = false;
    //To check file counts
    console.log("test");
    this.ViewFileCount();
  }

// file drops
handleFile(e) {

  this.file = e.target.files[0];
 
  let fileReader = new FileReader();
  let objContent = fileReader.readAsDataURL(this.file);
  fileReader.onload = (e) => {
    this.result = fileReader.result;
  
  }
  this.click=false; 

 for (var i = 0; i < e.target.files.length; i++) {
   this.add(e.target.files[i]);
  }
  e.target.value = null;
  
}
allowDrop(event) {
  if (!this.uploading) {
    event.preventDefault();
  }
}
handleDragEnter() {
  if (!this.uploading) {
    document.getElementById('droptarget').style.backgroundColor = '#689F38';
  }
}

handleDragLeave() {
  document.getElementById('droptarget').style.backgroundColor = 'grey';
}

handleDrop(event) {
  event.stopPropagation();
  event.preventDefault();
  const files = event.dataTransfer.files;
  for (let i = 0; i < files.length; i++) {
    this.add(files[i]);
  }
  this.handleDragLeave();
}


add(file: any) {
  if (this.isGoodFile(file)) {
    this.files.push(file);
  }
  this.enable = this.files.length > 0;
}
isGoodFile(file): boolean {
  if (file.name == undefined || file.name.length < 5) {
    this.errorMesg = "Bad File Name";
    return false;
  }

  if (file.size > 1024000000) {
    // 1 GB max
    this.errorMesg = "File too large";
    return false;
  }

  const pos = file.name.lastIndexOf('.');
  if (pos < 1) {
    this.errorMesg = 'Bad file extension';
    return false;
  }

  if (".jpg,.pdf,.doc,.docx,.png,.gif".indexOf(file.name.slice(pos).toLowerCase()) < 0) {
    this.errorMesg = "Unsupported file type"
    return false;
  }
  
  if (this.inQueue(file)) {
    this.errorMesg = "File already in queue";
    return false;
  }

  return true;
}
inQueue(file): boolean {
  var res = false;
  this.files.forEach(element => {
    if (element.name === file.name) {
      res = true;
    }
  });

  return res;
}

removeAll() {
  this.files = [];
  this.enable = false;
  this.uploadlaterEnable=true;
  this.errorMesg = '';
  return false;
}
removeFile(idx) {
  this.files.splice(idx, 1);
  this.enable = this.files.length > 0;
  this.uploadlaterEnable=this.files.length <=0;
  this.errorMesg = '';
  return false;
}



callUpload(){

  let workOrderId= this.data.WorkorderId;
  console.log("workorderid",this.data.WorkorderId);

  var csn=0;
   this.spinner.show();

    //Length of files
    var fLength=this.files.length;
    var indFileLength=0;
  
    for (var content = 0; content < this.files.length; content++) {
      this.fileNames.push(this.files[content]);

      let fileReader = new FileReader();
      let objContent = fileReader.readAsDataURL(this.files[content]);
      let fExtention =  this.files[content].name.split('.').pop();
      fileReader.onload = (e) => {
        this.result = fileReader.result;
        this.result = this.result.split(',')[1];
        this.myFiles.push(this.result);
        let body = {
          "File": this.files[csn].name,
          "ImageCode": 'REC',
          "User": 'sfen_user',
          "Content": this.myFiles[csn]
        }
        csn++;
        indFileLength++;
        console.log('body',body);
        this.uploads.push(this.workorderService.uploadFile(body, workOrderId));
        this.uploadsSubscribe(workOrderId);
      }
     
    }
}

  //subscribe to upload files
  uploadsSubscribe(workOrderId)
  {
    forkJoin(this.uploads).subscribe((resUpload: any) => {

      for (var iUpload = 0; iUpload < resUpload.length; iUpload++) {
        if (!resUpload[iUpload].Success) 
        {
          this.spinner.hide();
          Swal.fire({
            customClass:{       
              confirmButton: 'swal2-popup-confirm',           
             },
            text: "File upload error",
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            this.matdialog.closeAll();
          
          })
        }
        else
        {
          this.spinner.hide();
         
          Swal.fire({
            customClass:{       
              confirmButton: 'swal2-popup-confirm'              
             },
            text: "File uploaded successfully!",
            icon: 'success',
            confirmButtonText: 'OK',
          }).then(() => {
            this.dialog.closeAll();
            this.removeAll();  
            this.rebate.setItem('ReceiptsComponent', true);
            this.ViewFileCount();
          })
        }
        
      }
      
    });

  }

 viewFiles()
 {
  const dialogRef = this.dialog.open(ViewDialogComponent, {
    height: '70%', width: '60%',
    data: {
      workOrderID : this.data.WorkorderId,  
    },
});
 }

 //To get the file count
 ViewFileCount()
 {
  let workOrderId= this.data.WorkorderId;
 
  this.workorderService.getDocumentsForWorkorder(workOrderId).subscribe(res=>{
    let count = res;
   
    if(count.length>0)
    {
      this.isShowViewFileCount=1;
      this.rebate.setItem('ReceiptsComponent', true);
      console.log("FileLength",this.files.length);
    }
    })
 }

}
