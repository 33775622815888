/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { WhiteSpaceValidator } from '../../../services/whitespace.validator';

import { ChargerData } from '../charger-data';
import { Charger } from '../charger-model';

@Component({
  selector: 'app-charger-dialog',
  templateUrl: './charger-dialog.component.html',
  styleUrls: ['./charger-dialog.component.scss']
})
export class ChargerDialogComponent implements OnInit {

  form = new UntypedFormGroup({
    manufacturersFormControl: new UntypedFormControl('', Validators.required),
    modelFormControl: new UntypedFormControl('', Validators.required),
    networksFormControl: new UntypedFormControl('', Validators.required),
    serialNo: new UntypedFormControl('', [Validators.required, WhiteSpaceValidator.noWhiteSpace]),
    confirmSerialNo: new UntypedFormControl('', [Validators.required, this.serialNumberMatchValidator.bind(this)])
  });

  defModels = [{ RESPONSE_ID: '0', RESPONSE_TEXT: 'Select a Manufacturer', MANUFACTURER_ID: '0' }];
  defNetworks = [{ RESPONSE_ID: '0', RESPONSE_TEXT: 'Select a Manufacturer', MANUFACTURER_ID: '0' }];
  // defPortTypes = [{ RESPONSE_ID: '0', RESPONSE_TEXT: 'Select a Manufacturer', MANUFACTURER_ID: '0' }];
  defPortTypes = [];

  chargerData: ChargerData;
  numOfChargers = 10;

  constructor(private dialog: MatDialogRef<ChargerDialogComponent>, @Inject(MAT_DIALOG_DATA) public cdata: any, @Inject(MAT_DIALOG_DATA) public numChargers: any) { }

  ngOnInit(): void {
    this.chargerData = this.cdata['cdata'];
    this.numOfChargers = this.numChargers['numChargers'];
    console.log("juijuiceLinkIdUrl2=" + this.chargerData.juiceLinkIdUrl);
    if (this.chargerData.hasPortType) {
      this.defPortTypes = this.chargerData.portTypeList;
      this.form.addControl('portTypesFormControl', new UntypedFormControl('', Validators.required));
    }

    if (this.chargerData.hasJuiceNetId) {
      this.form.addControl('juiceId', new UntypedFormControl('', WhiteSpaceValidator.noWhiteSpace));
      this.form.addControl('confirmJuiceId', new UntypedFormControl('', this.juiceNetIdMatchValidator.bind(this)));
    }

    this.form?.get('juiceId').valueChanges.subscribe(() => {
      this.form?.get('confirmJuiceId').updateValueAndValidity();
    });
    this.form?.get('serialNo').valueChanges.subscribe(() => {
      this.form?.get('confirmSerialNo').updateValueAndValidity();
    });
  }

  changeManufacturer(e) {
    this.defModels = this.chargerData.modelList.filter((model) => model['MANUFACTURER_ID'] == this.form.get('manufacturersFormControl').value.RESPONSE_ID);
    this.defNetworks = this.chargerData.networkList.filter((model) => model['MANUFACTURER_ID'] == this.form.get('manufacturersFormControl').value.RESPONSE_ID);
    // if (this.chargerData.hasPortType) {
    //   this.defPortTypes = this.chargerData.portTypeList.filter((model) => model['MANUFACTURER_ID'] == this.form.get('manufacturersFormControl').value.RESPONSE_ID);
    // }

    this.form.get('modelFormControl').setValue('');
    this.form.get('networksFormControl').setValue('');
    this.form.get('juiceId').setValue('');
    this.form.get('confirmJuiceId').setValue('');
  }

  displayJuiceNetIdField() {
    return this.chargerData.hasJuiceNetId && this.form.get('manufacturersFormControl').value?.RESPONSE_TEXT == 'Enel X';
  }

  missingJuiceNetId() {
    let isMissing = false;

    if (this.displayJuiceNetIdField() && (this.form.get('juiceId').value == null || this.form.get('juiceId').value == '')) {
      this.form.get('juiceId').setErrors({'required': true});
      isMissing = true;
    }
    if (this.displayJuiceNetIdField() && (this.form.get('confirmJuiceId').value == null || this.form.get('confirmJuiceId').value == '')) {
      this.form.get('confirmJuiceId').setErrors({'required': true});
      isMissing = true;
    }
    if (!isMissing) {
      this.form.get('juiceId').setErrors(null);
      if (!this.form.get('confirmJuiceId')?.errors?.juiceNetIdMismatch) {
        this.form.get('confirmJuiceId').setErrors(null);
      }
    }

    return isMissing;
  }

  addCharger() {
    let newcharger = new Charger();
    newcharger.manufacturerID = this.form.get('manufacturersFormControl').value.RESPONSE_ID;
    newcharger.manufacturerName = this.form.get('manufacturersFormControl').value.RESPONSE_TEXT;
    newcharger.modelID = this.form.get('modelFormControl').value.RESPONSE_ID;
    newcharger.modelName = this.form.get('modelFormControl').value.RESPONSE_TEXT;
    newcharger.networkID = this.form.get('networksFormControl').value.RESPONSE_ID;
    newcharger.networkName = this.form.get('networksFormControl').value.RESPONSE_TEXT;
    newcharger.serialNumber = this.form.get('serialNo').value;

    if (this.chargerData.hasPortType) {
      newcharger.portTypeID = this.form.get('portTypesFormControl').value.RESPONSE_ID;
      newcharger.portTypeName = this.form.get('portTypesFormControl').value.RESPONSE_TEXT;
    }

    if (this.chargerData.hasJuiceNetId) {
      newcharger.juiceNetId = this.form.get('juiceId').value;
    }

    this.chargerData.chargers.push(newcharger);

    // reset form
    this.form.reset();

    this.defModels = [{ RESPONSE_ID: '0', RESPONSE_TEXT: 'Select a Manufacturer', MANUFACTURER_ID: '0' }];
    this.defNetworks = [{ RESPONSE_ID: '0', RESPONSE_TEXT: 'Select a Manufacturer', MANUFACTURER_ID: '0' }];
    // this.defPortTypes = [{ RESPONSE_ID: '0', RESPONSE_TEXT: 'Select a Manufacturer', MANUFACTURER_ID: '0' }];
  }

  deleteCharger(i) {
    this.chargerData.chargers.splice(i, 1);
  }

  serialNumberMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (!this.form) {
      return null; 
    }
    const serialNumber = this.form.get('serialNo').value;
    const confirmSerialNumber = control.value;

    return serialNumber === confirmSerialNumber ? null : {serialNumberMismatch: true};
  }

  juiceNetIdMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (!this.form) {
      return null; 
    }
    const juiceId = this.form.get('juiceId').value;
    const confirmJuiceId = control.value;

    return juiceId === confirmJuiceId ? null : {juiceNetIdMismatch: true};
  }
}
