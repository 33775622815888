<app-enrollment-header></app-enrollment-header>

<mat-card style="width:300px;margin-right:50px;">
  <p style="text-align: center;">{{answered}} of {{questions.length}} Questions Answered</p>
  <mat-progress-bar mode="determinate" value="{{((answered)*100/(questions.length))}}"></mat-progress-bar>
</mat-card>
<br>
<div class="intro-text">PROGRAM ENROLLMENT QUESTIONS - {{ workOrdername }}</div>

<div class="flex-container">
  <div class="qual-card-div">

    <table width="100%" cellspacing="0">
      <tr *ngFor="let ques of questions; index as i">

        <td width="60px">
          <span class="rcorners">
            <p class="rcorners-text">{{ i + 1 }}</p>
          </span>
        </td>
        <td class="dividing-line" width="1px">
        </td>

        <td width="500px" style="padding-left: 15px;">
          <mat-card style="margin: 10px;">



            <h3 [ngClass]="{'asterix--before': ques.QUESTION_REQUIRED }">{{ ques.QUESTION_TEXT }}</h3>

            <mat-divider></mat-divider>


            <br>


            <mat-form-field style="width: 80%;" *ngIf="ques.QUESTION_TYPE === 'TEXT'">
              <input matInput [(ngModel)]="ques.currentValue" (focusout)="onAnswering(i,i+1,ques.currentValue)"
                [disabled]="isDisabled(i)" maxlength="{{ ques.RESPONSE_LENGTH }}">
            </mat-form-field>

            <mat-form-field style="width: 80%;" *ngIf="ques.QUESTION_TYPE === 'DECIMAL'">
              <input matInput (keypress)="onlyNumbersWithDecimal($event)" (paste)="$event.preventDefault()"
                [(ngModel)]="ques.currentValue" (focusout)="onAnswering(i,i+1,ques.currentValue)"
                [disabled]="isDisabled(i)" maxlength="{{ ques.RESPONSE_LENGTH }}">
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="fill" *ngIf="ques.QUESTION_TYPE === 'DATE'">
              <mat-label>Choose a date</mat-label>
              <input matInput #resultPickerModel="ngModel" (paste)="$event.preventDefault()" [matDatepicker]="picker"
                [(ngModel)]="ques.currentValue" [disabled]="isDisabled(i)"
                (ngModelChange)="onAnswering(i,i+1,ques.currentValue)" readonly="true">
              <mat-hint>MM/DD/YYYY</mat-hint>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>

              <mat-error *ngIf="resultPickerModel.hasError('matDatepickerParse')">
                "{{resultPickerModel.getError('matDatepickerParse').text}}" is not a valid date!
              </mat-error>

            </mat-form-field>


            <span *ngIf="ques.QUESTION_TYPE === 'LABEL'">
              {{ ques.currentValue }}
            </span>


            <mat-form-field style="width: 30%;"
              *ngIf="ques.QUESTION_TYPE === 'NUMBER' || ques.QUESTION_TYPE === 'VALUE'">
              <input matInput type="number" style="text-align: right;" min=1 [(ngModel)]="ques.currentValue"
                [disabled]="isDisabled(i)" (keypress)="onlyNumbers($event)"
                (focusout)="onAnswering(i,i+1,ques.currentValue)" (paste)="$event.preventDefault()">
            </mat-form-field>

            <span *ngIf="rebateGroup=='REBATE'">
              <mat-form-field style="width: 60%;" *ngIf="ques.QUESTION_TYPE === 'LIST'">
                <mat-select [(value)]="ques.currentValue" (selectionChange)="onAnswering(i,i+1,ques.currentValue)">
                  <mat-option *ngFor="let selitem of ques.QUESTION_RESPONSES" value="{{selitem.RESPONSE_ID}}"
                    [disabled]="isDisabled(i)"
                    style="font-size: 11px;">
                    {{ selitem.RESPONSE_TEXT }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>

            <span *ngIf="rebateGroup!='REBATE'">
              <mat-form-field style="width: 60%;" *ngIf="ques.QUESTION_TYPE === 'LIST'">
                <mat-select [(value)]="ques.currentValue" [disabled]="isDisabled(i)">
                  <mat-option *ngFor="let selitem of ques.QUESTION_RESPONSES" value="{{selitem.RESPONSE_ID}}"
                    (focusout)="onAnswering(i,i+1,ques.currentValue)" style="font-size: 11px;">
                    {{ selitem.RESPONSE_TEXT }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </span>

            <app-chargers *ngIf="ques.QUESTION_TYPE === 'CUST_CHRGS'" [loading]="savingIndividual"
              [disableControl]="isDisabled(i)" [question]="ques" [numOfChargers]="10"
              (answered)="onAnswering(i,i+1,$event)"></app-chargers>

            <app-chargers *ngIf="ques.QUESTION_TYPE === 'CHRGS_RES'" [loading]="savingIndividual"
              [disableControl]="isDisabled(i)" [question]="ques" [numOfChargers]="1"
              (answered)="onAnswering(i,i+1,$event)"></app-chargers>

            <span *ngIf="ques.QUESTION_TEXT === 'Are you the homeowner?' && 'enable'">
              <br />
            </span>
            <span *ngIf="ques.QUESTION_TEXT === 'Are you the homeowner?' && 'enable'" [innerHTML]="downLoadText">
            </span>
            <br>
            <span *ngIf="ques.QUESTION_TEXT === 'Are you the homeowner?' && 'enable'" [innerHTML]="downLoadLink">
            </span>

          </mat-card>

        </td>
      </tr>
    </table>

  </div>
</div>

<!-- Routing to enrollment page based on showResponsePopup for other office IDs like SJG etc..-->

<span *ngIf=!showResponsePopup>

  <mat-card-actions style="text-align:center">
    <button mat-raised-button class="mainbutton" routerLink="/programtypes">Back</button>
    <button mat-raised-button class="mainbutton" [disabled]="quesCount != answeredQuesCount"
      (click)="getRecommendations()">{{buttonName}}</button>
  </mat-card-actions>

</span>

<!-- Routing to Santeecooper scheduling page based on  no uploads -->

<span *ngIf="showResponsePopup">

  <span *ngIf="!smallScreen">
    <span *ngIf="rebateGroup=='REBATE'">
      <mat-card-actions style="font-size: 14px;margin-left:350px;">
        <mat-checkbox name="agreeterms" required [(ngModel)]="agreeTerms"></mat-checkbox>
        <span [innerHTML]=termsAndConditions></span><br>
        <mat-checkbox *ngIf="rebateGroup=='REBATE'" name="agreeotherterms" required
          [(ngModel)]="agreeOtherTerms"></mat-checkbox>
        <span *ngIf="rebateGroup=='REBATE'" [innerHTML]=otherTermsAndConditions></span>
      </mat-card-actions>
    </span>
  </span>

  <span *ngIf="smallScreen">
    <span *ngIf="rebateGroup=='REBATE'">
      <mat-card-actions style="font-size: 13px;margin-left:5px;">
        <mat-checkbox name="agreeterms" required [(ngModel)]="agreeTerms"></mat-checkbox>
        <span [innerHTML]=termsAndConditions></span><br>
        <mat-checkbox *ngIf="rebateGroup=='REBATE'" name="agreeotherterms" required
          [(ngModel)]="agreeOtherTerms"></mat-checkbox>
        <span *ngIf="rebateGroup=='REBATE'" [innerHTML]=otherTermsAndConditions></span>
      </mat-card-actions>
    </span>
  </span>

  <span *ngIf="!smallScreen">
    <span *ngIf="rebateGroup!='REBATE'">
      <div class="flex-container" *ngIf="landLordConsentCheck==1">
        <div style="padding: 20px; margin:auto;color:black">
          <table width="132%" cellspacing="0">
            <tr>
              <td width="600px">
                <span [innerHTML]=landLordConsentMsg></span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <span *ngIf="rebateGroup!='REBATE'">
        <div class="flex-container" *ngIf="landLordConsentCheck==1">
          <div style="margin:auto;color:black;">
            <table width="132%" cellspacing="0">
              <tr>
                <td width="332px">
                  <mat-card-actions style="font-size: 13px;">
                    &nbsp;&nbsp;<mat-checkbox name="landlordconsent" required
                      [(ngModel)]="landlordconsent"></mat-checkbox>
                    <span [innerHTML]=landLordConsent></span>
                    <br>
                    &nbsp;&nbsp;<mat-checkbox name="agreeterms" required [(ngModel)]="agreeTerms"></mat-checkbox>
                    <span [innerHTML]=termsAndConditions></span>
                  </mat-card-actions>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="landLordConsentCheck!=1">
          <mat-card-actions style="font-size: 13px;text-align:center;">
            <mat-checkbox name="agreeterms" required [(ngModel)]="agreeTerms"></mat-checkbox>
            <span [innerHTML]=termsAndConditions></span>
          </mat-card-actions>

        </div>
      </span>
    </span>
  </span>
  <span *ngIf="smallScreen">
    <span *ngIf="rebateGroup!='REBATE'">

      <div class="flex-container" *ngIf="landLordConsentCheck==1">
        <div style="padding: 20px; margin:auto;color:black">
          <table width="100%" cellspacing="0">
            <tr>
              <td width="600px">
                <span [innerHTML]=landLordConsentMsg></span>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <span *ngIf="rebateGroup!='REBATE'">
        <div class="flex-container" *ngIf="landLordConsentCheck==1">
          <div style="margin:auto;color:black;">
            <table width="132%" cellspacing="0">
              <tr>
                <td width="332px">
                  <mat-card-actions style="font-size: 13px;">
                    &nbsp;&nbsp;<mat-checkbox name="landlordconsent" required
                      [(ngModel)]="landlordconsent"></mat-checkbox>
                    <span [innerHTML]=landLordConsent></span>
                    <br>
                    &nbsp;&nbsp;<mat-checkbox name="agreeterms" required [(ngModel)]="agreeTerms"></mat-checkbox>
                    <span [innerHTML]=termsAndConditions></span>
                  </mat-card-actions>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div *ngIf="landLordConsentCheck!=1">
          <mat-card-actions style="font-size: 13px;text-align:center;">
            <mat-checkbox name="agreeterms" required [(ngModel)]="agreeTerms"></mat-checkbox>
            <span [innerHTML]=termsAndConditions></span>
          </mat-card-actions>

        </div>
      </span>
    </span>
  </span>
  <span *ngIf="landLordConsentCheck==1">
    <mat-card-actions style="text-align:center">
      <button mat-raised-button class="mainbutton" routerLink="/programtypes">Back</button>

      <button *ngIf="rebateGroup!='REBATE' && !loading" mat-raised-button class="mainbutton"
        [disabled]="(quesCount != answeredQuesCount) || (!agreeTerms) || (!landlordconsent)"
        (click)="enrollAndSchedule()">{{buttonName}}</button>


      <button *ngIf="rebateGroup=='REBATE' && !loading" mat-raised-button class="mainbutton"
        [disabled]="(quesCount != answeredQuesCount) || (!agreeTerms) || (!agreeOtherTerms)"
        (click)="enrollAndSchedule()">{{buttonName}}</button>
    </mat-card-actions>
  </span>
  <span *ngIf="landLordConsentCheck==0">
    <mat-card-actions style="text-align:center">
      <button mat-raised-button class="mainbutton" routerLink="/programtypes">Back</button>

      <button *ngIf="rebateGroup!='REBATE' && !loading" mat-raised-button class="mainbutton"
        [disabled]="(quesCount != answeredQuesCount) || (!agreeTerms)"
        (click)="enrollAndSchedule()">{{buttonName}}</button>


      <button *ngIf="rebateGroup=='REBATE' && !loading" mat-raised-button class="mainbutton"
        [disabled]="(quesCount != answeredQuesCount) || (!agreeTerms) || (!agreeOtherTerms)"
        (click)="enrollAndSchedule()">{{buttonName}}</button>
    </mat-card-actions>
  </span>

</span>

<!-- Routing to Santeecooper scheduling page based on  uploads length -->

<app-enrollment-footer></app-enrollment-footer>