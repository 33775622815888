/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from '@angular/common/http';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { environment } from '../environments/environment';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTabsModule } from '@angular/material/tabs';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingComponent } from './pages/loading/loading.component';
import { MainComponent } from './pages/main/main.component';
import { CustomerLookupComponent } from './pages/customer-lookup/customer-lookup.component';
import { CustomerConfirmComponent } from './pages/customer-confirm/customer-confirm.component';
import { ProgramTypesComponent } from './pages/program-types/program-types.component';
import { QualifyComponent } from './pages/qualify/qualify.component';
import { EnrollSuccessComponent } from './pages/enroll-success/enroll-success.component';
import { EnrollErrorComponent } from './pages/enroll-error/enroll-error.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { EnrollmentHeaderComponent } from './enrollment-header/enrollment-header.component';
import { EnrollmentFooterComponent } from './enrollment-footer/enrollment-footer.component';
import { ScheduleListComponent } from './pages/schedule-list/schedule-list.component';
import { EnrollListComponent } from './pages/enroll-list/enroll-list.component';
import { UtilityErrorComponent } from './pages/utility-error/utility-error.component';
import { EnrollComponent } from './util/enroll/enroll.component';
import { TimeslotdialogComponent } from './util/timeslotdialog/timeslotdialog.component';
import { ReScheduleComponent } from './pages/re-schedule/re-schedule.component';
import { SchedulerDialogComponent } from "./pages/scheduler-dialog/scheduler-dialog.component";
import { CanceldeactivateDialogComponent } from "./pages/canceldeactivate-dialog/canceldeactivate-dialog.component";
import { CancelscheduleDialogComponent } from "./pages/cancelschedule-dialog/cancelschedule-dialog.component";
import { UploadDialogComponent } from './util/upload-dialog/upload-dialog.component';
import { ViewDialogComponent } from './util/view-dialog/view-dialog.component';
import { ConfirmEqualValidatorDirective } from './services/confirm-equal-validator.directive';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { EnrollcustomerComponent } from './pages/enrollcustomer/enrollcustomer.component';
import { UploadRebatesDocumentComponent } from './upload-rebates-document/upload-rebates-document.component';
import { CallbackComponent } from './pages/callback/callback.component';
import { ChargersComponent } from './custom/chargers/chargers.component';
import { ChargerDialogComponent } from './custom/chargers/charger-dialog/charger-dialog.component';


import { DatePipe } from "@angular/common";
import { AuditComponent } from './pages/audit/audit.component';
import { AlertDialogComponent } from './util/alert-dialog/alert-dialog.component';
import { AuditReportComponent } from './pages/audit-report/audit-report.component';
import { OnlineAppComponent } from './pages/rebates/online-app/online-app.component';
import { PayeeComponent } from './pages/rebates/payee/payee.component';
import { MeasureComponent } from './pages/rebates/measure/measure.component';
import { OtherComponent } from './pages/rebates/other/other.component';
import { ReceiptsComponent } from './pages/rebates/receipts/receipts.component';
import { UploadreceiptsComponent } from './pages/rebates/uploadreceipts/uploadreceipts.component';
import { EnrollmentSuccessComponent } from './pages/enrollment-success/enrollment-success.component';
import { SubmitComponent } from './pages/rebates/submit/submit.component';
import { MyDynamicDirective } from './pages/rebates/directives/my-dynamic.directive';
import { ComponentTypes } from './pages/rebates/classes/component-types';

import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';

import { RecaptchaModule } from "ng-recaptcha";
import { ContractorComponent } from './pages/rebates/contractor/contractor.component';
import { PhoneMaskDirective } from './pages/rebates/directives/phone-mask.directive';
import { NgxBarcodeModule } from 'ngx-barcode';

@NgModule({
    declarations: [
        AppComponent,
        LoadingComponent,
        MainComponent,
        CustomerLookupComponent,
        CustomerConfirmComponent,
        ProgramTypesComponent,
        QualifyComponent,
        EnrollSuccessComponent,
        EnrollErrorComponent,
        ScheduleComponent,
        EnrollmentHeaderComponent,
        EnrollmentFooterComponent,
        ScheduleListComponent,
        EnrollListComponent,
        UtilityErrorComponent,
        EnrollComponent,
        TimeslotdialogComponent,
        ReScheduleComponent,
        SchedulerDialogComponent,
        CanceldeactivateDialogComponent,
        CancelscheduleDialogComponent,
        UploadDialogComponent, ViewDialogComponent,
        ConfirmEqualValidatorDirective,
        UploadDocumentsComponent,
        EnrollcustomerComponent,
        UploadRebatesDocumentComponent,
        CallbackComponent,
        ChargersComponent,
        ChargerDialogComponent,
        AuditComponent,
        AlertDialogComponent,
        AuditReportComponent,
        OnlineAppComponent,
        PayeeComponent,
        MeasureComponent,
        OtherComponent,
        ReceiptsComponent,
        SubmitComponent,
        UploadreceiptsComponent,
        EnrollmentSuccessComponent,
        MyDynamicDirective,
        ContractorComponent,
        PhoneMaskDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatProgressSpinnerModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatListModule,
        MatCardModule,
        MatRadioModule,
        MatDividerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        MatTabsModule,
        MatSelectModule,
        MatProgressBarModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatTableModule,
        NgxSpinnerModule.forRoot({ type: 'timer' }),
        MatCheckboxModule,
        MatDatepickerModule,
        MatMomentDateModule,
        RecaptchaModule,
        NgxBarcodeModule
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [Title, DatePipe, ComponentTypes,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: environment.sentryShowdialog,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }],
    bootstrap: [AppComponent]
})
export class AppModule { }
