/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2024 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import Swal from 'sweetalert2';
import { EnrollService } from '../services/enroll.service';
import { WorkorderService } from '../services/workorder.service';
import { UtilityService } from '../services/utility.service';
import { NgxSpinnerService } from "ngx-spinner";
import { forkJoin } from 'rxjs';
import { EnrollmentSuccessComponent } from '../pages/enrollment-success/enrollment-success.component';

@Component({
  selector: 'app-upload-documents',
  templateUrl: './upload-documents.component.html',
  styleUrls: ['./upload-documents.component.scss']
})
export class UploadDocumentsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
  public data: any,
    private dialog: MatDialogRef<UploadDocumentsComponent>, private enrollmentService: EnrollService,
    private router: Router, private workorderService: WorkorderService, private matdialog: MatDialog, public utilityService: UtilityService,private spinner: NgxSpinnerService) { }

  uploading = false;
  files = [];
  files1= [];
  files2= [];
  files3= [];
  files4= [];
  filesCSN=[];
  totalFiles=[];
  errorMesg = '';
  uploads: any[] = [];
  uploadText = [];
  file: any;
  result: any;
  click: any;
  fileObj: any;
  loadFile: any = false;
  uploadTextLength: any;
  myFiles1: any[] = [];
  myFiles2: any[] = [];
  myFiles3: any[] = [];
  myFiles4: any[] = [];
  myFiles: any[] = [];
  fileNames: any[] = [];
  PersoncompanyId: any;
  programDetailId: any;
  uploadUnique: any;
  enable: boolean;
  woResponse: any;
  agreeTerms: any;
  smallScreen: any;
  rebateGroup: any;
  isShowHeader:any;
  personcompanyid:any;
  isUploadButtonEnablement:any;
  isRebateFlow:any;
  uploadlaterEnable:boolean;
  uploadlaterEnableCSN:boolean;
  uploadlaterEnableW9:boolean;
  uploadlaterEnableIn:boolean;
  uploadlaterEnableCh:boolean;
  uploadText1:any;
  DownloadW9Form:any;
  enableW9:boolean;
  enableIn:boolean;
  enableCh:boolean;
  landlordconsent:any;
  chargePointMessage:any;
  chargePointFlag:any;
  dialogWidth = '800px';
  ngOnInit(): void {

    this.isShowHeader= this.utilityService.utilitySettings.showHeader;
    this.isUploadButtonEnablement = localStorage.getItem('uploadButtonEnablement');
    this.personcompanyid = localStorage.getItem('personcompanyid')
    if(this.isShowHeader==0)
    {
      this.uploadTextLength = this.uploadText.length;
    }

    if(this.isUploadButtonEnablement==0)
    {
      this.uploadText = this.data.uploadText1;
      this.uploadTextLength = this.uploadText.length;
      this.programDetailId = this.data.programdetailID;
      this.agreeTerms = this.data.agreeTerms;
      
    }
    
    this.enable = false;
    this.enableW9 = false;
    this.enableIn = false;
    this.uploadlaterEnable=false;
    this.uploadlaterEnableCSN=true;
    this.uploadlaterEnableW9=true;
    this.uploadlaterEnableIn=true;
    this.uploadlaterEnableCh=true;
    this.rebateGroup = localStorage.getItem('rebategroup');
    if(this.rebateGroup!="REBATE")
    {
      this.isRebateFlow=0;
    }
    else
    {

      this.uploadText=[];
      this.uploadText1=this.utilityService.utilitySettings.UploadEnableContent;
      this.uploadText.push(this.uploadText1);
      this.isRebateFlow=1;
      this.DownloadW9Form=this.utilityService.utilitySettings.DownloadW9Form;
     
    }
    this.smallScreen = screen.width < 600;
    
  }

  // file drops
  handleFile(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      this.add(e.target.files[i]);
    }
  }

  handleFileCSN(e) {
    for (var i = 0; i < e.target.files.length; i++) {
      var size = e.target.files[i].size;
      if(size>2097152)
      {
        
        Swal.fire({
          text: "Please choose the file less than 2 MB size",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
        })
        return;
      }
      if(this.files1.length < 10) {
        this.addCSN(e.target.files[i]);
        }
      else{
        Swal.fire({
          text: " You cannot select more than 10 files",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
        })

      }
      
    }
  }

  handleFileW9(e) {
    
    this.files2 = e.target.files;
    var size = e.target.files[0].size;
    if(size>2097152)
    {
     this.removeAllW9();
      Swal.fire({
        text: "Please choose the file less than 2 MB size",
        icon: 'error',
        confirmButtonText: 'OK',
      }).then(() => {
      })
      return;
    }
    this.enableW9 = this.files2.length > 0;
    this.uploadlaterEnableW9 = this.files2.length <= 0;
  }

  handleFileInvoice(e) {
    this.files3 = e.target.files;
    var size = e.target.files[0].size;
    
    if(size>2097152)
    {
      this.removeAllInvoice();
      Swal.fire({
        text: "Please choose the file less than 2 MB size",
        icon: 'error',
        confirmButtonText: 'OK',
      }).then(() => {
      })
      return;
    }
    this.enableIn = this.files3.length > 0;
    this.uploadlaterEnableIn=this.files3.length <= 0;
  }

  handleImageCharger(e) {
    this.files4 = e.target.files;
    var size = e.target.files[0].size;
    
    if(size>2097152)
    {
      this.removeAllCharger();
      Swal.fire({
        text: "Please choose the file less than 2 MB size",
        icon: 'error',
        confirmButtonText: 'OK',
      }).then(() => {
      })
      return;
    }
    this.enableIn = this.files4.length > 0;
    this.uploadlaterEnableCh=this.files4.length <= 0;
  }

  allowDrop(event) {
    if (!this.uploading) {
      event.preventDefault();
    }
  }

  handleDragEnter() {
    if (!this.uploading) {
      document.getElementById('droptarget').style.backgroundColor = '#689F38';
    }
  }

  handleDragLeave() {
    document.getElementById('droptarget').style.backgroundColor = 'grey';
  }

  handleDrop(event) {
    event.stopPropagation();
    event.preventDefault();
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      this.add(files[i]);
    }
    this.handleDragLeave();
  }

  add(file: any) {
    if (this.isGoodFile(file)) {
      this.files.push(file);
    }

    this.enable = this.files.length > 0;
    this.uploadlaterEnable=this.files.length <=0;
  }

  addCSN(file: any) {
    if (this.isGoodFile(file)) {
      this.files1.push(file);
    }

    this.enable = this.files1.length > 0;
    this.uploadlaterEnableCSN=this.files1.length <=0;
  }

  isGoodFile(file): boolean {
    if (file.name == undefined || file.name.length < 5) {
      this.errorMesg = "Bad File Name";
      return false;
    }

    if (file.size > 1024000000) {
      // 1 GB max
      this.errorMesg = "File too large";
      return false;
    }

    const pos = file.name.lastIndexOf('.');
    if (pos < 1) {
      this.errorMesg = 'Bad file extension';
      return false;
    }

    if (".jpg,.pdf,.doc,.docx,.png,.gif".indexOf(file.name.slice(pos).toLowerCase()) < 0) {
      this.errorMesg = "Unsupported file type"
      return false;
    }
    
    if (this.inQueue(file)) {
      this.errorMesg = "File already in queue";
      return false;
    }

    return true;
  }

  inQueue(file): boolean {
    var res = false;
    this.files.forEach(element => {
      if (element.name === file.name) {
        res = true;
      }
    });

    return res;
  }

  removeAll() {
    this.files = [];
    this.enable = false;
    this.uploadlaterEnable=true;
    this.errorMesg = '';
  }

  removeAllCSN() {
    this.files1 = [];
    this.enable = false;
    this.uploadlaterEnable=true;
    this.errorMesg = '';
  }

  removeAllW9() {
    this.files2 = [];
    this.enableW9 = false;
    this.uploadlaterEnableW9=true;
    this.errorMesg = '';
  }

  removeAllInvoice() {
    this.files3 = [];
    this.enableIn = false;
    this.uploadlaterEnableIn=true;
    this.errorMesg = '';
  }

  removeAllCharger() {
    this.files4 = [];
    this.enableIn = false;
    this.uploadlaterEnableCh=true;
    this.errorMesg = '';
  }

  removeFile(idx) {
    this.files.splice(idx, 1);
    this.enable = this.files.length > 0;
    this.uploadlaterEnable=this.files.length <=0;
    this.errorMesg = '';
  }

  removeFileCSN(idx) {
    this.files1.splice(idx, 1);
    this.enable = this.files1.length > 0;
    this.uploadlaterEnable=this.files1.length <=0;
    this.errorMesg = '';
  }

  removeFileW9(idx) {
    this.files2.splice(idx, 1);
    this.enable = this.files2.length > 0;
    this.uploadlaterEnable=this.files2.length <=0;
    this.errorMesg = '';
  }

  removeFileInvoice(idx) {
    this.files3.splice(idx, 1);
    this.enable = this.files3.length > 0;
    this.uploadlaterEnable=this.files3.length <=0;
    this.errorMesg = '';
  }

  removeImageCharger(idx) {
    this.files4.splice(idx, 1);
    this.enable = this.files4.length > 0;
    this.uploadlaterEnable=this.files4.length <=0;
    this.errorMesg = '';
  }

  uploadDoc(){
    this.spinner.show();
    this.enable = false;
    this.uploadlaterEnable=false;
    if (this.files1 != null && this.files1.length > 0 || this.files2 != null && this.files2.length > 0 || this.files3 != null && this.files3.length > 0 || this.files4 != null && this.files4.length > 0 ) {
      this.enrollAndUpload();
    }
    else {
      Swal.fire({ text: "File upload error. Please enter all the fields.", confirmButtonText: 'OK', icon: 'error' })

    }

  }

  enrollAndUpload()
  {
    this.spinner.show();
    this.PersoncompanyId = localStorage.getItem('personcompanyid');

    this.enrollmentService.enrollCustomer(
      this.PersoncompanyId, this.PersoncompanyId, this.programDetailId, this.agreeTerms, this.rebateGroup,this.landlordconsent).subscribe((res) => {
        let newWorkOrderId = res.WorkorderId;
        this.chargePointMessage=res.PopupMessage;
        this.chargePointFlag=res.PopupFlag;
        localStorage.setItem('workOrderID', newWorkOrderId);
        this.woResponse = res;
        /*if(this.chargePointFlag!=undefined)
        {
          this.spinner.hide();
          this.documentsUpload();
          Swal.fire({
            title: 'Chargepoint Information',
            html: '<div style="text-align:left"> ' + this.chargePointMessage + ' </div>',
            icon: 'info',
            width: this.dialogWidth,
            confirmButtonText: 'OK',
          }); 

        }
        else
        {*/
          //calling document upload after workorder successful creation
          this.documentsUpload();
       // }


      }, err => {
        this.spinner.hide();
        Swal.fire({
          title: 'Error',
          text: err,
          icon: 'error',
          confirmButtonText: 'OK',
        })


      });
  }
  documentsUpload(){
    let workOrderId = localStorage.getItem('workOrderID');
    var csn=0;
    this.spinner.show();

    //Length of files
    var fLength=this.files1.length+this.files2.length+this.files3.length+this.files4.length;
    var indFileLength=0;
  
    for (var content = 0; content < this.files1.length; content++) {
      this.fileNames.push(this.files1[content]);

      let fileReader = new FileReader();
      let objContent = fileReader.readAsDataURL(this.files1[content]);
      let fExtention =  this.files1[content].name.split('.').pop();
      fileReader.onload = (e) => {
        this.result = fileReader.result;
        this.result = this.result.split(',')[1];
        this.myFiles1.push(this.result);
        var fileName = workOrderId +"_ChargerSerialNumber" + Math. floor(Math. random() * 500)  + "." + fExtention;
        let body = {
          "File": fileName,
          "ImageCode": 'CSN',
          "User": 'sfen_user',
          "Content": this.myFiles1[csn]
        }
        csn++;
        indFileLength++;
        console.log('body',body);
        this.uploads.push(this.workorderService.uploadFile(body, workOrderId));
       
        if(indFileLength==fLength)
        {
            
            this.uploadsSubscribe(workOrderId);
        }
      }
     
    }
      
    var w9f=0;
    for (var content = 0; content < this.files2.length; content++) {
      this.fileNames.push(this.files2[content]);

      let fileReader = new FileReader();
      let objContent = fileReader.readAsDataURL(this.files2[content]);
      let fExtention =  this.files2[content].name.split('.').pop();
      fileReader.onload = (e) => {
        this.result = fileReader.result;
        this.result = this.result.split(',')[1];
        this.myFiles2.push(this.result);
        var fileName = workOrderId +"_W9form" + Math. floor(Math. random() * 500)  + "." + fExtention;
        let body = {
          "File": fileName,
          "ImageCode": 'W9F',
          "User": 'sfen_user',
          "Content": this.myFiles2[w9f]
        }
        w9f++;
        indFileLength++;
       
        this.uploads.push(this.workorderService.uploadFile(body, workOrderId));
       
        if(indFileLength==fLength)
        {
          
            this.uploadsSubscribe(workOrderId);
        }
      }
    }
    var invoice=0;
    for (var content = 0; content < this.files3.length; content++) {
      this.fileNames.push(this.files3[content]);

      let fileReader = new FileReader();
      let objContent = fileReader.readAsDataURL(this.files3[content]);
      let fExtention =  this.files3[content].name.split('.').pop();
      fileReader.onload = (e) => {
        this.result = fileReader.result;
        this.result = this.result.split(',')[1];
        this.myFiles3.push(this.result);       
        var fileName = workOrderId +"_Invoice"  + Math. floor(Math. random() * 500)  + "." + fExtention;
        let body = {
          "File": fileName,
          "ImageCode": 'INI',
          "User": 'sfen_user',
          "Content": this.myFiles3[invoice]
        }
        invoice++;
        indFileLength++;
       
        this.uploads.push(this.workorderService.uploadFile(body, workOrderId));
      
        if(indFileLength==fLength)
        {
              
              this.uploadsSubscribe(workOrderId);
         
        }
      }
   }
   var charger=0;
    for (var content = 0; content < this.files4.length; content++) {
      this.fileNames.push(this.files4[content]);

      let fileReader = new FileReader();
      let objContent = fileReader.readAsDataURL(this.files4[content]);
      let fExtention =  this.files4[content].name.split('.').pop();
      fileReader.onload = (e) => {
        this.result = fileReader.result;
        this.result = this.result.split(',')[1];
        this.myFiles4.push(this.result);       
        var fileName = workOrderId +"_CSNWLMT"  + Math. floor(Math. random() * 500)  + "." + fExtention;
        let body = {
          "File": fileName,
          "ImageCode": 'CSNWLMT',
          "User": 'sfen_user',
          "Content": this.myFiles4[charger]
        }
        charger++;
        indFileLength++;
       
        this.uploads.push(this.workorderService.uploadFile(body, workOrderId));
      
        if(indFileLength==fLength)
        {
              
              this.uploadsSubscribe(workOrderId);
         
        }
      }
   }





   
  }

  //subscribe to upload files
  uploadsSubscribe(workOrderId)
  {
    forkJoin(this.uploads).subscribe((resUpload: any) => {

      for (var iUpload = 0; iUpload < resUpload.length; iUpload++) {
        if (!resUpload[iUpload].Success) 
        {
          this.spinner.hide();
          Swal.fire({
            text: "File upload error",
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            this.matdialog.closeAll();
          
          })
        }
        if (iUpload == resUpload.length - 1)
        {
         
          if(this.isUploadButtonEnablement==1)
            {
              
              this.callRebatecalc(workOrderId);
              this.dialog.close();
              this.spinner.hide();
              this.router.navigate(['/enrollments']);
            }
            else
            {
              
               this.callRebatecalc(workOrderId);
               this.dialog.close();
               this.spinner.hide();
               if(this.chargePointFlag!=undefined)
                {
                  this.spinner.hide();
                  Swal.fire({
                    title: 'Chargepoint Information',
                    html: '<div style="text-align:left"> ' + this.chargePointMessage + ' </div>',
                    icon: 'info',
                    width: this.dialogWidth,
                    confirmButtonText: 'OK',
                  }).then(()=> {

                     //this.router.navigate(['/success'], { state: { data: this.woResponse } }); 
                     let dialogBoxSettings = {
                      height: '80%',
                      width: '80%',
                      disableClose: true,
                      hasBackdrop: true,
            
                    };
                  const dialogRef = this.matdialog.open(EnrollmentSuccessComponent,dialogBoxSettings);
                  })
                
                }
                else
                {
                  //this.router.navigate(['/success'], { state: { data: this.woResponse } });
                  let dialogBoxSettings = {
                    height: '80%',
                    width: '80%',
                    disableClose: true,
                    hasBackdrop: true,
          
                  };
                const dialogRef = this.matdialog.open(EnrollmentSuccessComponent,dialogBoxSettings);
                }
            }
        }
      }
      
    });

  }

  //To call rebates - for calculations
  callRebatecalc(workOrderId)
  {
    this.spinner.show();
    var isEP=1;
    this.workorderService.saveRebateWorkOrder(workOrderId,'sfen_user',isEP).subscribe((resUpload: any) => {
    });
    this.spinner.hide();
  }


  uploadDocEnrollments() {
    this.spinner.show();
    this.enable = false;
    this.enableW9 = false;
    this.enableIn = false;
    this.enableCh =false;
    this.uploadlaterEnable=false;
    if (this.files1 != null && this.files1.length > 0 || this.files2 != null && this.files2.length > 0 || this.files3 != null && this.files3.length > 0 || this.files4 != null && this.files4.length > 0  ) {
      this.documentsUpload();
    }
    else {
      Swal.fire({ text: "File upload error. Please enter all the fields.", confirmButtonText: 'OK', icon: 'error' })

    }

  }

 fileUpload(body,workOrderId){
  
    this.workorderService.uploadFile(body, workOrderId).subscribe((resUpload: any) => {
      //this.spinner.hide();
        if (resUpload.Success) {
          
          this.dialog.close();

          if (localStorage.getItem('SelfSchedule') == '0') {
            this.spinner.hide();
            //this.router.navigate(['/success'], { state: { data: this.woResponse } });
          }
          else if (this.isRebateFlow==1) {
            this.spinner.hide();
            //this.router.navigate(['/success'], { state: { data: this.woResponse } });
        }
          else {
            this.spinner.hide();
            this.router.navigate(['/scheduling'], { state: { data: this.woResponse } });
          }

        }
        else {
          this.spinner.hide();
          Swal.fire({
            text: "File upload error",
            icon: 'error',
            confirmButtonText: 'OK',
          }).then(() => {
            this.matdialog.closeAll();
            this.router.navigate(['/customerlookup']);
          })
        }
      }
      )
  }

/*For DR upload documents*/

uploadDocDR(){
    this.spinner.show();
    this.enable = false;
    this.uploadlaterEnable=false;
    if (this.files != null && this.files.length > 0) {
      this.retrieveDRContent();
      this.callDRUpload();
    }
    else {

      Swal.fire({ text: "File upload error. Please enter all the fields.", confirmButtonText: 'OK', icon: 'error' })
    }
}
retrieveDRContent() {
  for (var i = 0; i < this.files.length; i++) {
    this.fileNames.push(this.files[i]);
    let fileReader = new FileReader();
    let objContent = fileReader.readAsDataURL(this.files[i]);
    fileReader.onload = (e) => {
      this.result = fileReader.result;
      this.result = this.result.split(',')[1];
      this.myFiles.push(this.result);
    }
  }
}
callDRUpload(){
      this.PersoncompanyId = localStorage.getItem('personcompanyid');
      this.enrollmentService.enrollCustomer(
        this.PersoncompanyId, this.PersoncompanyId, this.programDetailId, this.agreeTerms, this.rebateGroup,this.landlordconsent).subscribe((res) => {
          let newWorkOrderId = res.WorkorderId;
          localStorage.setItem('workOrderID', newWorkOrderId);
          this.woResponse = res;
            //calling document upload after workorder successful creation
          this.documentDRUpload();
          }, err => {
          this.spinner.hide();
          Swal.fire({
            title: 'Error',
            text: err,
            icon: 'error',
            confirmButtonText: 'OK',
          })
          });
}

documentDRUpload(){
    let workOrderId = localStorage.getItem('workOrderID');
    for (var i = 0; i < this.files.length; i++) {
    //To append the file name if less than 10 characters
    let leftRightFileName = this.files[i].name.split('.');
    let fName = leftRightFileName[0];
    let fExtention =  this.files[i].name.split('.').pop();
    let lengthFname = fName.length;
    if (lengthFname <= 10) {
      var fileName = fName + workOrderId + "." + fExtention;
    }
    else {
      fileName = fileName = fName + "." + fExtention;;
    }
    let body = {
      "File": fileName,
      "ImageCode": 'REC',
      "User": 'sfen_user',
      "Content": this.myFiles[i]
    }
      this.workorderService.uploadFile(body, workOrderId).subscribe((resUpload: any) => {
      if (resUpload.Success) {
        this.spinner.hide();
        this.dialog.close();
        if (localStorage.getItem('SelfSchedule') == '0') {
          this.spinner.hide();
          //this.router.navigate(['/success'], { state: { data: this.woResponse } });
          let dialogBoxSettings = {
            height: '80%',
            width: '80%',
            disableClose: true,
            hasBackdrop: true,
  
          };
        const dialogRef = this.matdialog.open(EnrollmentSuccessComponent,dialogBoxSettings);
        }
        else if (this.isRebateFlow==1) {
          this.spinner.hide();
          //this.router.navigate(['/success'], { state: { data: this.woResponse } });
          let dialogBoxSettings = {
            height: '80%',
            width: '80%',
            disableClose: true,
            hasBackdrop: true,
  
          };
        const dialogRef = this.matdialog.open(EnrollmentSuccessComponent,dialogBoxSettings);
      }
        else {
          this.spinner.hide();
          this.router.navigate(['/scheduling'], { state: { data: this.woResponse } });
        }
      }
      else {
        this.spinner.hide();
        Swal.fire({
          text: "File upload error",
          icon: 'error',
          confirmButtonText: 'OK',
        }).then(() => {
          this.matdialog.closeAll();
          this.router.navigate(['/customerlookup']);
        })
      }
    }
    )
  }

}

uploadDocsLater() {
  this.spinner.show();
  this.PersoncompanyId = localStorage.getItem('personcompanyid');

  this.enrollmentService.enrollCustomer(
    this.PersoncompanyId, this.PersoncompanyId, this.programDetailId, this.agreeTerms, this.rebateGroup,this.landlordconsent).subscribe((res) => {
      this.spinner.hide();
      let newWorkOrderId = res.WorkorderId;
      localStorage.setItem('workOrderID', newWorkOrderId);
      this.woResponse = res;

      this.chargePointMessage=res.PopupMessage;
      this.chargePointFlag=res.PopupFlag;
      console.log("PopupFlag",this.chargePointFlag);

      //Call rebate calc
       this.callRebatecalc(newWorkOrderId)
      //End

      //To close the dialog and lead to sucessful page
      this.dialog.close();

      if(this.chargePointFlag!=undefined)
      {
        this.spinner.hide();
        Swal.fire({
          title: 'Chargepoint Information',
          html: '<div style="text-align:left"> ' + this.chargePointMessage + ' </div>',
          icon: 'info',
          width: this.dialogWidth,
          confirmButtonText: 'OK',
        }).then(()=> {

          //this.router.navigate(['/success'], { state: { data: this.woResponse } }); 
          let dialogBoxSettings = {
            height: '80%',
            width: '80%',
            disableClose: true,
            hasBackdrop: true,
  
          };
        const dialogRef = this.matdialog.open(EnrollmentSuccessComponent,dialogBoxSettings);
        })
      
      }
      else
      {
        if (localStorage.getItem('SelfSchedule') == '0') {
          //this.router.navigate(['/success'], { state: { data: this.woResponse } });
          let dialogBoxSettings = {
            height: '80%',
            width: '80%',
            disableClose: true,
            hasBackdrop: true,
  
          };
        const dialogRef = this.matdialog.open(EnrollmentSuccessComponent,dialogBoxSettings);
        }
        else if (this.isRebateFlow==1) {
          //this.router.navigate(['/success'], { state: { data: this.woResponse } });
          let dialogBoxSettings = {
            height: '80%',
            width: '80%',
            disableClose: true,
            hasBackdrop: true,
  
          };
        const dialogRef = this.matdialog.open(EnrollmentSuccessComponent,dialogBoxSettings);
             }
        else {

          this.router.navigate(['/scheduling'], { state: { data: this.woResponse } });
        }
      }
    }, err => {
      this.spinner.hide();
      Swal.fire({
        title: 'Error',
        text: err,
        icon: 'error',
        confirmButtonText: 'OK',
      })
    });
}

}
