<!-- Normal screen START-->
<span *ngIf="!smallScreen">

  <app-enrollment-header [programName]="selProgramName"></app-enrollment-header>
 
  <div class="intro-text" [innerHTML]=utilityService.utilitySettings.mainIntro></div>
  <mat-card class="mat-elevation-z0">

    <div style="width: 75%; float:left" *ngIf="enableEnrollCustomer==1" class="enableHelpContent"
      [innerHTML]=utilityService.utilitySettings.SearchContentHelp></div>

      <div style="width: 22%; float:right" >
        <div [innerHTML]=utilityService.utilitySettings.CustomProgramGuide></div>
       </div>
    <div class="container">

      <div class="font-style-bold" *ngIf="isEnableSearches==0">{{utilityService.utilitySettings.searchContent}}</div>
      <div class="intro-desc"><span *ngIf="enableEnrollCustomer==1"><a routerLink="/enrollcustomer"><mat-radio-button
              value="enroll"></mat-radio-button></a>{{utilityService.utilitySettings.mainDesc}}</span></div>
      <div class="ribbon" *ngIf="isEnableHelpContent && utilityService.utilitySettings.custominfocallback==null ">Need
        help enrolling?<br>{{utilityService.utilitySettings.enableHelpContent}}<br>
        Or&nbsp;<a class='ribbon' [routerLink]="[]" (click)="callback()">request a call back </a>
      </div>
      <div class="ribbon" *ngIf="isEnableHelpContent && utilityService.utilitySettings.custominfocallback!=null "
        innerHTML="{{utilityService.utilitySettings.custominfocallback}}">
      </div>
    </div>

    <div class="buttonbox" *ngIf="isEnableSearches!=0">
      <mat-radio-group [(ngModel)]="selVerificationMethod.SearchTypeId">
        <mat-radio-button *ngFor="let method of customerSearchOPtions" [value]="method.SearchTypeId"
          (change)="onItemChange(method)">
          {{method.SearchTypeDesc}}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card>

  <mat-card>
    <p>
      <mat-form-field appearance="outline" *ngFor="let item of selVerificationMethod.SearchAttributes; let i =index">
        <mat-label>{{item.AttributeName}}</mat-label>
        <input type="text" name={{item.SelectedValue}} matInput [required]="item.Mandatory == 1? 'required': null"
          [(ngModel)]="item.SelectedValue" [value]="item.SelectedValue | uppercase"
          placeholder={{item.AttributeNamePlaceHolder}}
          (ngModelChange)="dataChanged($event,selVerificationMethod.SearchTypeId,item.AttributeName)">
      </mat-form-field>
    </p>
    <div *ngIf="showCaptcha">
      <re-captcha (resolved)="resolved($event)" (errored)="onError($event)" errorMode="handled" siteKey="{{capKey}}"></re-captcha>
      <br />
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="verify()" class="mainbutton" [disabled]="!enableVer || !captchGood">
        NEXT
      </button>
    </mat-card-actions>
  </mat-card>
  <app-enrollment-footer></app-enrollment-footer>
</span>
<!--  End Normal screen-->

<!-- Mobile screen-->
<span *ngIf="smallScreen" class="mradio">
  <app-enrollment-header [programName]="selProgramName"></app-enrollment-header>
<br>
  <div class="intro-text" [innerHTML]=utilityService.utilitySettings.mainIntro></div>

  <!-- <div class="intro-text">{{utilityService.utilitySettings.mainIntro}}</div> -->

  <mat-card class="mat-elevation-z0">

    <div *ngIf="enableEnrollCustomer==1" class="enableHelpContent" [innerHTML]=utilityService.utilitySettings.SearchContentHelp></div>
    <!-- <div *ngIf="enableEnrollCustomer==1" class="enableHelpContent">{{utilityService.utilitySettings.SearchContentHelp}}
    </div> -->
    <br>
    <div style="width: 22%; float:right" >
      <div [innerHTML]=utilityService.utilitySettings.CustomProgramGuide></div>
     </div>
    <div class="intro-desc"><span *ngIf="enableEnrollCustomer==1"><a routerLink="/enrollcustomer"><mat-radio-button
            value="enroll"></mat-radio-button></a>{{utilityService.utilitySettings.mainDesc}}</span></div>
    <div class="searchcontent" *ngIf="isEnableSearches==0">{{utilityService.utilitySettings.searchContent}}</div>
    <br>
    <div class="mribbon" *ngIf="isEnableHelpContent && utilityService.utilitySettings.custominfocallback==null ">Need
      help enrolling?<br>{{utilityService.utilitySettings.enableHelpContent}}<br>
      Or&nbsp;<a class='mribbon' [routerLink]="[]" (click)="callback()">request a call back </a>
    </div>
    <div class="ribbon" *ngIf="isEnableHelpContent && utilityService.utilitySettings.custominfocallback!=null "
      innerHTML="{{utilityService.utilitySettings.custominfocallback}}">
    </div>

    <div class="mbuttonbox" *ngIf="isEnableSearches!=0">
      <mat-radio-group [(ngModel)]="selVerificationMethod.SearchTypeId">
        <mat-radio-button style="margin-left:0px" *ngFor="let method of customerSearchOPtions"
          [value]="method.SearchTypeId" (change)="onItemChange(method)">
          {{method.SearchTypeDesc}}<br>
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </mat-card>

  <mat-card>
    
    <div>
      <mat-form-field [style.width.px]=300 appearance="outline"
        *ngFor="let item of selVerificationMethod.SearchAttributes; let i =index">
        <mat-label>{{item.AttributeName}}</mat-label>
        <div><input type="text" name={{item.SelectedValue}} matInput [required]="item.Mandatory == 1? 'required': null"
            [(ngModel)]="item.SelectedValue" [value]="item.SelectedValue | uppercase"
            placeholder={{item.AttributeNamePlaceHolder}}
            (ngModelChange)="dataChanged($event,selVerificationMethod.SearchTypeId,item.AttributeName)">
        </div>

      </mat-form-field>
    </div>
    <div *ngIf="showCaptcha">
      <re-captcha (resolved)="resolved($event)" (errored)="onError($event)" errorMode="handled" siteKey="{{capKey}}"></re-captcha>
      <br />
    </div>
    <mat-card-actions>
      <button mat-raised-button (click)="verify()" class="mainbutton" [disabled]="!enableVer || !captchGood">
        Next
      </button>




    </mat-card-actions>
  </mat-card>
  <app-enrollment-footer></app-enrollment-footer>
</span>
<!--  End Mobile screen-->