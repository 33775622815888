/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { FormBuilder,FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";

import { ViewChild, AfterViewInit } from '@angular/core';
import { Config } from '../classes/config';
import Swal from 'sweetalert2';
import {Input} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { UtilityService } from 'src/app/services/utility.service';
import { RebateService } from 'src/app/services/rebate.service';
@Component({
  selector: 'app-payee',
  templateUrl: './payee.component.html',
  styleUrls: ['./payee.component.scss']
})
export class PayeeComponent implements OnInit {
  payeeform: FormGroup;
  Payeeoptions:any=[];
  Customerinfo:any=[];
  Payeeinfo:any=[];
  selectedOption:any;
  selectedValue: string;
 payeereadonly:any=false;
 ls_payee_message: any;
 //@Input() WorkorderId: any;
 @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
 @Input() data: any;
 @ViewChild('myButtonRef') myButtonRef: MatButton;
 isFormModified = false;
  isSubmitEnabled = false;

  constructor(private formBuilder: FormBuilder,private spinner: NgxSpinnerService,
    private rebate:RebateService,public utilityService: UtilityService) {
      this.payeeform= this.formBuilder.group({
        Payeeoptions: new FormControl(''),
        customerName:new FormControl('', [Validators.required,]),
          streetNumber: new FormControl('', [Validators.required,Validators.pattern(/^\S(.*\S)?$/)]),
          streetName: new FormControl('', [Validators.required,Validators.pattern(/^\S(.*\S)?$/)]),
          apartment:new FormControl(''),
          zip: new FormControl('', [Validators.required]),
          city: new FormControl('', [Validators.required]),
          state: new FormControl('', [Validators.required]),
      });


    }

    markAllFieldsreset() {

      Object.values(this.payeeform.controls).forEach(control => {
        control.markAsUntouched();
        control.markAsPristine();

       // control.clearValidators();
        //control.setValidators(Validators.required);
        //control.updateValueAndValidity();
      });
     // this.payeeform.updateValueAndValidity();
    }
    resetField(fieldName: string) {
      const formControl = this.payeeform.get(fieldName);
      if (formControl) {

        formControl.setValue('');
        formControl.markAsUntouched();
        formControl.markAsPristine();
        //formControl.clearValidators();
        //formControl.setValidators(Validators.required);
        //formControl.updateValueAndValidity();
      }

    }
    onPayeeChange(event: any) {
    //  console.log(this.selectedOption);
      //console.log(event.value);

      if  (Config.getValue(event.value)=="1"){
        this.payeereadonly=true;
        this.customerinfoupdate();
        this.markAllFieldsreset();
      } else if (Config.getValue(event.value)===Config.getValue(this.selectedOption)) {
        this.markAllFieldsreset();
        this.payeeinfoupdate();
        this.payeereadonly=false;
      } else {
        this.payeereadonly=false;
        this.resetField('customerName');
        this.resetField('streetNumber');
        this.resetField('streetName');
        this.resetField('apartment');
        this.resetField('zip');
        this.resetField('city');
        this.resetField('state');
      }
      this.rebate.setItem('PayeeComponent', false);
      const isButtonDisabled = this.myButtonRef.disabled;
       if (this.payeeform.valid && isButtonDisabled){
         this.rebate.setItem('PayeeComponent', true);
       }
    console.log(this.payeereadonly);

      //console.log(event.value);
    }
    payeeinfoupdate(){
      this.Payeeinfo.forEach(payeeinfo => {

        this.payeeform.patchValue({
          'customerName': payeeinfo.PayeeName.trim() === '' ? null : payeeinfo.PayeeName,
          'streetNumber':payeeinfo.PayeeStreetNumber.trim() === '' ? null : payeeinfo.PayeeStreetNumber,
          'streetName':payeeinfo.PayeeStreeName.trim() === '' ? null : payeeinfo.PayeeStreeName,
          'apartment':payeeinfo.PayeeApartment.trim() === '' ? null : payeeinfo.PayeeApartment,
          'zip':payeeinfo.PayeeZip.trim() === '' ? null : payeeinfo.PayeeZip,
          'city':payeeinfo.PayeeCity.trim() === '' ? null : payeeinfo.PayeeCity,
          'state':payeeinfo.PayeeState.trim() === '' ? null : payeeinfo.PayeeState
        });
    });
    }
  customerinfoupdate(){
    this.Customerinfo.forEach(cuztomerinfo => {

      this.payeeform.patchValue({
        'customerName': cuztomerinfo.CustomerName.trim() === '' ? null : cuztomerinfo.CustomerName,
        'streetNumber':cuztomerinfo.StreetNumber.trim() === '' ? null : cuztomerinfo.StreetNumber,
        'streetName':cuztomerinfo.StreeName.trim() === '' ? null : cuztomerinfo.StreeName,
        'apartment':cuztomerinfo.Apartment.trim() === '' ? null : cuztomerinfo.Apartment,
        'zip':cuztomerinfo.Zip.trim() === '' ? null : cuztomerinfo.Zip,
        'city':cuztomerinfo.City.trim() === '' ? null : cuztomerinfo.City,
        'state':cuztomerinfo.State.trim() === '' ? null : cuztomerinfo.State
      });
  });
  }
    setinitvalues(){
      this.Payeeoptions.forEach(options => {

        if (options.PayeeOpt_selected=="1"){

         console.log(options.PayeeOpt_id);
          this.selectedOption=options.PayeeOpt_id;

          this.payeeform.patchValue({
            'Payeeoptions': options.PayeeOpt_id
          });


        if  (Config.getValue(options.PayeeOpt_id)=="1"){


            this.payeereadonly=true;
        this.customerinfoupdate();

         } else {

         this.payeeinfoupdate();
          this.payeereadonly=false;
        }
      }
      });
      const isButtonDisabled = this.myButtonRef.disabled;
      if (this.payeeform.valid && isButtonDisabled){
        this.rebate.setItem('PayeeComponent', true);
      }
 this.ls_payee_message=this.utilityService.utilitySettings.PayeeInfo
    }
  ngOnInit() {

   // console.log(this.data);

    this.spinner.show();
   this.getpayeeinfo();

   this.payeeform.valueChanges.subscribe(status => {
    this.isSubmitEnabled = this.payeeform.valid && this.isFormModified;
  });


  }
  onFormChange() {
    this.isFormModified = true;
    this.rebate.setItem('PayeeComponent', false);
  }
  private getpayeeinfo(){

    this.rebate.getpayeeinfo(this.data.WorkorderId).subscribe(res => {
      //console.log(res);
     this.Payeeoptions=res.Payee_Options;
     this.Customerinfo=res.Customer_Info;
     this.Payeeinfo=res.Payee_Info;
    //console.log(this.Payeeinfo);
     this.setinitvalues();
     this.spinner.hide();
   },
   err => {
    this.spinner.hide();

   })
  }
  onSave() {


    const formData = this.payeeform.value;
    const jsonData = JSON.stringify(formData);
  //console.log(jsonData);

  this.spinner.show();

      this.rebate.savepayee(jsonData,this.data.WorkorderId).subscribe((res: any) => {
        this.spinner.hide();
      //console.log(res);

        if (!res.Action) {
           Swal.fire({
            customClass:{
              confirmButton: 'swal2-popup-confirm'
             },
            text: res.Message,
             icon:'success',
            confirmButtonText: 'OK',
          });
          this.formGroupDirective.resetForm();
        this.getpayeeinfo();
        this.markAllFieldsreset();
        this.rebate.setItem('PayeeComponent', true);
        this.isFormModified = false;
        }

      },
        err => {
         Swal.fire({
            customClass:{       
              confirmButton: 'swal2-popup-confirm'             
             },
            title: 'Error',
            icon: 'error',
            confirmButtonText: 'OK',
          })
        })

  }
}
