/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilityService } from '../../services/utility.service';
import { CallbackComponent } from '../callback/callback.component';
import { MatDialog } from "@angular/material/dialog";
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';

@Component({
    selector: 'app-customer-lookup',
    templateUrl: './customer-lookup.component.html',
    styleUrls: ['./customer-lookup.component.scss']
})
export class CustomerLookupComponent implements OnInit {

    selVerificationMethod: any = {};
    customerSearchOPtions: any = [];
    selProgramName: any;
    //showSpinner:any = false; 
    model: any = {};
    enableVer = false;
    manCount = 0;
    smallScreen:any;
    enableEnrollCustomer:any;
    enableHelpContent:any;
    isEnableHelpContent:boolean;
    isEnableSearches:boolean;

    showCaptcha = false;
    capKey = environment.captchaKey;
    captchGood = false;

    public href: string = "";
    url: string = "/enroll";
    
    constructor(private router: Router, public utilityService: UtilityService,
        public dataTransfer:DataTransferService, private spinner: NgxSpinnerService,private dialog: MatDialog,private location: Location) { }

    ngOnInit(): void {
        //------removed session storage---------------
        sessionStorage.removeItem('ContractorComponent');
        sessionStorage.removeItem('MeasureComponent');
        sessionStorage.removeItem('OtherComponent');
        sessionStorage.removeItem('PayeeComponent');
        sessionStorage.removeItem('ReceiptsComponent');
        sessionStorage.removeItem('SubmitComponent');
        //-------------End of removed session storage--------
        this.enableEnrollCustomer=this.utilityService.utilitySettings.enableEnrollCustomer;
        this.showCaptcha = this.utilityService.utilitySettings.enableCaptcha;
        if (!this.showCaptcha) {
            // need to make sure verify / next button works if no Captcha
            this.captchGood = true;
        }
        this.dataTransfer.myMethod(false);
        this.selProgramName = history.state.data
        this.getCustomerOptionsData();
        this.smallScreen=screen.width < 600;

        //To replace the URL
        this.href = this.router.url;
        this.location.replaceState(this.url);
        //End

        //To get enable help content
        this.enableHelpContent=this.utilityService.utilitySettings.enableHelpContent;  
        
        if(this.enableHelpContent!=null)  
        {
            this.isEnableHelpContent=true;

        }
        else
        {
            this.isEnableHelpContent=false;
        }

        //To enable search buttons and content
        this.isEnableSearches=this.utilityService.utilitySettings.enableSearches;
        console.log("search", this.isEnableSearches);
       
    }

    callback()
    {
        const dialogRef = this.dialog.open(CallbackComponent,{height:'60%',width:'60%'});
    }

    public resolved(captchaResponse: string): void {
        // console.log(`Resolved captcha with response: ${captchaResponse}`);
        this.captchGood = true;
      }
    
      public onError(errorDetails: RecaptchaErrorParameters): void {
        // console.log(`reCAPTCHA error encountered; details:`, errorDetails);
        this.captchGood = false;
      }

    verify() {
        //console.log('verify id', id)
        // a/c no: 02650990456
        this.enableVer = false;
        //this.showSpinner = true;
        this.spinner.show();
        const data = this.selVerificationMethod.SearchAttributes;
        //console.log('verify data', data)
        const result =  data.reduce(
            (obj, item) => Object.assign(obj, {[item.AttributeName.replace(/\s/g,'')] : item.SelectedValue}), {}
        )
         //console.log('result', result)   
        const body = {
            "SearchType": this.selVerificationMethod.SearchTypeId,
            ...result       
        };

        //console.log('body', body)   

        this.utilityService.getCustomerOptions(body).subscribe(res => {
           //console.log('customer search list', res)
            //this.showSpinner = false;
            this.spinner.hide();
            if(res && res.length > 0 && res.length == 1){
               
                this.model = {}
                this.enableVer = false;
                this.manCount = 0;
                const result = res.length > 0 ? res[0]: {}
                sessionStorage.setItem('getAccountNumber', JSON.stringify(result));
                localStorage.setItem('personcompanyid', result.PersonCompanyId);
                this.dataTransfer.myMethod(true);
                this.router.navigate(['/customerconfirm'], {state: {data: result }});

              } else if ( res && res.length >= 2) {
              
                Swal.fire({
                   
                    icon: 'info',
                    html:'<div style="text-align:left"> '+this.utilityService.utilitySettings.utilMessage2+'</div>',
                    width:'800px',
                    confirmButtonText: 'OK',
                  })
              }
              else
              {
                
                Swal.fire({
                   
                    //text: 'We were unable to locate that account. Please try again or try another search option.',
                    icon: 'info',
                    html:'<div style="text-align:left"> '+res.Message1+' <br>'+res.Message2+'</div>',
                    //html: res.Message1+"<br>"+"<br>"+"<br>"+res.Message2,
                    width:'800px',
                    confirmButtonText: 'OK',
                  })
              }

         }, err => {
             console.log(err);
             //this.showSpinner = false;
             this.spinner.hide();
             Swal.fire({
                text: 'Please try again',
                icon: 'error',
                confirmButtonText: 'OK',
              })
         });
    }

    getCustomerOptionsData() {
        this.spinner.show();
        this.customerSearchOPtions = []
        this.manCount = 0;
        this.utilityService.getCustomerSearchOptions().subscribe(res => {
            //console.log('customer options list', res)
            this.spinner.hide();
            this.customerSearchOPtions = res.SearchTypes || [];
 
            const temp = this.customerSearchOPtions.length > 0 ? this.customerSearchOPtions[0] : {};
            temp.SearchAttributes.map(element => {
                element['SelectedValue']='';
                if(element.Mandatory) {
                    this.manCount++;
                }
            });
            this.selVerificationMethod = temp;
            //console.log(' first this.selVerificationMethod api',  this.selVerificationMethod )
         }, err => {
            this.spinner.hide();
             console.log(err);
         });
     }

    // getCustomerList() {
    //     this.utilityService.getCustomerOptions().subscribe(res => {
    //         //console.log('customer search list', res)
    //      }, err => {
    //          //console.log(err);
    //      });
    //  }

     onItemChange(item: any) {
        this.selVerificationMethod = {}
        this.model = {}
        this.manCount = 0;
        this.enableVer = false;
        const temp = item.SearchAttributes || [];    
        item.SearchAttributes.forEach(element => {
            element['SelectedValue']='';
            if(element.Mandatory) {
                this.manCount++;
            }
            //console.log(' this.selVerificationMethod element', element)
        });
        this.selVerificationMethod = item;
        //console.log('this.selVerificationMethod.SearchTypeDesc', this.selVerificationMethod.SearchTypeDesc)
        //console.log(' this.selVerificationMethod ', this.selVerificationMethod)
     }

     dataChanged($event, typeId, attr) {
        console.log('$event', $event)
        console.log('typeId', typeId)
        console.log('attr', attr)

        if($event) {
            //if(this.model.hasOwnProperty(attr))
            this.selVerificationMethod.SearchAttributes.forEach(element => {
                if(element.AttributeName ==  attr && element.Mandatory) {
                    this.model[attr] = $event
                }
            });
        } else {
            delete this.model[attr];
        }

        if( this.manCount == Object.keys(this.model).length){
            this.enableVer = true
        }else {
            this.enableVer = false
        }
      }




}
