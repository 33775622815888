/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

export class Config {
    static settings = {
        OWNER: '1',
        LANDLORD: '2',
        TENANT: '3',
        OTHER:'4'
    };
    
    static getValue(key: string) {
        return Config.settings[key];
    }
}