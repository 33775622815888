/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, Validator } from "@angular/forms";

@Directive({
    selector: '[appConfirmEqualValidator]',
    providers:[{
    provide : NG_VALIDATORS,
    useExisting : ConfirmEqualValidatorDirective,
    multi: true

    }]

})
export class ConfirmEqualValidatorDirective implements Validator{
    @Input() appConfirmEqualValidator :string;
    validate(control: AbstractControl): {[key:string]: any} | null {
    const controlToCompare = control.parent.get(this.appConfirmEqualValidator);
    if (controlToCompare && controlToCompare.value !== control.value){
        return {'notEqual':true};
    }
    return null;

    }

}