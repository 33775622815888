/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from 'src/app/services/utility.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-enrollment-success',
  templateUrl: './enrollment-success.component.html',
  styleUrls: ['./enrollment-success.component.scss']
})
export class EnrollmentSuccessComponent implements OnInit {

  constructor(private router: Router, public utilityService: UtilityService,private spinner:NgxSpinnerService,private dialogRef: MatDialogRef<EnrollmentSuccessComponent>) { }
  isShowButton: boolean=false;
  isShowReportButton: boolean=false;
  rebateGroup:any;
  confirmData:any={}

  ngOnInit(): void {
    this.rebateGroup = localStorage.getItem('rebategroup');
                
    //To hide/un-hide based on Self-Schedule
    if(this.utilityService.utilitySettings.SelfScheduleEnablement=="1")
    {
      this.isShowButton=false;
    }
    else
    {
      this.isShowButton=true;
    }
   //To hide/un-hide based on report enablement
    if(this.utilityService.utilitySettings.ReportEnablement=="1")
    {
      this.isShowReportButton=false;
    }
   else
    {
      this.isShowReportButton=true;
    }
  }

  routeToCustomerLookup(){
    this.dialogRef.close(); 
    this.router.navigate(['/customerlookup']);

  }
  confirmed() {
    this.router.navigate(['/scheduling'],{state:{data:this.confirmData}});
  }

  navigatetoReport()
  {
    this.dialogRef.close(); 
    this.router.navigate(['/auditreport'], {state:{data:this.confirmData}});
  }

}
