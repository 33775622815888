


<mat-card class="mat-elevation-z0">
  <div style= "padding:0px" [innerHTML]=utilityService.utilitySettings.enrollSuccessIntro></div>
    <br>
    <div [innerHTML]=utilityService.utilitySettings.enrollSuccessDesc></div>

    <mat-card-actions>
    
  </mat-card-actions>
  </mat-card>
  <mat-card class="mat-elevation-z0">
    
      <mat-card-actions>
      <div style="padding:0px;text-align: center;">
        <button *ngIf="isShowReportButton" mat-raised-button class="mainbutton" (click)="routeToCustomerLookup()">DONE</button>
        <button *ngIf="!isShowButton" mat-raised-button class="mainbutton" (click)="confirmed()">Schedule Appointment</button> 
        <button *ngIf="!isShowReportButton" mat-raised-button class="mainbutton" (click)="navigatetoReport()">View Report</button>    
      </div>
    </mat-card-actions>
    </mat-card>