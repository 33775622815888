<!-- Normal screen START-->
<span *ngIf="!smallScreen">
    <div class="close">
        <button mat-icon-button class="mainbutton" [mat-dialog-close]="true">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <br>
    
    <form #loginForm="ngForm">
        
            <div><b> Enter details below:</b></div><br>
        
        <table>
            
            <tr>
              <td>
                  <mat-form-field appearance="outline" style="width:230px !important" >
                      <mat-label>Name</mat-label>
                      <input type="text"  placeholder="Please enter first&last name" maxlength="20" name="name" id="name" [(ngModel)]="name" matInput  required>
                  </mat-form-field>
              </td>
              <td>
                <mat-form-field appearance="outline" style="width:230px !important" >
                    <mat-label> Phone Number</mat-label>
                    <input type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"   placeholder="Please enter phone number"  name="phonenumber"  minlength="10" maxlength="10" id="phonenumber" [(ngModel)]="phonenumber"  #primaryPhone="ngModel" matInput  required>
                </mat-form-field>
                <div *ngIf="primaryPhone.errors && (primaryPhone.invalid || primaryPhone.touched)">
                    <small class="text-danger" *ngIf="primaryPhone.touched" style="font-size: 10px; color: red">Please enter 10 digit phone number</small>
                     </div>
              </td>
              </tr>
              <tr>
                <td colspan=2>
                    <mat-form-field appearance="outline" [color]="primary" style="width:100%;font-size: 0.9vw;">
                        <mat-label>Remarks/Comments</mat-label>
                        <textarea appearance="outline" id="remarks" [ngModelOptions]="{standalone: true}" [color]="primary"  rows="3" cols="30" maxlength="500" placeholder="Please enter remarks/comments" matInput [(ngModel)]="remarks"></textarea>
                    </mat-form-field>
                </td>
              </tr>
        </table>
    
        <mat-card-actions>    
            <button mat-raised-button  (click)="callbackNumber()"  class="mainbutton" [disabled]="loginForm.invalid">Submit</button>
        </mat-card-actions>
    
    </form>
    </span>
    <!--  End Normal screen-->
    
    
    <!-- Mobile screen START-->
    <span *ngIf="smallScreen">
        <div class="close">
            <button mat-icon-button class="mainbutton" [mat-dialog-close]="true">
              <mat-icon>close</mat-icon>
            </button>
          </div>
          <br><br>
          
        <form #loginForm="ngForm">
            
            <div style=" font-size:12px;text-align: center;"><b> Enter details below:</b></div>
            <div style="align-items: center;">
            <table>
                
                <tr>
                  <td>
                      <mat-form-field appearance="outline" style="width:200px !important" >
                          <mat-label>Name</mat-label>
                          <input type="text"  placeholder="Please enter first&last name" maxlength="20" name="name" id="name" [(ngModel)]="name" matInput  required>
                      </mat-form-field>
                  </td>
                  <tr>
                  <td>
                    <mat-form-field appearance="outline" style="width:200px !important" >
                        <mat-label>Phone Number</mat-label>
                        <input type="text" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" placeholder="Please enter phone number"  name="phonenumber" minlength="10" maxlength="10" id="phonenumber" [(ngModel)]="phonenumber"  #primaryPhone="ngModel" matInput  required>
                    </mat-form-field>
                    <div *ngIf="primaryPhone.errors && (primaryPhone.invalid || primaryPhone.touched)">
                        <small class="text-danger" *ngIf="primaryPhone.touched" style="font-size: 10px; color: red">Please enter 10 digit phone number</small>
                         </div>
                  </td>
                  </tr> 
                  <tr>
                    <td colspan=2>
                        <mat-form-field appearance="outline" [color]="primary" style="width:100%;font-size: 3.5vw;">
                            <mat-label>Remarks/Comments</mat-label>
                            <textarea appearance="outline" id="remarks" [ngModelOptions]="{standalone: true}" [color]="primary"  rows="5" cols="30" maxlength="500" placeholder="Please enter remarks/comments" matInput [(ngModel)]="remarks"></textarea>
                        </mat-form-field>
                    </td>
                  </tr>
            </table>
            </div>
            <div style="text-align: center;">
            <mat-card-actions>    
                <button  mat-raised-button  (click)="callbackNumber()"  class="mainbutton" [disabled]="loginForm.invalid">Submit</button>
            </mat-card-actions>
            </div>
        </form>
        
        </span>
        <!--  End Mobile screen-->