/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import { Router } from '@angular/router';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilityService } from '../../services/utility.service';
import { MatDialog } from "@angular/material/dialog";
import { AlertDialogComponent } from 'src/app/util/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-audit-report',
  templateUrl: './audit-report.component.html',
  styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements OnInit {

  @ViewChild('htmlData') htmlData!: ElementRef;

  constructor(private router: Router, public utilityService: UtilityService,
    public dataTransfer:DataTransferService, private spinner: NgxSpinnerService,
    private dialog: MatDialog) {  }

  reportData:any;
  body: any;
  WorkorderId:any;
  smallScreen= screen.width < 600; 
  isMobile=screen.width < 600; 
  isModeChange=false;
  reportLogo;
  homeLogo;
  isImageloaded=0;

  ngOnInit(): void {
  
    this.WorkorderId = localStorage.getItem('WorkorderId');    
   //this.reportLogo='assets/ClearEnergy/cce Logo.png';// ../../../assets/ClearEnergy/cce Logo.png'this.utilityService.utilitySettings.ReportLogo;
   //this.homeLogo= '../../../assets/ClearEnergy/HomeImage.jpg';//this.utilityService.utilitySettings.HomeImage;
   this.reportLogo=this.utilityService.utilitySettings.ReportLogo;//this.utilityService.utilitySettings.ReportLogo;
   this.homeLogo= this.utilityService.utilitySettings.HomeImage;//this.utilityService.utilitySettings.HomeImage;
    console.log('homeLogo', this.homeLogo);
    console.log('reportLogo', this.reportLogo);
    
  this.getData();

  let reportData2={
    "officeid":"CLR1"
    ,"REPORTNAME":{
    "Header":"REPORTNAME"
    ,"Value":"VIRTUAL HOME ENERGY AUDIT REPORT"
    }
    ,"EMAIL":{
    "Header":"EMAIL"
    ,"Value":"santhosh.d2@honeywell.com"
    }
    ,"TOP3RECOMMENDATIONS":{
    "Header":"TOP 3 RECOMMENDATIONS"
    ,"Value":[
    {
    "Measure":"Install Smart Devices For Better Control"
    ,"Sequence":1
    }
    ,{
    "Measure":"Replace Electric DHW With Heat Pump Water Heater"
    ,"Sequence":2
    }
    ,{
    "Measure":"Removal Of Additional Refrigerator Or Freezer"
    ,"Sequence":3
    }
    ]
    }
    ,"REPORTFOOTERINFO":{
    "Header":"REPORTFOOTERINFO"
    ,"Value":"FOR MORE INFORMATION OR QUESTIONS REGARIDNG YOU ENERGY AUDIT CALL: 123-456-7890 OR EMAIL US AT virtulaudit@cleanenergy.com"
    }
    ,"Introduction":{
    "Header":"INTRODUCTORY MESSAGE"
    ,"Value":"Thank you for taking the time to conduct a virtual energy assessment of your home. Clean Choice Energy wants to help you take better control of using your energy wisely and offers the following information and recommendations based on the information you provided.   We encourage you to review these recommendations and come up with the plan that best suits your needs."
    }
    ,"Explanation":{
    "Header":"EXPLANATION AND DIRECTIONS ON AUDIT RESULT CONTENT, USE AND PRESENTATION"
    ,"Value":"This report is broken out into 4 sections. The first are directly below and highlight the top 3 recommendations based on the cost\/savings value to you.   On the following pages, you will find the measures you can do quickly and easily at low or no cost, followed by the measures that would require more significant investments but are still cost effective to complete.   At the end are additional general recommended actions or behaviors that will help you save even more.    Please review and consider what fits your needs.    We are here to help you with your journey to take control of your energy use."
    }
    ,"LowCostMeasuresText":{
    "Header":"RECOMMENDED LOW COST MEASURES"
    ,"Value":"Listed here are the lowest cost recommended measures that our assessment found were cost effective to install.\u003Cbr\u003E\u003Cbr\u003EThese are measures that you can most likely do yourself in very little time that can have an immediate impact on your bill.   Each measure shows the range of the minimum and maximum savings you can expect to obtain from each measure.  The range in savings is based on how often you use that item currently and in the future. (note savings shown is per unit \u2013 i.e per bulb or per showerhead replaced )  Click on the icon if you want to see more information on each measure."
    }
    ,"MajorMeasuresText":{
    "Header":"RECOMMENDED MAJOR MEASURES"
    ,"Value":"Listed here are the major cost effective energy saving measures that require a more substantial investment in time and\/or money.\u003Cbr\u003E\u003Cbr\u003EMany of these measures may require a professional to install.  Each measure shows the range of the minimum and maximum savings you can expect to obtain from each measure.  The range in savings is based on how often you use that item, both currently and in the future. (note savings shown is either per unit or home as defined depending on the measure)  Click on the icon if you want to see more information on each measure or to review any considerations for installation."
    }
    ,"Summary":{
    "Header":"SUMMARY OF HOW TO USE THE REPORT AND RECOMMENDATIONS"
    ,"Value":"Clean Choice Energy has provided the information above based on the information you provided in the virtual audit tool. After you have reviewed the recommendations and information contained in this report, use these recommendations to build an action plan for your home. You don\u2019t have to tackle them all at once. Pick the measures you can do first and work your way through the list as you watch the savings pile up, or schedule an in home energy audit by a trained professional for more details. Remember, Clean Choice Energy is here to help. Call the number on this report if you have questions or need more information."
    }
    ,"TermsAndConditions":{
    "Header":"General Terms and Conditions"
    ,"Value":[
    {
    "Header":"Eligibility","Value":"The Virtual Audit Program is valid for current residential retail electricity customers of CleanChoice Energy"
    }
    ,{
    "Header":"Audit Providers"
    ,"Value":" Auditor providers are not employees of CleanChoice Energy."
    }
    ,{
    "Header":"Document Retention"
    ,"Value":" Customers are advised to retain a copy of this form and any other documents provided to you by the program. CleanChoice Energy will not be responsible for lost documents pertaining to the program. Details of this program are subject to change or cancellation without prior notice."
    }
    ,{
    "Header":"Safety and Building Codes"
    ,"Value":"The customer will verify that the recommended products meet all applicable building and zoning codes, including local, state, and federal laws and regulations, and other relevant requirements as installed."
    }
    ,{
    "Header":"Property Rights"
    ,"Value":"Customer certifies that they have the right to permit installation of the energy-saving products detailed in the Energy-Savings Report, at the property identified above."
    }
    ,{
    "Header":"Renter\u0027s Certification"
    ,"Value":"As a renter, Customer hereby certifies that he\/she has received consent from the landlord or homeowner for the receipt of the virtual audit and associated direct installation of products."
    }
    ,{
    "Header":"Endorsement"
    ,"Value":"CleanChoice Energy does not endorse any particular manufacturer, product, system design or contractor as part of this program."
    }
    ,{
    "Header":"Information Release"
    ,"Value":"Customer agrees that CleanChoice Energy may include customer\u2019s name, products installed, energy usage, and services provided and share energy-savings reports and\/or documentation submitted to the ruling regulatory body or its agents or other agents or consultants as required working on behalf of CleanChoice Energy. CleanChoice Energy will treat all other information gathered in evaluations as confidential and report it only in the aggregate."
    }
    ,{
    "Header":"Limitation of Liability"
    ,"Value":"CleanChoice Energy assumes no responsibility for the performance of any software installed (per the recommendation provided in your report), the quality of the work, labor and\/or materials supplied, and\/or the acts or omissions of the participating contractor and\/or auditor. The customer agrees to indemnify, defend and hold harmless CleanChoice Energy from any and all liability associated with this project. CleanChoice Energy is not liable for any direct, consequential or incidental damages, or for any damages connected in any way with, or resulting from participation in this program. CleanChoice Energy reserves the right to not deliver program services and products if the form is not filled out completely and accurately."
    }
    ,{
    "Header":"Warranties"
    ,"Value":"CleanChoice Energy does not warrant the recommendations or results of the virtual audit product. CleanChoice Energy makes no warranties or representations of any kind, whether statutory, express, or implied, including, without limitations, warranties of merchantability or fitness for a particular purpose regarding measures outlined in the report. CleanChoice Energy makes no guarantee of energy savings results by receiving a virtual audit and subsequent installation of energy-saving products."
    }
    ,{
    "Header":"Etc"
    ,"Value":"By participating in this CleanChoice Energy energy efficiency program, customers agree their electric utility will maintain ownership of all Capacity Rights from electric savings measures, which refers to the demand reduction associated with any energy efficiency and peak demand reduction measure for which incentives were provided by CleanChoice Energy. Your electric utility will aggregate these energy efficiency demand reduction attributes into the appropriate capacity market as appropriate with proceeds being used to reduce customer\u0027s costs for the programs."
    }
    ]
    }
    ,"CUSTOMER":{
    "Header":"Customer"
    ,"Value":[
    {
    "Header":"CUSTOMER NAME"
    ,"Value":"TEST945 TEST945"
    }
    ,{
    "Header":"CUSTOMER ADDRESS"
    ,"Value":"945 GRAYSTONE RD WHITE HALL, MD 21161"
    }
    ,{
    "Header":"CUSTOMER ACCOUNT NO#"
    ,"Value":"900147"
    }
    ]
    }
    ,"TABLES":[
    {
    "TableName1":"Recommended Electric Low Cost Measures"
    ,"TableHeaderInfo1":{
    "Header1":"Measure"
    ,"Header2":"More Info?"
    ,"Header3":"Avg savings per year"
    }
    ,"MEASUREINFO":[
    {
    "MEASURE":"LED Bulbs - Indoor and Outdoor"
    ,"ICON":"Replacing your existing bulbs with LED bulbs is easy and quick to do (just unscrew the old bulb and screw in th new one) and can save $1 - 6 dollars per year for each bulb you switch out depending on howmuch you use the light and how high the wattage of the bulb being replaced."
    ,"MINRANGE":0.33
    ,"MAXRANGE":4.18
    }
    ,{
    "MEASURE":"Energy Efficient Managed Flow Kitchen Dual Spray Aerator"
    ,"ICON":"Aerators control how much water flows out of the kitchen sink per minute. Replacing your old one with a new one with a rated GPM of 1.5 will reduce hot water costs. A dual sparay will also give you a wide spray for cleaning dishes. Buy at any big box or hardware store. Simply unscrew the old faucet tip and screw in the new one."
    ,"MINRANGE":2.31
    ,"MAXRANGE":9.35
    }
    ,{
    "MEASURE":"Energy Efficient Managed Flow Bath Faucet Aerator"
    ,"ICON":"Aerators control how much water flows out of the sink per minute. Replacing your old one with a new one with a rated GPM of 1.5 or less will reduce hot water costs. Buy at any big box or hardware store. Simply unscrew the old faucet tip and screw in the new one. "
    ,"MINRANGE":7.48
    ,"MAXRANGE":17.71
    }
    ,{
    "MEASURE":"Energy Efficient Managed Flow Showerhead"
    ,"ICON":"Replacing your old showerhead with a new one with a rated GPM of 2.0 or less will reduce hot water costs and still provide a soothing shower. You can buy one at any big box or hardware store and there are many models to find the type of shower spray you prefer. Simply unscrew the old showerhead and screw in the new one in."
    ,"MINRANGE":15.84
    ,"MAXRANGE":71.17
    }
    ,{
    "MEASURE":"Shower Starter Thermostatic Control"
    ,"ICON":"A thermostatic control cuts off water flows as soon as the shower reaches hot temperature. Installing one onto your shower will reduce hot water costs. Buy at any big box or hardware store. Simply unscrew the showerhead tip and screw in the hermostatic control before rescrewing the showerhead on."
    ,"MINRANGE":3.52
    ,"MAXRANGE":11.88
    }
    ,{
    "MEASURE":"Hot Water Tank Temperature Setback"
    ,"ICON":"You can save a significant percentage of your water heating costs by reducing the temperature setting on your hot water tank at least 5 - 10 degree. For electric tanks, remove the upper and lower plates and dial back the temperature setting with a screwdriver."
    ,"MINRANGE":5.94
    ,"MAXRANGE":11.99
    }
    ,{
    "MEASURE":"Domestic Hot Water Pipe Insulation"
    ,"ICON":"The pipes going to and from your hot water heater lose a lot of heat costing you money. You can buy easy to install foam insulation for the pipes entering and leaving your hot water tank at any big box or hardware store. Measure the length of pipe to be covered, cut the lengths to the right size, slip the foam over the pipe and secure with electrical tape."
    ,"MINRANGE":1.87
    ,"MAXRANGE":3.19
    }
    ,{
    "MEASURE":"Water Heater Tank Wrap"
    ,"ICON":"If your water heater tank does not have a sticker indicating it is sufficiently insulated, you can save money on your water heating costs by covering your water tank with an insulated blanket that you can buy at any big box or hardware store. If your heater is gas, make sure to keep the flame and flue areas clear of insulation."
    ,"MINRANGE":11.06
    ,"MAXRANGE":30.01
    }
    ,{
    "MEASURE":"Tier 2 Commuincating Smart Power Strip"
    ,"ICON":"Intelligent control devices built inside certain Power strips that you can buy online or hardware store remotely or automatically control a range of energy consuming devices from small appliances to electronics. Consider doing an inventory of the devices in your home that use energy and purchase smart plugs devices that will reduce uneeded on or run time of those devices through smart technology."
    ,"MINRANGE":43.89
    ,"MAXRANGE":51.81
    }
    ,{
    "MEASURE":"Removal Of Additional Refrigerator Or Freezer"
    ,"ICON":"That second (or third) refrigerator in your garage or basement is using a lot of excess energy that is costing you money.  Consider unplugging or even removing the extra refrigerator or freezer if it is not full and absolutely necessary for your family."
    ,"MINRANGE":75.57
    ,"MAXRANGE":120.89
    }
    ,{
    "MEASURE":"Dehumidifier With More Energy Efficient Model"
    ,"ICON":"Older dehumidifiers use much more energy than new efficient models.  Consider buying a new energy efficient model and discontinuing use of the old unit. The cost of a new efficient model will pay for the cost of purchase quickly in reduced energy bills."
    ,"MINRANGE":9.35
    ,"MAXRANGE":11.99
    }
    ,{
    "MEASURE":"Install Smart Devices For Better Control"
    ,"ICON":"Intelligent control devices that you can buy online or hardware store that help to remotely or automatically control a range of energy consuming devices from lights to electronics to thermostats and more.Consider doing an inventory of the devices in your home that use energy and research smart control devices that will reduce uneeded on or run time of those devices through smart technology."
    ,"MINRANGE":0.52
    ,"MAXRANGE":270.16
    }
    ,{
    "MEASURE":"Total Potential Savings"
    ,"ICON":null
    ,"MINRANGE":177.68
    ,"MAXRANGE":614.33
    }
    ]
    }
    ,{
    "TableName2":"Recommended Non Electric Low Cost Measures"
    ,"TableHeaderInfo2":{
    "Header1":"Measure"
    ,"Header2":"More Info?"
    ,"Header3":"Avg savings per year"
    }
    ,"MEASUREINFO":[
    {
    "MEASURE":"Total Potential Savings"
    ,"ICON":null
    ,"MINRANGE":0
    ,"MAXRANGE":0
    }
    ]
    }
    ,{
    "TableName3":"Recommended Major Electric Measures"
    ,"TableHeaderInfo3":{
    "Header1":"Measure"
    ,"Header2":"More Info?"
    ,"Header3":"Avg savings per year"
    }
    ,"MEASUREINFO":[
    {
    "MEASURE":"Replace Central AC With Higher EER Unit"
    ,"ICON":"In most homes, your central air conditioning (AC) system is the largest single electric energy user. Newer AC systems are now available that are more efficient and use far less energy to run. Replacing your existing AC system with a more energy efficient unit will reduce the amount of energy needed to help cool your home, significantly reducing your cooling costs."
    ,"MINRANGE":25.96
    ,"MAXRANGE":112.09
    }
    ,{
    "MEASURE":"Replace Electric DHW With Heat Pump Water Heater"
    ,"ICON":"Newer water heater systems are now available that use heat pump tehcnology that are more efficient and use far less energy to run than electric resistance tanks. Replacing your existing electric resistance hot water tank with a more energy efficient heat pump unit will significantly reduce the amount of energy needed to help heat your water reducing your electricity costs."
    ,"MINRANGE":133.87
    ,"MAXRANGE":179.85
    }
    ,{
    "MEASURE":"Total Potential Savings"
    ,"ICON":null
    ,"MINRANGE":159.83
    ,"MAXRANGE":291.94
    }
    ]
    }
    ,{
    "TableName4":"Recommended Major Non Electric Measures"
    ,"TableHeaderInfo4":{
    "Header1":"Measure"
    ,"Header2":"More Info?"
    ,"Header3":"Avg savings per year"
    }
    ,"MEASUREINFO":[
    {
    "MEASURE":"Total Potential Savings"
    ,"ICON":null
    ,"MINRANGE":0
    ,"MAXRANGE":0
    }
    ]
    }
    ]
    ,"GeneralRecommendations":{
    "Header":"General Recommendations"
    ,"HeaderText":"In addition to the specific recommendations offered above, based on your feedback to the questions provided, Clean Choice Energy offers the following general energy saving actions and recommendations that can provide you additional savings at little or no cost.    Many of these are easy and quick to complete and most cost little or no money.    While not all may apply to your home, consider acting on those that do as they will pay immediate dividends."
    ,"VALUE":[
    {
    "Sequence":1
    ,"Recommendation":"Keep all unneeded lights off durung summer - Lighting adds extra heat to a space that must be cooled by air conditioning."
    }
    ,{
    "Sequence":2
    ,"Recommendation":"Set the air-conditioner thermostat at 78 degrees or higher - reduce your cooling costs by 1 percent to 3 percent for each degree the air conditioner is raised."
    }
    ,{
    "Sequence":3
    ,"Recommendation":"Protect your air conditioning compressor (outside unit) with shade - it will work more efficiently."
    }
    ,{
    "Sequence":4
    ,"Recommendation":"Allow enough room around the air conditioning compressor for free air flow for most efficient operation."
    }
    ,{
    "Sequence":5
    ,"Recommendation":"Major appliances add extra heat to your home and make your air conditioner work harder. Operate them in the morning or evening when it is cooler outside."
    }
    ,{
    "Sequence":6
    ,"Recommendation":"Repair leaks in pipes and faucets - One drop per second can lose up to 165 gallons a month that you are paying to heat."
    }
    ,{
    "Sequence":7
    ,"Recommendation":"Shut off the water flow when not using it during showering or shaving to reduce hot water use."
    }
    ,{
    "Sequence":8
    ,"Recommendation":"Take a shower instead of a bath and you\u0027ll use up to 60% less hot water."
    }
    ,{
    "Sequence":9
    ,"Recommendation":"Turn your electric water heater off or to the \"vacation\" mode when you are away from home for more than two days."
    }
    ,{
    "Sequence":10
    ,"Recommendation":"Use mastic to seal your ducts, covering large gaps (more than 1\/4inch) with butyl tape, foil tape, or other heat-approved tapes. Avoid cloth-backed, rubber adhesive duct tape, which tends to fail quickly."
    }
    ,{
    "Sequence":11
    ,"Recommendation":"Be sure a well-sealed vapor barrier exists on the outside of the insulation on cooling ducts to prevent moisture buildup."
    }
    ,{
    "Sequence":12
    ,"Recommendation":"Caulk and weather strip your doors and windows to prevent heated or cooled air from escaping."
    }
    ,{
    "Sequence":13
    ,"Recommendation":"Install ready-made foam insulating gaskets behind outlet and switch plates to reduce air leaks."
    }
    ,{
    "Sequence":14
    ,"Recommendation":"Look for dirty spots in your insulation, indicating holes where air leaks that can be sealed with caulk or spray foam."
    }
    ,{
    "Sequence":15
    ,"Recommendation":"Look for dirty spots on your ceiling paint and carpet, which may indicate air leaks at wall\/ceiling or wall\/floor joists that can be sealed."
    }
    ,{
    "Sequence":16
    ,"Recommendation":"Place kitchen exhaust fan covers, (with magnets for easy replacement) when the fan is not in use to stop air  leaks."
    }
    ,{
    "Sequence":17
    ,"Recommendation":"Replace existing door bottoms and thresholds with ones that have pliable sealing gaskets to eliminate conditioned air leaking out from underneath the doors."
    }
    ,{
    "Sequence":18
    ,"Recommendation":"Seal air leaks where plumbing, ducts or electrical wires penetrate the exterior walls, floors and ceilings with caulk or foam."
    }
    ,{
    "Sequence":19
    ,"Recommendation":"Use foam sealant around larger gaps around windows, baseboards, and other places where warm air may be leaking out."
    }
    ,{
    "Sequence":20
    ,"Recommendation":"Add an extra blanket for warmth and turn down the furnace while sleeping."
    }
    ,{
    "Sequence":21
    ,"Recommendation":"Consider using a portable heater to keep warm if you are only using one room."
    }
    ,{
    "Sequence":22
    ,"Recommendation":"Set the thermostat at 68 degrees or lower for heating, health permitting. Save 1 percent to 3 percent of your heating costs for each degree the temperature is turned down in winter."
    }
    ,{
    "Sequence":23
    ,"Recommendation":"Bleed air from hot water radiators a couple of times each season to make them work more efficiently."
    }
    ,{
    "Sequence":24
    ,"Recommendation":"Place heat resistant radiator reflectors between the exterior walls and the radiators to direct heat away from exterior walls."
    }
    ,{
    "Sequence":25
    ,"Recommendation":"Adjust the thermostat in small degree changes only to the temperature you want - your home won\u0027t heat or cool faster by cranking it up."
    }
    ,{
    "Sequence":26
    ,"Recommendation":"Clean radiator fins or duct registers regularly to allow free air flow."
    }
    ,{
    "Sequence":27
    ,"Recommendation":"Keep furniture and drapes away from the HVAC air supply and return air registers or radiators to increase the energy efficiency of your system."
    }
    ,{
    "Sequence":28
    ,"Recommendation":"Turn heat down or AC temperature up when you leave for extended periods of time (more than 2 hours) - The energy saved is more than the energy required to return temperature to normal."
    }
    ,{
    "Sequence":29
    ,"Recommendation":"Use ventilating fans in your kitchen, bath and utility area only when nessesary. In just one hour of use, some can pull out a houseful of heated or cooled air."
    }
    ,{
    "Sequence":30
    ,"Recommendation":"Clean or replace furnace and air-conditioner filters regularly, following manufacturers instructions to improve air flow and reduce drag on fan motor."
    }
    ,{
    "Sequence":31
    ,"Recommendation":"Installing a programmable thermostat that will automatically adjust the temperature according to your schedule will pay for itself in less than a year if you use setbacks."
    }
    ,{
    "Sequence":32
    ,"Recommendation":"Ceiling fans are more energy efficient at circulating the air than turning on the main furnace fan, but turn them off when you leave the room."
    }
    ,{
    "Sequence":33
    ,"Recommendation":"Closing the doors and shutting the vents in the rooms you do not use reduces the heating or cooling load on the system reducing costs."
    }
    ,{
    "Sequence":34
    ,"Recommendation":"Regular maintenance checks by a trained professional maintain the operational efficiency to manufacturers specs."
    }
    ,{
    "Sequence":35
    ,"Recommendation":"Make sure fan setting on furnace and air conditioner are set to \"automatic\" to only run when air is being conditioned."
    }
    ,{
    "Sequence":36
    ,"Recommendation":"Close all the window blinds and drapes at night to reduce heat loss through the windows."
    }
    ,{
    "Sequence":37
    ,"Recommendation":"Consider protective overhangs on the west walls to keep home cooler in the summer."
    }
    ,{
    "Sequence":38
    ,"Recommendation":"During daylight hours in the winter, open all the drapes and blinds on south-facing windows to gain maximum heat through the windows."
    }
    ,{
    "Sequence":39
    ,"Recommendation":"During the hot part of the day in the summer, close the drapes and blinds on the windows where the sun \"beats in\" to minimize the heat gain."
    }
    ,{
    "Sequence":40
    ,"Recommendation":"Keep evergreen plants and trees trimmed away from south-facing windows to get the maximum solar heating effect in winter."
    }
    ,{
    "Sequence":41
    ,"Recommendation":"Keep windows on the south side of your house clean to let in the maximum heat from winter sun."
    }
    ,{
    "Sequence":42
    ,"Recommendation":"Keep your doors and windows in good repair. Replace seals when worn or no longer tight."
    }
    ,{
    "Sequence":43
    ,"Recommendation":"Periodically check windows in heating and cooling seasons to make sure the are closed tightly - windows often loosen over time."
    }
    ,{
    "Sequence":44
    ,"Recommendation":"Plant deciduous plants and trees that shade south-facing windows in the summmer to reduce solar heat gain in summer."
    }
    ,{
    "Sequence":45
    ,"Recommendation":"Reflective window film can help reduce heat gain during the summer and it will protect your furniture and carpet."
    }
    ]
    }
   }
    
      
  }
  ngAfterViewInit() {
   // this.smallScreen=true;
   this.isImageloaded=1;
   console.log(1)
  
 
  }

  imageLoad(){
    console.log(2)
    if(this.isModeChange)
       this.openPDF();
  }

   getData(): void{
   this.spinner.show();  
    this.utilityService.getVirtualAuditWOInfo(this.WorkorderId).subscribe((res:any)=>{
      console.log(res);
      this.reportData=res;    
     // this.openPDF();
      this.spinner.hide();
    });
  }

  Mobile():void{
   this.spinner.show();
   this.isModeChange=true;
   if(this.isMobile)
      this.smallScreen=false;
   //this.openPDF();  
   this.spinner.hide();
  }
 
  public openPDF(): void { 
    console.log(3)   
    if(this.smallScreen)
      return;

    this.spinner.show();

    let DATA: any = document.getElementById('htmlData');
    var x=this.utilityService;
    var y=this.reportData.OfficeId;
    console.log("openPDF OfficeId:",y);
    var z=this.WorkorderId;
    var customerName=this.reportData.CUSTOMER.Value[0].Value;
    var email=this.reportData.EMAIL.Value;
    var spinner= this.spinner;
    var isMobile= this.isMobile;
    var smallScreen=this.smallScreen;

    console.log('******')

    html2canvas(DATA,{useCORS: true}).then((canvas) => {  
      console.log(canvas);
        var imgData = canvas.toDataURL('image/png');
       var imgWidth = 210; 
       var pageHeight = 295; 
       
        //var imgHeight = canvas.height * imgWidth / canvas.width;
        var imgHeight =2368.0263157894738;    
        var heightLeft = imgHeight;    

        //Final setting 
        var doc = new jsPDF('p', 'mm','a4',true);
        var position = 0;
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,'','FAST');
      
        heightLeft -= pageHeight;
        var pagecnt=1;

        while (heightLeft >= 100 ) {   
          position = heightLeft - imgHeight;
          if(pagecnt <8) {
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight,'','FAST');   
          }
          heightLeft -= pageHeight;
          pagecnt++;     
        }
        doc.save( 'Virtual Audit Report.pdf');  

        var blob = doc.output('blob');
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function() {
          let base64data = reader.result.toString();
          console.log(base64data);
          let body={
              officeId:y,
              email:email,
              name: customerName,
              zip:false,
              data: base64data.split(',')[1]
          } 
            x.sendVirtualAuditWOInfo(body, z).subscribe((res: any) => {
                    console.log("API response,:" , res);  
                    if(isMobile)
                      smallScreen=true;
                    spinner.hide();   
                           
            });   
            return;
        }
        //Temp hide  
       this.spinner.hide();
        
        // Reset the changes
        if(this.isMobile)
           this.smallScreen=true;
       
    });

  }

  openAlertDialog(imagearr, message1) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: {
        message: message1,
        image1: imagearr,
        buttonText: {
          cancel: 'Close',
        },
        position: {
          top: '0px',
          left: '0px'
        }
      },
    });
  }
  
}
