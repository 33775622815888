/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UtilityService } from '../../services/utility.service';
/* import { CustomerService } from '../../services/customer.service';
import { WorkorderService } from 'src/app/services/workorder.service'; */
import Swal from 'sweetalert2';
import { EnrollService } from 'src/app/services/enroll.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
    selector: 'app-enroll',
    templateUrl: './enroll.component.html',
    styleUrls: ['./enroll.component.scss']
})
export class EnrollComponent implements OnInit {

    recs = [];
    searching = true;
    saving = true;
    recommendedCount = [];
    recommendationText: string = "Finding the best programs for you based on your response";
    showForEligible = false;
    notQualifiedReason:string='';
    confirmData:any={};
    agreeTerms:false;
    rebateGroup:any;
    landlordconsent:false;
    constructor(
        private dialog: MatDialogRef<EnrollComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private enrollmentService: EnrollService,
        public utilityService: UtilityService,
        private spinner: NgxSpinnerService,
        
        private router: Router
    ) { }

    ngOnInit() {
        this.searching = true;
        this.saving = true;
        this.confirmData = history.state.data;
        this.rebateGroup = localStorage.getItem('rebategroup'); 
        this.saveQuestions();
       
    }

    private saveQuestions() {
        
        if (this.data.callNewAPI) {
            this.recommendationText = "Eligibility Qualification";
            //console.log('Response popup', this.data.responsePopup);
            console.log("DataList", this.data.elList);
            this.showForEligible = this.data.callNewAPI;
            this.enrollmentService.saveIndividualQuestionsByPersonCompanyId(this.data.personcompId, this.data.elList,this.data.programDetailID,this.rebateGroup)
                .subscribe(() => {
                    this.saving = false;   
                    this.verifyEligibility();
                }, err => {
                    console.log('Error: ' + JSON.stringify(err, null, 2));

                    this.searching = false;
                });
        } else {
            this.enrollmentService.saveQuestionsByPersonCompanyId(this.data.personcompId, this.data.elList)
                .subscribe(() => {
                    this.saving = false;
                    this.getRecs();
                }, err => {
                    console.log('Error: ' + JSON.stringify(err, null, 2));

                    this.searching = false;
                });
        }

    }

    private verifyEligibility() {
        this.enrollmentService.verifyIndividualEligibilityQuestions(this.data.personcompId, this.data.programDetailID)
            .subscribe((data) => {
                console.log('verify: ', data);
                if (data.QUALIFIED) {
                    this.recommendedCount = [data]
                } 
                
                //To handle the respone with proper reason
                else {
                    console.log("Message",data.MESSAGE);
                    this.notQualifiedReason=data.REASON+data.MESSAGE;
                    this.recommendedCount = []
                    
                }
                

                this.searching = false;

            }, err => {
                console.log('Error: ' + JSON.stringify(err, null, 2));
                this.searching = false;
            });
    }

    private getRecs() {
       
        this.enrollmentService.getRecommendationsByPersonCompanyId(this.data.personcompId)
            .subscribe((data) => {
                this.recs = data[this.data.personcompId];

                this.recommendedCount = this.recs.filter(obj => {
                  
                    console.log(this.recommendationText);
                    return obj.RECOMMEND === 1
                })

                this.searching = false;
                this.recommendationText="Based on your response, recommended Programs for you";
               
            }, err => {
                console.log('Error: ' + JSON.stringify(err, null, 2));

                this.searching = false;
            });
                
    }

    handleCancel() {
        // this.router.navigate(['/detail']);
        //this.dialogRef.close('1');
        this.dialog.close({ data: false });
    }

    handleEnroll(rec) {
        Swal.fire({

            text: "Are you sure you want to enroll?",
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
            
        })
            .then((result) => {
                if (result.value) {
                    
                    this.spinner.show(),
                    this.doEnroll(rec);
                   
                }
            })
    }

   

    doEnroll(rec) {
        
        //console.log('personcompanyId',this.data.personcompId);
        //console.log('ProgramDetailId',rec.PROGRAMDETAIL);
        //this.customerService.currentCustomer.Id
        this.enrollmentService.enrollCustomer(
            this.data.personcompId, this.data.personcompId, this.data.programDetailID,this.agreeTerms,this.rebateGroup,this.landlordconsent).subscribe((res) => {
                // console.log(res);


                if (res.Action == 0) {
                   
                   if(localStorage.getItem('SelfSchedule')=='0')
                   {
                        this.router.navigate(['/success'], { state: { data: res } });
                   }
                   else
                   {
                       console.log('ConfirmData',this.confirmData)
                        this.router.navigate(['/scheduling'],{state:{data:res}});
                   }

                } else {
                    Swal.fire({
                        title: 'Error',
                        text: res.Message,
                        icon: 'error',
                        confirmButtonText: 'OK',
                    })
                }
                // this.router.navigate(['/detail']);
                //this.dialogRef.close('1');
                this.dialog.close({ data: false });
            }, err => {
                //console.log('Error: ' + JSON.stringify(err, null, 2));
                Swal.fire({
                    title: 'Error',
                    text: err,
                    icon: 'error',
                    confirmButtonText: 'OK',
                })
                // this.dialogRef.close('1');
                this.dialog.close({ data: false });
            });
    }
}
