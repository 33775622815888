<app-enrollment-header></app-enrollment-header>

<div class="intro-text">{{ utilityService.utilitySettings.enrollSuccessIntro }}</div>

  
<mat-card class="mat-elevation-z0">
   
  <div style= "padding:0px" [innerHTML]=utilityService.utilitySettings.enrollSuccessDesc></div>
  <mat-card-actions>
  <div style="padding:0px;margin-left:5px;">
    <button *ngIf="isShowReportButton"mat-raised-button class="mainbutton" (click)="navigate()" >Done</button>
    <button *ngIf="!isShowButton" mat-raised-button class="mainbutton" (click)="confirmed()">Schedule Appointment</button> 
    <button *ngIf="!isShowReportButton" mat-raised-button class="mainbutton" (click)="navigatetoReport()">View Report</button>   
  </div>
</mat-card-actions>
</mat-card>


<app-enrollment-footer></app-enrollment-footer>
