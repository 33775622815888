/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { NgxSpinnerService } from 'ngx-spinner';
import { RebateService } from 'src/app/services/rebate.service';
import { UtilityService } from 'src/app/services/utility.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-contractor',
  templateUrl: './contractor.component.html',
  styleUrls: ['./contractor.component.scss'],
})
export class ContractorComponent implements OnInit {
  contractorform: FormGroup;
  contractorinfo: any = [];
  contractorOptions: any = [];
  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  @Input() data: any;
  @ViewChild('myButtonRef') myButtonRef: MatButton;
  isFormModified = false;
  isSubmitEnabled = false;
  isOtherSelected: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private rebate: RebateService,
    public utilityService: UtilityService
  ) {
    this.contractorform = this.formBuilder.group({
      InstallationContractor: new FormControl(''),
      ContractorName: new FormControl(''),
      ContractorPhone: new FormControl('', [
        Validators.pattern('^(\\([0-9]{3}\\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$')
      ]),
    });
  }

  markAllFieldsreset() {
    Object.values(this.contractorform.controls).forEach((control) => {
      control.markAsUntouched();
      control.markAsPristine();
    });
  }
  resetField(fieldName: string) {
    const formControl = this.contractorform.get(fieldName);
    if (formControl) {
      formControl.setValue('');
      formControl.markAsUntouched();
      formControl.markAsPristine();
    }
  }

  contractorinfoupdate() {
    this.contractorinfo.forEach((contractorinfo) => {
      this.contractorform.patchValue({
        InstallationContractor: contractorinfo.InstallationContractor,
        ContractorName: contractorinfo.ContractorName,
        ContractorPhone: contractorinfo.ContractorPhone
      });
    });
  }

  setinitvalues() {
    const isButtonDisabled = this.myButtonRef.disabled;
    this.contractorinfoupdate()
  }
  ngOnInit() {
    this.spinner.show();
    this.getContractorinfo();

    this.contractorform?.valueChanges?.subscribe((status) => {
      if (status?.InstallationContractor != 1 ) {
        status.ContractorName = "";
        status.ContractorPhone = "";
      }
      this.isSubmitEnabled = this.contractorform.valid && this.isFormModified;
    });
  }
  onFormChange() {
    this.isFormModified = true;
    this.rebate.setItem('ContractorComponent', false);
  }
  private getContractorinfo() {
    this.rebate.getContractorInfo(this.data.WorkorderId).subscribe(
      (res) => {
        this.contractorOptions = res.Contractor_Options;
        this.contractorinfo = res.Contractor_Info;
        res.Contractor_Info[0].InstallationContractor == 1 ? this.isOtherSelected = false : this.isOtherSelected = true;
        if (res.Contractor_Info[0].InstallationContractor != 1) {
          this.rebate.setItem('ContractorComponent', true)
        }
        else if (res.Contractor_Info[0].InstallationContractor == 1 && res.Contractor_Info[0].ContractorName != null){
          this.rebate.setItem('ContractorComponent', true)
        }
        this.setinitvalues();
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }
  onSave() {
    const formData = this.contractorform.value;
    const jsonData = JSON.stringify(formData);
    this.spinner.show();

    this.rebate.saveContractor(jsonData, this.data.WorkorderId).subscribe(
      (res: any) => {
        this.spinner.hide();
        if (!res.Action) {
          Swal.fire({
            customClass: {
              confirmButton: 'swal2-popup-confirm',
            },
            text: res.Message,
            icon: 'success',
            confirmButtonText: 'OK',
          });
          this.formGroupDirective.resetForm();
          this.getContractorinfo();
          this.markAllFieldsreset();
          this.rebate.setItem('ContractorComponent', true);
          this.isFormModified = false;
        }
        else {
          Swal.fire({
            customClass: {
              confirmButton: 'swal2-popup-confirm',
            },
            text: res.Message,
            icon: 'error',
            timer: 3000,
            confirmButtonText: 'OK',
          });
        }
      },
      (err) => {
        Swal.fire({
          customClass: {
            confirmButton: 'swal2-popup-confirm',
          },
          title: 'Error',
          icon: 'error',
          confirmButtonText: 'OK',
        });
      }
    );
  }

  onListChange($event) {
    this.isFormModified = true;
    this.isSubmitEnabled = true;
    $event.value == 1 ? this.isOtherSelected = false : this.isOtherSelected = true;
    this.rebate.setItem('ContractorComponent', false);
  }
}
