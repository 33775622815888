<app-enrollment-header></app-enrollment-header>
<div class="desc-text">{{desc}} appointment scheduled on {{redate | date:'EEEE'}}&nbsp;{{redate | date:'longDate'}} at {{reschduleTime}}</div>
<div class="enroll-card-div" *ngIf="response != null" style="width: 50%;">
    <div mat-dialog-title style="margin: 0 !important">
        <button matTooltip="Close" mat-button style="height: 2.5vw !important;float: right;"
                 (click)="back()">
                  <mat-icon style="margin-bottom: 0.5vw;">close</mat-icon>
              </button>
        </div>
        <br>
        <br>
                    
               <mat-card style="width: 50%;">
          
          <pre class="main-header">{{response.Street}}</pre>
          <pre class="main-header">{{response.City}},{{response.State}} {{response.Zip}}</pre>
          <pre class="main-header">{{formatPhoneNumber(response.Phone)}}</pre>
          <pre class="main-header">{{response.Email}}</pre>
          <br>
          <mat-card-actions class="btn-action">
            <button mat-raised-button class="mainbutton" (click)="reschedule()">Reschedule</button>
            <button mat-raised-button class="mainbutton" (click)="cancel()">Cancel Appointment</button>
        </mat-card-actions>
        </mat-card>
      </div>

<app-enrollment-footer></app-enrollment-footer>
