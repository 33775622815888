/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Injectable } from "@angular/core";
import { MeasureComponent } from "../measure/measure.component";
import { OtherComponent } from "../other/other.component";
import { PayeeComponent } from "../payee/payee.component";
import { ReceiptsComponent } from "../receipts/receipts.component";
import { SubmitComponent } from "../submit/submit.component";
import { ContractorComponent } from '../contractor/contractor.component';

@Injectable()
export class ComponentTypes {
    types = {
        PayeeComponent: PayeeComponent,
        MeasureComponent:MeasureComponent,
        OtherComponent:OtherComponent,
        ReceiptsComponent:ReceiptsComponent,
        SubmitComponent:SubmitComponent,
        ContractorComponent:ContractorComponent
      };
      toType(name:string) {
        return this.types[name];
      }
}
