/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from "ngx-spinner";
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { EnrollService } from 'src/app/services/enroll.service';
import { UploadDocumentsComponent } from 'src/app/upload-documents/upload-documents.component';
import { EnrollComponent } from 'src/app/util/enroll/enroll.component';
import Swal from 'sweetalert2';
import { UtilityService } from '../../services/utility.service';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-qualify',
    templateUrl: './qualify.component.html',
    styleUrls: ['./qualify.component.scss']
})
export class QualifyComponent implements OnInit {

    constructor(private datePipe: DatePipe, private router: Router, public utilityService: UtilityService,
        public enrollService: EnrollService, public dialog: MatDialog,
        public dataTransfer: DataTransferService, private spinner: NgxSpinnerService) { }

    personcompanyid: any;
    answered: any = 0;
    confirmData: any = {};
    ind: any = -1;
    indCurrentValue: any = '';
    count: {
        index: Number,
        count: Number
    };
    countArray: any = [];
    workOrdername: any;
    questions = [];
    quesCount = 0;
    answeredQues = {};
    answeredQuesCount: any = 0;
    newAPI = false;
    buttonName = "Enroll";
    programDetailId: any;
    showResponsePopup = false;
    downLoadLink: any;
    downLoadText: any;
    uploadText = [];
    enable: boolean;
    uploadUnique = [];
    agreeTerms: any;
    termsAndConditions: any;
    otherTermsAndConditions: any;
    landLordConsent:any;
    landLordConsentMsg:any;
    uploadTextOwner: any;
    uploadTextHC: any;
    uploadTextWH: any;
    downloadEnable: boolean;
    uploadTextReceipt: any;
    dialogWidth = '800px';
    rebateGroup: any;
    loading = false;
    countResponse = 0;
    savedChargerList = [];
    smallScreen: any;
    savingIndividual = false;
    landLordConsentCheck:any;
    // disable all questions following the current one
    isDisableFollowingQuestions = false;  // should be set to utility setting
    lastAnsweredQuestionIndex = -1;
    landlordconsent:any;
    
    ngOnInit(): void {
        if (screen.width < 850) {
            this.dialogWidth = '300px';
        }
           
        this.landLordConsentCheck = 0;
        this.isDisableFollowingQuestions = this.utilityService.utilitySettings.IsQuestionsEnableSeq;
        this.smallScreen = screen.width < 600;
        this.dataTransfer.myMethod(true);
        this.confirmData = history.state.data
        this.newAPI = history.state.callNewAPI
        this.agreeTerms = false;
        this.downloadEnable = false;
        this.landlordconsent=false;
        this.rebateGroup = localStorage.getItem('rebategroup');
        if (this.newAPI) {
            this.buttonName = "Next";
        }

        this.personcompanyid = localStorage.getItem('personcompanyid') || this.confirmData.PersonCompanyId;
        this.workOrdername = history.state.program.Shortname
        if (history.state.program.ProgramdetailId == undefined) {

            this.programDetailId = history.state.program
            this.workOrdername = history.state.shortName;
        }
        else {
            this.programDetailId = history.state.program.ProgramdetailId
        }

        this.loadQuestions(this.personcompanyid, this.programDetailId);
        localStorage.setItem('uploadButtonEnablement', "0");


    }



    enroll() {
        this.router.navigate(['/success']);
    }

    onAnswering(index, count, currentValue) {

        if (this.questions[index].QUESTION_TYPE === 'CUST_CHRGS' || this.questions[index].QUESTION_TYPE === 'CHRGS_RES') {
            if (currentValue[0]["RESPONSE_ID"] === null) {

                this.answeredQues[index] = false;
                this.answeredQuesCount = 0;
            } else {

                this.answeredQues[index] = true;
                this.answeredQuesCount = 1;
            }
        }
        else
            if (this.questions[index] && this.questions[index].QUESTION_REQUIRED) {
                if (this.questions[index].QUESTION_TYPE === 'TEXT') {
                    if (currentValue.trim() != '') {

                        this.answeredQuesCount = 1;
                        this.answeredQues[index] = true;
                    }
                    else {

                        this.answeredQuesCount = 0;
                        this.answeredQues[index] = false;
                    }
                }
                else {
                    if (currentValue != '') {

                        this.answeredQuesCount = 1;
                        this.answeredQues[index] = true;
                    }
                    else {

                        this.answeredQuesCount = 0;
                        this.answeredQues[index] = false;
                    }
                }
            }

        this.answeredQuesCount = 0;
        var x = this.answeredQues;
        var y = this.answeredQuesCount;

        Object.keys(this.answeredQues).forEach(function (key) {
            var val = x[key];
            if (val != null && val) {
                y++;
            }
        });

        this.answeredQuesCount = y;

        if (this.showResponsePopup) {

            // need to manually qualify
            var currentResponse;
            this.questions[index].QUESTION_RESPONSES.forEach(q => {
                if (q.RESPONSE_ID === currentValue) {
                    currentResponse = q;
                }

            });

            if (this.rebateGroup != "REBATE") {
                //to download the consent form
                if ((currentResponse.RESPONSE_TEXT === "No") && (currentResponse.RESPONSE_DOWNLOAD_LINK != undefined)) {

                    this.downLoadLink = currentResponse.RESPONSE_DOWNLOAD_LINK;
                    this.downLoadText = currentResponse.RESPONSE_LABEL_TEXT;
                    this.enable = true;

                }

                if (currentResponse.RESPONSE_TEXT === "Yes") {
                    this.enable = false;
                    this.downLoadLink = "";
                    this.downLoadText = "";
                }


                if (currentResponse != undefined && currentResponse.RESPONSE_REASON != undefined) {
                    Swal.fire({
                        html: '<div style="text-align:left"> ' + currentResponse.RESPONSE_REASON + ' </div>',
                        confirmButtonText: 'OK',
                        width: this.dialogWidth,
                        icon: 'error',
                    }).then(() => {
                        this.router.navigate(['/programtypes']);
                    });
                }
            }
        }

        if (this.countArray != undefined) {
            if (this.countArray.length > 0) {
                this.ind = this.countArray.findIndex(i => i.index == index)
                this.indCurrentValue = this.countArray.find(function (el) {
                    return el.index === index;
                }) || {};
            }

            if (this.questions[index].QUESTION_TYPE === 'CUST_CHRGS' || this.questions[index].QUESTION_TYPE === 'CHRGS_RES') {
                this.countArray.forEach((element) => {
                    if (element.index === index) {
                        if (currentValue[0]["RESPONSE_ID"] === null && this.indCurrentValue.currentValue !== '') {
                            this.answered--;
                            this.setLastQuestionAnswered(index, false);
                            element.currentValue.currentValue = '';
                        } else if (currentValue[0]["RESPONSE_ID"] !== null && this.indCurrentValue.currentValue === '') {
                            this.answered++;
                            this.setLastQuestionAnswered(index, true);
                            element.currentValue = currentValue;
                        }
                    }
                });
            } else if (this.ind >= 0 && (currentValue != "")) {
                //this.countArray.push({index:index,currentValue:currentValue});

                this.countArray.forEach((element) => {
                    if (element.index === index) {
                        if (!element.currentValue) {
                            if (this.answered <= this.questions.length) {
                                this.setLastQuestionAnswered(index, true);
                                this.answered++;
                            }
                        }
                        element.currentValue = currentValue;
                    }
                });


            } else if (currentValue == "" || currentValue == undefined) {

                this.answered--;
                this.setLastQuestionAnswered(index, false);
                this.countArray.forEach((element) => {
                    if (element.index === index) {
                        element.currentValue = currentValue;
                    }
                });

            }

        }

        //To save questions
        let elList: any;

        if (this.questions[index].QUESTION_TYPE === 'CUST_CHRGS' || this.questions[index].QUESTION_TYPE === 'CHRGS_RES') {

            this.savedChargerList = currentValue;
            elList = currentValue;
            this.savingIndividual = true;
        } else {
            elList = this.buildEligilityListForEachQuestion(index);
        }

        this.rebateGroup = localStorage.getItem('rebategroup');

        this.enrollService.saveIndividualQuestionsByPersonCompanyId(this.personcompanyid, elList, this.programDetailId, this.rebateGroup)
            .subscribe(() => {
                this.savingIndividual = false;
            }, err => {
                this.savingIndividual = false;
                console.log('Error: ' + JSON.stringify(err, null, 2));

            });
        //End of save questions on each and every question

        //Handle this to enable Next button after save
        //this.loadQuestions(this.personcompanyid, this.programDetailId);
        //End of save questions on each and every question
        //End of save questions on each and every question

    }

    private buildEligilityListForEachQuestion(questionIndex): any[] {

        const elList = [];

        let respId;
        let respText;
        let eligibility_Id;


        if (this.questions[questionIndex].QUESTION_TYPE === 'LIST') {
            respId = this.questions[questionIndex].currentValue;

            const index = this.questions[questionIndex].QUESTION_RESPONSES.findIndex(val => val.RESPONSE_ID === this.questions[questionIndex].currentValue);
            const eligibilityIndex = this.questions[questionIndex].QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
            if (index === -1) {
                respText = '';
            } else {
                respText = this.questions[questionIndex].QUESTION_RESPONSES[index].RESPONSE_TEXT;

            }
            if (eligibilityIndex === -1) {
                eligibility_Id = this.questions[questionIndex].ELIGIBILITY_ID;
            }
            else {
                eligibility_Id = this.questions[questionIndex].QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
            }
        }
        else if (this.questions[questionIndex].QUESTION_TYPE === 'DATE') {

            respId = this.questions[questionIndex].QUESTION_RESPONSES[0].RESPONSE_ID;
            respText = this.questions[questionIndex].currentValue;
            if (respText != '') {
                respText = moment(respText).format("MM/DD/YYYY");
               

            }
            eligibility_Id = this.questions[questionIndex].QUESTION_RESPONSES[0].ELIGIBILITY_ID;

        }
        else if (this.questions[questionIndex].QUESTION_TYPE === 'TEXT') {

            respId = this.questions[questionIndex].QUESTION_RESPONSES[0].RESPONSE_ID;
            respText = this.questions[questionIndex].currentValue.trim();
            eligibility_Id = this.questions[questionIndex].QUESTION_RESPONSES[0].ELIGIBILITY_ID;

        }
        else if (this.questions[questionIndex].QUESTION_TYPE === 'CUST_CHRGS') { }
        else if (this.questions[questionIndex].QUESTION_TYPE === 'CHRGS_RES') { }
        else {
            respId = this.questions[questionIndex].QUESTION_RESPONSES[0].RESPONSE_ID;
            respText = this.questions[questionIndex].currentValue;
            eligibility_Id = this.questions[questionIndex].QUESTION_RESPONSES[0].ELIGIBILITY_ID;
        }

        const el = {
            QUESTION_ID: this.questions[questionIndex].QUESTION_ID,
            RESPONSE_ID: respId,
            RESPONSE_TEXT: respText,
            ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
        };

        elList.push(el);
        return elList;
    }

    enhanceQuestions(res, percompanyid) {
        this.resetLastQuestionAnswered(-1);

        this.spinner.hide();
        this.questions = res[percompanyid];

        this.questions.map(element => {

            if (element.QUESTION_REQUIRED) {
                this.quesCount++;
            }


        });
     
        this.questions.forEach((ques, index) => {

            if (ques.QUESTION_TYPE === 'TEXT') {
                if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                    && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined && ques.QUESTION_RESPONSES[0].RESPONSE_TEXT !== undefined) {
                    ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;

                    if (ques.currentValue != undefined) {
                        this.answered++;
                        this.setLastQuestionAnswered(index, true);
                        if (this.questions[index].QUESTION_REQUIRED) {
                            this.answeredQues[index] = true;
                        }
                    }

                } else {
                    ques.currentValue = '';
                }
            }

            //Added for Date functionality
            if (ques.QUESTION_TYPE === 'DATE') {
                if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                    && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {


                    ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;

                    ques.currentValue = this.datePipe.transform(ques.currentValue, 'yyyy-MM-dd');

                    if (ques.currentValue != undefined) {
                        this.answered++;
                        this.setLastQuestionAnswered(index, true);
                        if (this.questions[index].QUESTION_REQUIRED) {
                            this.answeredQues[index] = true;
                        }
                    }


                }



            }

            if (ques.QUESTION_TYPE === 'LABEL') {
                if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                    && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                    ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
                    if (ques.currentValue != undefined) {
                        this.answered++;
                        this.setLastQuestionAnswered(index, true);
                        if (this.questions[index].QUESTION_REQUIRED) {
                            this.answeredQues[index] = true;
                        }
                    }

                } else {
                    ques.currentValue = '';
                }
            }

            //To handle custom type of chargers
            if (ques.QUESTION_TYPE === 'CUST_CHRGS' || ques.QUESTION_TYPE === 'CHRGS_RES') {
                if (ques.QUESTIONS !== undefined) {
                    if (ques.QUESTIONS.QUESTIONS_ARRAY[0]['ELIGIBILITY_ID'] !== null) {
                        ques.currentValue = ques.QUESTIONS.QUESTIONS_ARRAY;
                        if (ques.currentValue != undefined) {
                            this.answered++;
                            this.setLastQuestionAnswered(index, true);
                            if (this.questions[index].QUESTION_REQUIRED) {
                                this.answeredQues[index] = true;
                            }
                        }

                    } else {
                        ques.currentValue = '';
                    }
                }

            }
            //End 

            if (ques.QUESTION_TYPE === 'NUMBER' || ques.QUESTION_TYPE === 'VALUE') {
                if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                    && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                    ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;
                    if (ques.currentValue != undefined) {
                        this.answered++;
                        this.setLastQuestionAnswered(index, true);
                        if (this.questions[index].QUESTION_REQUIRED) {
                            this.answeredQues[index] = true;
                        }
                    }

                } else {
                    ques.currentValue = '';
                }
            }

            if (ques.QUESTION_TYPE === 'DECIMAL') {
                if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0
                    && ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID !== undefined) {
                    ques.currentValue = ques.QUESTION_RESPONSES[0].RESPONSE_TEXT;

                    if (ques.currentValue != undefined) {
                        this.answered++;
                        this.setLastQuestionAnswered(index, true);
                        if (this.questions[index].QUESTION_REQUIRED) {
                            this.answeredQues[index] = true;
                        }
                    }

                } else {
                    ques.currentValue = '';
                }
            }

            if (ques.QUESTION_TYPE === 'LIST') {
                ques.currentValue = '';

                //To download consent form text based on question

                if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0) {
                    if (ques.QUESTION_ID === "STC1P00002") {
                        ques.QUESTION_RESPONSES.forEach(resp => {


                            if (resp.ELIGIBILITY_ID === undefined) {

                                this.downloadEnable = false;
                            }
                            else {
                                ((resp.RESPONSE_TEXT === "No") && (resp.RESPONSE_UPLOAD === "1"))
                                this.enable = true;
                                this.downLoadLink = resp.RESPONSE_DOWNLOAD_LINK;
                                this.downLoadText = resp.RESPONSE_LABEL_TEXT;

                            }

                        });
                    }

                }
                //To download consent form text based on question             

                if (ques.QUESTION_RESPONSES !== undefined && ques.QUESTION_RESPONSES.length > 0) {
                    ques.QUESTION_RESPONSES.forEach(resp => {


                        if (resp.ELIGIBILITY_ID !== undefined) {
                            ques.currentValue = resp.RESPONSE_ID;

                            if (ques.currentValue != undefined) {
                                this.answered++;
                                this.setLastQuestionAnswered(index, true);
                                if (this.questions[index].QUESTION_REQUIRED) {
                                    this.answeredQues[index] = true;
                                }
                            }

                        }
                    });
                }
            }
            this.answeredQuesCount = Object.keys(this.answeredQues).length;
            this.countArray.push({ index: index, currentValue: ques.currentValue });
        });

    }

    loadQuestions(percompanyid, ProgramdetailId) {
        this.answered = 0;
        this.quesCount = 0;
        this.answeredQues = {};
        this.answeredQuesCount = 0;
        this.spinner.show();
        this.questions = [];

        if (this.newAPI) {
            this.enrollService.getIndividualQuestions(percompanyid, ProgramdetailId).subscribe(res => {
                this.showResponsePopup = res.ShowResponsePopup;
                this.termsAndConditions = res.TermsAndConditions;
                this.landLordConsent = res.LandLordConsent;
                this.landLordConsentMsg = res.LandLordConsentMsg;
              
                if(this.landLordConsent!=undefined)
                {
                    this.landLordConsentCheck = 1;
                }
                else
                {
                    this.landLordConsentCheck = 0;
                }
                this.otherTermsAndConditions = res.OtherTermsAndConditions;

                this.enhanceQuestions(res, percompanyid)
            },
                err => {
                    this.spinner.hide();

                })
        } else {
            this.enrollService.getQuestions(percompanyid).subscribe(res => {

                this.enhanceQuestions(res, percompanyid)

            },
                err => {
                    this.spinner.hide();

                })
        }

    }

    private buildEligilityList(): any[] {

        const elList = [];

        this.questions.forEach(ques => {
            let respId;
            let respText;
            let eligibility_Id;


            if (ques.QUESTION_TYPE === 'LIST') {
                respId = ques.currentValue;

                const index = ques.QUESTION_RESPONSES.findIndex(val => val.RESPONSE_ID === ques.currentValue);
                const eligibilityIndex = ques.QUESTION_RESPONSES.findIndex(val => val.ELIGIBILITY_ID != null);
                if (index === -1) {
                    respText = '';
                } else {
                    ques.QUESTION_RESPONSES[index].RESPONSE_TEXT;
                    respText = ques.QUESTION_RESPONSES[index].RESPONSE_TEXT;

                }
                if (eligibilityIndex === -1) {
                    eligibility_Id = ques.ELIGIBILITY_ID;
                }
                else {
                    eligibility_Id = ques.QUESTION_RESPONSES[eligibilityIndex].ELIGIBILITY_ID;
                }

                const el = {
                    QUESTION_ID: ques.QUESTION_ID,
                    RESPONSE_ID: respId,
                    RESPONSE_TEXT: respText,
                    ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
                };

                elList.push(el);
            }
            else if (ques.QUESTION_TYPE === 'CUST_CHRGS' || ques.QUESTION_TYPE === 'CHRGS_RES') {
                this.savedChargerList.forEach(q => elList.push(q));
            }

            //Added newly to handle the date -8/29/2022
            else if (ques.QUESTION_TYPE === 'DATE') {

                respId = ques.QUESTION_RESPONSES[0].RESPONSE_ID;
                respText = ques.currentValue;
                eligibility_Id = ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID;

                if (respText != '') {
                    respText = moment(respText).format("MM/DD/YYYY");
                    //console.log(respText)

                }
                const el = {
                    QUESTION_ID: ques.QUESTION_ID,
                    RESPONSE_ID: respId,
                    RESPONSE_TEXT: respText,
                    ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
                };

                elList.push(el);
                //console.log("ElistInside",el);


            }
            //End

            else {
                respId = ques.QUESTION_RESPONSES[0].RESPONSE_ID;
                respText = ques.currentValue;
                eligibility_Id = ques.QUESTION_RESPONSES[0].ELIGIBILITY_ID;

                const el = {
                    QUESTION_ID: ques.QUESTION_ID,
                    RESPONSE_ID: respId,
                    RESPONSE_TEXT: respText,
                    ELIGIBILITY_ID: (eligibility_Id != null || eligibility_Id != undefined || eligibility_Id != "") ? eligibility_Id : null
                };

                elList.push(el);
                
            }
        });

        return elList;
    }


    public getRecommendations() {
        let className = localStorage.getItem("themeName");
        const dialogRef = this.dialog.open(EnrollComponent,
            {
                data: {
                    personcompId: this.personcompanyid,
                    elList: this.buildEligilityList(),
                    callNewAPI: this.newAPI,
                    programDetailID: this.programDetailId,
                }, panelClass: className
            }
        );

        dialogRef.afterClosed().subscribe((res) => {

            this.loadQuestions(this.personcompanyid, this.programDetailId);
        });
    }

    uploadDocuments(uploadText) {
        this.personcompanyid = localStorage.getItem('personcompanyid');
        console.log('personcompanyID:', this.personcompanyid);

        let elList: any;
        elList = this.buildEligilityList();
     
        //to filter duplicate values
        this.uploadUnique = this.uploadText.filter((n, i) => this.uploadText.indexOf(n) === i);

        this.spinner.hide();
        const dialogRef = this.dialog.open(UploadDocumentsComponent, {
            height: '70%', width: '60%',
            data: {
                uploadText1: this.uploadUnique,
                programdetailID: this.programDetailId,
                agreeTerms: this.agreeTerms,
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            this.loading = false;
            this.spinner.hide();
        });
    }

    /* this method is for enroll directly and move to scheduling from Qualification page*/

    enrollAndSchedule() {
        this.spinner.show();
        this.loading = true;
        //JCP Upload start
        this.rebateGroup = localStorage.getItem('rebategroup');


        this.personcompanyid = localStorage.getItem('personcompanyid');

        let elList: any;
        elList = this.buildEligilityList();
        console.log("DataList", elList);
        this.uploadText = [];

        //upload documents

        elList.forEach(list => {


            if ((list.QUESTION_ID === "STC1P00002" || list.QUESTION_ID === "STC1HVAC02" || list.QUESTION_ID ==="STC1PCOM02") && (list.RESPONSE_TEXT === "No")) {

                this.uploadTextOwner = "Please upload your signed Landlord Consent Form.";
                this.uploadText.push(this.uploadTextOwner);

            }
            if ((list.QUESTION_ID === "STC1P00004" || list.QUESTION_ID ==="STC1PCOM04") && (list.RESPONSE_TEXT === "Not Sure")) {

                this.uploadTextHC = "Please upload a photo of your central heating system that shows the power cable, vents and exhaust pipes where they enter the unit.";
                this.uploadText.push(this.uploadTextHC);
            }
            if ((list.QUESTION_ID === "STC1P00007" || list.QUESTION_ID ==="STC1PCOM07") && (list.RESPONSE_TEXT === "Not sure")) {

                this.uploadTextWH = "Please upload a photo of your water heater.";
                this.uploadText.push(this.uploadTextWH);
            }

            //JCPL prototype
            if ((list.QUESTION_ID === "APC1000002") && (list.RESPONSE_TEXT === "Required and Received")) {

                this.uploadTextReceipt = "Please upload your receipt form.";
                this.uploadText.push(this.uploadTextReceipt);
            }


        });

        if (this.rebateGroup == "REBATE") {
            this.uploadTextReceipt = this.utilityService.utilitySettings.UploadEnableContent;
            this.uploadText.push(this.uploadTextReceipt);
        }
        
        if(this.landLordConsentCheck == 1 && (this.rebateGroup != "REBATE"))
        {
                this.uploadTextOwner = "Please upload your signed Landlord Consent Form.";
                this.uploadText.push(this.uploadTextOwner);
        }

        //To save questions
        this.enrollService.saveIndividualQuestionsByPersonCompanyId(this.personcompanyid, elList, this.programDetailId, this.rebateGroup)
            .subscribe((res) => {
                if (res.Action == 0) {

                    console.log('uploadtexttotal:', this.uploadText);

                    if (this.uploadText.length != 0) {
                        // this also enrolls customer
                        this.uploadDocuments(this.uploadText);
                    } else {

                       
                        //once questions are saved then enroll customer 
                        this.enrollService.enrollCustomer(
                            this.personcompanyid, this.personcompanyid, this.programDetailId, this.agreeTerms, this.rebateGroup,this.landlordconsent).subscribe((res) => {
                                this.spinner.hide();
                                if (res.Action == 0) {

                                    if (localStorage.getItem('SelfSchedule') == '0') {
                                        this.router.navigate(['/success'], { state: { data: res } });
                                    }
                                    else if (this.rebateGroup == "REBATE") {
                                        this.router.navigate(['/success'], { state: { data: res } });
                                    }
                                    else {

                                        this.router.navigate(['/scheduling'], { state: { data: res } });
                                    }

                                } else {
                                    Swal.fire({
                                        title: 'Error',
                                        text: res.Message,
                                        icon: 'error',
                                        confirmButtonText: 'OK',
                                    })
                                    // this.loading = false;
                                    this.spinner.hide();
                                }


                            }, err => {

                                Swal.fire({
                                    title: 'Error',
                                    text: err,
                                    icon: 'error',
                                    confirmButtonText: 'OK',
                                })


                            });
                    }
                }

            }, err => {
                
                Swal.fire({
                    title: 'Error',
                    text: err,
                    icon: 'error',
                    confirmButtonText: 'OK',
                })

            });
    }

    //Validation with numbers and decimal values
    onlyNumbersWithDecimal(event) {
        var pattern = /^[0-9]+.[0-9]{2}/g;
        var charCode = (event.which) ? event.which : event.keyCode;
        if (charCode != 46 && charCode > 31
            && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        }

        return true;
    }

    onlyNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    // disable questions follwing current
    resetLastQuestionAnswered(index: number) {
        // console.log("################### resetLastQuestionAnswered: index=" + index + ", lastAnsweredQuestionIndex=" + this.lastAnsweredQuestionIndex);
        this.lastAnsweredQuestionIndex = index;
    }

    setLastQuestionAnswered(index: number, answered: boolean) {
        // console.log("################### setLastQuestionAnswered: index=" + index 
        // + ", lastAnsweredQuestionIndex=" + this.lastAnsweredQuestionIndex + ", answered=" + answered);
        if (answered && index > this.lastAnsweredQuestionIndex) {
            this.lastAnsweredQuestionIndex = index;
        } else if (!answered) {
            if (this.lastAnsweredQuestionIndex == index) {
                this.lastAnsweredQuestionIndex--;
            }
        }
    }

    isDisabled(questionIndex: number): boolean {
        // console.log("################### isDisabled: questionIndex=" + questionIndex + ", lastAnsweredQuestionIndex=" + this.lastAnsweredQuestionIndex);
        if (!this.isDisableFollowingQuestions) {
            return false;
        } else {
            return questionIndex > this.lastAnsweredQuestionIndex + 1;
        }
    }
}

