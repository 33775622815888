
<mat-spinner class="center" style="margin-top: 50px;"></mat-spinner>
<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0)" size = "medium" color = "#ff4081" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->
<br/>
<h2 style="text-align: center; margin-top: 50px;">Loading</h2>
<p style="text-align: center; margin-top: 250px;">Powered by</p>

<div  style="text-align: center;">
<a href="https://www.honeywell.com">
<img src="assets/honeywell_logo.png" alt="Powered by">
</a>
</div>
