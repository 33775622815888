<app-enrollment-header></app-enrollment-header>

<span *ngIf="!smallScreen">
<div *ngIf="programCount">
  <div class="intro-text" [innerHTML]=utilityService.utilitySettings.mainIntro></div>
  <div class="desc-text">{{ utilityService.utilitySettings.progTypeDesc}}</div>
  <div class="desc-text" *ngIf="programName">Selected Program:{{programName}}</div>

  <!--This is for Santee Cooper-->

<div *ngIf=" showAttributes == 1 " style="height: 205px;align-items: center;">

<table class="content-table">
    <tbody>
        <tr *ngFor="let prog of prgms">
            <td *ngIf="prog.Enable"><button mat-raised-button style="width:450px" [disabled]="!prog.Enable" class="programlist" (click)="startEnrollment(prog)">{{prog.Shortname}}</button>
            
            <span *ngIf="prog.SubGroup=='REBATE' && prog.ApplicationStatus > 0 "><br><div>{{prog.ApplicationStatus}}% of application submitted</div>
            <mat-progress-bar class="my-component" mode="buffer" value="{{prog.ApplicationStatus}}" ></mat-progress-bar>
            </span> 
            </td>
            <td *ngIf="prog.Enable" [innerHTML]="prog.AttributeValue"></td>  
        </tr>
    </tbody>
</table>





<div *ngIf="isShowHeader==1">
  <p class="program-note" style="text-align: center">(Note: To view your Enrolled programs, please refer to <strong>Enrollments</strong> under <strong>My Profile</strong>)<p>
  </div>

</div>

  <!--This is for  Other programs-->

<div *ngIf="showAttributes == 0 ">

  <div class="flex-cont">
    <h2 class="mat-subheading-1">Select Workorder Type</h2>
    <mat-selection-list #programsWorkOrders [multiple]="false" class="program">
      <ng-container *ngFor="let prog of prgms; let i=index">
        <mat-list-option [value]="prog.ProgramId" *ngIf="prog.Enable">
          <button mat-raised-button   [disabled]="!prog.Enable" class="programlist" (click)="startEnrollment(prog)">{{prog.Shortname}}</button>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
    <br>
    <br>
    
    <div *ngIf="isShowHeader==1">
      <p class="program-note" style="text-align: center">(Note: To view your Enrolled programs, please refer to <strong>Enrollments</strong> under <strong>My Profile</strong>)<p>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<div *ngIf="!programCount">
  <p class="program-note flex-cont">You are already enrolled into the program</p>
  <div *ngIf="isShowHeader==1">
    <p class="program-note" style="text-align: center">(Note: Enrolled programs, please refer to <strong>Enrollments</strong> under <strong>My Profile</strong>)<p>
  </div>
 </div>
 <br>
 
</div>

</span>

<!--Mobile support Start-->
<span *ngIf="smallScreen">
<div *ngIf="programCount">
  <div class="intro-text" [innerHTML]=utilityService.utilitySettings.mainIntro></div>
  <div class="desc-text">{{ utilityService.utilitySettings.progTypeDesc}}</div>
  <div class="desc-text" *ngIf="programName">Selected Program:{{programName}}</div>
  <br>
  

  <!--This is for Santee Cooper Start-->

<div *ngIf=" showAttributes == 1 " style="height: 170px; align-items: center;">

<table class="mcontent-table">
    <tbody>
        <tr *ngFor="let prog of prgms">
            <td *ngIf="prog.Enable"><button mat-raised-button style="font-size:12px;width:410px;margin-top:0px;text-align:left;" matTooltip="{{prog.Shortname}}" #tooltip="matTooltip" (click)="tooltip.toggle()" [disabled]="!prog.Enable" class="mprogramlist" (click)="startEnrollment(prog)">{{prog.Shortname}}</button>
              <span *ngIf="prog.SubGroup=='REBATE' && prog.ApplicationStatus > 0 "><br><div style="font-size:10px">{{prog.ApplicationStatus}}% of application submitted</div>
                <mat-progress-bar class="my-component" mode="buffer" value="{{prog.ApplicationStatus}}" ></mat-progress-bar>
            </span><br>
            <div style="font-size:10px;" *ngIf="prog.Enable"  [innerHTML]="prog.AttributeValue"></div>
            <br>
            </td>           
        </tr>
    </tbody>
</table>

</div>

<!--This is for Santee Cooper End-->
<!--This is for  Other programs-->

<div *ngIf="showAttributes == 0 ">

  <div class="flex-cont">
    <h2 class="mat-subheading-1">Select Workorder Type</h2>
    <mat-selection-list #programsWorkOrders [multiple]="false" class="program">
      <ng-container *ngFor="let prog of prgms; let i=index">
        <mat-list-option [value]="prog.ProgramId" *ngIf="prog.Enable">
          <button mat-raised-button  [disabled]="!prog.Enable" class="programlist" (click)="startEnrollment(prog)">{{prog.Shortname}}</button>
        </mat-list-option>
      </ng-container>
    </mat-selection-list>
    <br>
    <br>
    <div *ngIf="isShowHeader==1">
      <p class="program-note" style="text-align: center">(Note: To view your Enrolled programs, please refer to <strong>Enrollments</strong> under <strong>My Profile</strong>)<p>
    </div>
  </div>
</div>
<br>
<br>
<br>
<br>
<div *ngIf="!programCount">
  <p class="program-note flex-cont">You are already enrolled into the program</p>
  <div *ngIf="isShowHeader==1">
    <p class="program-note" style="text-align: center">(Note: Enrolled programs, please refer to <strong>Enrollments</strong> under <strong>My Profile</strong>)<p>
  </div>
 </div>
 <br>
 
</div>

</span>
<!--Mobile support End-->

<app-enrollment-footer></app-enrollment-footer>

