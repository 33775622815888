
<div mat-dialog-content>
    <div style="margin: 0 !important" class="btn-align">
        <h1 mat-dialog-title class="header" style="padding:0.5vw;font-size:1.2vw; color:black">Please provide a reason for your cancellation</h1>
        <button matTooltip="Close" mat-button style="height: 2.5vw !important;" class="close-icon"
            [mat-dialog-close]="true">
            <mat-icon style="margin-bottom: 0.5vw;">close</mat-icon>
        </button>
    </div>
    <mat-card style="width: 40vw;">
        <mat-card-content>

            <mat-spinner *ngIf="loadReasons" [diameter]="25" color="primary"></mat-spinner>
            <!--   <table style="font-size: 1vw;width: 100% !important;">
                <tbody> -->
            <div *ngIf="!loadReasons">
                <mat-list style="cursor: pointer ;" *ngFor="let reason of reasons" dense>
                    <button mat-list-item style="width:100% !important;cursor: pointer;" [disableRipple]="false"
                        (dblclick)="cancelSchedule(reason)"> {{reason.Description}}</button>
                </mat-list>
            </div>

            <!--   </tbody>
            </table> -->
        </mat-card-content>
    </mat-card>
</div>