/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EnrollService } from 'src/app/services/enroll.service';
import { NgxSpinnerService } from "ngx-spinner";
import Swal from 'sweetalert2';
import { MatDialog } from "@angular/material/dialog";
import { UploadDialogComponent } from 'src/app/util/upload-dialog/upload-dialog.component';
import { ViewDialogComponent } from 'src/app/util/view-dialog/view-dialog.component';
import { WorkorderService } from 'src/app/services/workorder.service';
import { UtilityService } from 'src/app/services/utility.service';
import { UploadDocumentsComponent } from 'src/app/upload-documents/upload-documents.component';

@Component({
  selector: 'app-enroll-list',
  templateUrl: './enroll-list.component.html',
  styleUrls: ['./enroll-list.component.scss']
})
export class EnrollListComponent implements OnInit {
   
    appointments: any = [];
    displayedColumns5: string[];//for enrollments
    noOfEnrollments:any;
    enableDone:boolean;
    enrollments="Your Applications";
    uploadViewDoc:any;
    workOrders:any = [];
    displayedColumns3:string[];
    isShowHeader:any;
    group:any;
    uploadFeatureHide:any;
    
    isUploadDoc:any;
    isViewDoc:any;
    smallScreen:any;
    
    constructor(private router: Router, private enrollService:EnrollService,private spinner: NgxSpinnerService,private dialog: MatDialog,private workorderService: WorkorderService,public utilityService: UtilityService) { }
    //workorderID: any = this.workorderService.;
    ngOnInit(): void {
      let pcid = localStorage.getItem("personcompanyid");
      this.loadEnrollments(pcid);
     
      this.isShowHeader= this.utilityService.utilitySettings.showHeader;   
      this.uploadFeatureHide= this.utilityService.utilitySettings.custominfoshowuploaddoc;

      //To hide/un-hide upload or view docs
      this.isUploadDoc= this.utilityService.utilitySettings.Isuploaddoc;
      this.isViewDoc= this.utilityService.utilitySettings.Isviewdoc;
      //End

      this.smallScreen=screen.width < 600;
    }
    
    loadEnrollments(personcompanyId){
        this.spinner.show();
        this.enrollService.getEnrollmentAndWorkorderByPersonCompanyId(personcompanyId).subscribe(res=>{
        this.appointments = res.Enrollments;
        console.log('appointments',this.appointments);
            
        this.noOfEnrollments=this.appointments.length;
        this.enableDone=false;
     
        //If no enrollments
        
        if(this.noOfEnrollments==0)
        {
          this.enableDone=false;
          Swal.fire({

            text: "You have no enrollments, please enroll.",
            icon: 'success',
            confirmButtonText: 'OK',

          }).then(() => {

            this.spinner.show();
            this.router.navigate(['/main']);
            
          })
       
        }
        else
        {
          this.enableDone=true;
          this. displayedColumns5= ['Name', 'Description', 'Status' , 'Upload' , 'View' ];
          this. displayedColumns3= ['Name', 'Description', 'Status'];
             
        }
        
        this.spinner.hide();
        
      })
     
    }
    
    gotoAppointment(workorder) {

       //To hide/un-hide based on office id
      
       if(localStorage.getItem('currentUtility')=='SJG1')
       {
            return;
       }
      else if(workorder.StatusDesc == "Ready to Schedule"){
        localStorage.setItem('workOrderID',workorder.workorder);
        this.router.navigate(['/scheduling'],{state: {data: { WorkorderId: workorder.Workorder, WorkorderType: workorder.ProgramdetailDesc}}});
        //this.router.navigate(['/scheduling'],{state: {data: {WorkorderId:workorder.Workorder} }});
      } else if(workorder.StatusDesc == "Scheduled"){
        // Todo:  go to reschdhule page
        localStorage.setItem('workOrderID',workorder.workorder);
        this.router.navigate(['/reschedule'],{state: {data: { Workorder:workorder.Workorder,ApptStartDate:workorder.ApptStartDate,
          ProgramdetailDesc:workorder.ProgramdetailDesc,ApptStartTime:workorder.ApptStartTime}}});
        //this.router.navigate(['/scheduling'],{state: {data: {WorkorderId:workorder.Workorder} }});
      } else {
        Swal.fire({
            text: `This appointment can’t be changed because it is in ${workorder.StatusDesc} status`,
            icon: 'info',
            confirmButtonText: 'OK',
          })
      }

    }
    //To upload files
    uploadFile(workorder) {
     
     localStorage.setItem('workOrderID',workorder.Workorder);
     console.log("ishowheader",this.isShowHeader);
     if(this.isShowHeader)
     {
        localStorage.setItem('uploadButtonEnablement',"1");
        const dialogRef = this.dialog.open(UploadDocumentsComponent,{height:'70%',width:'60%'});
     }
     else
     {
     const dialogRef = this.dialog.open(UploadDialogComponent);
     }
         
     }
     
     //To view files
    viewFiles(workorder){

    localStorage.setItem('workOrderID',workorder.Workorder);
    const dialogRef = this.dialog.open(ViewDialogComponent,
      
      {
        data: {
            workOrderID: workorder.Workorder,
            
        }, 
    }

      );
     
    }

}
