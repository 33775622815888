<!-- <mat-dialog-title>Description</mat-dialog-title> -->
<mat-dialog-actions align="end" style="padding:0.5px; border:0.5px; margin:-20px">
	<button mat-button [mat-dialog-close]="true">
	<mat-icon>close</mat-icon>
  </button>		
</mat-dialog-actions><br>
	 <mat-card>
		<div align="left" >
			<mat-card-content class="contenttext" [innerHTML]="message" style="font-size:medium;"></mat-card-content> 	
			<table>				
				<tr class="tbrow" *ngFor="let item of data.image1; index as i">
				  <td class="tbdata">
					<img mat-card-md-image [src]=item.IMAGE_CONTENT [title]=item.IMAGE_TITLE  >					
				<!-- <figcaption>{{item.IMAGE_TITLE}}</figcaption> -->
				  </td>
				  <td style="text-align: center;" class="tbdata">
						{{item.IMAGE_TITLE}}					
				  </td>				 
				</tr>
			  </table>				
		</div>
	</mat-card> 

