<span *ngIf="!smallScreen">
<app-enrollment-header></app-enrollment-header>

<div class="intro-text">{{enrollments}}</div>

  
  <mat-accordion class="mat-table" style="table-layout: fixed;width:100% !important;" multi>
    <br>
    <mat-expansion-panel class="enrollmentPanel enroll-card-div" [expanded]="true" *ngFor="let wo of appointments" style="margin: 0 auto; min-height: 100px;
    width: 70%;">
        <mat-expansion-panel-header class="mat-row">
            <mat-panel-title class="enroll-title">
                <span class="mat-cell program-desc-name" style="font-size:1vw;text-align: left">Applications started on&nbsp; {{wo.StartDate | date:'EEEE'}}&nbsp;{{wo.StartDate |  date:'longDate'}}</span>
            </mat-panel-title>

        </mat-expansion-panel-header>
        <table *ngIf="wo.Workorders !== undefined && wo.Workorders.length > 0"
            style="width: 100% !important; margin-top: 1.2vw !important;" mat-table
            [dataSource]="wo.Workorders" class="mat-elevation-z8">
            <ng-container matColumnDef="Name">
                <th mat-header-cell *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Workorder ID</th>
                <td style="font-size:0.8vw;text-align: left;width:20%" mat-cell *matCellDef="let workorder">
                    {{workorder.Workorder}}
                </td>
            </ng-container>
            <ng-container matColumnDef="Description">
                <th mat-header-cell *matHeaderCellDef style="text-align:left;font-size: 0.8vw">Type</th>
                <td style="font-size:0.8vw;text-align: left;width:30%" mat-cell *matCellDef="let workorder">
                    {{workorder.ProgramdetailDesc}}
                </td>
            </ng-container>
            
            <ng-container matColumnDef="Status">
                <th mat-header-cell *matHeaderCellDef style="text-align: left;font-size: 0.8vw">Status</th>
                <td style="font-size:0.8vw;text-align: left;width:20%" mat-cell *matCellDef="let workorder"> 
                    <!-- check with kumudha on logic -->
                    <span class="show-pointer" *ngIf="workorder.StatusCd >=190 && workorder.StatusCd<=200 && workorder.SelfSchedule != 0" (click)="gotoAppointment(workorder)"><u>{{workorder.StatusDesc}}</u></span>
                    <span *ngIf="workorder.StatusCd <190 || workorder.StatusCd>200">{{workorder.StatusDesc}}</span>
                        <span class="show-pointer" *ngIf="workorder.StatusCd >=190 && workorder.StatusCd<=200 && (workorder.SelfSchedule==0 || workorder.SelfSchedule==null)">{{workorder.StatusDesc}}</span>                    
                    &nbsp;
                </td>
            </ng-container>

            
            <ng-container matColumnDef="Upload">
                              
                <th mat-header-cell *matHeaderCellDef style="text-align: left;font-size: 0.8vw">
              
                    <span *ngIf="isUploadDoc">Upload Docs</span></th>
              
                <td style="font-size:0.8vw;text-align: left;width:15%" mat-cell *matCellDef="let workorder">
                    <span *ngIf="isUploadDoc">
                    <span *ngIf="workorder.StatusDesc != 'Ready to Schedule' || 'New' " >
                        <span *ngIf="workorder.UploadViewDoc">
                        <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                        <button  mat-button matTooltip="Upload"   [disabled]="workorder.StatusCd >= 400"  mat-button (click)="uploadFile(workorder)"><mat-icon>publishrounded</mat-icon></button>   
                        </span>
                     <span *ngIf!="workorder.UploadViewDoc">
                        <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                       <button  mat-button matTooltip="Upload"   [disabled]="workorder.StatusCd >= 400"  mat-button (click)="uploadFile(workorder)"><mat-icon>publishrounded</mat-icon></button>   
                     </span>
                    </span> 
                </span>
                </td>   
               
            </ng-container>
           
            <ng-container matColumnDef="View">
                
                <th mat-header-cell *matHeaderCellDef style="text-align: left;font-size: 0.8vw"><span *ngIf="isViewDoc">View Docs</span></th>
                <td style="font-size:0.8vw;text-align: left;width:15%" mat-cell *matCellDef="let workorder">
                    <span *ngIf="isViewDoc">
                    <span *ngIf="workorder.StatusDesc != 'Ready to Schedule' || 'New' " >
                        <span *ngIf="workorder.UploadViewDoc">
                            <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                        <button  mat-button matTooltip="View"   (click)="viewFiles(workorder)" ><i class="material-icons">visibility</i></button>
                        </span>
                        <span *ngIf!="workorder.UploadViewDoc">
                            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                            <button  mat-button matTooltip="View"   (click)="viewFiles(workorder)" ><i class="material-icons">visibility</i></button>
                        </span>
                    </span>
                </span>
                </td>
            </ng-container>
           
           <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns5;" ></tr> 
           
        </table>
        
        <div *ngIf="wo.Workorders === undefined || wo.Workorders.length < 1" style=" vertical-align: middle;
    text-align: center;
    margin: 0 auto;
    margin-top: 2vw;">No workorders for the current user</div>

    </mat-expansion-panel>

</mat-accordion>
<br>
<br>
<div style="text-align:center" *ngIf="enableDone">
<button mat-raised-button class="mainbutton" style="text-align:center;" routerLink="/main">Done</button>
</div>

<app-enrollment-footer></app-enrollment-footer>

</span>
<!-- Mobile screen-->
<span *ngIf="smallScreen" class="mradio">
    
    <app-enrollment-header></app-enrollment-header>

    <div class="intro-text">{{enrollments}}</div>
    
      
      <mat-accordion class="mat-table" style="table-layout: fixed;width:100% !important;" multi>
        <br>
        <mat-expansion-panel class="enrollmentPanel enroll-card-div" [expanded]="true" *ngFor="let wo of appointments" style="margin: 0 auto; min-height: 100px;
        width: 70%;">
            <mat-expansion-panel-header class="mat-row">
                <mat-panel-title class="enroll-title">
                    <span class="mat-cell program-desc-name" style="font-size:2vw;text-align: left">Applications started on&nbsp; {{wo.StartDate | date:'EEEE'}}&nbsp;{{wo.StartDate |  date:'longDate'}}</span>
                </mat-panel-title>
    
            </mat-expansion-panel-header>
            <table *ngIf="wo.Workorders !== undefined && wo.Workorders.length > 0"
                style="width: 100% !important; margin-top: 1.2vw !important;" mat-table
                [dataSource]="wo.Workorders" class="mat-elevation-z8">
                <ng-container matColumnDef="Name">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left;font-size: 1.8vw">Workorder ID</th>
                    <td style="font-size:1.8vw;text-align: left;width:30%" mat-cell *matCellDef="let workorder">
                        {{workorder.Workorder}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="Description">
                    <th mat-header-cell *matHeaderCellDef style="text-align:left;font-size: 1.8vw">&nbsp;&nbsp;Type</th>
                    <td style="font-size:1.8vw;text-align: left;width:30%" mat-cell *matCellDef="let workorder">
                        &nbsp;&nbsp;{{workorder.ProgramdetailDesc}}
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="Status">
                    <th mat-header-cell *matHeaderCellDef style="text-align: left;font-size: 1.8vw">Status</th>
                    <td style="font-size:1.8vw;text-align: left;width:30%" mat-cell *matCellDef="let workorder"> 
                        
                        <span class="show-pointer" *ngIf="workorder.StatusCd >=190 && workorder.StatusCd<=200 && workorder.SelfSchedule != 0" (click)="gotoAppointment(workorder)"><u>{{workorder.StatusDesc}}</u></span>
                        <span *ngIf="workorder.StatusCd <190 || workorder.StatusCd>200">{{workorder.StatusDesc}}</span>
                            <span class="show-pointer" *ngIf="workorder.StatusCd >=190 && workorder.StatusCd<=200 && (workorder.SelfSchedule==0 || workorder.SelfSchedule==null)">{{workorder.StatusDesc}}</span>                    
                        &nbsp;
                    </td>
                </ng-container>
    
                
                <ng-container matColumnDef="Upload">
                                  
                    <th mat-header-cell *matHeaderCellDef style="text-align: left;font-size: 1.8vw">
                  
                        <span *ngIf="isUploadDoc">Upload Docs</span></th>
                  
                    <td style="font-size:1.8vw;text-align: left;width:15%" mat-cell *matCellDef="let workorder">
                        <span *ngIf="isUploadDoc">
                        <span *ngIf="workorder.StatusDesc != 'Ready to Schedule' || 'New' " >
                            <span *ngIf="workorder.UploadViewDoc">
                            <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                            <button  mat-button matTooltip="Upload"   [disabled]="workorder.StatusCd >= 400"  mat-button (click)="uploadFile(workorder)"><mat-icon>publishrounded</mat-icon></button>   
                            </span>
                         <span *ngIf!="workorder.UploadViewDoc">
                            <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                           <button  mat-button matTooltip="Upload"   [disabled]="workorder.StatusCd >= 400"  mat-button (click)="uploadFile(workorder)"><mat-icon>publishrounded</mat-icon></button>   
                         </span>
                        </span> 
                    </span>
                    </td>   
                   
                </ng-container>
               
                <ng-container matColumnDef="View">
                    
                    <th mat-header-cell *matHeaderCellDef style="text-align: left;font-size: 1.8vw"><span *ngIf="isViewDoc">View Docs</span></th>
                    <td style="font-size:1.8vw;text-align: left;width:15%" mat-cell *matCellDef="let workorder">
                        <span *ngIf="isViewDoc">
                        <span *ngIf="workorder.StatusDesc != 'Ready to Schedule' || 'New' " >
                            <span *ngIf="workorder.UploadViewDoc">
                                <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                            <button  mat-button matTooltip="View"   (click)="viewFiles(workorder)" ><i class="material-icons">visibility</i></button>
                            </span>
                            <span *ngIf!="workorder.UploadViewDoc">
                                <tr mat-header-row *matHeaderRowDef="displayedColumns3"></tr>
                                <button  mat-button matTooltip="View"   (click)="viewFiles(workorder)" ><i class="material-icons">visibility</i></button>
                            </span>
                        </span>
                    </span>
                    </td>
                </ng-container>
               
               <tr mat-header-row *matHeaderRowDef="displayedColumns5"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns5;" ></tr> 
               
            </table>
            
            <div *ngIf="wo.Workorders === undefined || wo.Workorders.length < 1" style=" vertical-align: middle;
        text-align: center;
        margin: 0 auto;
        margin-top: 2vw;">No workorders for the current user</div>
    
        </mat-expansion-panel>
    
    </mat-accordion>
    <br>
    <br>
    <div style="text-align:center" *ngIf="enableDone">
    <button mat-raised-button class="mainbutton" style="text-align:center;" routerLink="/main">Done</button>
    </div>
    
    <app-enrollment-footer></app-enrollment-footer>
    </span>
