/*
 * Honeywell Enrollment Portal
 * Database configurable portal for utility customers 
 * to enroll in energy savings programs.
 * 
 * Copyright (C) 2021 - 2023 Honeywell
 * All rights reserved.
 * 
 * This source code is the confidential property of Honeywell.
 * 
 */

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/services/data-transfer.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UtilityService } from '../../services/utility.service';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
    
    constructor(
        private utilityService: UtilityService,
        private router: Router,
        private activeRoute: ActivatedRoute,public dataTransfer:DataTransferService,private spinner: NgxSpinnerService
    ) 
    { 
       //this.spinner.show();
    }
    showLoading:any = true;
    
    ngOnInit(): void {
       //this.spinner.show();
        this.dataTransfer.myMethod(false);

        this.activeRoute.queryParams.subscribe(params => {
            
            var util = '';
            util = params.util ||  localStorage.getItem('currentUtility');
            if(params.util && params.util != localStorage.getItem('currentUtility')){
                util = params.util
                sessionStorage.removeItem('getAccountNumber')
            }
            this.showLoading = true;
            if(util) {
                this.utilityService.    loadUtilitySettings(util).then(res => {
                    this.showLoading = false;
                });
            } else {
                localStorage.removeItem('ProgramId');
                localStorage.removeItem('currentUtility');
                localStorage.removeItem('providerName');
                localStorage.removeItem('personcompanyid');
                this.router.navigate(['/utilityerror']);
            }

        }
        );
    }

}
